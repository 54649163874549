import React, {useRef, useState} from "react";
import {BiSkipNext, BiSkipPrevious} from 'react-icons/bi'
import {
    UploadFileBtn,
    FileUploadContainer,
    FormField,
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    FileMetaData,

} from "../Styles/UploadStyle";
import {TiDelete} from "react-icons/ti";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 3145728;
// const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5242880;

const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
                        imageLimit,
                        label,
                        updateFilesCb,
                        maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
                        ...otherProps
                    }) => {
    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});
    const [imageCount, setImageCount] = useState(0);
    const [disableButton, setDisableButton] = useState(false);
    const [error, setError] = useState(null);
    const [photos, setPhotos] = useState([]);

    // const size = useWindowSize();

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };
    const doNothing = () => {
    };


    const addNewFiles = (newFiles) => {
        for (let file of newFiles) {
            if (file.size <= maxFileSizeInBytes) {

                if (!otherProps.multiple) {
                    return {file};
                }
                files[file.name] = file;
            } else {
                return false
            }
        }
        return {...files};
    };

    const callUpdateFilesCb = (files) => {
        const filesAsArray = convertNestedObjectToArray(files);
        setImageCount(filesAsArray.length)
        if (filesAsArray.length >= imageLimit) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
        updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = (e) => {
        const filesAsArray = convertNestedObjectToArray(files);
        const {files: newFiles} = e.target;

        if (newFiles.length && (filesAsArray.length + newFiles.length) <= imageLimit) {
            var updatedFiles = addNewFiles(newFiles);
            if (updatedFiles === false) {
                handleFileSizeLimit()
            } else {
                setFiles(updatedFiles);
                callUpdateFilesCb(updatedFiles);
                setError(null)
            }

        } else {
            setError(`Maksimum resim yükleme limitini aştınız! Limitiniz ${imageLimit} adettir.`)
            setTimeout(() => {
                setError(null)
            }, 5000);

        }

        const handleNewFileUpload = async (e) => {
            const filesAsArray = convertNestedObjectToArray(files);
            const { files: newFiles } = e.target;

            if (newFiles.length && filesAsArray.length + newFiles.length <= imageLimit) {
                var updatedFiles = addNewFiles(newFiles);
                if (updatedFiles === false) {
                    handleFileSizeLimit();
                } else {
                    setFiles(updatedFiles);
                    callUpdateFilesCb(updatedFiles);
                    setError(null);
                }
            } else {
                setError(
                    `Maksimum resim yükleme limitini aştınız! Limitiniz ${imageLimit} adettir.`
                );
                setTimeout(() => {
                    setError(null);
                }, 5000);
            }

            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: false,
                    audio: false,
                    mediaSource: "screen",
                });
                const track = stream.getTracks()[0];
                const imageCapture = new ImageCapture(track);
                const photoBlob = await imageCapture.takePhoto();
                const photoUrl = URL.createObjectURL(photoBlob);
                setPhotos([...photos, photoUrl]);
            } catch (err) {
                console.error(err);
            }
        };
    };

    function handleFileSizeLimit() {
        setError(`Dosya boyutu 3MB altında olmadılıdır.`)
        setTimeout(() => {
            setError(null)
        }, 5000);
    }

    const removeFile = (fileName) => {
        delete files[fileName];
        setFiles({...files});
        callUpdateFilesCb({...files});
    };


    function objectToArray() {
        var array = Object.keys(files)
            .map(function (key) {
                return files[key];
            });
        return array
    }

    function moveArrayItemToNewIndex(old_index, new_index) {
        let arr = objectToArray();
        let element = arr[old_index];
        arr.splice(old_index, 1);
        arr.splice(new_index, 0, element);
        setFiles({...arr});
        callUpdateFilesCb({...arr})

        return arr;
    };

    return (
        <>
            <FileUploadContainer>
                {!disableButton
                    ? (<UploadFileBtn className="rounded-0" type="button"
                                      onClick={handleUploadBtnClick}>
                        <i className="fas fa-file-upload"/>
                        <span>Fotoğraf Yükleyin</span>
                    </UploadFileBtn>)
                    : (<UploadFileBtn disabled className="rounded-0" type="button"
                                      onClick={doNothing}>
                        <i className="fas fa-file-upload"/>
                        <span>Fotoğraf Yükleyin</span>
                    </UploadFileBtn>)}
                {!disableButton ?
                    (<FormField
                        type="file"
                        ref={fileInputField}
                        onChange={handleNewFileUpload}
                        title=""
                        value=""
                        {...otherProps}
                    />)
                    :
                    (<FormField
                        // type="file"
                        // ref={fileInputField}
                        onChange={doNothing}
                        title=""
                        value=""
                        {...otherProps}
                    />)}
            </FileUploadContainer>
            {error && (<h6>{error}</h6>)}
            <FilePreviewContainer>
                <PreviewList>
                    {Object.keys(files).map((fileName, index) => {
                        let file = files[fileName];
                        let isImageFile = file.type.split("/")[0] === "image";
                        let arrayLength = objectToArray().length;

                        return (
                            <PreviewContainer key={fileName}>
                                <div>
                                    {isImageFile && (
                                        <ImagePreview
                                            src={URL.createObjectURL(file)}
                                            alt={`file preview ${index}`}
                                        />
                                    )}
                                    <FileMetaData isImageFile={isImageFile}>
                                        <span>{file.name}</span>
                                        <aside>
                                            {/*<span>{convertBytesToKB(file.size)} kb</span>*/}
                                            {index !== 0 && (<button
                                                // onClick={() => removeFile(fileName)}
                                                style={styles.delete}
                                                onClick={() => {
                                                    moveArrayItemToNewIndex(index, parseInt(index - 1))
                                                }}
                                                type="reset">
                                                <BiSkipPrevious style={{fontSize: '32px', color: 'blue'}}/>
                                            </button>)}
                                            <button
                                                onClick={() => removeFile(fileName)}
                                                style={styles.delete}
                                                type="reset">
                                                <TiDelete style={{fontSize: '32px', color: 'red'}}/>
                                            </button>
                                            {index !== arrayLength - 1 && (<button
                                                onClick={() => {
                                                    moveArrayItemToNewIndex(index, parseInt(index + 1))
                                                }}
                                                style={styles.delete}
                                                type="reset">
                                                <BiSkipNext style={{fontSize: '32px', color: 'blue'}}/>
                                            </button>)}
                                            {/*<RemoveFileIcon*/}
                                            {/*    className="fas fa-trash-alt"*/}
                                            {/*    onClick={() => removeFile(fileName)}*/}
                                            {/*/>*/}
                                        </aside>
                                    </FileMetaData>
                                </div>
                            </PreviewContainer>
                        );
                    })}
                </PreviewList>
            </FilePreviewContainer>
        </>
    );
};

export default FileUpload;


const styles = {
    container: {
        width: '100%',
        height: '100%',
        // display:'inline-block'
    },
    preview: {
        marginTop: 50,
        // display: "flex",
        // flexDirection: "column",
        width: '130px',
        // height: '140px',
        margin: 10
    },
    image: {maxWidth: "100%", maxHeight: "100%"},
    delete: {
        cursor: "pointer",
        // padding: 10,
        // background: "red",
        // color: "white",
        border: "none",
    },
};
