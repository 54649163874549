import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import SmallSearch from "../Search/Filters/SmallSearch";
import NavLogo from "./NavLogo";
import {Navbar, Container, Nav} from "react-bootstrap";
import {useWindowSize} from "../../static/helpers";
import {ImCross} from 'react-icons/im'
import {FiSearch} from 'react-icons/fi'
import BottomNavBar from "./BottomNavBar";
import {BiArrowBack} from "react-icons/bi";

const NavbarOut = ({navLogo}) => {
    const [showSearch, setShowSearch] = useState(false)
    const navigate = useNavigate();
    const getSize = useWindowSize();

    function goBack() {
        navigate(-1);
    }

    async function GoHome() {
        navigate("/");
    }


    function handleSearchBar() {
        if (showSearch === true) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }

    const menuStyle = {
        alignItems: "flex-end",
        float: "left",
        flexWrap: 'nowrap',
        flexDirection: 'row',
        marginTop: '0.55rem',
        height: '40px',
        maxWidth: '100%',
        fontWeight: 600
    }

    const showSearchStyle = {
        marginTop: '6%',
        color: "black"
    }

    const hideSearchStyle = {
        display: 'none'
    }

    const hrStyle = {
        backgroundColor: "#000000",
        height: 0.5,
        borderColor: "#000000",
    }

    const navbar_element = {
        fontSize: "0.95em",
        textTransform: "capitalize",
        margin: "5px",
        color: "#b6bab7",
        fontFamily: "'Josefin Sans', sans-serif",
        letterSpacing: "0px"
    }

    return (
        <div>
            <Navbar style={{marginTop: '1rem'}} className="navbar navbar-expand-md navbar-dark bg-white ">
                <Container fluid>
                    {navLogo
                        ? getSize?.width < 680 && (
                        <div
                            className="m-3"
                            style={{
                                maxWidth: '50px',
                                maxHeight: '70px',
                                left: '-3%',
                                marginTop: '30px !important'
                            }}
                            onClick={goBack}>
                            <BiArrowBack size={32} style={{paddingRight: '10px'}}/>
                        </div>
                    )
                        : (
                            <div
                                className={`d-flex row`}
                                style={{flexDirection: 'row', flexWrap: 'nowrap'}}
                            >
                                {getSize?.width < 680 && (
                                    <div
                                        className="m-3"
                                        style={{
                                            maxWidth: '50px',
                                            maxHeight: '70px',
                                            left: '-3%',
                                            marginTop: '30px !important'
                                        }}
                                        onClick={goBack}>
                                        <BiArrowBack size={32} style={{paddingRight: '10px'}}/>
                                    </div>
                                )}
                                <Link className="col-md-4"
                                      to="/"
                                      onClick={() => {
                                          GoHome()
                                      }}
                                      style={showSearch === false ? showSearchStyle : hideSearchStyle}
                                >
                                    <NavLogo/>
                                </Link>

                                {getSize?.width > 680
                                    ? (
                                        <div className="col-md-8">
                                            <SmallSearch/>
                                        </div>
                                    )
                                    : (
                                        <>
                                            <Link
                                                id="search_button_small"
                                                style={{
                                                    top: '45%',
                                                    width: '45px',
                                                    textDecoration: 'none',
                                                    position: 'absolute',
                                                }}
                                                to="#"
                                                onClick={() => {
                                                    handleSearchBar()
                                                }}
                                            >

                                                {showSearch === true && getSize?.width < 680
                                                    ? <ImCross style={{position: 'absolute'}} className="fa-2x"/>
                                                    : <FiSearch style={{position: 'absolute'}} className="fa-2x"/>
                                                }
                                            </Link>
                                            <Link
                                                id="search_button_small"
                                                style={{
                                                    top: '45%',
                                                    width: '45px',
                                                    textDecoration: 'none',
                                                    position: 'absolute',
                                                }}
                                                to="#"
                                                onClick={() => {
                                                    handleSearchBar()
                                                }}
                                            >

                                                {showSearch === true && getSize?.width < 680
                                                    ? <ImCross style={{position: 'absolute'}} className="fa-2x"/>
                                                    : <FiSearch style={{position: 'absolute'}} className="fa-2x"/>
                                                }
                                            </Link>
                                            <div
                                                style={{
                                                    margin: '0 auto',
                                                    width: '100%',
                                                    position: 'absolute',
                                                    zIndex: '9999'

                                                }}
                                            >
                                                <BottomNavBar/>
                                            </div>
                                        </>

                                    )}
                                {showSearch && (<div style={{marginTop: '1rem', width: '40%'}}>
                                    <SmallSearch/>
                                </div>)}
                            </div>
                        )
                    }

                    {navLogo ?? getSize?.width > 680
                        ? (
                            <div style={menuStyle}>
                                <Navbar.Toggle aria-controls="navbarScroll"/>
                                <Navbar.Collapse id="navbarScroll">
                                    {getSize?.width > 680
                                        ? (
                                            <Nav
                                                className="me-auto my-2 my-lg-0"
                                                style={{maxHeight: "150px"}}
                                            >
                                                <Nav.Link href="/#/Messages" className="d-flex">
                                                </Nav.Link>
                                                <Nav.Link href="/#/giris-yap">
                                                    <div className="navbar-element-wrapper">
                                                        <div className="navbar-element">
                                                            GİRİŞ YAP
                                                            <div style={hrStyle}></div>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                                <Nav.Link href="/#/uye-ol">
                                                    <div className="navbar-element-wrapper">
                                                        <div className="navbar-element">
                                                            ÜYE OL
                                                            <div style={hrStyle}></div>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                                <Nav.Link href="/#/ilan-olustur">
                                                    <div className="navbar-element-wrapper">
                                                        <div className="navbar-element">
                                                            ÜCRETSİZ İLAN VER
                                                            <div style={hrStyle}></div>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav>
                                        )
                                        : (<Nav
                                            className="m-auto"
                                            style={{maxHeight: "150px"}}
                                            // navbarScroll
                                        >
                                            <Nav.Link href="/#/Messages" className="d-flex">
                                            </Nav.Link>
                                            <Nav.Link href="/#/giris-yap" style={{fontSize: '0.8em'}}>
                                                GİRİŞ YAP
                                            </Nav.Link>
                                            <Nav.Link href="/#/uye-ol" style={{fontSize: '0.8em'}}>
                                                ÜYE OL
                                            </Nav.Link>
                                            <Nav.Link href="/#/ilan-olustur" style={{fontSize:'0.8em'}}>
                                                ÜCRETSİZ İLAN VER
                                            </Nav.Link>
                                        </Nav>)}

                                </Navbar.Collapse>
                                <BottomNavBar/>
                            </div>
                        )
                        : (
                            <>

                            </>
                        )
                    }

                </Container>
            </Navbar>
        </div>
    );
};

export default NavbarOut;