import React, {useContext, useEffect, useState} from "react";
import FirstMessage from "../Components/Messages/StartConvestation";
import {useParams} from "react-router-dom";
import Navbar from "../Components/Layout/Navbar";
import Cookies from "universal-cookie";
import {protectedEnpointGetRequest} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";

const StartConversation = () => {
    const {baseURL} = useContext(BaseURLContext);
    const {id, type, username} = useParams();
    const [currentUser, setCurrentUser] = useState('')
    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function getUser() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/UserManage/GetUser`,
            cookie
        );
        if (response.status === 200 || response.status === 201) {
            setCurrentUser(response.data.username)
        }
    }

    return (
        <div>
            <Navbar/>
            {currentUser &&
                (
                    <FirstMessage currentUser={currentUser} productId={id} type={type} adOwnerUsername={username}/>
                )
            }

        </div>
    );
}

export default StartConversation;
