import React, {useEffect,useContext, useState} from "react";
import Navbar from "../Components/Layout/Navbar";
import {Link} from "react-router-dom";
import {setAdTitleStringLimit} from "../static/helpers";
import { defaultGetRequestWithParams} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import NoImage from "../img/no-image.png";

const ShowcaseAds = () => {
    const {baseURL} = useContext(BaseURLContext);

    const [showcaseAds, setShowcaseAds] = useState([]);
    async function getShowcaseAds() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Showcase/GetShowcaseAds`,
            {
                params: {
                    estateCount: 20,
                    carCount: 20
                }
            }
        );
        if (response) {
            setShowcaseAds(response.data);
            return null;
        }
    }
    useEffect(() => {
        getShowcaseAds()
        //eslint-disable-next-line
    },[])
    return (
        <div>
            <div>
                <Navbar/>
            </div>
            <div className="category-container">
                <div className="row">
                    <div className="row mt-5" style={{background: 'lightyellow'}}>
                        <h4>Vitrin</h4>
                    </div>
                    <h6>Oncelikli ilanlar</h6>
                    <div className="col-md-11 p-1">
                        <div style={{marginTop: '1rem'}} className="row image-row">
                            {showcaseAds.map((item, i) => {
                                return (
                                    <div key={item.id} className="image-column">
                                        <Link to={item.type === 0 ? `/emlak/${item.id}` : `/vasita/${item.id}`}>
                                            <div className="image-content">
                                                {item?.smallImage != null
                                                    ? (<img style={{width: '100%'}}
                                                            src={`${item.smallImage}`}
                                                            alt="test"/>)
                                                    : (<div>
                                                        <img src={NoImage} alt={item.id}/>
                                                    </div>)}
                                                <p>{setAdTitleStringLimit(item.name)}</p>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShowcaseAds;