import React, {useState, useEffect, useRef, useContext, createRef} from 'react';
import {HubConnectionBuilder} from '@microsoft/signalr';
import {protectedEnpointGetRequest, protectedEnpointPostRequest} from "../../static/functions";
import Cookies from "universal-cookie";
import * as signalR from "@microsoft/signalr";
import {BaseURLContext} from "../../baseURL-context";
import moment from "moment";
import 'moment/locale/tr';

const ChatFinal = ({currentUser}) => {
    const {baseURL} = useContext(BaseURLContext);
    // const [chat, setChat] = useState([]);


    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")
    const [connection, setConnection] = useState(null);
    const [message, setMessage] = useState('');
    const [to, setTo] = useState('');
    const [lastMessages, setLastMessages] = useState([]);
    const [from, setFrom] = useState([])
    const [messages, setMessages] = useState([]);
    const [sender, setSender] = useState([])
    const latestChat = useRef([React.createRef(), React.createRef()]);

    latestChat.current = messages;

    // const elementsRef = useRef(messages.map(() => createRef()));
    const messageRef = useRef(messages.map(() => createRef()));
    // const executeScroll = async (index) => messageRef.current[index].scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'center',
    //     inline: 'center',
    // })

    const handleScroll = () => {

        const element = document.getElementById(`${to}-${messages.length}`)
        setTimeout(() => {
            window.scrollTo({
                behavior: element ? "smooth" : "auto",
                top: element ? element.offsetParent : 0
            });
        }, 100);
    };

    const onButtonClick = () => {
        // `current` points to the mounted text input element
        latestChat.current.focus();
    };

    const handleMessageChange = e => {
        setMessage(e.target.value);

    };

    const handleSend = e => {

        setMessages((elem) => [...elem, {
            from: currentUser,
            messageText: message,
            to: to,
            productId: 3,
            productType: ','
        }])
    }

    const handleUserChange = (user) => {
        // console.log("userc"+e.target.value)
        setTo(user);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        handleSend(e)
        sendMessage(to, message);
    }

    useEffect(() => {
        const options = {
            accessTokenFactory: () => cookie,
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "https://hepsiotoemlak.com:4242",
            "Access-Control-Allow-Credentials": true,
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        };
        const connection = new HubConnectionBuilder()
            .withUrl('https://hepsiotoemlak.com:4242/hubs/chat',
                options
            )
            .withAutomaticReconnect()
            .build();
        setConnection(connection)
        connection.start()
            .then(result => {
                console.log('Connected!');
                connection.on('ReceiveMessage'
                    , message => {
                        const updatedChat = [...latestChat.current];
                        setMessages(updatedChat);
                        onButtonClick()
                    });
            })
            .catch(e => console.log('Connection failed: ', e));
        getMessages();
    }, [connection]);

    const sendMessage = async () => {
        const chatMessage = {
            from: currentUser,
            messageText: message,
            to: to,
            productId: 3,
            productType: ''
            // ,
            // sentOn:
        };


        if (connection) {
            try {
                await connection.send('SendChatMessage', chatMessage);
                setMessage('')
            } catch (e) {
                console.log(e);
            }
        } else {
            alert('No connection to server yet.');
        }
    }

    async function getMessages() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Message/GetContactUsers`,
            cookie
        );
        if (response.status === 200 || response.status === 201) {
            setFrom(response.data.users)
            setMessages(response.data.messages);
            setLastMessages(response.data.lastMessage)
        } else {
            console.log("error on getContacts")
        }
    }

    async function readMessages(selected) {
        const model = {
            to: selected
        }
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Message/ReadMessages`,
            model,
            cookie
        );
        if (response.status === 200 || response.status === 201) {
            console.log('successfully read');
            // executeScroll()
        } else {
            console.log("error on getContacts")
        }
    }

    return (
        <div>
            <div className="container-message">
                <h3 className=" text-center">Mesajlar</h3>
                <div className="messaging">
                    <div className="inbox_msg">
                        <div className="inbox_people">
                            <div className="headind_srch">
                                <div className="recent_heading m-2 " style={{minWidth: '160px'}}>
                                    <h4>Son Konuşmalar</h4>
                                </div>
                            </div>
                            <div className="inbox_chat">
                                {lastMessages && from && from.map((user, i) => {
                                    return (
                                        <div key={i} className="chat_list active_chat">
                                            <div className="chat_people">
                                                <div onClick={(e) => {
                                                    setSender(user);
                                                    handleUserChange(user);
                                                    readMessages(user)
                                                    // handleScroll(messageRef)
                                                    // executeScroll(messages.length -1)
                                                }}
                                                     className="chat_ib"
                                                     style={{cursor: 'pointer'}}
                                                >
                                                    <h5>
                                                        {user}
                                                    </h5>
                                                    <p>
                                                        {lastMessages[user]?.messageText || lastMessages[currentUser]?.messageText}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="mesgs">
                            <form
                                onSubmit={onSubmit}>
                                <div className="msg_history">
                                    <div className="incoming_msg">

                                        {messages && messages.map((mess, i) => {
                                            return (
                                                <div key={i}
                                                    // ref={latestChat}
                                                     className={mess.from}>
                                                    {mess.from === sender && mess.to === currentUser
                                                        ? (<div className="received_msg">
                                                            <div className="received_withd_msg">
                                                                <p>{mess.messageText}</p>
                                                                <span className="time_date">
                                                                    {moment(mess.createdOn).locale('tr').format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                                                </span>
                                                            </div>
                                                        </div>)
                                                        :
                                                        mess.from === currentUser && mess.to === sender ? (
                                                            <div className="outgoing_msg">
                                                                <div className="sent_msg">
                                                                    <p
                                                                        id={`${to}-${messages.length}`}
                                                                    >{mess.messageText}</p>
                                                                    <span
                                                                        className="time_date">
                                                                        {moment(mess.createdOn).locale('tr').format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                                                    </span>{" "}
                                                                </div>
                                                            </div>) : (<span></span>)}
                                                </div>
                                            )
                                        })}
                                        {/*<span ref={messageRef}></span>*/}
                                    </div>
                                </div>
                                <div className="type_msg">
                                    <div className="input_msg_write">
                                        <input
                                            type="text"
                                            style={{textIndent: '1rem'}}
                                            id="message"
                                            name="message"
                                            value={message}
                                            onChange={handleMessageChange}
                                        />
                                        <button className="msg_send_btn" style={{marginRight: '5px'}}>
                                            <i className="fa fa-paper-plane-o" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatFinal;
