import React from "react";

const Building = ({
                      // roomCount,
                      // floorCount,
                      // buildingAge,
                      // // setRoomCount,
                      setFloorCount,
                      setBuildingAge,
                      setFlatCountPerFloor,
                      buildingAge,
                      floorCount,
                      flatCountPerFloor
                  }) => {

    return (
        <div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="floorcount" className="float-start">
                        <strong>Kat Sayısı</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="floorcount"
                        className="form-control"
                        id="floorcount"
                        value={floorCount}
                        // value={floorCount || 0}
                        placeholder="Kat Sayısı"
                        onChange={(e) => {
                            setFloorCount(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="flatcountperfloor" className="float-start">
                        <strong>Bir Kattaki Daire Sayısı</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="flatcountperfloor"
                        className="form-control"
                        id="flatcountperfloor"
                        value={flatCountPerFloor}
                        placeholder="Bir Kattaki Daire Sayısı"
                        // value={roomCount || ""}
                        onChange={(e) => {
                            setFlatCountPerFloor(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="buildingage" className="float-start">
                        <strong>Bina Yaşı</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="buildingage"
                        className="form-control"
                        id="buildingage"
                        value={buildingAge}
                        placeholder="Bina Yaşı"
                        // value={values. || ""}
                        onChange={(e) => {
                            setBuildingAge(e.target.value)
                        }}
                    />
                </div>
            </div>

        </div>
    );
}

export default Building;
