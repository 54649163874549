import React from "react";

const HotelView = ({
                       hrStyle,
                       ClosedAreaM2,
                       StarCount,
                       RoomCount,
                       BedCount,
                       FloorCount,
                       GroundSurvey
                   }) => {
    return (
        <div>
            <div>
                <div
                    className="price-detail-wrap row"
                >
                    <label className="col-md-4" style={{color: "black"}}>
                        <strong>Kapalı Alan (m2)</strong>
                    </label>

                    <span className="col-md-8 ad-detail">{ClosedAreaM2}</span>
                </div>
                <hr
                    style={hrStyle}
                />
            </div>
            <div>
                <div
                    className="price-detail-wrap row"
                >
                    <label className="col-md-4" style={{color: "black"}}>
                        <strong>Yıldız Sayısı</strong>
                    </label>

                    <span className="col-md-8 ad-detail">{StarCount}</span>
                </div>
                <hr
                    style={hrStyle}
                />
            </div>
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Oda Sayısı</strong>
                </label>

                <span className="col-md-8 ad-detail">{RoomCount}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Yatak Sayısı</strong>
                </label>

                <span className="col-md-8 ad-detail">{BedCount}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Kat Sayısı</strong>
                </label>
                <span className="col-md-8 ad-detail">{FloorCount}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Zemin Etüdü</strong>
                </label>

                <span className="col-md-8 ad-detail">{GroundSurvey}</span>
            </div>
        </div>
    );
}

export default HotelView;
