import React  from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/NoAuthComponents/Footer";
import Navbar from "../Components/Layout/Navbar";

const Estates = () => {
  return (
    <div>
      <Navbar />
      <div style={{ padding: 20 }}>
        <Outlet />
      </div>
    </div>
  );
};

export default Estates;
