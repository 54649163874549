import React, {useEffect, useState} from "react";
import Navbar from '../../../Layout/Navbar'
import AdPayment from "../../Car/Create/Payment";
import CreateEstateFormTest from "./CreateEstateFormTest";
import Success from "../../Success";
import EstatePreview from "../View/EstatePreview";
import SelectCategory from "./SelectEstate";

const StartCreateEstateTest = () => {
    const [state, setState] = useState("start");
    const [estateData, setEstateData] = useState();
    const [images, setImages] = useState([])
    const [doping, setDoping] = useState(1);
    const [formData, setFormData] = useState();
    const [categoryId, setCategory] = useState();
    const [categoryDetails, setCategoryDetails] = useState();
    const [id, setId] = useState(0);

    //payment
    const [amount, setAmount] = useState("")
    const [resp, setResp] = useState(null)

    const goStart = () => {
        setState("start");
    };
    const goForm = () => {
        setState("form");
    };

    const changeState = (e) => {
        setState(e);
    };
    const type = 0;

    return (
        <div>
            <div>
                <Navbar/>
            </div>
            <div style={{minHeight: "70vh", marginTop: "5rem"}}>
                {/* <CreateCarForm /> */}
                {state === "start" && (
                    <SelectCategory
                        nextPage={goForm}
                        setCategory={setCategory}
                        setCategoryDetails={setCategoryDetails}
                    />
                )}
                {state === "form" && (
                    <div>
                        <CreateEstateFormTest
                            goBack={goStart}
                            nextPage={changeState}
                            categoryId={categoryId}
                            setEstateData={setEstateData}
                            setImages={setImages}
                            setFormData={setFormData}
                            doping={doping}
                            setDoping={setDoping}
                            categoryDetails={categoryDetails}
                        />
                    </div>
                )}
                {state === 'payment' && <AdPayment data={estateData} doping={doping} state={changeState}/>}
                {state === 'no-payment' &&
                    <EstatePreview setId={setId} estateData={estateData} images={images} formData={formData}
                                   state={changeState} goBack={goForm} catData={categoryDetails}/>}

                {state === 'success' && <Success id={id} type={type} doping={doping} amount={amount} setAmount={setAmount}
                    resp={resp} setResp={setResp} />}
            </div>
        </div>
    );
};

export default StartCreateEstateTest;
