import React, {useEffect, useState} from "react";
import Select from "react-select";

const options = [
  { value: 1, label: "Evet" },
  { value: 2, label: "Hayir" }
];

const Insurance = ({ setSelectedInsurance,selectedLabel }) => {
    const [label, setLabel] = useState();
    useEffect(() => {

        if(selectedLabel != null) {
            for (let i = 0; i < options.length ; i++) {
                if(options[i].label === selectedLabel) {
                    setLabel(options[i])

                    // setExistedLabel
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLabel])
  return (
    <>
      <Select
        options={options}
        value={label}
        onChange={(e) => {
            setSelectedInsurance(e.label);
            setLabel(e)
        }}
        placeholder="Garanti"
      />
    </>
  );
};

export default Insurance;
