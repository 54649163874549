import React, {useEffect, useContext, useState, useRef} from "react";
import {BaseURLContext} from "../../baseURL-context";
import {Link, useNavigate} from "react-router-dom";
import {
    protectedEnpointGetRequest
} from "../../static/functions";
import Navbar from "react-bootstrap/Navbar";
import {Nav, NavDropdown, Container} from "react-bootstrap";
import NavLogo from "./NavLogo";
import Cookies from "universal-cookie";
import SmallSearch from "../Search/Filters/SmallSearch";
import {useWindowSize} from "../../static/helpers";
import {ImCross} from "react-icons/im";
import {FiSearch} from "react-icons/fi";
import {BiArrowBack} from "react-icons/bi";
import Navigation from "./BottomNavBar";

const NavbarIn = ({navLogo, setCurrentUser, searchBar}) => {
    const {baseURL} = useContext(BaseURLContext);
    const [user, setUser] = useState([]);
    const [messageCount, setMessageCount] = useState([]);
    const [showSearch, setShowSearch] = useState(false)
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt");
    const navigate = useNavigate();
    const latestChat = useRef([React.createRef(), React.createRef()]);
    const getSize = useWindowSize();

    latestChat.current = messageCount;

    function goBack() {
        navigate(-1);
    }

    async function GoHome() {
        navigate("/");
    }

    async function getUnreadMessageCount() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Message/GetUnreadMessageCount`,
            cookie
        );
        if (response.status === 200) {
            setMessageCount(response.data);
        } else {

        }
    }

    useEffect(() => {
        getUnreadMessageCount()
    }, []);

    useEffect(() => {
        if (!cookie) {
            navigate("/giris-yap");
        } else {
            getUser();
        }
    }, []);

    async function getUser() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/UserManage/GetUser`,
            cookie
        );
        if (response.status === 200 || response.status === 201) {
            setUser(response.data);
            if (setCurrentUser !== undefined) {
                setCurrentUser(response.data.username)
            }
        } else {
            // cookies.remove("hoe-jwt")
            // navigate("/");
        }
        // history.go(0)

    }

    async function logOut() {
        cookies.remove("hoe-jwt")
        // const response = await defaultGetRequest(
        //   // `${baseURL}/authentication/Logout`
        // );
        window.location.reload();
        // if (response.data === true) {
        //   // cookies.remove("hoe-jwt");

        // }
    }

    // console.log("user" + user["firstName"]);
    const firstName = user.firstName;
    const eMail = user.email;
    const username = user.username

    function handleSearchBar() {
        if (showSearch === true) {
            setShowSearch(false);
            // setNav(false)
        } else {
            setShowSearch(true);
        }
    }

    const menuStyle = {
        alignItems: "flex-end",
        float: "right",
        marginTop: '0.55rem',
        // border:'black 1px solid',
        // borderRadius:'20px',
        height: '40px',
        fontWeight: 600
    }

    const showSearchStyle = {
        marginTop: '6.8%',
        color: "black"
    }

    const hideSearchStyle = {
        display: 'none'
    }

    return (
        <div>
            <Navbar bg="yellow" expand="lg">
                <Container fluid>
                    {navLogo
                        ? getSize?.width < 680 && (
                        <div
                            className="m-3"
                            style={{
                                maxWidth: '50px',
                                maxHeight: '70px',
                                left: '-3%',
                                marginTop: '30px !important'
                            }}
                            onClick={goBack}>
                            <BiArrowBack size={32} style={{paddingRight: '10px'}}/>
                        </div>
                    )
                        : (
                            <div className="d-flex column">
                                {getSize?.width < 680 && (
                                        <div
                                            className="m-3"
                                            style={{
                                                maxWidth: '50px',
                                                maxHeight: '70px',
                                                left: '-3%',
                                                marginTop: '30px !important'
                                            }}
                                            onClick={goBack}>
                                            <BiArrowBack size={32} style={{paddingRight: '10px'}}/>
                                        </div>
                                        )}
                                        <Link
                                            className="col-md-4"
                                            to="/"
                                            onClick={() => {
                                                GoHome();
                                            }}
                                            style={showSearch === false ? showSearchStyle : hideSearchStyle}
                                        >
                                            <NavLogo className="mt-3"/>
                                        </Link>
                                        {getSize?.width > 680
                                            ? (
                                                <div className="col-md-8">
                                                    <SmallSearch/>
                                                </div>
                                            )
                                            : (
                                                <>

                                                    <Link
                                                        id="search_button_small"
                                                        style={{
                                                            top: '45%',
                                                            width: '45px',
                                                            textDecoration: 'none',
                                                            position: 'absolute',

                                                        }}
                                                        to="#"
                                                        onClick={() => {
                                                            handleSearchBar()
                                                        }}
                                                    >

                                                        {showSearch === true && getSize?.width < 680
                                                            ?
                                                            <ImCross style={{position: 'absolute'}} className="fa-2x"/>
                                                            :
                                                            <FiSearch style={{position: 'absolute'}} className="fa-2x"/>
                                                        }

                                                    </Link>
                                                    <div
                                                        style={{
                                                            margin: '0 auto',
                                                            width: '100%',
                                                            position: 'absolute',
                                                            zIndex: '9999'
                                                        }}
                                                    >
                                                        <Navigation/>
                                                    </div>
                                                </>
                                            )
                                        }
                                        {showSearch && (<div style={{marginTop: '1rem', width: '40%'}}>
                                            <SmallSearch/>
                                        </div>)}
                                    </div>

                                )}
                                {navLogo ?? getSize?.width > 680
                                    ? (
                                        <div style={menuStyle}>
                                            <Navbar.Toggle aria-controls="navbarScroll"/>
                                            <Navbar.Collapse id="navbarScroll">
                                                {getSize?.width > 680
                                                    ? (<Nav
                                                        className="me-auto my-2 my-lg-0"
                                                        style={{maxHeight: "190px"}}
                                                        navbarScroll
                                                    >
                                                        <Nav.Link href="/#/Messages" className="d-flex">
                                                        </Nav.Link>
                                                        <Nav.Link href="/#/ilan-olustur">
                                                            <div className="navbar-element-wrapper">
                                                                <div className="navbar-element">
                                                                    İlan Oluştur
                                                                </div>
                                                            </div>
                                                        </Nav.Link>
                                                        <div className="navbar-element-wrapper">
                                                            <div className="navbar-element"
                                                                 style={{textTransform: 'none'}}>
                                                                <NavDropdown title={username}
                                                                             id="navbarScrollingDropdown">

                                                                    <NavDropdown.Item href="/#/profil-genel">Profil
                                                                        Bilgilerim</NavDropdown.Item>
                                                                    {messageCount && messageCount > 0
                                                                        ? (<NavDropdown.Item href="/#/mesajlar">
                                                                            <div className="d-flex">
                                                                                <label>
                                                                                    Mesajlar
                                                                                </label>
                                                                                <span style={
                                                                                    {
                                                                                        position: 'absolute',
                                                                                        left: '6%'
                                                                                    }
                                                                                }>
                                                <strong>
                                                    <p
                                                        style={{
                                                            color: "white",
                                                            backgroundColor: "red",
                                                            fontSize: "8px",
                                                            marginLeft: "-8px",
                                                            width: '12px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        {messageCount}
                                                    </p>
                                                </strong>
                                                </span>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </NavDropdown.Item>)
                                                                        : (<NavDropdown.Item
                                                                            href="/#/mesajlar">Mesajlar</NavDropdown.Item>)
                                                                    }
                                                                    <NavDropdown.Item href="/#/ilanlarim">
                                                                        İlanlarim
                                                                    </NavDropdown.Item>
                                                                    <NavDropdown.Item href="/#/favoriler">Favori
                                                                        İlanlar</NavDropdown.Item>
                                                                    <NavDropdown.Divider/>
                                                                    <NavDropdown.Item
                                                                        onClick={() => {
                                                                            logOut();
                                                                        }}
                                                                    >
                                                                        Logout
                                                                    </NavDropdown.Item>
                                                                </NavDropdown>
                                                            </div>
                                                        </div>
                                                    </Nav>)
                                                    : (<Nav
                                                        className="me-auto my-2 my-lg-0"
                                                        style={{maxHeight: "190px"}}
                                                        navbarScroll
                                                    >
                                                        <Nav.Link href="/#/Messages" className="d-flex">
                                                        </Nav.Link>
                                                        <Nav.Link href="/#/ilan-olustur">İlan Oluştur</Nav.Link>
                                                        <NavDropdown title={username} id="navbarScrollingDropdown">

                                                            <NavDropdown.Item href="/#/profil-genel">
                                                                Profil Bilgilerim
                                                            </NavDropdown.Item>
                                                            {messageCount && messageCount > 0
                                                                ? (<NavDropdown.Item href="/#/mesajlar">
                                                                    <div className="d-flex">
                                                                        <label>
                                                                            Mesajlar
                                                                        </label>
                                                                        <span style={
                                                                            {
                                                                                position: 'absolute',
                                                                                left: '6%'
                                                                            }
                                                                        }>
                                                <strong>
                                                    <p
                                                        style={{
                                                            color: "white",
                                                            backgroundColor: "red",
                                                            fontSize: "8px",
                                                            marginLeft: "-8px",
                                                            width: '12px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        {messageCount}
                                                    </p>
                                                </strong>
                                                </span>
                                                                    </div>
                                                                    <div>

                                                                    </div>
                                                                </NavDropdown.Item>)
                                                                : (<NavDropdown.Item
                                                                    href="/#/mesajlar">Mesajlar</NavDropdown.Item>)
                                                            }
                                                            <NavDropdown.Item href="/#/ilanlarim">
                                                                İlanlarim
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item href="/#/favoriler">Favori
                                                                İlanlar</NavDropdown.Item>
                                                            <NavDropdown.Divider/>
                                                            <NavDropdown.Item
                                                                onClick={() => {
                                                                    logOut();
                                                                }}
                                                            >
                                                                Logout
                                                            </NavDropdown.Item>
                                                        </NavDropdown>
                                                    </Nav>)
                                                }

                                            </Navbar.Collapse>
                                            <Navigation/>
                                        </div>
                                    )
                                    : (
                                        <></>
                                    )
                                }

                            </Container>
                        </Navbar>
                        </div>
                        );
                    };

                    export default NavbarIn;
