import React, {useContext, useState} from "react";
import {useEffect} from "react";
import Select from "react-select";
import data from "./data.json";
import {defaultGetRequest, defaultGetRequestWithParams} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";

function Location({
                      selectedCity,
                      setSelectedCity,
                      selectedTown,
                      setSelectedTown,
                      selectedNeighbourhood,
                      setSelectedNeighbourhood
                  }) {
    const [country, setCountry] = useState(null);
    const [lang, setLang] = useState(null);
    const [langList, setLangList] = useState([]);

    const {baseURL} = useContext(BaseURLContext);
    const sampleObject = {label: '', value: ''}
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(sampleObject);
    const [states, setStates] = useState([]);
    const [state, setState] = useState(sampleObject);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [neighbourhood, setNeighbourhood] = useState(sampleObject);

    // useEffect((e) => {
    //   if (selectedCity !== undefined) {
    //     setSelectedCity()
    //     // console.log(e.target.value)
    //     // console.log("loc2"+location2);
    //     // handleCityChange(location1);
    //     // if (location2 !== null) {
    //     //   // convertToObjetcs()
    //     //   console.log(location2);
    //     // }
    //   }
    // }, []);


    // const onSelect = () => {
    //   setSelectedCity(country)
    // }


    async function getCityData() {
        // const cityNames = await getCityName()
        setCities([])
        setCity(sampleObject)
        setStates([])
        setState(sampleObject)
        setNeighbourhoods([])
        setNeighbourhood(sampleObject)
        const response = await defaultGetRequest(
            `${baseURL}/Addresses/GetCities`,
        )
        if (response) {
            for (let i = 0; i < response.data.length; i++) {
                setCities(elem => [...elem, {value: response.data[i], label: response.data[i]}])
            }
        }

        return true
    }

    async function getStateData(selected) {
        setStates([])
        setState(sampleObject)
        setNeighbourhoods([])
        setNeighbourhood(sampleObject)

        const response = await defaultGetRequestWithParams(
            `${baseURL}/Addresses/GetStates`,
            {
                params: {
                    city: selected
                }
            }
        )
        if (response) {
            for (let i = 0; i < response.data.length; i++) {
                setStates(elem => [...elem, {value: response.data[i], label: response.data[i]}])
            }
        }

        return true
    }

    async function getNeighbourhoodData(selectedState) {
        setNeighbourhoods([])
        setNeighbourhood(sampleObject)

        const response = await defaultGetRequestWithParams(
            `${baseURL}/Addresses/GetNeighbourhoods`,
            {
                params: {
                    city: city.label,
                    state: selectedState
                }
            }
        )
        if (response) {
            for (let i = 0; i < response.data.length; i++) {
                setNeighbourhoods(elem => [...elem, {value: response.data[i], label: response.data[i]}])
                // arr.push({value: cityNames[i], label: cityNames[i]})
            }
        }

        return true
    }


    useEffect(() => {
        if (selectedCity != null) {
            for (let i = 0; i < cities.length; i++) {
                if (cities[i].label === selectedCity) {
                    setCity(cities[i])
                    setSelectedCity(cities[i].city)
                }
            }
        }
    }, [selectedCity])

    useEffect(() => {
        if (selectedTown != null) {
            for (let i = 0; i < states?.length; i++) {
                if (states[i].label === selectedTown) {
                    setState(states[i]);
                    setSelectedTown(states[i].label.toString())
                }
            }
        }
    }, [selectedTown])

    const handleTownChange = (obj) => {
        // console.log(obj["name"]);
        setLang(obj);
        setSelectedTown(obj["name"].toString())
    };
    var arr = [];

    function convertToObjetcs(obj) {
        for (var i = 0; i < obj.district.length; i++) {
            var dist = new Object();
            dist.code = i.toString();
            dist.name = obj.district[i];
            arr.push(dist);
        }
        setLangList(arr);
        return arr;
    }

    const handleCityChange = async (obj) => {
        setCity(obj);
        setSelectedCity(obj.label)
        await getStateData(obj.label)
    };

    const handleStateChange = async (obj) => {
        setState(obj);
        setSelectedTown(obj.label)
        await getNeighbourhoodData(obj.label)
    };

    return (
        <div className="App">
            <div>
                <b>İl</b>
                <Select
                    placeholder="Seçim yapın"
                    value={city}
                    options={cities}
                    onChange={handleCityChange}
                    getOptionLabel={(x) => x.label}
                    getOptionValue={(x) => x.value}
                />
                <br/>
                <b>İlçe</b>
                <Select
                    placeholder="Seçim yapın"
                    value={state}
                    options={states}
                    onChange={handleStateChange}
                    getOptionLabel={(x) => x.label}
                    getOptionValue={(x) => x.value}
                />
            </div>
        </div>
    );
}

export default Location;
