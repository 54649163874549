import React, {useEffect, useState, useContext} from "react";
import Checkout from "../Components/Payment/Checkout/Checkout";
import {Link, useLocation} from "react-router-dom";
import {defaultGetRequestWithParams} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import NoImage from "../img/no-image.png";
import moment from "moment";
import {useWindowSize} from "../static/helpers";
import Navbar from "../Components/Layout/Navbar";

const Doping = () => {
    const [amount, setAmount] = useState("")
    const [resp, setResp] = useState(null)
    const {baseURL} = useContext(BaseURLContext);
    const [ad, setAd] = useState([])
    const [user, setUser] = useState([])
    const location = useLocation();
    const size = useWindowSize();
    const adId = location?.state?.id
    const adtype = location?.state?.type

    async function getProductDetail(id, type) {
        if (type === 0) {
            var response = await defaultGetRequestWithParams(
                `${baseURL}/Search/GetEstateById`,
                {
                    params: {
                        id: id
                    },

                })
            if (response) {
                setAd(response.data.estate);
                setUser(response.data.owner)
            }
        } else if (type === 1) {
            const response = await defaultGetRequestWithParams(
                `${baseURL}/Search/GetCarById`,
                {
                    params: {
                        id: id
                    },
                })
            if (response) {
                setAd(response.data);
            }
        }
    }

    async function getDopingPrice() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/FieldManage/GetField`,
            {
                params: {
                    fieldType: 'MembershipDetail'
                },
            }
        );
        if (response) {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].label === 'Doping')
                    setAmount(response.data[i].value)
            }
        }
    }



    useEffect(() => {
        getProductDetail(adId, adtype)
        getDopingPrice()
    }, [])

    const rowStyle = {
        // maxHeight: '120px'
    }

    const paymentModel = {
        type : 'Doping',
        detail: adtype, //ad type
        id: adId
    }

    const columnStyle = {
        // maxHeight: '100px',
        // marginBottom: '1.5rem',
        float: 'left',
    }


    // useEffect(() => {setAmount()})
    return (
        <div>
            <div>
                <Navbar/>
            </div>
            <div>
                <h6>

                </h6>
            </div>
            <div className="row mt-5 d-flex justify-content-center align-items-center">
                <div className="col-md-8">
                    {ad && user && (
                        <tr style={rowStyle} className="d-flex m-3 row">
                            <td style={columnStyle}
                                className={`col-md-1 m-3  ${size?.width < 680 ? `` : ``}`}>
                                <Link to={`/${adtype === 0 ? `emlak` : `vasita`}/${ad.id}`}>
                                    {ad?.coverImage !== null ? (<img style={columnStyle}
                                                                     src={ad?.coverImage}

                                                                     alt="test"/>) :
                                        <div><img alt={`${ad.name}`} src={NoImage}/></div>}
                                </Link>
                            </td>
                            <td className={`col-md-8 row`}>
                                <div style={{display: 'flex'}} className={`col-md-12`}>
                                    <h5>{ad.name}</h5>
                                </div>
                                <div className={`col-md-12 row d-flex`}>
                                    <div className="col-md-3">
                                        <h6 style={{float: 'left'}}>{user.ownerName}</h6>
                                    </div>
                                </div>
                            </td>
                            <td className={`col-md-4`}>

                            </td>
                        </tr>
                    )
                    }
                    <div className="mt-5">
                        <div style={{float:'left'}}>
                            <h6>Sectiğiniz ilana {amount} ₺ karşılığında doping uygulanacak.</h6>

                        </div>
                    </div>
                </div>

                <div>
                    <Checkout
                        amount={amount}
                        setResp={setResp}
                        resp={resp}
                        paymentModel={paymentModel}
                        // setAccStatus={setAccStatus}
                    />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {resp && (
                            <div style={{width: '90vw', height: '90vh', display: ''}}
                                 dangerouslySetInnerHTML={{__html: `${resp}`}}>

                            </div>)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Doping;
