import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {defaultGetRequest} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
// import { beautifyCategoryName } from "../static/helpers";
import '../assets/css/index.css'

const EstateCatList = () => {
    const {baseURL} = useContext(BaseURLContext);
    const [estateCats, setEstateCats] = useState([]);

    async function getMainCats() {
        const response = await defaultGetRequest(
            `${baseURL}/Categories/GetEstateLevel1WithCounts`
        );
        if (response) {
            for (const responseElement of response.data) {
                if (responseElement?.name != null) {
                    setEstateCats((elem) => [...elem, responseElement])
                }
            }
        }
    }

    useEffect(() => {
        getMainCats();
    }, []);

    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: 1,
        borderColor: "#000000",
        minWidth:'200px'
    }

    return (
        <div className="cat-list m-auto">
            <hr style={hrStyle}/>
            <ul className="list-1 w-100">
                {estateCats && estateCats.map((item, i) => {
                    return (
                        <li key={i} style={{minWidth:'130px',width:'90%'}}>
                            <Link
                                to={`/emlak-kategori/${item.name}`}
                                key={i}
                            >
                                {item.name} ({item.count})
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default EstateCatList;