import React from "react";

const LandView = ({
                       hrStyle,
                      Reconstruction,
                      Ada,
                      Parcel,
                      Pafta,
                      Kaks,
                      Gabari,
                      FloorPayback,
                      Swappable,
                      Ownership
                   }) => {
    return (
        <div>

            <div>


                <div
                    className="price-detail-wrap row"
                >
                    <label className="col-md-4" style={{color: "black"}}>
                        <strong>İmar Durumu</strong>
                    </label>

                    <span className="col-md-8 ad-detail">{Reconstruction}</span>
                </div>
                <hr
                    style={hrStyle}
                />
            </div>


            <div>
                <div
                    className="price-detail-wrap row"
                >
                    <label className="col-md-4" style={{color: "black"}}>
                        <strong>Ada No</strong>
                    </label>

                    <span className="col-md-8 ad-detail">{Ada}</span>
                </div>
                <hr
                    style={hrStyle}
                />
            </div>

            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Parsel No</strong>
                </label>

                <span className="col-md-8 ad-detail">{Parcel}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Pafta No</strong>
                </label>

                <span className="col-md-8 ad-detail">{Pafta}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Kaks</strong>
                </label>

                <span className="col-md-8 ad-detail">{Kaks}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Gabari</strong>
                </label>

                <span className="col-md-8 ad-detail">{Gabari}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Kat Karşılığı</strong>
                </label>

                <span className="col-md-8 ad-detail">{FloorPayback}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Takaslı</strong>
                </label>
                <span className="col-md-8 ad-detail">{Swappable}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Tapu Durumu</strong>
                </label>

                <span className="col-md-8 ad-detail">{Ownership}</span>
            </div>
        </div>
    );
}

export default LandView;
