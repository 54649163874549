import React, {useContext, useEffect, useState} from "react";
import Navbar from "../Components/Layout/Navbar";
import {useLocation} from "react-router-dom";
import {
    defaultGetRequestWithParams,
    protectedEnpointGetRequest,
    protectedEnpointPostRequest
} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import House from "../Components/Forms/CreateEstate/Type/House";
import {Row, Col} from "react-bootstrap";
import Description2 from "../Components/Forms/Common/Description2";
import Cookies from "universal-cookie";
import useForm from "../Components/useForm/useForm";
import Land from "../Components/Forms/CreateEstate/Type/Land";
import Building from "../Components/Forms/CreateEstate/Type/Building";
import Hotel from "../Components/Forms/CreateEstate/Type/Hotel";
import Office from "../Components/Forms/CreateEstate/Type/Office";
import GeoLocationNewNew from "../Components/Map/GeoLocationNewNew";
import CurrencyInput from "react-currency-input-field";
import FileUpload from "../Components/Common/EditImages";
import isNumeric from "../static/helpers";
import axios from "axios";
import {useNavigate} from "react-router";
import NumberFormat from "react-number-format";


const EditEstate = () => {
    // const [imgSrc, setImgSrc] = useState("");
    const {baseURL} = useContext(BaseURLContext);
    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")
    const location = useLocation()
    const navigate = useNavigate();
    const {values, errors, handleChange, handleSubmit} = useForm();
    const [disable, setDisable] = useState(false);
    const [status, setStatus] = useState('edit')
    const [estateType, setEstateType] = useState('')
    const [estateObject, setEstateObject] = useState([])
    const [estateId, setEstateId] = useState('')
    const [existingImages, setExistingImages] = useState([])
    const [selectedImage, setSelectedImage] = useState({})
    const [files, setFiles] = useState({});

    //general props
    const [estateName, setEstateName] = useState("");
    const [price, setPrice] = useState("");
    const [rawPrice, setRawPrice] = useState(0.1);
    const [squaremeter, setSquaremeter] = useState(0);
    const [description, setDescription] = useState('');
    const [existingDesc, setExistingDesc] = useState('');
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedTown, setSelectedTown] = useState("");
    const [userRole, setUserRole] = useState("");

    //map
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [initialLocation, setInitialLocation] = useState(null)
    const [center, setCenter] = useState({lat: latitude, lng: longitude});

    //house
    const [selectedRooms, setSelectedRooms] = useState("");
    const [selectedHeating, setSelectedHeating] = useState("");
    const [selectedUsage, setSelectedUsage] = useState("");
    const [selectedOwnership, setSelectedOwnership] = useState("");
    const [pricePerM2, setPricePerM2] = useState(null);
    const [buildingAge, setBuildingAge] = useState(0);
    const [floor, setFloor] = useState(0);
    const [totalfloor, setTotalfloor] = useState(0);
    const [balcony, setBalcony] = useState(0);
    const [bathrooms, setBathrooms] = useState(0);
    const [monthlyextra, setMonthlyextra] = useState(0);
    const [withFurniture, setWithFurniture] = useState('');
    const [availableForCredit, setAvailableForCredit] = useState('');
    const [frontSide, setFrontSide] = useState('');


    //land
    const [reconstruction, setReconstruction] = useState("");
    const [ada, setAda] = useState("");
    const [parcel, setParcel] = useState("");
    const [pafta, setPafta] = useState("");
    const [kaks, setKaks] = useState("");
    const [gabari, setGabari] = useState("");
    const [floorPayback, setFloorPayback] = useState("");
    const [swappable, setSwappable] = useState("");

    //hotel
    const [starCount, setStarCount] = useState();
    const [roomCount, setRoomCount] = useState();
    const [bedCount, setBedCount] = useState();
    const [floorCount, setFloorCount] = useState();
    const [closedArea, setClosedAreaM2] = useState()
    const [groundSurvey, setGroundSurvey] = useState();

    // building
    const [flatCountPerFloor, setFlatCountPerFloor] = useState(0);


    useEffect(() => {
        GetRoles()
        getEstate(location?.state?.id)
        getImagesNew(location?.state?.id)
        // setEstateType(location?.state?.type)
        setEstateId(location?.state?.id)
    }, [])



    async function getEstate(id) {
        var response = await defaultGetRequestWithParams(
            `${baseURL}/Search/GetEstateById`,
            {
                params: {
                    id: id
                },
            })
        if (response) {
            setEstateName(response.data.estate?.name)
            setPrice(response.data.estate?.price)
            setSquaremeter(response.data.estate?.squareMeter)
            setExistingDesc(response.data.estate?.description)
            setDescription(response.data.estate?.description)
            setInitialLocation(
                {
                    id: response.data?.estate?.id,
                    pos: {
                        lat: parseFloat(response.data?.estate?.latitude),
                        lng: parseFloat(response.data?.estate?.longitude)
                    }
                }
            )
            setLatitude(response.data?.estate?.latitude)
            setLongitude(response.data?.estate?.longitude)

            if (response.data.estate.familyType === 'Konut') {
                setEstateObject(response.data.estate)
                setEstateType(response.data.estate.familyType)
                setBuildingAge(response.data.estate.buildingAge)
                setFloor(response.data.estate.floor)
                setTotalfloor(response.data.estate.totalFloor)
                setBalcony(response.data.estate.balcony)
                setBathrooms(response.data.estate.bathrooms)
                setMonthlyextra(response.data.estate.monthlyExtra)
                setSelectedHeating(response.data.estate.heating)
                setSelectedOwnership(response.data.estate.ownership)
            }
            if (response.data.estate.familyType === 'Arsa') {
                setEstateObject(response.data.estate)
                setEstateType(response.data.estate.familyType)
                setReconstruction(response.data.estate.reconstruction)
                setAda(response.data.estate.ada)
                setParcel(response.data.estate.parcel)
                setPafta(response.data.estate.pafta)
                setKaks(response.data.estate.kaks)
                setGabari(response.data.estate.gabari)
                setFloorPayback(response.data.estate.floorPayback)
                setSwappable(response.data.estate.swappable)
                setSelectedOwnership(response.data.estate.ownership)
            }
            if (response.data.estate.familyType === 'Bina') {
                setEstateObject(response.data.estate)
                setEstateType(response.data.estate.familyType)
                setBuildingAge(response.data.estate.buildingAge)
                setFloorCount(response.data.estate.floorCount)
                setFlatCountPerFloor(response.data.estate.flatCountPerFloor)
            }

            if (response.data.estate.familyType === 'Turistik Tesis') {
                setEstateObject(response.data.estate)
                setEstateType(response.data.estate.familyType)
                setStarCount(response.data.estate.starCount)
                setBedCount(response.data.estate.bedCount)
                setClosedAreaM2(response.data.estate.closedAreaM2)
                setFloorCount(response.data.estate.floorCount)
                setGroundSurvey(response.data.estate.groundSurvey)
                setRoomCount(response.data.estate.roomCount)
            }
            if (response.data.estate.familyType === 'İş Yeri') {
                setEstateObject(response.data.estate)
                setEstateType(response.data.estate.familyType)
                setBuildingAge(response.data.estate.buildingAge)
                setTotalfloor(response.data.estate.totalFloor)
                setMonthlyextra(response.data.estate.monthlyExtra)
                setSelectedHeating(response.data.estate.heating)
                setSelectedUsage(response.data.estate.inUsage)
            }
        }
    }

    async function GetRoles() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/UserRoles/GetUserRoles`,
            cookie
        )
        if (response) {
            if (response.data[0] === 'IndividualUser') {
                setUserRole("Sahibinden")
            } else if (response.data[0] === 'AutoShowroom') {
                setUserRole("Galeriden")
            } else if (response.data[0] === 'Bank') {
                setUserRole("Bankadan")
            } else if (response.data[0] === 'AutorizedDealer') {
                setUserRole("Yetkili Bayiiden")
            } else if (response.data[0] === 'EstateAgency') {
                setUserRole("Emlak Ofisinden")
            } else {
                setUserRole("Sahibinden")
            }
        }
    }


    async function saveEdit() {
        setDisable(true)
        const estateData = {
            Id: estateId,
            Name: estateName,
            // AdType: categoryDetails[1],
            // FamilyType: categoryDetails[0],
            // EstateType: categoryDetails[2] ?? categoryDetails[0],
            Description: description,
            Price: price.replace(',','.'),
            City: selectedCity,
            State: selectedTown,
            SquareMeter: parseInt(squaremeter),
            Rooms: selectedRooms,
            BuildingAge: parseInt(buildingAge),
            Floor: parseInt(floor),
            TotalFloor: parseInt(totalfloor),
            Heating: selectedHeating,
            Balcony: parseInt(balcony),
            Bathrooms: parseInt(bathrooms),
            WithFurniture: withFurniture === 'Evet',
            InUsage: selectedUsage,
            MonthlyExtra: parseInt(monthlyextra),
            CreditStatus: availableForCredit === 'Evet',
            Ownership: selectedOwnership,
            From: userRole,
            // CategoryId: parseInt(categoryId),
            Reconstruction: reconstruction,
            Ada: ada,
            Parcel: parcel,
            Pafta: pafta,
            Kaks: kaks,
            Gabari: gabari,
            FloorPayback: floorPayback,
            Swappable: swappable,
            BedCount: bedCount,
            ClosedAreaM2: parseInt(closedArea),
            FloorCount: parseInt(floorCount),
            StarCount: parseInt(starCount),
            FlatCountPerFloor: parseInt(flatCountPerFloor),
            GroundSurvey: groundSurvey,
            RoomCount: parseInt(roomCount),
            FrontSide: frontSide,
            Latitude: initialLocation?.pos?.lat?.toString(),
            Longitude: initialLocation?.pos?.lng?.toString()
        };
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Estate/EditEstateProduct`,
            estateData,
            cookie
        )
        if (response) {

            if (selectedImage?.length > 0) {
                let newFormData = new FormData();
                for (let a = 0; a < selectedImage?.length; a++) {
                    newFormData.append(`formFile`, selectedImage[a],selectedImage[a].name);
                }

                newFormData.append("productId", estateId);
                newFormData.append("type", 0);

                const createImageResponse = await protectedEnpointPostRequest(
                    `${baseURL}/Estate/SetOrderAndUpdateImages`,
                    newFormData,
                    cookie
                );
                if (createImageResponse) {
                    setStatus('true')
                } else {
                    setStatus('false')
                }
            }
            setStatus('true')
        } else {
            setStatus('false')
        }
    }

    async function getCurrentLocation() {
        navigator.geolocation.getCurrentPosition(function (position) {
            setInitialLocation({
                id: 0,
                pos: {
                    lat: position?.coords?.latitude,
                    lng: position?.coords?.longitude
                }
            })
            setLatitude(position?.coords?.latitude)
            setLongitude(position?.coords?.longitude)
            setCenter({lat: position?.coords?.latitude, lng: position?.coords?.longitude})
        });
    }

    const updateUploadedFiles = (files) => {
        setSelectedImage({})
        setSelectedImage(files)
    }

    async function getImagesNew(id) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/GetAdImages`,
            {
                params: {
                    id: id,
                    type: 0
                },
            }
        )
        if (response) {
            const tempArray = await setImageResponses(response.data)
            setFiles({...Object.assign({}, ...tempArray.map(key => ({[key?.name]: key})))})
            setSelectedImage({...Object.assign({}, ...tempArray.map(key => ({[key.name]: key.name})))})

        }
    }

    async function setImageResponses(array) {
        var tempArray = []
        setExistingImages([])
        for (let i = 0; i < array.length; i++) {
            const firstResponse = await axios({
                method: 'get',
                url: array[i].path,
                responseType: 'blob'
            })
            if (firstResponse) {
                let test = await handleImages(firstResponse.data, array[i])
                tempArray.push(test)
            }
        }

        return tempArray
    }

    async function handleImages(imageData, item) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(imageData);
            reader.onload = () => {
                resolve(
                    fetch(reader.result.toString())
                        .then(res => res.blob())
                        .then((blob) => {
                            let fileName = item.path.match(/\/([^\/]+)\/?$/)[1];
                            fileName = isNumeric(fileName[fileName.length - 1])
                                ? fileName.slice(0, -1)
                                : fileName;
                            let file = new File([blob], `${fileName}`, {type: 'image/jpeg'});
                            setExistingImages((elem) => [...elem, file])

                            return file
                        }))
            }
            reader.onerror = reject;
        })
    }


    const MAX_VAL = 1000000000000;
    const withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value <= MAX_VAL) return true;
        return false;
    };


    return (
        <div>
            <Navbar/>
            {status === 'edit' &&
                (<Row>
                    <Col md={12}>
                        <div className="ms-4 me-4">
                            <div className="form-group mt-4 mb-4">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name"
                                        placeholder="İlan Adı"
                                        value={estateName || ''}
                                        // onChange={setEstateName}
                                        onChange={(e) => {
                                            setEstateName(e.target.value)
                                        }}
                                    />
                                    <label htmlFor="name">İlan Başlığı</label>
                                </div>


                                {files && (<div className="form-floating mb-3 row">
                                    <FileUpload
                                        accept=".jpg,.png,.jpeg"
                                        label="Profile Image(s)"
                                        // onChange={() => {setSelectedImage([])}}
                                        updateFilesCb={updateUploadedFiles}
                                        multiple
                                        existingImages={existingImages}
                                        files={files}
                                        setFiles={setFiles}
                                    /></div>)}

                                {/*{files && (<div className="form-floating mb-3 row">*/}
                                {/*    <UploadImage*/}
                                {/*        selectedImage={selectedImage}*/}
                                {/*        setSelectedImage={setSelectedImage}*/}
                                {/*        existingImages={existingImages}*/}
                                {/*        setExistingImages={setExistingImages}*/}
                                {/*        files={files}*/}
                                {/*        setFiles={setFiles}*/}
                                {/*        // existingImagesFormData={existingImagesFormData}*/}

                                {/*    />*/}
                                {/*</div>)}*/}

                                {/*<div className="form-floating mb-3">*/}
                                {/*{existingImages && (<FileUpload*/}
                                {/*    accept=".jpg,.png,.jpeg"*/}
                                {/*    label="Profile Image(s)"*/}
                                {/*    // onChange={() => {setSelectedImage([])}}*/}
                                {/*    updateFilesCb={updateUploadedFiles}*/}
                                {/*    multiple*/}
                                {/*    existingImages={existingImages}*/}
                                {/*/>)}*/}

                                {/*</div>*/}
                                <div className="form-floating mb-3 row">
                                    <div className="col-md-6">
                                        <Row>
                                            <Col>
                                                <label>
                                                    <strong>Fiyat</strong>
                                                </label>
                                                <NumberFormat
                                                    thousandSeparator=","
                                                    id="input-example"
                                                    name="input-name"
                                                    className="form-control"
                                                    decimalSeparator={false}
                                                    value={price || "0"}
                                                    allowNegative={false}
                                                    // allowEmptyFormatting={}
                                                    isAllowed={withValueCap}
                                                    isNumericString
                                                    defaultValue={"0"}
                                                    onValueChange={(values) => {
                                                        setPrice(values.formattedValue.toString())
                                                    }}
                                                />
                                            </Col>
                                            <Col style={{paddingTop: "30px"}}>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <label htmlFor="buildingage" className="float-start">
                                            <strong>Meterekare</strong>
                                        </label>
                                        <input
                                            type="number"
                                            name="squaremeter"
                                            min="0"
                                            className="form-control"
                                            value={squaremeter}
                                            id="squaremeter"
                                            placeholder="M²"
                                            onChange={(e) => {
                                                setSquaremeter(e.target.value)
                                                // calculatePricePerM2(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                {estateType === 'Konut'
                                    && (
                                        <House
                                            setBuildingage={setBuildingAge}
                                            setFloor={setFloor}
                                            setTotalfloor={setTotalfloor}
                                            setBalcony={setBalcony}
                                            setBathrooms={setBathrooms}
                                            setMonthlyextra={setMonthlyextra}
                                            setSelectedRooms={setSelectedRooms}
                                            setSelectedHeating={setSelectedHeating}
                                            setSelectedUsage={setSelectedUsage}
                                            setSelectedOwnership={setSelectedOwnership}
                                            selectedRooms={selectedRooms}
                                            selectedHeating={selectedHeating}
                                            selectedUsage={selectedUsage}
                                            selectedOwnership={selectedOwnership}
                                            availableForCredit={availableForCredit}
                                            setAvailableForCredit={setAvailableForCredit}
                                            withFurniture={withFurniture}
                                            setWithFurniture={setWithFurniture}
                                            setFrontSide={setFrontSide}
                                            // categoryType={categoryDetails[1]}
                                            handleChange={handleChange}
                                            buildingage={buildingAge}
                                            floor={floor}
                                            totalfloor={totalfloor}
                                            balcony={balcony}
                                            bathrooms={bathrooms}
                                            monthlyextra={monthlyextra}
                                            estateObject={estateObject}
                                            selectedHeating={selectedHeating}
                                        />
                                    )}

                                {estateType === 'Arsa' && estateObject
                                    && (
                                        <Land
                                            handleChange={handleChange}
                                            values={values}
                                            setReconstruction={setReconstruction}
                                            setAda={setAda}
                                            setParcel={setParcel}
                                            setPafta={setPafta}
                                            setKaks={setKaks}
                                            setGabari={setGabari}
                                            setFloorPayback={setFloorPayback}
                                            setSwappable={setSwappable}
                                            setSelectedOwnership={setSelectedOwnership}
                                            reconstruction={reconstruction}
                                            ada={ada}
                                            parcel={parcel}
                                            pafta={pafta}
                                            kaks={kaks}
                                            gabari={gabari}
                                            estateObject={estateObject}
                                        />
                                    )
                                }

                                {estateType === 'Bina' &&
                                    <Building
                                        // setRoomCount={setRoomCount}
                                        setBuildingAge={setBuildingAge}
                                        setFloorCount={setFloorCount}
                                        setFlatCountPerFloor={setFlatCountPerFloor}
                                        buildingAge={buildingAge}
                                        floorCount={floorCount}
                                        flatCountPerFloor={flatCountPerFloor}
                                    />}

                                {estateType === 'Turistik Tesis' &&
                                    <Hotel
                                        // setOpenAreaM2={setOpenAreaM2}
                                        setStarCount={setStarCount}
                                        setBedCount={setBedCount}
                                        setClosedAreaM2={setClosedAreaM2}
                                        setFloorCount={setFloorCount}
                                        setGroundSurvey={setGroundSurvey}
                                        setRoomCount={setRoomCount}
                                        starCount={starCount}
                                        bedCount={bedCount}
                                        closedAreaM2={closedArea}
                                        floorCount={floorCount}
                                        groundSurvey={groundSurvey}
                                        roomCount={roomCount}
                                    />
                                }

                                {estateType === 'İş Yeri' &&
                                    <Office
                                        setBuildingage={setBuildingAge}
                                        setTotalfloor={setTotalfloor}
                                        setMonthlyextra={setMonthlyextra}
                                        setSelectedHeating={setSelectedHeating}
                                        setSelectedUsage={setSelectedUsage}
                                        selectedHeating={selectedHeating}
                                        selectedUsage={selectedUsage}
                                        buildingage={buildingAge}
                                        totalfloor={totalfloor}
                                        monthlyextra={monthlyextra}
                                    />}
                            </div>

                            {initialLocation &&
                                (<div>
                                    <div style={{fontSize: '22px'}}>
                                        <label>
                                            <div style={{cursor: 'pointer', float: 'right'}} onClick={() => {
                                                getCurrentLocation()
                                            }}>Su an ki konumu al
                                            </div>
                                        </label>
                                        <label>

                                        </label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        {(initialLocation && latitude && longitude) && (<GeoLocationNewNew
                                            latitude={latitude}
                                            setLatitude={setLatitude}
                                            longitude={longitude}
                                            setLongitude={setLongitude}
                                            initialLocation={initialLocation}
                                            setInitialLocation={setInitialLocation}
                                            center={center}
                                            setCenter={setCenter}
                                            clickEnabled={true}
                                        />)}


                                        {/*<GeoLocation*/}
                                        {/*    initialExistingLocation={initialExistingLocation}*/}
                                        {/*    setInitialExistingLocation={setInitialExistingLocation}*/}
                                        {/*/>*/}
                                        {/*<Map*/}
                                        {/*    // latitudeExisting={latitudeExisting}*/}
                                        {/*    // setLatitudeExisting={setLatitudeExisting}*/}
                                        {/*    // longitudeExisting={longitudeExisting}*/}
                                        {/*    // setLongitudeExisting={setLongitudeExisting}*/}
                                        {/*    initialExistingLocation={initialExistingLocation}*/}
                                        {/*    setInitialExistingLocation={setInitialExistingLocation}*/}
                                        {/*/>*/}
                                    </div>
                                </div>)
                            }

                            <div>
                                <div style={{fontSize: '22px'}}>
                                    <label>
                                        <strong>Açıklama</strong>
                                    </label>
                                </div>
                                {existingDesc && <div className="form-floating mb-3">
                                    <Description2 setDescription={setDescription} existingDesc={existingDesc ? existingDesc : undefined}/>
                                </div>}
                                {/*{! && <div className="form-floating mb-3">*/}
                                {/*    <Description2 setDescription={setDescription}/>*/}
                                {/*</div>}*/}
                            </div>
                        </div>
                    </Col>
                    <div className="m-auto mt-2 mb-5">
                        <button className="btn btn-click rounded-0"
                                style={!disable ? {backgroundColor: "green", color: "white"} : {backgroundColor: "lightgray", color: "white"}}
                                disabled={disable}
                                onClick={() => {
                                    saveEdit()
                                }}>
                            Kaydet
                        </button>
                    </div>
                </Row>)
            }
            {status === 'true' &&
                (
                    <Row>
                        <div style={{marginTop: '5rem', marginBottom: '10rem'}}>
                            Degisiklikler basariyla kaydedildi.
                        </div>
                    </Row>
                )}
            {status === 'false' &&
                (
                    <Row>
                        <div style={{marginTop: '5rem', marginBottom: '10rem'}}>
                            Bir hata olustu. Lutfen tekrar deneyin.
                        </div>
                    </Row>
                )}
        </div>
    );
}

export default EditEstate;
