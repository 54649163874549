import React, {useContext, useState} from "react";
import {Modal, Button} from 'react-bootstrap'
import '../../../assets/css/styles.css'
import '../../../assets/css/demo.css'
import visa from '../../../assets/images/visa.jpg'
import mastercard from '../../../assets/images/mastercard.jpg'
import amex from '../../../assets/images/amex.jpg'
import Select from "react-select";
import {defaultPostRequest, protectedEnpointPostRequest} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";
import Cookies from "universal-cookie";

const months = [
    {value: 1, label: "01"},
    {value: 2, label: "02"},
    {value: 3, label: "03"},
    {value: 4, label: "04"},
    {value: 5, label: "05"},
    {value: 6, label: "06"},
    {value: 7, label: "07"},
    {value: 8, label: "08"},
    {value: 9, label: "09"},
    {value: 10, label: "10"},
    {value: 11, label: "11"},
    {value: 12, label: "12"},
]

const years = [
    {value: 22, label: "2022"},
    {value: 23, label: "2023"},
    {value: 24, label: "2024"},
    {value: 25, label: "2025"},
    {value: 26, label: "2026"},
    {value: 27, label: "2027"},
    {value: 28, label: "2028"},
    {value: 29, label: "2029"},
    {value: 30, label: "2030"},
    {value: 31, label: "2031"},
]

const colourStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '30px',
        height: '30px',
        boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
    }),
};

// const customStyles = {
//     menu: (provided, state) => ({
//         ...provided,
//         height: '100px',
//         borderBottom: '1px dotted pink',
//         color: state.selectProps.menuColor,
//         padding: 20,
//     }),
//     control: () => ({
//         // none of react-select's styles are passed to <Control />
//         // width: 200,
//         height: 130,
//     }),
//     singleValue: (provided, state) => {
//         const opacity = state.isDisabled ? 0.5 : 1;
//         const transition = 'opacity 300ms';
//
//         return { ...provided, opacity, transition };
//     }
// }


const Checkout = ({amount, setResp, resp, paymentModel}) => {
    const {baseURL} = useContext(BaseURLContext);
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt")


    const [cardOwner, setCardOwner] = useState("")
    const [cardNumber, setCardNumber] = useState("")
    const [validate, setValidate] = useState(false)
    const [cvv, setCvv] = useState("")
    // const [amount, setAmount] = useState("")
    const [expMonth, setExpMonth] = useState("")
    const [expYear, setExpYear] = useState("")

    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    async function getPayment() {

        // odeme-basarili?id={payment.OrderId}&paymentType={payment.PaymentType}
        // &type={payment?.PaymentDetail}&adId={payment.PaymentDetailId.ToString()}
        const cardInfo = {
            CardHolderName: cardOwner,
            CardNumber: cardNumber,
            CardCVV2: cvv,
            TotalAmount: amount?.toString(),
            CardExpireDateYear: expYear?.value?.toString(),
            CardExpireDateMonth: expMonth?.label,
            PaymentType: paymentModel?.type,
            PaymentDetail: paymentModel?.detail.toString(),
            PaymentDetailId: paymentModel?.id,
        }

        const response = await defaultPostRequest(
            `${baseURL}/Payment/SendPayment`,
            cardInfo
        )
        // cookie
        if (response) {
            let getRidOfText = response.data.replace('Please click the submit button below.', '');
            getRidOfText = getRidOfText.replace('Javascript is turned off or not supported!', '');
            // getRidOfText = getRidOfText.replace('<h2>3D Secure Processing</h2>', '');
            // getRidOfText = getRidOfText.replace('<img src=\'preloader.gif\' alt=\'Please wait..\'/>', '');
            // getRidOfText = getRidOfText.replace('Please click here to continue', 'Ödemeye Devam Et');
            let changeInputAttrs = getRidOfText.replace('<input type="submit" name="submit" value="Submit">', '<input  type="submit" name="submit" value="Onayla" class="modal-button m-auto btn rounded-0"> ')
            // let htmlString = response.data.replace('Please click the submit button below.<br>    <input type="submit" name="submit" value="Submit">', ' <br>    <input  type="submit" name="submit" value="Onayla" class="modal-button btn rounded-0"> ')
            setResp(changeInputAttrs.replace('noscript', 'div'))
            handleShow()
        }
    }

    function formats(value) {
        if (value.length <= 19) {
            value = value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
            return true;
        } else {
            return false;
        }
    }

    function numberValidation(value) {
        value = value.replace(/[^\d ]/g, '');
        return false;
    }

    // const formatAndVerifyNumericValue = (value, callback) => {
    //     const reg = new RegExp('^[0-9]+$');
    //     let newValue = value.replace(/\s/g, '');
    //     if (reg.test(newValue)) {
    //         newValue = newValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+.(?!\d))/g, ' ');
    //         return callback(newValue);
    //     }}

    function validateCard(value) {
        // Accept only digits, dashes or spaces
        if (/[^0-9-\s]+/.test(value)) return false;

        // The Luhn Algorithm. It's so pretty.
        let nCheck = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n),
                nDigit = parseInt(cDigit, 10);

            if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

            nCheck += nDigit;
            bEven = !bEven;
        }

        if ((nCheck % 10) == 0) {
            setValidate(true)
        } else {
            setValidate(false)
        }
    }

    function setSpaces(value, length) {
        return length % 4 === 0 && length !== 0 ? value + " " : value;
    }


    function handleCardNumber(value) {

        if (value.length <= 19) {
            const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
            const onlyNumbers = value.replace(/[^\d]/g, '')
            const result = onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
                [$1, $2, $3, $4].filter(group => !!group).join(' ')
            )
            setCardNumber(result)
            if (value.length === 19) {
                validateCard(value)
            }

        }
    }

    function containsWhitespace(str) {
        return /\s/.test(str);
    }

    return (
        <div className="container-fluid" style={{zIndex: 9999}}>
            <div className="creditCardForm" style={{zIndex: 9999}}>
                <div className="payment" style={{zIndex: 9999}}>
                    <div>
                        <div className="form-group owner">
                            <label htmlFor="owner">Kart Sahibi</label>
                            <input type="text"
                                   className="form-control"
                                   id="owner"
                                   onChange={(e) => {
                                       setCardOwner(e.target.value)
                                   }}/>
                        </div>
                        <div className="form-group CVV">
                            <label htmlFor="cvv">CVV</label>
                            <input type="text"
                                   className="form-control"
                                   id="cvv"
                                   onChange={(e) => {
                                       setCvv(e.target.value)
                                   }}
                            />
                        </div>
                        <div className="form-group" id="card-number-field">
                            <label htmlFor="cardNumber">Kart Numarası</label>
                            <input type="text"
                                   className="form-control"
                                   id="cardNumber"
                                   name="cardNumber"
                                   value={cardNumber}
                                   onChange={
                                       (e) => {
                                           handleCardNumber(e.target.value)
                                           // validateCard(e.target.value)
                                       }
                                   }
                            />
                        </div>
                        <div className="form-group" id="expiration-date">
                            <label>Bitiş Tarihi</label>
                            <div className="d-flex" style={{width: '100%', zIndex: '9999'}}>
                                <Select
                                    className="m-1 select-date"
                                    options={months}
                                    classNamePrefix="Select"
                                    value={expMonth}
                                    style={colourStyles}
                                    placeholder="Seçiniz"
                                    onChange={(e) => {
                                        setExpMonth(e);
                                    }}
                                />
                                <Select
                                    className="m-1 select-date"
                                    classNamePrefix="Select"
                                    options={years}
                                    value={expYear}
                                    onChange={(e) => {
                                        setExpYear(e);
                                    }}
                                    placeholder="Seçiniz"
                                    style={colourStyles}
                                />
                            </div>
                        </div>
                        <div className="form-group" id="credit_cards">
                            <img className="m-1" alt="aaa" src={visa} id="visa"/>
                            <img className="m-1" alt="aa" src={mastercard} id="mastercard"/>
                            {/*<img className="m-1" alt="a" src={amex} id="amex"/>*/}
                        </div>
                        <div style={{zIndex: '-9'}} className="form-group" id="pay-now">
                            <button variant="primary" onClick={() => {
                                // handleShow()
                                getPayment()
                            }}
                                    type="button"
                                // data-toggle="modal"
                                // data-target="#paymentModal"
                                    style={{color: 'black', background: 'lightblue'}} className="btn btn-default"
                                    id="confirm-purchase">
                                Ödeme Yap
                            </button>
                        </div>

                    </div>

                </div>
            </div>

            {/*{resp && (*/}
            <Modal style={{background: 'white', minHeight: '15rem', zIndex: '9999'}} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Odemeyi Dogrulayim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*{resp != null && (*/}
                    {/*    <div style={{width: '90vw', height: '10vh', display: ''}}*/}
                    {/*         dangerouslySetInnerHTML={{__html: `${resp}`}}>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {resp && (
                        <div className="d-flex justify-content-center" style={{zIndex: '9999'}}>
                            <h6>{amount} ₺ degerinde islem gerceklestirilecek. Onayliyor musunuz?</h6>
                            <div style={{width: '90vw', height: '10vh'}}
                                 dangerouslySetInnerHTML={{__html: `${resp}`}}>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            {/*)}*/}
        </div>
    );
}

export default Checkout;
