import React, {useEffect, useState, useContext} from "react";
import PaymentForm from "../PaymentForm";
import Advantages from "../../Common/Advantages";
import Checkout from "../Checkout/Checkout";
import {defaultGetRequestWithParams} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";

const options = [
    {
        key: 0,
        label: 'Ücretsiz Üyelik',
        price: '0',
        desc: '2 Emlak, 2 Vasıta olmak üzere toplam 4 ilan verin',
        subdesc: 'Her ürün için en fazla 7 görsel ekleyebilirsiniz'
    },
    {
        key: 1,
        label: '1 Aylık Üyelik',
        price: '250 TL',
        desc: '1 ay(30 gün) geçerli olmak üzere Emlak veya Vasıta, toplam 10 ilan verin',
        subdesc: 'Her ürün için en fazla 15 görsel ekleyebilirsiniz'
    },
    {
        key: 2,
        label: '1 Yıllık Üyelik',
        price: '1500 TL',
        desc: '1 yıl(365 gğn) geçerli olmak üzere Emlak veya Vasıta, toplam 30 ilan verin',
        subdesc: 'Her ürün için en fazla 30 görsel ekleyebilirsiniz'
    },
]


const RegisterPayment = ({advantages, setAdvantages, currentType}) => {
    const {baseURL} = useContext(BaseURLContext);
    const [amount, setAmount] = useState([])
    const [amounts, setAmounts] = useState([])
    const [resp, setResp] = useState(null)
    const [paymentModel, setPaymentModel] = useState(null)


    // const paymentModel = {
    //     type : 'Account',
    //     detail: advantages?.toString(), //ad type or
    //     // id: adId
    // }

    function getPrices() {
        getAdvantagePrices()
    }

    async function getAdvantagePrices() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/FieldManage/GetField`,
            {
                params: {
                    fieldType: 'MembershipDetail'
                }
            }
        )
        if (response) {
            setAmounts(response.data)
            if (advantages === 1) {
                setAmount(response.data[0].value)
            } else if (advantages === 2) {
                setAmount(response.data[1].value)
            }
        }
    }

    useEffect(() => {
        // getAdvantagePrices()
        getPrices()
        setPaymentModel(null)
        setPaymentModel({
                type : 'Account',
                detail: advantages?.toString()
            })
    }, [advantages])

    return (
        <>
            {/*{accStatus === 'form' &&*/}
            {/*    (*/}
            <>
                    <div style={{textAlign: "center"}}>
                        <div>

                            {amounts && (<Advantages
                                advantages={advantages}
                                setAdvantages={setAdvantages}
                                currentType={currentType}
                                amounts={amounts}
                            />)}


                        </div>
                        <div style={{height: '5vh'}}>


                        </div>
                        {advantages === 0
                            ?
                            (<div>
                                <h3>
                                    {options[0].label}
                                </h3>
                                <h6>
                                    {options[0].desc}
                                </h6>
                                <p>
                                    {options[0].subdesc}
                                </p>
                            </div>)
                            :
                            advantages === 1
                                ?
                                (
                                    <div>
                                        <h3>
                                            {options[1].label}
                                        </h3>
                                        <h5>
                                            {amounts && amounts[0]?.value}  ₺
                                        </h5>
                                        <h6>
                                            {options[1].desc}
                                        </h6>
                                        <p>
                                            {options[1].subdesc}
                                        </p>
                                    </div>
                                )
                                :
                                advantages === 2
                                    ? (
                                        <div>
                                            <h3>
                                                {options[2].label}
                                            </h3>
                                            <h5>
                                                {amounts && amounts[1]?.value}  ₺
                                            </h5>
                                            <h6>
                                                {options[2].desc}
                                            </h6>
                                            <p>
                                                {options[2].subdesc}
                                            </p>
                                        </div>
                                    )
                                    : (
                                        <div>
                                            {options[0].label}
                                        </div>
                                    )
                        }
                        {
                            advantages !== 0
                                ?
                                (
                                    <>
                                        <Checkout
                                            amount={amount}
                                            setResp={setResp}
                                            resp={resp}
                                            paymentModel={paymentModel}
                                            // setAccStatus={setAccStatus}
                                        />
                                        {/*<div className="row">*/}
                                        {/*    <div className="col-md-12">*/}
                                        {/*        {resp && (*/}
                                        {/*            <div style={{width: '90vw', height: '90vh', display: ''}} dangerouslySetInnerHTML={{__html: `${resp}`}}>*/}

                                        {/*            </div>)}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </>
                                )
                                :
                                (
                                    <>
                                    </>
                                )
                        }

                    </div>
                </>
            {/*)*/}
            {/*}*/}
            {/*{accStatus === 'done' &&*/}
            {/*    (<>*/}
            {/*            <div style={{textAlign: "center"}}>*/}
            {/*                <h3>Ödeme başarıyla gerçekleştirildi.</h3>*/}
            {/*            </div>*/}
            {/*    </>)}*/}
            {/*{accStatus === 'fail' &&*/}
            {/*    (<>*/}
            {/*            <div style={{textAlign: "center"}}>*/}
            {/*                <h3>Ödeme gerçekleştirilirken bir sorunla karşılaşıldı.</h3>*/}
            {/*            </div>*/}
            {/*    </>)}*/}
        </>
    )
}

export default RegisterPayment;