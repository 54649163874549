import { useState } from "react";
import { AuthContext } from "../../auth-context";

const AuthProvider = ({ children }) => {
    let [jwt, setJWT] = useState(null);
    let [isLoggedIn, setIsLoggedIn] = useState(false);

    let signin = (newJWT, callback) => {
        setIsLoggedIn(true);
        setJWT(newJWT);
        callback();
    };

    let signout = (callback) => {
        setIsLoggedIn(false);
        setJWT(null);
        callback();
    };

    let value = {isLoggedIn , jwt, signin, signout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
