import React, {useState} from "react";
import AdOverview from "../Components/User/AdOverview";
import NavbarIn from "../Components/Layout/NavbarIn";
import {Link} from "react-router-dom";
import UserManage from "../Components/User/UserManage";

const AdOverviews = () => {

    const [currentUser, setCurrentUser] = useState();
    return (
        <div>
            <NavbarIn setCurrentUser={setCurrentUser}/>
            <UserManage currentUser={currentUser} eventKeyProp={'favourites'}/>
        </div>
    );
}

export default AdOverviews;
