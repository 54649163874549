import React, {useContext, useState} from "react";
import {useEffect} from "react";
import Select from "react-select";
import {defaultGetRequest, defaultGetRequestWithParams} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";

const sampleObject = {
    label: '',
    value: ''
}

function Location({
                      setSelectedCity,
                      setSelectedTown,
                      setSelectedNeighbour,
                      isRedDot,
                      selectedC,
                      selectedT,
                      selectedN,
                      extentedLocation,
                      position
                  }) {
    const {baseURL} = useContext(BaseURLContext);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(sampleObject);
    const [states, setStates] = useState([]);
    const [state, setState] = useState(sampleObject);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [neighbourhood, setNeighbourhood] = useState(sampleObject);
    // const [stateList, setStateList] = useState([]);
    const [redDot, setRedDot] = useState([]);

    useEffect(() => {
        if (isRedDot === true) {
            setRedDot(true)
        }
    }, [])

    async function getCityData() {
        // const cityNames = await getCityName()
        setCities([])
        setCity(sampleObject)
        setStates([])
        setState(sampleObject)
        setNeighbourhoods([])
        setNeighbourhood(sampleObject)
        const response = await defaultGetRequest(
            `${baseURL}/Addresses/GetCities`,
        )
        if (response) {
            for (let i = 0; i < response.data.length; i++) {
                setCities(elem => [...elem, {value: response.data[i], label: response.data[i]}])
            }
        }

        return true
    }

    async function getStateData(selected) {
        // if (city) {
            setStates([])
            setState(sampleObject)
            setNeighbourhoods([])
            setNeighbourhood(sampleObject)

            const response = await defaultGetRequestWithParams(
                `${baseURL}/Addresses/GetStates`,
                {
                    params: {
                        city: selected
                    }
                }
            )
            if (response) {
                for (let i = 0; i < response.data.length; i++) {
                    setStates(elem => [...elem, {value: response.data[i], label: response.data[i]}])
                }
            }

            return true
        // }
    }

    async function getNeighbourhoodData(selectedState,selectedCity) {
        setNeighbourhoods([])
        setNeighbourhood(sampleObject)

        const response = await defaultGetRequestWithParams(
            `${baseURL}/Addresses/GetNeighbourhoods`,
            {
                params: {
                    city: city.label || selectedCity,
                    state: selectedState
                }
            }
        )
        if (response) {
            for (let i = 0; i < response.data.length; i++) {
                setNeighbourhoods(elem => [...elem, {value: response.data[i], label: response.data[i]}])
                // arr.push({value: cityNames[i], label: cityNames[i]})
            }
        }

        return true
    }


    useEffect(() => {
        if (selectedC != null) {
            setCity({label: selectedC, value: selectedC})
        }
    }, [selectedC])


    useEffect(() => {
        if (selectedT != null && selectedC != null) {
            setState({label: selectedT, value: selectedT})
            getNeighbourhoodData(selectedT,selectedC)
        }
    }, [selectedT,selectedC])

    useEffect(() => {
        const response = getStateData(selectedC);

        if (selectedT != null && selectedC != null && response) {
            for (let i = 0; i < neighbourhoods?.length; i++) {
                if (neighbourhoods[i].label === selectedT) {
                    setNeighbourhood(neighbourhoods[i]);
                    setSelectedNeighbour(neighbourhoods[i].label.toString())
                }
            }
        }
    }, [selectedN])

    useEffect(() => {
        getCityData()
        // const test = getNeighbourhoodsAsync('Manisa', 'Turgutlu')
        // console.log('test neighbourhoods', test);
    }, []);


    const handleCityChange = async (obj) => {
        setCity(obj);
        setSelectedCity(obj.label)
        await getStateData(obj.label)
    };

    const handleStateChange = async (obj) => {
        setState(obj);
        setSelectedTown(obj.label)
        await getNeighbourhoodData(obj.label,'')
    };

    const handleNeighbourChange = (obj) => {
        setNeighbourhood(obj);
        setSelectedNeighbour(obj.label)
    };
    // var arr = [];

    // function convertToObjetcs(obj) {
    //     for (var i = 0; i < obj.district.length; i++) {
    //         var dist = new Object();
    //         dist.code = i.toString();
    //         dist.name = obj.district[i];
    //         arr.push(dist);
    //     }
    //     setStateList(arr);
    //     return arr;
    // }

    return (
        <div
            className={position ? "d- flex" : "App"}
            style={position ? {display: 'flex'} : {}}
        >
            {cities && (<div className="m-2 w-100"
                // style={{width: '200px'}}
            >
                <b>
                    {redDot === true ? (<span style={{color: 'red', fontSize: '14px'}}>*</span>) : (<></>)}
                    İl
                </b>
                <Select
                    placeholder="Seçim yapın"
                    value={city}
                    options={cities}
                    onChange={handleCityChange}
                    getOptionLabel={(x) => x.label}
                    getOptionValue={(x) => x.value}
                />
            </div>)}
            <br/>
            {states && (<div className="m-2 w-100">
                <b>
                    {redDot === true ? (<span style={{color: 'red', fontSize: '14px'}}>*</span>) : (<></>)}
                    İlçe
                </b>
                <Select
                    placeholder="Seçim yapın"
                    value={state}
                    options={states}
                    onChange={handleStateChange}
                    getOptionLabel={(x) => x.label}
                    getOptionValue={(x) => x.value}
                />
            </div>)}
            <br/>

            {extentedLocation && neighbourhoods &&
                (<div className="m-2 w-100">
                    <b>
                        {redDot === true ? (<span style={{color: 'red', fontSize: '14px'}}>*</span>) : (<></>)}
                        Mahalle
                    </b>
                    <Select
                        placeholder="Seçim yapın"
                        value={neighbourhood}
                        options={neighbourhoods}
                        onChange={handleNeighbourChange}
                        getOptionLabel={(x) => x.label}
                        getOptionValue={(x) => x.value}
                    />
                </div>)}
        </div>
    );
}

export default Location;
