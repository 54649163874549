import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {defaultGetRequest, defaultGetRequestWithParams} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import {setAdTitleStringLimit} from "../static/helpers";
import City from "../Components/SearchTest/Filters/City";
import useForm from "../Components/useForm/useForm";
import CarSubCategory from "../Components/Search/Filters/CarSubCategory";

const CarCat = () => {
    const {baseURL} = useContext(BaseURLContext);
    const {slug} = useParams();
    // const [carCats, setCarCats] = useState([]);
    const {values, errors, handleChange, handleSubmit} = useForm();
    const [result, setResult] = useState([]);
    // const [mainCat, setMainCat] = useState();
    // const [mainCatVal, setMainCatVal] = useState("");

    // const [subCatVal4, setSubCatVal4] = useState('');
    // const [subCat4, setSubCat4] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedTown, setSelectedTown] = useState("");
    const [type, setType] = useState(null);
    const [year, setYear] = useState(null);
    const [make, setMake] = useState(null);
    const [fuelType, setFuelType] = useState(null);

    useEffect(() => {
        GetCarSearch();
        setType(slug)
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        // GetCarSearch();
        setType(slug)
        //eslint-disable-next-line
    }, []);



    async function GetCarSearch() {
        setResult([])
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/SearchCars`,
            {
                params: {
                    name: '',
                    city: selectedCity,
                    state: selectedTown,
                    category: slug,
                    // make: subCatVal1,
                    // model: subCatVal2,
                    // year: subCatVal3,
                    // type
                    // year
                    // make
                    minPrice: values.minPrice || 0,
                    maxPrice: values.maxPrice || 0,
                },
            }
        );

        if (response) {
            setResult(response.data)
        }
    };

    // async function ClearLevel1(selected) {
    //     setSubCat1([])
    //     setSubCat2([])
    //     setSubCatVal2(null)
    //     setSubCat3([null])
    //     setSubCatVal3(null)
    //     // setSubCat4([])
    //     setSubCat1(elem => [...elem, selected])
    // }


    return (
        <div className="category-container">
            <div className="row">
                <div className="col-md-11 p-1">
                    <div className="row">
                        {/*style={{background:'lightgrey'}}*/}
                        <div className="filter-container">
                            <form onSubmit={handleSubmit} className="form-floating">
                                <div className="row">
                                    <div className="col-md-6">
                                        <City
                                            selectedCity={selectedCity}
                                            setSelectedCity={setSelectedCity}
                                            selectedTown={selectedTown}
                                            setSelectedTown={setSelectedTown}
                                            onChange={handleChange}
                                            className="col-md-12"
                                        />
                                    </div>
                                    <div className="col-md-5 row">
                                        <div className="m-1 p-1 col-md-2">
                                            <label className="field field_v3" htmlFor="minPrice">
                                                Min(₺)
                                            </label>
                                            <input className="rounded-1 number-input field__input"
                                                   style={{
                                                       borderWidth: '1px',
                                                       lineHeight: '27px',
                                                       borderColor: 'gray',
                                                       width: '70px',
                                                       textIndent: '0.5em'
                                                   }}
                                                   id="minPrice"
                                                   type="number"
                                                   name="minPrice"
                                                   value={values.minPrice || 0}
                                                   onChange={handleChange}/>
                                        </div>
                                        <div className="m-1 p-1 col-md-2">
                                            <label className="field field_v3" htmlFor="maxPrice">
                                                Max(₺)
                                            </label>
                                            <input className="rounded-1 number-input field__input"
                                                   style={{
                                                       width: '70px',
                                                       textIndent: '0.6em'
                                                   }}
                                                   id="maxPrice"
                                                   name="maxPrice"
                                                   type="number"
                                                   value={values.maxPrice || 0}
                                                   onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <CarSubCategory
                                            type={slug}
                                            // year={year}
                                            // setYear={setYear}
                                            make={make}
                                            setMake={setMake}
                                            fuelType={fuelType}
                                            setFuelType={setFuelType}
                                        />
                                    </div>
                                    <div className="col-md-2"
                                         style={{marginLeft: '0', float: 'left', marginTop: '1.7rem'}}>
                                        <Link
                                            type="button"
                                            style={{width: '80px'}}
                                            className="p-2 rounded rounded-5 btn-info"
                                            to={`/vasita-ara?category=${slug}&city=${selectedCity}&state=${selectedTown}&minPrice=${values.minPrice}&maxPrice=${values.maxPrice}&make=${make?.label}&fuelType=${fuelType?.label}`}
                                        >
                                            Ara
                                        </Link>
                                    </div>
                                </div>

                                {/*        <div>*/}
                                {/*</div>*/}
                            </form>
                        </div>
                    </div>
                    <div style={{marginTop: '5rem'}} className="image-row row">
                        <h5>Vitrin</h5>
                        {result && result.map((item, i) => {
                            return (
                                <div key={item.id} className="image-column">
                                    <Link to={`/vasita/${item.id}`}>
                                        <div className="image-content">
                                            {/*NULL IMAGE HANDLER*/}
                                            <img src={`${item.coverImage}`} alt="Mountains" style={{width: '100%'}}/>
                                            <p>{setAdTitleStringLimit(item.name)}</p>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default CarCat;
