import React, {useState} from "react";
import NavbarIn from "../Components/Layout/NavbarIn";
import UserManage from "../Components/User/UserManage";

const AdOverviews = () => {
    const [currentUser, setCurrentUser] = useState();
    return (
        <div>
            <NavbarIn setCurrentUser={setCurrentUser}/>
            <UserManage currentUser={currentUser} eventKeyProp={'board'}/>

            {/*<AdOverview d />*/}

        </div>
    );
}

export default AdOverviews;
