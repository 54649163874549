import React, {useState, useEffect} from "react";
import Cookies from "universal-cookie";
import Logo from "./Logo";
// import SearchBody from "../Search/SearchBody";
import Main from "./Main";
import NavbarOut from "./NavbarOut";
import NavbarIn from "./NavbarIn";
// import useAuth from "../useAuth/useAuth";
import SearchNew from "../Search/SearchNew";
import '../../assets/css/index.css'
import { Helmet } from 'react-helmet'
import {useWindowSize} from "../../static/helpers";
// import Navbar from "./Navbar";

const Home = () => {
    // const auth = useAuth();
    const [navbar, setNavbar] = useState(false);
    const [navLogo, setNavLogo] = useState(false);
    const [search, setSearch] = useState("");
    const [searchTerm, setSearchTerm] = useState();
    const [searchType, setSearchType] = useState();
    const [innerHeight, setInnerHeight] = useState(0);
    const [initialInnerHeight, setInitialInnerHeight] = useState(0);
    // const [focus, setFocus] = useState(0);
    const [val, setVal] = useState([]);
    const [logo, setLogo] = useState(true);
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt");
    const token = cookie;

    const size = useWindowSize();

    useEffect(() => {
        setInitialInnerHeight(window.innerHeight)
        if (token) {
            setNavbar(true);
        }
        if (search === "" || search === undefined) {
            setLogo(false)
            setNavLogo(true);
        } else {
            setLogo(true)
            setNavLogo(false);
        }
    },[]);

    return (
        <div>
            <Helmet>
                <title>
                    Hepsi Oto Emlak
                </title>
            </Helmet>
            <div>
                {navbar === false ? (
                    <NavbarOut navLogo={navLogo}/>
                ) : (
                    <NavbarIn navLogo={navLogo}/>
                )}
            </div>
            <div>
                {!logo && <Logo initialInnerHeight={initialInnerHeight} size={size}/>}

            </div>
            <div style={{marginTop:'2rem'}}>
                    <SearchNew
                        search={search}
                        setSearch={setSearch}
                        setVal={setVal}
                        val={val}
                        setSearchTerm={setSearchTerm}
                        searchTerm={searchTerm}
                        setSearchType={setSearchType}
                    />
            </div>
            {!logo &&
                (<div style={size.width > 680 ? {paddingBottom: "25%"} : {paddingBottom: "100%"}}>
                </div>)}
            <div>
                <Main/>
            </div>
        </div>
    );
};

export default Home;
