import React, {useContext, useEffect, useRef, useState} from "react";
import * as signalR from "@microsoft/signalr";
import {HubConnectionBuilder} from "@microsoft/signalr";
import Cookies from "universal-cookie";
import {BaseURLContext} from "../../baseURL-context";
import {defaultGetRequestWithParams} from "../../static/functions";
import useForm from "../useForm/useForm";
import NoImage from "../../img/no-image.png";
import '../../assets/css/userManage.css'
// import {Link} from "react-router-dom";
import {useNavigate} from "react-router";

const FirstMessage = ({productId, currentUser, adOwnerUsername, type}) => {
    const {baseURL} = useContext(BaseURLContext);
    const {values, handleChange, handleSubmit} = useForm(null);
    const [message, setMessage] = useState('');
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt");
    const [connection, setConnection] = useState(null);
    // const [message, setMessage] = useState('');
    const [to, setTo] = useState('');
    const [from, setFrom] = useState([])
    const [messages, setMessages] = useState([]);
    const [sender, setSender] = useState([])
    const [ad, setAd] = useState()
    const navigate = useNavigate();


    const latestChat = useRef();


    latestChat.current = messages;


    // useEffect(() => {
    //     let interval = null;
    //     if (redirect) {
    //         setRedirect(false);
    //         interval = setInterval(() => {
    //             // Redirect to another page
    //         }, 1000);
    //     }
    //     return () => interval ? clearInterval(interval) : null;
    // }, [redirect])

    useEffect(() => {
        const options = {
            accessTokenFactory: () => cookie,
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": process.env.REACT_APP_URL,
            // "Access-Control-Allow-Origin": "https://hepsiotoemlak.com:4242",
            "Access-Control-Allow-Credentials": true,
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
        };
        const connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API + '/hubs/chat',
            // .withUrl('https://hepsiotoemlak.com:44373/hubs/chat',
                options
            )
            .withAutomaticReconnect()
            .build();
        setConnection(connection)
        connection.start()
            .then(result => {
                console.log('Connected!');
                connection.on('ReceiveMessage'
                    , message => {
                        setMessages((elem) => [...elem, message]);
                        const updatedChat = [...latestChat.current];
                        updatedChat.push(message);

                        console.log("message : " + message)
                        var receivedMessage = {
                            from: currentUser,
                            messageText: message,
                            to: to,
                            productId: 3
                        }

                        setMessages((elem) => [...elem, receivedMessage]);
                    });
            })
            .catch(e => console.log('Connection failed: ', e));
        getProductDetail(productId);
    }, [])

    const sendMessage = async () => {
        const chatMessage = {
            from: currentUser,
            to: adOwnerUsername,
            productId: parseInt(productId),
            messageText: message,
            productType: ''
        };

        if (connection) {
            try {

                await connection.send('SendChatMessage', chatMessage);
                const timer = setTimeout(() => {
                    navigate("/mesajlar", {state:{user:adOwnerUsername,message: message}})
                }, 3000);

            } catch (e) {
                console.log(e);
            }
        } else {
            alert('No connection to server yet.');
        }
    }
    const onSubmit = (e) => {
        // e.preventDefault();
        // console.log(message)
        //CHECK IF username AND adId PARAMETERS MATCH!!!
        // currentUser && adOwnerUsername && productId  &&
        sendMessage();
        setMessage('')
        // navigate("/mesajlar")

    }

    const handleMessageChange = e => {
        setMessage(e.target.value);
    };

    async function getProductDetail(prodId) {
        if (type === 'estate') {
            var response = await defaultGetRequestWithParams(
                `${baseURL}/Search/GetEstateById`,
                {
                    params: {
                        id: prodId
                    },

                })
            if (response) {
                setAd(response.data.estate);
            } else {
                console.log("getProductDetail no response")
            }
        } else if (type === 'car') {
            const response = await defaultGetRequestWithParams(
                `${baseURL}/Search/GetCarById`,
                {
                    params: {
                        id: prodId
                    },
                })
            if (response) {
                setAd(response.data);
                // console.log("estate message ad data "+JSON.stringify(response))
            } else {
                console.log("getProductDetail no response")
            }
        }
    }

    function goMessages() {

    }


    const tableStyle = {
        margin: '0 auto',
        width: '60%'
    }
    const messageStyle = {
        margin: '0 auto',
        width: '60%',
        paddingTop: '3rem',

        height: '200px'
    }

    const textAreaStyle = {
        width: '100%',

    }

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <button>Tum mesajlara git</button>
                </div>
                <div className="col-md-12">
                    <div>
                        <table style={tableStyle}>
                            <tbody>
                            {/*{estate && estate.map((item,i) => {*/}
                            {/*    return (*/}
                            {ad && (
                                <tr>
                                    <td className="w-25">
                                        {ad?.smallImage != null ? (<img style={{width: '100%'}}
                                                                        src={ad?.smallImage}
                                                // src={`data:image/jpeg;base64,${estate.coverImage}`}
                                                                        alt="test"/>) :
                                            <div><img src={NoImage}/></div>}
                                    </td>
                                    <td>
                                        {/*<div style={{float: 'left'}}>*/}
                                        <p>
                                            {ad.name}
                                        </p>

                                        {/*</div>*/}

                                    </td>
                                    <td>
                                        <p>
                                            {ad.city}{" "}/{" "}{ad.state}
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            {ad.price}
                                        </p>
                                    </td>
                                    {/*<td>test123</td>*/}
                                </tr>

                            )}
                            </tbody>
                        </table>
                    </div>
                    <div style={messageStyle}>
                        {/*<form*/}
                        {/*    // onSubmit={onSubmit}*/}
                        {/*>*/}
                            <div className="type_msg2">
                                <div className="input_msg_write">
                                    <input
                                        type="text"
                                        // rows="2"
                                        style={{textIndent: '1rem'}}
                                        id="message2"
                                        name="message"
                                        value={message || ''}
                                        onChange={handleMessageChange}
                                    />
                                    <button
                                        className="msg_send_btn2"
                                        onClick={() => {
                                            onSubmit()
                                        }}
                                        style={{marginRight: '5px'}}>
                                        <i className="fa fa-paper-plane-o" aria-hidden="true"/>
                                    </button>
                                </div>
                            </div>
                        {/*</form>*/}
                        {/*<form onSubmit={onSubmit} style={{width: '100%'}}>*/}
                        {/*    <textarea*/}
                        {/*        // className="form-floating"*/}
                        {/*        style={textAreaStyle}*/}
                        {/*              id="message"*/}
                        {/*              name="message"*/}
                        {/*              onChange={handleChange}*/}
                        {/*              value={values.message || ""}*/}
                        {/*              rows={1}*/}
                        {/*    />*/}
                        {/*    <button>*/}
                        {/*        mesaj gonder*/}
                        {/*    </button>*/}
                        {/*</form>*/}
                    </div>
                </div>
            </div>
            <div>
                <button>
                    Mesajlara Git
                </button>
            </div>
            {/*ASIDE -- GO TO MESSAGES*/}
            {/*CREATE A PREVIEW*/}
            {/*MESSAGE BOX*/}
            {/*SEND BUTTON*/}
        </div>
    );
}
export default FirstMessage;