import React, {useContext, useEffect, useState} from "react";
import {Row, Col} from "react-bootstrap";
import useForm from "../../useForm/useForm";
// import {BaseURLContext} from "../../../baseURL-context";
import {Link} from "react-router-dom";
import '../../../assets/css/searchBar.css'
import {BaseURLContext} from "../../../baseURL-context";

const SmallSearch = () => {
    const {baseURL} = useContext(BaseURLContext);
    const {values, handleChange} = useForm(null);
    const [optionText, setOptionText] = useState('Emlak')
    const [searchType, setSearchType] = useState('')
    const [optionQuery, setOptionQuery] = useState('')
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        if (optionText === 'Emlak') {
            setOptionQuery('emlak-ara')
        } else if (optionText === 'Vasıta') {
            setOptionQuery('vasita-ara')
        }
        setSearchQuery(`/${optionQuery}?text=${values.keyword}`)
    }, [optionText, optionQuery, searchQuery, values.keyword])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
        }
    }

    const navbar = {
    }

    return (
        <div>
            <Row className="small-search-wrapper">
                <Col md={12} className="centered">
                    <ul style={navbar} className="nav2 border border-5">
                        <li id="search2" className="row">
                            <form>
                                <input
                                    style={{backgroundColor: "white", borderStyle: "none", height: 'auto'}}
                                    placeholder="Aramak için anahtar kelime girin"
                                    name="keyword"
                                    id="keyword_small"
                                    className="col-md-7"
                                    value={values.keyword || ''}
                                    onChange={handleChange}
                                    type="text"
                                />
                                <div>
                                    <Link
                                        className="fa fa-search"
                                        id="search_button_small"
                                        style={{marginTop: '0.5rem', marginLeft:'0.5rem', textDecoration: 'none'}}
                                        to={`${searchQuery}`}
                                    >
                                    </Link>
                                </div>
                            </form>
                        </li>
                        <li id="options2">
                            {optionText && <button className="search-option2">
                                <strong>
                                    {optionText}
                                </strong>
                            </button>}
                            <ul className="subnav2">
                                <li>
                                    <div
                                        className="search-option2"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            setOptionText('Emlak')
                                        }}
                                    >
                                        <strong>
                                            Emlak
                                        </strong>

                                    </div>
                                </li>
                                <li>
                                    <div
                                        className="search-option2"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            setOptionText('Vasıta')
                                        }}
                                    >
                                        <strong>
                                            Vasıta
                                        </strong>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
    );
};

export default SmallSearch;
