import React from "react";
import Swappable from "../Swappable";
import FloorPayback from "../FloorPayback";
import Reconstruction from "../Reconstruction";
import Ownership from "../Ownership";


const Land = ({
                  handleChange,
                  values,
                  setReconstruction,
                  setAda,
                  setParcel,
                  setPafta,
                  setKaks,
                  setGabari,
                  setFloorPayback,
                  setSwappable,
                  reconstruction,
                  ada,
                  parcel,
                  pafta,
                  kaks,
                  gabari,
                  setSelectedOwnership,
                  selectedOwnership,
                  estateObject
              }) => {

    return (
        <div>
            <div className="form-floating mb-3">
                <div className="col-md-3 d-flex flex-column">
                    <label htmlFor="reconstruction" className="float-start">
                        <strong>İmar durumu</strong>
                    </label>
                    <Reconstruction
                        className='w-100'
                        id="reconstruction"
                        setReconstruction={setReconstruction}
                        selectedLabel={estateObject?.reconstruction}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="ada" className="float-start">
                        <strong>Ada No</strong>
                    </label>
                    <input
                        type="text"
                        min="0"
                        name="ada"
                        className="form-control"
                        id="ada"
                        placeholder="Ada No"
                        value={ada}
                        // value={values. || ""}
                        onChange={(e) => {
                            setAda(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="parcel" className="float-start">
                        <strong>Parsel No</strong>
                    </label>
                    <input
                        type="text"
                        min="0"
                        name="parcel"
                        className="form-control"
                        id="parcel"
                        value={parcel}
                        placeholder="Parsel No"
                        // value={values. || ""}
                        onChange={(e) => {
                            setParcel(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="pafta" className="float-start">
                        <strong>Pafta No</strong>
                    </label>
                    <input
                        type="text"
                        min="0"
                        name="pafta"
                        className="form-control"
                        id="pafta"
                        value={pafta}
                        placeholder="Pafta No"
                        // value={values. || ""}
                        onChange={(e) => {
                            setPafta(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="kaks" className="float-start">
                        <strong>Kaks</strong>
                    </label>
                    <input
                        type="text"
                        min="0"
                        name="kaks"
                        value={kaks}
                        className="form-control"
                        id="kaks"
                        placeholder="Kaks"
                        // value={values. || ""}
                        onChange={(e) => {
                            setKaks(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="gabari" className="float-start">
                        <strong>Gabari</strong>
                    </label>
                    <input
                        type="text"
                        min="0"
                        name="gabari"
                        value={gabari}
                        className="form-control"
                        id="gabari"
                        placeholder="Gabari"
                        // value={values. || ""}
                        onChange={(e) => {
                            setGabari(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3 d-flex flex-column">
                    <label htmlFor="floorpayback" className="float-start w-100">
                        <strong className="float-start">Kat Karşılığı</strong>
                    </label>
                    <FloorPayback
                        className='w-100'
                        id="floorpayback"
                        setFloorPayback={setFloorPayback}
                        selectedLabel={estateObject?.floorPayback}
                    />
                </div>
            </div>
            {/*{estateObject && */}
            <div className="form-floating mb-3">
                <div className="col-md-3 d-flex flex-column">
                    <label htmlFor="swappable" className="float-start w-100">
                        <strong className="float-start">Takaslı</strong>
                    </label>
                    <Swappable
                        className='w-100'
                        id="swappable"
                        setSwappable={setSwappable}
                        selectedLabel={estateObject?.swappable}
                    />
                </div>
            </div>
            {/*}*/}
            {/*{estateObject && */}
            <div className="form-floating mb-3">
                <div className="col-md-3 d-flex flex-column">
                    <label htmlFor="ownership" className="d-flex" style={{flexDirection: 'column'}}>
                        {" "}
                        <strong>Tapu Durumu</strong>
                        <Ownership
                            setSelectedOwnership={setSelectedOwnership}
                            selectedLabel={estateObject?.ownership || "Seçiniz"}
                            selectedOwnership={selectedOwnership}
                        />
                    </label>
                </div>
            </div>
            {/*}*/}
        </div>
    );
}

export default Land;
