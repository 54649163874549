import React from "react";
import StartCreateCar from "../Components/Product/Car/Create/StartCreateCar"
import '../assets/css/createAd.css'

const CreateCar = () => {
    return (
        <div>
            <StartCreateCar />
        </div>
    );
}

export default CreateCar;
