import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Tab, Nav, Row, Col} from "react-bootstrap";
import {
    defaultGetRequest,
    defaultGetRequestWithParams,
    protectedEnpointGetRequest,
    protectedEnpointPostRequest
} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import Carousel from '../Components/Products/Carousel'
import Location from "../Components2/Product/ProductDetails/Location"
import Cookies from "universal-cookie";
// import {AiFillStar} from "react-icons/ai";
// import {AiOutlineStar} from "react-icons/ai";
// import TechicalDetails from "../Components/Product/Car/View/Components/TechnicalDetails";
import '../assets/css/adPreview.css'
import MessageBox from "../Components/Common/MessageBox";
import TechnicalDetails from "../Components/Product/Car/View/Components/TechnicalDetails";
import {
    FacebookIcon,
    FacebookShareButton, TelegramIcon, TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {generateUrl} from "../static/helpers";
// import HelmetMetaData from "../Components/Core/HelmetMetaData";
// import Details from "../Components/Core/Details";


const Post = ({setMetadata}) => {
    const {baseURL} = useContext(BaseURLContext);
    const baseApp = process.env.REACT_APP_MAIN
    const [car, setCar] = useState([]);
    const [owner, setOwner] = useState([]);
    const [favourite, setFavourite] = useState(null);
    const [currentUser, setCurrentUser] = useState([]);
    const [favIconStatus, setFavIconStatus] = useState(null);
    const [isLogged, setIsLogged] = useState();
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt")
    const [tabState, setTabState] = useState('details');

    const {id} = useParams();
    const type = "car";
    useEffect(() => {
        getCarById();
        CheckFavourite();
        CheckUserActivity();
        getCurrentUser();
        //eslint-disable-next-line
    }, []);

    async function CheckUserActivity() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/authentication/CheckUserActivity`,
            cookie
        )
        if (response) {
            if (response.data === true) {
                setIsLogged(true)
            } else {
                setIsLogged(false)
            }
        } else {
            setIsLogged(false)
        }
    }

    async function getCurrentUser() {
        const getUser = await protectedEnpointGetRequest(
            `${baseURL}/UserManage/GetUser`,
            cookie
        )
        if (getUser) {
            setCurrentUser(getUser.data)
        }
    }

    async function getCarById() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/GetCarById`,
            {
                params: {
                    id: id,
                },
            }
        );
        if (response) {
            setCar(response.data);
            setOwner(response.data.owner)
            setMetadata(
                {
                    image: response.data.car.coverImage,
                    title: response.data.car.name,
                }
            )
            return null;
        }
    }

    async function CheckFavourite() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/authentication/CheckUserActivity`,
            cookie
        );
        if (response) {
            if (response.data === true) {
                const requestBody = {
                    Id: parseInt(id),
                    Type: 1
                }
                const fav = await protectedEnpointPostRequest(
                    `${baseURL}/Membership/GetFavourite`,
                    requestBody,
                    cookie
                );

                if (fav) {
                    setFavIconStatus(fav.data.result)
                }
            }
        }
    }



    async function SetFavourite() {
        const requestBody = {
            Id: parseInt(id),
            Type: 1
        }
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Membership/SetFavourite`,
            requestBody,
            cookie
        );
        if (response) {
            window.location.reload()
        }
    }

    const post = car;

    const {
        adType,
        brand,
        caseType,
        categoryId,
        categoryType,
        city,
        color,
        coverImage,
        createdOn,
        description,
        editedOn,
        endyear,
        from,
        fuel,
        fuelType,
        gear,
        // id,
        insurance,
        isActive,
        isDopingEnabled,
        km,
        licensePlate,
        model,
        motorCapacity,
        motorPower,
        name,
        price,
        props,
        province,
        rootCatId,
        serie,
        smallImage,
        state,
        status,
        traction,
        userId,
        year,

    } = post.car || [];

    const {ownerName, phone, username} = owner || [];

    const border = {
        color: "black",
        borderBottom: '1px solid black',
        width: '100%',
        lineHeight: '2em'
    }
    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: '1px',
        borderColor: "#000000",
    }

    const activeStyle = {
        background: 'lightblue'
    }

    const passiveStyle = {
        background: 'lightgray'
    }

    const rrr = {image: coverImage, title: name}

    return (
        <div className="container-ad">
            <br/>
            <div
                className="card"
                style={{backgroundColor: "white"}}
            >
                <div className="row">
                    <h3 className="title col-md-12 d-flex m-auto mb-4"
                        style={border}>
                        {name}
                    </h3>
                </div>
                <div className="row">
                    <div className="col-md-5 border-right p-3 mt-4">
                        <Carousel id={id} adType={1}/>
                    </div>
                    <div className="col-md-4">
                        <article className="card-body">
                            <div className="price-detail-wrap row">
                                <p className="col-md-12 price-tag" style={{color: "black"}}>
                                    <strong> {price} </strong>
                                </p>
                            </div>
                            <div className="price-detail-wrap row">
                                <span className="col-md-12 city-state">
                                    {/*<strong>*/}
                                    {city} / {state}
                                    {/*</strong>*/}
                                </span>
                            </div>
                            <hr style={hrStyle}/>
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Marka</strong>
                                </label>
                                <span className="col-md-4 ad-detail">{brand}</span>
                            </div>
                            <hr style={hrStyle}/>
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Model</strong>
                                </label>
                                <span className="col-md-4 ad-detail">{model}</span>
                            </div>
                            <hr style={hrStyle}/>
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Yıl</strong>
                                </label>
                                <span className="col-md-4 ad-detail">{year}</span>
                            </div>
                            <hr style={hrStyle}/>
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Vites</strong>
                                </label>
                                <span className="col-md-4 ad-detail">{gear}</span>
                            </div>
                            <hr style={hrStyle}/>
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Kilometre</strong>
                                </label>
                                <span className="col-md-4 ad-detail">{km}</span>
                            </div>
                            <hr style={hrStyle}/>
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Kasa tipi</strong>
                                </label>
                                <span className="col-md-4 ad-detail">{caseType}</span>
                            </div>
                            <hr style={hrStyle}/>
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Çekiş</strong>
                                </label>
                                <span className="col-md-4 ad-detail">{traction}</span>
                            </div>
                            <hr style={hrStyle}/>
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Renk</strong>
                                </label>
                                <span className="col-md-4 ad-detail">{color}</span>
                            </div>
                            <hr
                                style={hrStyle}
                            />
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Plaka</strong>
                                </label>

                                <span className="col-md-4 ad-detail">{licensePlate}</span>
                            </div>
                            <hr
                                style={hrStyle}
                            />
                        </article>
                    </div>
                    <aside className="col-md-3 p-3 favourites-wrapper">
                        {currentUser?.username !== username
                            &&
                            (
                                <div>
                                    <MessageBox
                                        type={type}
                                        phone={phone}
                                        ownerName={ownerName}
                                        id={id}
                                        username={username}
                                        isLogged={isLogged}
                                    />
                                    <div className="title m-3 favourites-button-wrapper"
                                         style={{color: "black", cursor: 'pointer'}}
                                         onClick={() => {
                                             SetFavourite();
                                         }}>
                                        {favIconStatus
                                            ?
                                            (
                                                <h6 className="favourites-text">Favorilerden Cikar</h6>
                                            )
                                            :
                                            (
                                                <h6 className="favourites-text">Favorilere Ekle</h6>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div>
                            <div><h6>Paylaş</h6></div>
                            <div>
                                <FacebookShareButton
                                    url={generateUrl(baseApp)}
                                    quote={name}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={32} round/>
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={generateUrl(baseApp)}
                                    title={name}
                                    className="Demo__some-network__share-button"
                                >
                                    <TwitterIcon size={32} round/>
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    url={generateUrl(baseApp)}
                                    title={name}
                                    separator=":: "
                                    className="Demo__some-network__share-button"
                                >
                                    <WhatsappIcon size={32} round/>
                                </WhatsappShareButton>
                                {/*<a href={`https://api.whatsapp.com/send?phone=90${phone}&text=${generateUrl(baseApp)}`} target="_blank" rel="noreferrer">*/}
                                {/*    <WhatsappIcon size={32} size={14} round/>*/}
                                {/*</a>*/}
                                <TelegramShareButton
                                    url={generateUrl(baseApp)}
                                    title={name}
                                    className="Demo__some-network__share-button"
                                >
                                    <TelegramIcon size={32} round/>
                                </TelegramShareButton>
                            </div>
                        </div>
                    </aside>
                </div>
                <div className="row col-md-12">
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-2 btn rounded-0 m-2"
                         onClick={() => {
                             setTabState('details')
                         }}
                         style={tabState === 'details' ? activeStyle : passiveStyle}
                    >
                        İlan Detayları
                    </div>
                    <div className="col-md-2 btn rounded-0 m-2"
                         onClick={() => {
                             setTabState('technical')
                         }}
                         style={tabState === 'technical' ? activeStyle : passiveStyle}
                    >
                        Teknik Detaylar
                    </div>
                    <div className="col-md-4">

                    </div>
                </div>
                <div className="col-md-12">
                    {tabState === 'details' &&
                        (
                            <div className="row"
                                 style={{margin: "0 auto", backgroundColor: "lightgray", minHeight: "10rem"}}>
                                <div className="d-flex center justify-content-center align-items-center col-md-12">
                                    <div dangerouslySetInnerHTML={{__html: `${description}`}}>
                                    </div>
                                </div>
                            </div>
                        )}
                    {tabState === 'technical' &&
                        (
                            <div className="row" style={{margin: "0 auto", minHeight: "10rem"}}>
                                <TechnicalDetails categoryId={categoryId}/>
                            </div>
                        )}
                </div>
            </div>


        </div>
    );
};
export default Post;
