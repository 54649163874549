import React, {useEffect, useState} from "react";
import Select from "react-select";

const options = [
    { value: "Otomatik", label: "Otomatik" },
    { value: "Düz", label: "Düz" },
    { value: "Yarı Otomatik", label: "Yarı Otomatik" },
]

const GearType = ({ selectedGearType,setSelectedGearType,selectedLabel }) => {
    const [label, setLabel] = useState();
    useEffect(() => {

        if(selectedLabel != null) {
            for (let i = 0; i < options.length ; i++) {
                if(options[i].label === selectedLabel) {
                    setLabel(options[i])

                    // setExistedLabel
                }
            }
        }
    }, [selectedLabel])
    return (
        <>
            <Select
                options={options}
                value={label}
                onChange={(e) => {
                    setSelectedGearType(e.label);
                    setLabel(e)}
                }
                placeholder="Vites Tipi"
            />
        </>
    );
};

export default GearType;
