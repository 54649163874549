import React, {useContext, useState} from "react";
import NavbarOut from "../Components/Layout/NavbarOut";
import {defaultGetRequestWithParams} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";

const ForgetPassword = () => {
    const {baseURL} = useContext(BaseURLContext);
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(false);


    function handleChange(e) {
        setEmail(e.target.value)
    }

    async function sendVerification() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/authentication/ForgotPassword`,
            {
                params: {
                    email: email
                }
            }
        )
        if (response) {
            setStatus(true)
        }
    }

    const forgetPasswordFormContainer = {
    }

    return (
        <div>
            <div>
                <NavbarOut/>
            </div>
            <div className="row">
                <h3>Şifre Sıfırlama</h3>
                {!status
                    ? (
                        <div>
                            <div className="form-floating col-md-4 m-auto">
                                <input
                                    style={forgetPasswordFormContainer}
                                    name="email"
                                    className="form-control"
                                    id="email"
                                    type="text"
                                    placeholder="E-mail adresinizi girin"
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                    value={email || ''}
                                />
                                <label htmlFor="email">Email Adresinizi Girin</label>
                            </div>
                            <div style={{marginBottom: '2rem', marginTop: '2rem'}}>
                                <button className="btn btn-info rounded-0" onClick={sendVerification}>
                                    Doğrulama Gonder
                                </button>
                            </div>
                        </div>)
                    : (
                        <div >
                            <div style={{minHeight:'10rem'}}>

                            </div>
                            <h6>Şifre değistirme talebiniz alınmıştır. Lütfen Emailinizi kontrol edin.</h6>
                            <div style={{minHeight:'10rem'}}>

                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}

export default ForgetPassword;
