import React, {useState, useContext, useEffect, useRef} from "react";
import {ListGroup, Row, Col} from "react-bootstrap";
import {
    defaultGetRequest,
    defaultGetRequestWithParams,
} from "../../../../static/functions";
import {BaseURLContext} from "../../../../baseURL-context";
// import {checkLevelType} from '../../'
import '../../../../assets/css/selectCar.css'
import {checkLevelType} from "../../../../static/helpers";

const SelectCarNew = ({nextPage, setCarModel, setCarType, setCarCategoryId, setCarInfo}) => {

    const [mainCategories, setMainCategories] = useState([]);
    const [mainCategory, setMainCategory] = useState('');
    const [years, setYears] = useState([]);
    const [year, setYear] = useState('');
    const [fuelTypes, setFuelTypes] = useState([]);
    const [fuelType, setFuelType] = useState('');
    const [makes, setMakes] = useState([]);
    const [make, setMake] = useState('');
    const [models, setModels] = useState([]);
    const [model, setModel] = useState('');
    const [submodels, setSubmodels] = useState([]);
    const [submodel, setSubmodel] = useState('');
    const [caseTypes, setCaseTypes] = useState([]);
    const [caseType, setCaseType] = useState('');
    const [carList, setCarList] = useState([]);
    const [tableStyle, setTableStyle] = useState(null);
    const [props, setProps] = useState([]);
    const [prop, setProp] = useState('');
    const [catCompledeted, setCatCompledeted] = useState(false);


    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const [showNext, setShowNext] = useState(false);
    const {baseURL} = useContext(BaseURLContext);
    const onClickNext = () => setShowNext(true);
    const onClickNormalize = () => setShowNext(false);

    const ref = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    const ref6 = useRef(null)
    const ref7 = useRef(null)

    function scrollToRight() {
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        })
        // console.log('ref', ref)
    }

    function scrollToRight2() {
        ref2?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        })
        // console.log('ref', ref2)
    }

    function scrollToRight3() {
        ref3?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'end',
        })
        // console.log('ref', ref3)
    }

    function scrollToRight4() {
        ref4?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'end',
        })
    }

    function scrollToRight5() {
        ref5?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'end',
        })
    }

    function scrollToRight6() {
        ref6?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'end',
        })
    }

    function scrollToRight7() {
        ref7?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        })
    }

    useEffect(() => {
        if (years?.length === 1) {
            scrollToRight(ref)
        }
    }, [years]);
    useEffect(() => {
        scrollToRight2(ref2)
    }, [fuelTypes]);
    useEffect(() => {
        scrollToRight3(ref3)
    }, [makes]);
    useEffect(() => {
        scrollToRight4(ref4)
    }, [models]);
    useEffect(() => {
        scrollToRight5(ref5)
    }, [submodels]);
    useEffect(() => {
        scrollToRight6(ref6)
    }, [caseTypes]);
    useEffect(() => {
        scrollToRight7(ref7)
    }, [carList]);

    let rightRef = useRef(null)

    const executeScroll = () => rightRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
    })

    useEffect(() => {
        getCarCategories();
        setBreadcrumbs([]);
        //eslint-disable-next-line
    }, []);

    // function setLastBreadcrumb(lastCrumb) {
    //   if (breadcrumbs.length < 3) {
    //     setBreadcrumbs((breadcrumbs) => [...breadcrumbs, lastCrumb]);
    //   }
    //   //     setBreadcrumbs((breadcrumbs) => [...breadcrumbs, lastCrumb]);
    // }

    const getCarCategories = async () => {
        const response = await defaultGetRequest(
            `${baseURL}/categories/GetMainCarCategoryForNewAd/`
        );
        if (response) {
            for (var i = 0; i < response.data.length; i++) {
                setMainCategories((e) => [...e, response.data[i]]);
                // console.log(response.data)
            }
        } else {
            console.log("false");
            onClickNormalize();
        }
    };

    const clearLevel1 = () => {
        setCatCompledeted(false)
        setYears([])
        setYear(null);
        setProps([])
        setProp(null);
        setFuelTypes([]);
        setFuelType(null)
        setMakes([])
        setMake(null)
        setModels([])
        setModel(null)
        setSubmodels([])
        setSubmodel(null)
        setCaseTypes([])
        setCaseType(null)
        setCarList([])
        setShowNext(false)
    }
    const clearLevel2 = () => {
        setCatCompledeted(false)
        setFuelTypes([]);
        setFuelType(null)
        setMakes([])
        setMake(null)
        setModels([])
        setModel(null)
        setSubmodels([])
        setSubmodel(null)
        setCaseTypes([])
        setCaseType(null)
        setCarList([])
        setShowNext(false)
    }
    const clearLevel3 = () => {
        setCatCompledeted(false)
        setMakes([])
        setMake(null)
        setModels([])
        setModel(null)
        setSubmodels([])
        setSubmodel(null)
        setCaseTypes([])
        setCaseType(null)
        setCarList([])
        setShowNext(false)
    }
    const clearLevel4 = () => {
        setCatCompledeted(false)
        setModels([])
        setModel(null)
        setSubmodels([])
        setSubmodel(null)
        setCaseTypes([])
        setCaseType(null)
        setCarList([])
        setShowNext(false)
    }
    const clearLevel5 = () => {
        setCatCompledeted(false)
        setSubmodels([])
        setSubmodel(null)
        setCaseTypes([])
        setCaseType(null)
        setCarList([])

    }
    const clearLevel6 = () => {
        setCatCompledeted(false)
        setCaseTypes([])
        setCaseType(null)
        setCarList([])
        setShowNext(false)
    }
    const clearLevel7 = () => {
        setCatCompledeted(false)
        setCarList([])
        setShowNext(false)
    }


    // function generateMethod(catType, level) {
    //     var method = 'GetCreateChildCarCategoryLevel'
    //     if (catType === 'Engelli Araci' || catType === 'ATV & UTV') {
    //         console.log(method + level + 'Makes')
    //         return method + level + 'Makes'
    //     } else if (catType === 'Ticari Araç' || catType === 'Hasarli Araçlar') {
    //         console.log(method + level + 'Props')
    //         return method + level + 'Props'
    //     } else {
    //         console.log(method + level)
    //         return method + level
    //     }
    // }

    // function setMethodParameters(paramName,parameters,parameterValues) {
    //     var paramObject = new Object();
    //     for (let i = 0; i < parameters.length; i++) {
    //         paramObject[parameters[i]] = parameterValues[i]
    //     }
    //     return paramObject;
    // }


    //sketeleton
    // for (var i = 0; i < response.data.length; i++) {
    //     if (mainCategory === 'Engelli Araci' || mainCategory === 'ATV & UTV') {
    //
    //     } else if (mainCategory === 'Ticari Araç' || mainCategory === 'Hasarli Araçlar') {
    //
    //     } else {
    //
    //     }
    // }

    //STANDART SELECT
    const GetChildCarCategoryLevel1 = async (selected) => {
        var method = 'GetCreateChildCarCategoryLevel1'
        // generateMethod(selected, '1')

        console.log('method ', method)
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/${method}`,
            {
                params: {
                    category: selected.toString(),
                },
            }
        );

        if (response) {
            if (selected.toString() === 'Engelli Araci' ||
                selected.toString() === 'ATV & UTV' ||
                selected.toString() === 'Traktör' ||
                selected.toString() === 'Tarım & İş Makineleri' ||
                selected.toString() === 'Motosiklet'
            ) {
                //for atv utv data
                setMakes([])
                setMakes(response.data)
                console.log('setMakes(response.data)', response.data)
            } else if (selected.toString() === 'Ticari Araç' ||
                selected.toString() === 'Klasik Araçlar' ||
                selected.toString() === 'Elektrikli Araçlar' ||
                selected.toString() === 'Karavan' ||
                selected.toString() === 'Modifiyeli Arabalar' ||
                selected.toString() === 'Hasarli Araçlar'
            ) {
                //for atv utv data
                setProps([])
                setProps(response.data)
                console.log('setProps(response.data)', response.data)
            } else {
                setYears([])
                setYears(response.data)
            }
        }
    };

    const GetChildCarCategoryLevel2 = async (selected) => {
        var parameters = new Object();

        parameters.category = mainCategory;

        const level = checkLevelType(mainCategory);

        if (level === 0) {
            console.log('non-standart-2')
            parameters.make = selected;
        } else if (level === 1) {
            console.log('non-standart-1')
            parameters.props = selected;
        } else {
            console.log('standart')
            parameters.year = parseInt(selected.toString());
        }
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetCreateChildCarCategoryLevel2`,
            {
                params: parameters,
            }
        );

        if (response) {
            if (response.data.length > 0) {
                // const levelType = checkLevelType()
                if (level === 0) {
                    for (var i = 0; i < response.data.length; i++) {
                        setMakes((e) => [...e, response.data[i]]);
                        setCatCompledeted(true)
                    }
                } else if (level === 1) {
                    for (var i = 0; i < response.data.length; i++) {
                        setMakes((e) => [...e, response.data[i]]);
                    }
                } else {
                    for (var i = 0; i < response.data.length; i++) {
                        setFuelTypes((e) => [...e, response.data[i]]);
                    }
                }
            } else {
                GetChildCarCategoryLevel7('');
            }
        } else {
            console.log("Error here");
        }
    };

    const GetChildCarCategoryLevel3 = async (selected) => {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetCreateChildCarCategoryLevel3`,
            {
                params: {
                    year: parseInt(year.toString()),
                    category: mainCategory,
                    fuelType: selected,
                },
            }
        );
        setMakes([])
        if (response) {
            for (var i = 0; i < response.data.length; i++) {
                setMakes((elem) => [...elem, response.data[i]]);
            }
        } else {
            console.log("Error here");
        }
    };

    const GetChildCarCategoryLevel4 = async (selected) => {
        var parameters = new Object();
        parameters.category = mainCategory;
        parameters.props = prop;
        parameters.make = selected;
        parameters.year = parseInt(year || '0');
        parameters.fuelType = fuelType;
        parameters.make = selected;
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetCreateChildCarCategoryLevel4`,
            {
                params: parameters,
            }
        );
        setMake(selected)
        if (response) {
            if (response.data[0] != null) {
                setModels(response.data)
                console.log('setModels(response.data)', response.data)
                // setSubCarVal4(response.data);
            } else {
                GetChildCarCategoryLevel7('','','',selected)
            }
        } else {
            console.log("Error here");
        }
    };

    const GetChildCarCategoryLevel5 = async (selected) => {


        // if (mainCategory === 'Engelli Araci' || mainCategory === 'ATV & UTV') {
        //
        // }
        // if (mainCategory === 'ATV & UTV') {
        //     GetChildCarCategoryLevel7()
        //     onClickNext()
        // } else {

        var parameters = new Object();
        parameters.category = mainCategory;
        parameters.prop = prop;
        parameters.year = parseInt(year || '0');
        parameters.make = make;
        parameters.fuelType = fuelType;
        parameters.model = selected;

        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetCreateChildCarCategoryLevel5`,
            {
                params: parameters
            }
        );
        setModel(selected)
        // setShowNext(false)
        if (response) {
            console.log('level5', response.data)
            if (response.data[0] != null) {
                setSubmodels(response.data)
            } else {
                await GetChildCarCategoryLevel7('', '', selected)
            }
        } else {
            console.log("Error here");
        }
        // }
    };

    const GetChildCarCategoryLevel6 = async (selected) => {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetCreateChildCarCategoryLevel6`,
            {
                params: {
                    year: parseInt(year || 0),
                    category: mainCategory,
                    fuelType: fuelType,
                    make: make,
                    model: model,
                    submodel: selected,
                    prop:prop
                },
            }
        );
        console.log(selected)
        setSubmodel(selected)
        if (response) {
            console.log('response.dataa ', response.data[0])
            if (response.data[0] != null) {
                setCaseTypes(response.data)
            } else {
                await GetChildCarCategoryLevel7('', selected)

            }
            // setSubCarVal4(response.data);
        } else {
            console.log("Error here");
        }
    };

    const GetChildCarCategoryLevel7 = async (selected, submodelTemp, modelTemp,makeTemp) => {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetCreateChildCarCategoryLevel7`,
            {
                params: {
                    year: parseInt(year || 0),
                    category: mainCategory,
                    fuelType: fuelType,
                    make: make || makeTemp,
                    model: model || modelTemp,
                    submodel: submodel || submodelTemp,
                    caseType: selected,
                    prop: prop
                },
            }
        );
        setCaseType(selected)
        //if list is bigger than one --  show list to user to select
        if (response) {
            if(response.data.length > 1){
                setCarList(response.data)
            }
            else{
                console.log('selected car id ',response.data[0])
                onClickNext();
                selectCarDone()
                setCarCategoryId(response.data[0].id)
                setCatCompledeted(true)
            }
            // setSubCarVal4(response.data);
        } else {
            console.log("Error here");
        }
    };

    function FinalizeSelect(selected) {
        onClickNext();
        setCarCategoryId(selected)
        // setTableStyle( elem => [...elem,{rowName: selected}])
        setTableStyle(selected)
    }


    //NON-STANDART SELECT-2
    const GetChildCarCategoryLevel2Props = async (selected) => {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetCreateChildCarCategoryLevel2Props`,
            {
                params: {
                    category: mainCategory,
                    props: selected,
                },
            }
        );

        if (response) {
            for (var i = 0; i < response.data.length; i++) {
                setMakes((e) => [...e, response.data[i]]);
            }
        } else {
            console.log("Error here");
        }
    };


    //this should be implemented on each final to send the object to other component
    function selectCarDone() {
        const info = {
            year: parseInt(year || 0),
            category: mainCategory || '',
            fuelType: fuelType || '',
            make: make || '',
            model: model || '',
            submodel: submodel || '',
            caseType: caseType || ''
        }
        setCarInfo(info)
    }


    async function finalWithoutList() {

    }


    async function getNonStandart2(selected) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetCreateChildCarCategoryLevel7`,
            {
                params: {
                    category: mainCategory,
                    make: make,
                    model: selected
                },
            }
        );
        if (response) {
            setCarCategoryId(response.data[0])
            // return response.data[0].id
        }
    }

    return (
        <div id="container">
            <div style={{height: "5rem"}}></div>
            <h2>Kategori seçimi</h2>
            {!catCompledeted
                ?
                (<h5 style={{marginBottom: "3rem"}}>
                    Öncelikle vermek istediğiniz ilanın kategorisini seçin
                </h5>)
                :
                (
                    <h5 style={{background: 'green'}}>
                        Kategori seçimi tamamlandı
                    </h5>
                )
            }

            <div className="scrollmenu d-flex" style={{marginBottom: "8rem", flexWrap: 'nowrap'}}>
                <div className="col-md-2">
                    <ListGroup id="scroll" style={selectStyle}>
                        {mainCategories &&
                            mainCategories.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            // setYears('');
                                            setCarType(item.categoryName)
                                            setMainCategory(item.categoryName)
                                            GetChildCarCategoryLevel1(item.categoryName);
                                            onClickNormalize();
                                            clearLevel1();
                                            // executeScroll();
                                        }}
                                        action
                                        variant="dark"
                                        key={key}
                                    >
                                        {item.categoryName}
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>
                </div>
                <div className="col-md-2" style={years.length > 0 ? selectStyle : hideSelectStyle}>
                    <ListGroup id="scroll" style={selectStyle}>
                        {years &&
                            years.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            // setMakes("");
                                            setYear(item)
                                            GetChildCarCategoryLevel2(item);
                                            // onClickNormalize();
                                            clearLevel2();
                                            // executeScroll();
                                        }}
                                        action
                                        variant="dark"
                                        key={key}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>

                </div>
                <div className="col-md-2" style={props.length > 0 ? selectStyle : hideSelectStyle}>
                    <ListGroup id="scroll" style={selectStyle}>
                        {props &&
                            props.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            // setMakes("");
                                            setProp(item)
                                            GetChildCarCategoryLevel2(item);
                                            // onClickNormalize();
                                            clearLevel2();
                                            // executeScroll();
                                        }}
                                        action
                                        variant="dark"
                                        key={key}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>

                </div>
                <div ref={ref}/>
                <div className="col-md-2" style={fuelTypes.length > 0 ? selectStyle : hideSelectStyle}>
                    <ListGroup id="scroll" style={selectStyle}>
                        {fuelTypes &&
                            fuelTypes.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            setFuelType(item)
                                            GetChildCarCategoryLevel3(item);
                                            // onClickNormalize();
                                            clearLevel3();
                                            // executeScroll();
                                        }}
                                        action
                                        variant="dark"
                                        key={key}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}
                        <div ref={ref2}/>
                    </ListGroup>
                </div>
                <div className='col-md-2' style={makes.length > 0 ? selectStyle : hideSelectStyle}>
                    <ListGroup id="scroll" style={selectStyle}>
                        <div ref={ref3}/>
                        {makes &&
                            makes.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            // setModel(item);
                                            // setMake(item);
                                            GetChildCarCategoryLevel4(item);
                                            clearLevel4();
                                            // onClickNormalize();
                                            // onClickNext();
                                        }}
                                        action
                                        variant="dark"
                                        key={key}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}

                    </ListGroup>
                </div>
                <div className='col-md-2' style={models.length > 0 ? selectStyle : hideSelectStyle}>
                    <ListGroup id="scroll" style={selectStyle}>
                        <div ref={ref4}/>
                        {models &&
                            models.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            // setModel(item);
                                            GetChildCarCategoryLevel5(item);
                                            clearLevel5();

                                            // executeScroll();
                                            // onClickNormalize();

                                        }}
                                        action
                                        variant="dark"
                                        key={key}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}

                    </ListGroup>
                </div>
                <div className='col-md-2' style={submodels.length > 0 ? selectStyle : hideSelectStyle}>
                    <ListGroup id="scroll" style={selectStyle}>
                        <div ref={ref5}/>
                        {submodels &&
                            submodels.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            GetChildCarCategoryLevel6(item);
                                            executeScroll();
                                            clearLevel6();
                                        }}
                                        action
                                        variant="light"
                                        key={key}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}

                    </ListGroup>
                </div>

                <div ref={rightRef} className='col-md-2' style={caseTypes?.length > 0 ? selectStyle : hideSelectStyle}>
                    <ListGroup id="scroll" style={selectStyle}>
                        <div ref={ref6}/>
                        {caseTypes &&
                            caseTypes.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            // setModel(item);
                                            GetChildCarCategoryLevel7(item);
                                            executeScroll();
                                            clearLevel7();
                                            // onClickNormalize();
                                            // onClickNext();
                                        }}
                                        action
                                        variant="light"
                                        key={key}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}

                    </ListGroup>
                </div>
            </div>


            <div className="row" style={carList.length > 0 ? selectListStyle : hideSelectListStyle}>
                <table className="table">
                    <thead>
                    <tr>
                        {/*<th scope="col">#</th>*/}
                        <th scope="col">Marka</th>
                        <th scope="col">Özellikler</th>
                        <th scope="col">Kasa Tipi</th>
                        <th scope="col">Motor</th>
                    </tr>
                    </thead>
                    <tbody>

                    {carList && carList.map((item, i) => {
                        return (
                            <tr
                                // className="row"
                                // style={hrStyle}
                                style={tableStyle === item.id ? hoverSelected : selectListStyle}
                                onClick={() => {
                                    FinalizeSelect(item.id)
                                }}
                                key={i}
                            >
                                <td>
                                    {/*<p>*/}
                                    {item.make}
                                    {/*</p>*/}
                                </td>
                                <td>
                                    {item.nameFinal}
                                </td>
                                <td>
                                    {/*<p>*/}
                                    {item.detailFinal}
                                    {/*</p>*/}
                                </td>
                                <td>
                                    {/*<p>*/}
                                    {item.hpCcFinal}
                                    {/*</p>*/}
                                </td>

                            </tr>
                        )
                    })}

                    </tbody>
                    <div style={{minHeight: '5rem'}} ref={ref7} className="noHover"/>
                </table>
            </div>

            {showNext === true ? (
                <div>
                    <button
                        // style={button}
                        className="btn continue-button"
                        onClick={() => {
                            // setCarModel(subCarVal4);
                            nextPage();
                        }}
                    >
                        İlan Oluşturmaya Devam Et
                    </button>
                </div>
            ) : null}
        </div>
    );
};

// const button = {
//     backgroundColor: "green",
//     color: "white",
// }

const selectStyle = {
    width: '180px',
    height: '240px',
    marginRight: '10px'
}

const hideSelectStyle = {
    width: '0px',
    // width: '180px',
    // height: '240px',
    display: 'none'
}
const selectListStyle = {
    // width: '180px',
    // height: '7vh'
}
const hoverSelected = {
    opacity: '100%',
    color: 'black',
    fontWeight: '900'
    // height: '7vh'
}
const hideSelectListStyle = {
    // width: '10%',
    // height: '7vh',
    width: '0px',
    display: 'none'
}

export default SelectCarNew;
