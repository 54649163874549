import React, {useState, useContext, useEffect} from "react";
import {defaultGetRequest} from "../../static/functions";
import {BaseURLContext} from "../../baseURL-context";
import MainBody from "./MainBody";

const App = () => {
    const [mainCategories, setMainCategories] = useState([]);

    return (
        <div>
            <section id="main">
                <div className="container">
                    <div className="row">
                        <div>
                            <MainBody categories={mainCategories}/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default App;
