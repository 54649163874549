import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {defaultGetRequest} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import '../assets/css/spinner.css'


const CarCatList = () => {
    const {baseURL} = useContext(BaseURLContext);
    const [carCats, setCarCats] = useState([]);


    async function getMainCats() {
        const response = await defaultGetRequest(
            `${baseURL}/categories/GetMainCarCategoryTest`
        );
        if (response) {
            setCarCats(response.data);
        }
    }

    useEffect(() => {

        getMainCats();
        //eslint-disable-next-line
    }, []);

    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: 1,
        borderColor: "#000000",
        minWidth:'200px',
        maxWidth:'100%',
    }

    return (
        <div>
            <hr style={hrStyle}/>
            <ul className="list-1 w-100">
                {carCats && carCats.map((item, i) => (
                    <li key={i} style={{minWidth:'150px',width:'90%'}}>
                        <Link
                            to={`/vasita-kategori/${item?.categoryName}`}
                            key={i}
                        >
                            {item?.categoryName}<span>(</span>{item?.count}<span>)</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CarCatList;
