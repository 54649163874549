import React, {useContext, useEffect, useState} from "react";
import {
    defaultGetRequest,
    defaultGetRequestWithParams,
    protectedEnpointGetRequest,
    protectedEnpointPostRequest
} from "../../static/functions";
import Cookies from "universal-cookie";
import {BaseURLContext} from "../../baseURL-context";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import Checkout from "../Payment/Checkout/Checkout";
import {Button, Modal} from "react-bootstrap";

const Success = ({id, type, doping, amount, setAmount, resp, setResp}) => {
    const {baseURL} = useContext(BaseURLContext);
    const [limit, setLimit] = useState(0);
    const [body, setBody] = useState();
    // const navigate = useNavigate();

    var cookies = new Cookies()
    var cookie = cookies.get("hoe-jwt")

    async function GetUserActivity() {
        const requestBody = {
            Id: parseInt(id),
            Type: parseInt(type)
        }

        const response = await protectedEnpointPostRequest(
            `${baseURL}/Membership/GetUserActivity/`,
            requestBody,
            cookie
        );
        if (response) {
            setLimit(response.data.countLeft);
        }
    }

    async function getMembershipPrices() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Membership/GetDopingPrice`,
            cookie
        )

        if (response) {
            if (doping === 2) {
                setAmount(response.data?.membership?.monthly?.price)
            } else if (doping === 3) {
                setAmount(response.data?.membership?.yearly?.price)
            }

        }
    }


    useEffect(() => {
        // console.log('success id : ', from)
        // console.log('success type : ', type)
        GetUserActivity();
        if (doping === 2 || doping === 3) {
            getMembershipPrices()
        }
    }, [])

    return (
        <div>

            {amount && (<div>Gerçekleştirilecek ödeme tutarı : {amount} ₺</div>)}
            {doping === 2 || doping === 3
                ? (
                    <>
                        {/*<div className="row">*/}
                        {/*    <div className="col-md-12">*/}
                        {/*        {resp && (*/}
                        {/*            <div style={{width: '90vw', height: '10vh', display: ''}}*/}
                        {/*                 dangerouslySetInnerHTML={{__html: `${resp}`}}>*/}

                        {/*            </div>)}*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                        <Checkout amount={amount} setResp={setResp} resp={resp}/>
                    </>
                )
                : (
                    <div>
                        <h2>İlanınız Başarıyla Oluşturuldu</h2>

                        <h6>İlanlarım menüsünden ilanınızı aktif hale getirebilirsiniz.</h6>
                        <div style={{marginTop:'2rem'}}>
                            <Link className="btn btn-info rounded-0" to="/ilanlarim">
                                İlanlara git
                            </Link>
                        </div>
                    </div>
                )
            }

        </div>
    );
}

export default Success;
