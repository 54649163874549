import React, {useContext, useEffect, useState} from "react";
import {protectedEnpointGetRequest} from "../../static/functions";
import {BaseURLContext} from "../../baseURL-context";
import Cookies from "universal-cookie";
import {useNavigate} from "react-router";

const UserBoard = () => {
    const {baseURL} = useContext(BaseURLContext);
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt")
    const navigate = useNavigate();
    const [accountType, setAccountType] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [estateCount, setEstateCount] = useState(0);
    const [carCount, setCarCount] = useState(0);

    async function GetAllMembership() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Membership/GetAllMembership`,
            cookie
        );
        if (response) {
            // console.log("response adoveview : ", response.data.all.activeEstates)
            // setCount(response.data.availableAdPublish)
            setEstateCount(response.data?.availableEstateLeft)
            setCarCount(response.data?.availableCarLeft)
            setTotalCount(response.data?.availableAdPublish)
            setAccountType(response.data?.accountType)
            // setActiveEstates(response.data.all.activeEstates)
            // setActiveCars(response.data.all.activeCars)
            // setPassiveEstates(response.data.all.passiveEstates)
            // setPassiveCars(response.data.all.passiveCars)
        }
    }


    useEffect(() => {
        GetAllMembership()
    }, [])

    const countStyle = {
        marginLeft: '1rem'
    }

    return (
        <div>
            <div className="d-flex justify-content-center flex-column">
                <div>
                    <h5>
                        Kalan İlan Yayınlama Haklarınız
                    </h5>
                </div>
                {totalCount &&
                    (<div>
                        <label>
                            Toplam <strong>{totalCount}</strong> ilan yayinlama hakkiniz var
                        </label>
                    </div>)
                }
            </div>
            {/*<div>*/}
            {/*    */}
            {/*</div>*/}
            <div className="d-flex justify-content-center flex-column mt-5">
                <h4>Üyelik Tipi: {accountType === 0
                    ? <span>Ücretsiz Üyelik</span>
                    : accountType === 1
                        ? <span>1 Aylık Üyelik</span>
                        : accountType === 2 && <>1 Yıllık Üyelik</>}
                    {/*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                </h4>
                <button onClick={() => {
                    navigate('/hesap-yukselt', {state: {currentType: accountType}})
                }} className="rounded-0 customButtonUpgrade">
                    <b>Hesabı Yükselt</b>
                </button>
            </div>
        </div>
    );
}

export default UserBoard;
