import React from "react";

const RegisterFailed = () => {
    return (
        <div>
            BIR HATA OLUSTU
        </div>
    );
}

export default RegisterFailed;
