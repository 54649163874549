import React, {useContext, useEffect} from 'react';
import {useState} from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
// import Cookies from "universal-cookie";
import {BaseURLContext} from "../../baseURL-context";
import {defaultGetRequestWithParams} from "../../static/functions";
import {useWindowSize} from "../../static/helpers";
import "../../assets/css/carousel.css";

const App = ({id, adType, array}) => {
    const {baseURL} = useContext(BaseURLContext);
    const [imageObjects, setImageObjects] = useState([])
    const [type, setType] = useState([])
    const size = useWindowSize();
    // const cookies = new Cookies()
    // const cookie = cookies.get("hoe-jwt");

    useEffect(() => {
            if (adType === 0) {
                setType(0)
                getImage(id, adType)
            } else if (adType === 1) {
                setType(1)
                getImage(id, adType)
            } else {
                getPreviewImage()
            }
            //eslint-disable-next-line
        }, []
    )


    async function setImageArray(array) {
        for (const elem of array) {
            const a = {
                src: elem.path
            }
            // setImageObjects(...a)
            setImageObjects(prevArray => [...prevArray, a])
        }
    }

    async function getPreviewImage() {
        for (const elem of array) {
            const a = {
                src: elem
            }
            setImageObjects(prevArray => [...prevArray, a])
        }
        // console.log('imageObjects ',imageObjects)
    }

    async function getImage(id) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/GetAdImages`,
            {
                params: {
                    id: id,
                    type: adType
                },
            }
        )
        if (response) {
            setImageObjects([])
            await setImageArray(response.data);
        } else {
            console.log("error on get images")
        }
    }

    const big = {
        height: '100%',
        width: 450,
        // maxHeight: '300px'
    }
    const small = {width:'auto'}

    const bigWrapper = {width:'480px',height:'420px'}
    const smallWrapper = {height:400}

    return (
        <div style={size && size.length > 680
            ? bigWrapper
            : smallWrapper
        }>
            <Carousel
                thumbnailWidth={'15%'}
                thumbnailHeight={'11%'}
                hasMediaButton={false}
                shouldMaximizeOnClick={true}
                images={imageObjects}
                style={size && size.length > 680
                    ? big
                    : small
                }/>
        </div>
    );
};

export default App;