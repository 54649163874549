import React, {useEffect, useState, useContext} from "react";
import Select from "react-select";
import {components} from "react-select";
import {BaseURLContext} from "../../../baseURL-context";
import {defaultGetRequestWithParams} from "../../../static/functions";

const Front = ({setFrontSide, selectedLabel}) => {
    const [label, setLabel] = useState([]);
    const {baseURL} = useContext(BaseURLContext);
    const [options, setOptions] = useState([]);
    const [count, setCount] = useState(0);
    const [tempArray, setTempArray] = useState([]);

    async function getOptions() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/FieldManage/GetField`,
            {
                params: {
                    fieldType: 'FrontSide'
                }
            }
        )
        if (response) {
            setCount(1)
            setLabel({value: 0, label: ''})
            setOptions(response.data)
        }
    }

    function convertToLegitArray(array) {
        setTempArray([])
        let arr = [];
        for (let i = 0; i < array.length; i++) {
            arr.push(array[i].label)
        }
        let filteredArray = arr.filter(it => it !== 'Seçiniz').toString();

        filteredArray = filteredArray[0] === ',' ? filteredArray.substring(1) : filteredArray;
        setFrontSide('')
        setFrontSide(filteredArray)
    }

    useEffect(() => {
        if (selectedLabel != null) {
            setLabel({value: 0, label: selectedLabel})
        }
    }, [])

    const customStyles = { multiValueRemove: (base) => ({ ...base, display: 'none' }) }

    return (
        <>
            <span
                className="d-inline-block multi-option-header"
                data-toggle="popover"
                data-trigger="focus"
                data-content="Seçiniz"
            >

            {label && <Select
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                onMenuOpen={() => {
                    if (count === 0) {
                        getOptions()
                    }
                }}
                isClearable={false}
                styles={customStyles}
                options={options}
                components={{
                    Option
                }}
                value={label}
                onChange={(e) => {
                    convertToLegitArray(e)
                    setLabel(e);
                }}
                placeholder="Seçiniz"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
            />}
                </span>
        </>
    );
};

export default Front;


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    style={{float:'left'}}
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label style={{fontSize:'1em'}} className="multi-option-label">{props.label}</label>
            </components.Option>
        </div>
    );
};