import React from "react";
import Heating from "../Heating";
import InUsage from "../InUsage";

const Office = ({
                    setBuildingage,
                    setTotalfloor,
                    setMonthlyextra,
                    setSelectedHeating,
                    setSelectedUsage,
                    selectedHeating,
                    selectedUsage,
                    handleChange,
                    buildingage,
                    totalfloor,
                    monthlyextra
                }) => {

    //squaremeter,buildingage,floor, totalfloor,balcony,bathrooms,monthlyextra
    return (
        <div>
            <div className="form-floating mb-3">
                <div className="col-md-2">
                    <label htmlFor="buildingage" className="float-start">
                        <strong>Bina Yaşı</strong>
                    </label>
                    <input
                        type="number"
                        min="1"
                        //   max="100"
                        name="buildingage"
                        className="form-control"
                        id="buildingage"
                        value={buildingage}
                        placeholder="Bina Yaşı"
                        onChange={(e) => {
                            setBuildingage(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3 ">
                <div className="col-md-3">
                    <label htmlFor="totalfloor" className="float-start">
                        <strong>Kat Sayısı</strong>
                    </label>
                    <input
                        type="number"
                        name="totalfloor"
                        min="1"
                        max="200"
                        className="form-control"
                        id="totalfloor"
                        value={totalfloor}
                        placeholder="Kat Sayısı"
                        onChange={(e) => {
                            setTotalfloor(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="heating">
                        <strong>Isınma</strong>{" "}
                    </label>
                    <Heating
                        setSelectedHeating={setSelectedHeating}
                        selectedLabel={selectedHeating}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="monthlyextra" className="float-start">
                        <strong> Aidat</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        value={monthlyextra}
                        name="monthlyextra"
                        className="form-control"
                        id="monthlyextra"
                        placeholder="Aidat"
                        onChange={(e) => {
                            setMonthlyextra(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="inusage">
                        <strong> Kullanım Durumu</strong>
                    </label>
                    <InUsage
                        setSelectedUsage={setSelectedUsage}
                        selectedLabel={selectedUsage}
                    />
                </div>
            </div>
        </div>
    );
}

export default Office;
