import React from "react";
import Navbar from "../Components/Layout/Navbar";
import {useLocation} from "react-router-dom";

function CheckMail() {
    return (
        <div>
            <div>
                <Navbar/>
            </div>
            <div style={{margin: '5rem',minHeight:'15rem'}}>Giris Yapabilmek Icin Email Adresinize Gelen Dogrulama Linkine Tiklayin</div>
            <div>

            </div>
        </div>
    );
}

export default CheckMail;
