import React, {useContext, useEffect, useState} from "react";
import {protectedEnpointPostRequest} from "../static/functions";
import Cookies from "universal-cookie";
import {BaseURLContext} from "../baseURL-context";
import {useNavigate} from "react-router";
import {Link, useLocation, useParams} from "react-router-dom";
import NavbarIn from "../Components/Layout/NavbarIn";

const Success = () => {
    const {baseURL} = useContext(BaseURLContext);
    const [limit, setLimit] = useState(0);
    const {id,type} = useParams();
    var cookies = new Cookies()
    var cookie = cookies.get("hoe-jwt")

    useEffect(() => {
        GetUserActivity();
    }, [])

    async function GetUserActivity() {
        const requestBody = {
            adId: parseInt(id),
            type: parseInt(type)
        }

        const response = await protectedEnpointPostRequest(
            `${baseURL}/Membership/GetUserActivity/`,
            requestBody,
            cookie
        );
        if (response) {
            setLimit(response.data.countLeft);
        }
    }

    async function SetActiveAd() {
        const requestBody = {
            adId: parseInt(id),
            type: parseInt(type)
        }
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Membership/SetActiveAd/`,
            requestBody,
            cookie
        );
    }

    async function SetPassiveAd() {
        const requestBody = {
            adId: id,
            type: type
        }

        const response = await protectedEnpointPostRequest(
            `${baseURL}/Membership/SetPassiveAd/`,
            requestBody,
            cookie
        );
        if (response){
            window.location.reload()
        }
    }

    return (
        <div>
            <NavbarIn/>
            <h2>İlan başarıyla oluşturuldu</h2>

            <h6>Kalan ilan hakkınız: {limit}</h6>


            <Link to='/ilan' state={{value: 'İlan Başarıyla Yayınlandı'}}
                  onClick={() => {
                      SetActiveAd()
                  }}
                  type='submit'
                  className="btn btn-primary"
            >
                Yayınla
            </Link>


            <Link to='/ilan' state={{value: 'İlan Başarıyla Yayından Kaldırıldı'}} onClick={() => {
                SetPassiveAd()
            }}
            >
                Yayından Kaldır
            </Link>


        </div>
    );
}

export default Success;
