import React, {useEffect, useState} from "react";
import Cookies from "universal-cookie";
import NavbarOut from "./NavbarOut";
import NavbarIn from "./NavbarIn";
import '../../assets/css/navbar.css'

const Navbar = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")
    const [user, setUser] = useState([]);
    return (
        <div className="nav-container">
            {!cookie
                ? (<NavbarOut/>)
                : (<NavbarIn/>)}

        </div>
    );
}

export default Navbar;
