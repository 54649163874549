import React, {useContext, useEffect, useState} from "react";
import Navbar from "../Components/Layout/Navbar";
import RegisterPayment from "../Components/Payment/Register/RegisterPayment";
import {protectedEnpointGetRequest} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import Cookies from "universal-cookie";
import '../assets/css/upgradeAccount.css'

const UpgradeAccount = () => {
    const {baseURL} = useContext(BaseURLContext);
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt")
    const [currentType, setCurrentType] = useState()
    const [advantages, setAdvantages] = useState()

    async function GetAllMembership() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Membership/GetAllMembership`,
            cookie
        );
        if (response) {
            setCurrentType(response.data.accountType)
        }
    }

    useEffect(() => {
        GetAllMembership()
        //eslint-disable-next-line
    }, [])

    return (
        <div>
            <Navbar/>
            <div className="container">
                {/*<AccountTypes/>*/}
                <RegisterPayment
                    setAdvantages={setAdvantages}
                    advantages={advantages}
                    currentType={currentType}
                />
            </div>
        </div>
    );
}

export default UpgradeAccount;
