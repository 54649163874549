import React, {useContext, useEffect, useState} from "react";
import {BaseURLContext} from "../baseURL-context";
import Cookies from "universal-cookie";
import {useLocation} from "react-router-dom";
import useForm from "../Components/useForm/useForm";
import {
    defaultGetRequestWithParams,
    protectedEnpointGetRequest,
    protectedEnpointPostRequest
} from "../static/functions";
import {Col, Row} from "react-bootstrap";
import UploadPhoto from "../Components/Common/UploadImage";
import FuelType from "../Components/Forms/CreateCar/FuelType";
import GearType from "../Components/Forms/CreateCar/GearType";
import CaseType from "../Components/Forms/CreateCar/CaseType";
import City from "../Components/SearchTest/Filters/City";
import Color from "../Components/Forms/CreateCar/Color";
import Insurance from "../Components/Forms/CreateCar/Insurance";
import LicensePlate from "../Components/Forms/CreateCar/License";
import Description2 from "../Components/Forms/Common/Description2";
import DopingOnCreateAd from "../Components/Payment/CreateAd/DopingOnCreateAd";
import Navbar from "../Components/Layout/Navbar";
import UploadImage from "../Components/Common/UploadImage";
import CurrencyInput from "react-currency-input-field";
import axios from "axios";
import isNumeric from "../static/helpers";
import FileUpload from "../Components/Common/EditImages";
import NumberFormat from "react-number-format";

const EditCar = () => {
    const {baseURL} = useContext(BaseURLContext);
    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")
    const location = useLocation()
    const {values, errors, handleChange, handleSubmit} = useForm();
    const [disable, setDisable] = useState(false);

    const [status, setStatus] = useState('edit')

    const [carId, setCarId] = useState(0)
    const [carCat, setCarCat] = useState(0)
    const [carObject, setCarObject] = useState([])

    const [carName, setCarName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [price, setPrice] = useState("");
    const [existingImages, setExistingImages] = useState([])
    const [selectedImage, setSelectedImage] = useState({})
    const [files, setFiles] = useState({});

    const [description, setDescription] = useState('');
    const [traction, setTraction] = useState('');
    const [carType, setCarType] = useState('');
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedTown, setSelectedTown] = useState("");
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedInsurance, setSelectedInsurance] = useState("");
    const [selectedLicense, setSelectedLicense] = useState("");
    const [selectedCaseType, setSelectedCaseType] = useState("");
    const [selectedFuelType, setSelectedFuelType] = useState("");
    const [selectedGearType, setSelectedGearType] = useState("");
    const [km, setKm] = useState("");
    const [from, setFrom] = useState("");
    // const [color, setColor] = useState("");
    // const [selectedImage, setSelectedImage] = useState([]);

    async function getCar(id) {
        var response = await defaultGetRequestWithParams(
            `${baseURL}/Search/GetCarById`,
            {
                params: {
                    id: id
                },
            })
        if (response) {
            setCarObject(response.data.car)
            setCarName(response.data.car?.name)
            setPrice(response.data.car?.price)
            setTraction(response.data.car?.traction)
            setCarType(response.data.car?.categoryType)
            setSelectedFuelType(response.data.car?.fuelType)
            setSelectedGearType(response.data.car?.gear)
            setSelectedCaseType(response.data.car?.caseType)
            setSelectedCity(response.data.car?.city)
            setSelectedTown(response.data.car?.state)
            setSelectedColor(response.data.car?.color)
            setSelectedInsurance(response.data.car?.insurance)
            setSelectedLicense(response.data.car?.licensePlate)
            setKm(response.data.car?.km)
            setDescription(response.data.car?.description)
            setFrom("Sahibinden")
            const imageResponse = await defaultGetRequestWithParams(
                `${baseURL}/Search/GetAdImages`,
                {
                    params: {
                        id: id,
                        type: 1
                    },
                }
            )
            if (imageResponse) {
                setExistingImages(imageResponse.data)
            }
        }
    }

    useEffect(() => {
        // GetRoles()
        getCar(location?.state?.id)
        getImagesNew(location?.state?.id)
        setCarId(location?.state?.id)
    }, [])

    async function saveEdit() {
        setDisable(true)
        const carData = {
            Id : location?.state?.id,
            Name: carName,
            Price: price.replace(',', '.'),
            City: selectedCity,
            State: selectedTown,
            CategoryType: carType,
            CategoryId: parseInt(carObject.categoryId),
            Brand: carObject.make,
            Model: carObject.model,
            Year: carObject.year,
            Km: km,
            Gear: selectedGearType,
            Fuel: selectedFuelType,
            CaseType: selectedCaseType,
            Traction: traction,
            Color: selectedColor,
            LicensePlate: selectedLicense,
            // MotorPower: values.motorPower,
            // MotorCapacity: values.motorCapacity,
            Insurance: selectedInsurance,
            From: from,
            // Status: values.status,
            Description: description,
        };

        const response = await protectedEnpointPostRequest(
            `${baseURL}/Car/EditCarProduct`,
            carData,
            cookie
        );
        if (response) {
            if (selectedImage?.length > 0) {
                let newFormData = new FormData();
                for (let a = 0; a < selectedImage?.length; a++) {
                    newFormData.append(`formFile`, selectedImage[a], selectedImage[a].name);
                }

                newFormData.append("productId", carId);
                newFormData.append("type", 1);

                const createImageResponse = await protectedEnpointPostRequest(
                    `${baseURL}/Estate/SetOrderAndUpdateImages`,
                    newFormData,
                    cookie
                );
                if (createImageResponse) {
                    setStatus('true')
                } else {
                    setStatus('false')
                    return
                }
            }
            setStatus('true')
        } else {
            setStatus('false')
        }
    }

    async function GetRoles() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/UserRoles/GetUserRoles`,
            cookie
        )
        if (response) {
            if (response.data[0] === 'IndividualUser') {
                setUserRole("Sahibinden")
            } else if (response.data[0] === 'AutoShowroom') {
                setUserRole("Galeriden")
            } else if (response.data[0] === 'Bank') {
                setUserRole("Bankadan")
            } else if (response.data[0] === 'AutorizedDealer') {
                setUserRole("Yetkili Bayiiden")
            } else if (response.data[0] === 'EstateAgency') {
                setUserRole("Emlak Ofisinden")
            } else {
                setUserRole("Sahibinden")
            }
        }
    }


    async function getImagesNew(id) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/GetAdImages`,
            {
                params: {
                    id: id,
                    type: 1
                },
            }
        )
        if (response) {
            const tempArray = await setImageResponses(response.data)
            setFiles({...Object.assign({}, ...tempArray.map(key => ({[key?.name]: key})))})
            setSelectedImage({...Object.assign({}, ...tempArray.map(key => ({[key.name]: key.name})))})

        }
    }

    async function setImageResponses(array) {
        var tempArray = []
        setExistingImages([])
        for (let i = 0; i < array.length; i++) {
            const firstResponse = await axios({
                method: 'get',
                url: array[i].path,
                responseType: 'blob'
            })
            if (firstResponse) {
                let test = await handleImages(firstResponse.data, array[i])
                tempArray.push(test)
            }
        }

        return tempArray
    }

    async function handleImages(imageData, item) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(imageData);
            reader.onload = () => {
                resolve(
                    fetch(reader.result.toString())
                        .then(res => res.blob())
                        .then((blob) => {
                            let fileName = item.path.match(/\/([^\/]+)\/?$/)[1];
                            fileName = isNumeric(fileName[fileName.length - 1])
                                ? fileName.slice(0, -1)
                                : fileName;
                            let file = new File([blob], `${fileName}`, {type: 'image/jpeg'});
                            setExistingImages((elem) => [...elem, file])

                            return file
                        }))
            }
            reader.onerror = reject;
        })
    }

    const updateUploadedFiles = (files) => {
        setSelectedImage({})
        setSelectedImage(files)
    }


    const MAX_VAL = 1000000000000;
    const withValueCap = (inputObj) => {
        const {value} = inputObj;
        if (value <= MAX_VAL) return true;
        return false;
    };

    return (
        <div className="container">
            <Navbar/>
            {status === 'edit' &&
                (<Row>
                    <Col md={12}>
                        <div className="ms-4 me-4">
                            <div className="form-group mt-4 mb-4">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name"
                                        placeholder="İlan Adı"
                                        value={carName}
                                        onChange={(e) => {
                                            setCarName(e.target.value)
                                        }}
                                    />
                                    <label htmlFor="name">İlan Başlığı</label>
                                </div>

                                {files && (<div className="form-floating mb-3 row">
                                    <FileUpload
                                        accept=".jpg,.png,.jpeg"
                                        label="Profile Image(s)"
                                        updateFilesCb={updateUploadedFiles}
                                        multiple
                                        existingImages={existingImages}
                                        files={files}
                                        setFiles={setFiles}
                                    /></div>)}

                                <div className="form-floating mb-3 row">
                                    <div className="col-md-5">
                                        <Row>
                                            <Col>
                                                <label>
                                                    <strong>Fiyat</strong>
                                                </label>
                                                <NumberFormat
                                                    thousandSeparator=","
                                                    id="input-example"
                                                    name="input-name"
                                                    className="form-control"
                                                    decimalSeparator={false}
                                                    value={price || "0"}
                                                    allowNegative={false}
                                                    isAllowed={withValueCap}
                                                    isNumericString
                                                    defaultValue={"0"}
                                                    onValueChange={(values) => {
                                                        setPrice(values.formattedValue.toString())
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <label htmlFor="color">
                                            <strong>Yakıt</strong>
                                        </label>
                                        <FuelType
                                            setSelectedFuelType={setSelectedFuelType}
                                            onChange={handleChange}
                                            selectedLabel={selectedFuelType}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <label htmlFor="color">
                                            <strong>Vites</strong>
                                        </label>
                                        <GearType
                                            setSelectedGearType={setSelectedGearType}
                                            onChange={handleChange}
                                            selectetGear={selectedGearType}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <label htmlFor="color">
                                            <strong>Kasa Tipi</strong>
                                        </label>
                                        <CaseType
                                            setSelectedCaseType={setSelectedCaseType}
                                            onChange={handleChange}
                                            selectedLabel={selectedCaseType}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <City
                                            selectedCity={selectedCity}
                                            setSelectedCity={setSelectedCity}
                                            selectedTown={selectedTown}
                                            setSelectedTown={setSelectedTown}
                                            onChange={handleChange}
                                            selectedC={selectedCity}
                                            selectedT={selectedTown}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <div className="col-md-2">
                                        <label htmlFor="floor" className="float-start">
                                            <strong>KM</strong>
                                        </label>
                                        <input
                                            type="text"
                                            name="km"
                                            className="form-control"
                                            id="km"
                                            placeholder="KM"
                                            value={km}
                                            onChange={(e) => {
                                                setKm(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <div className="col-md-3">
                                        <label htmlFor="color">
                                            <strong>Renk</strong>
                                        </label>
                                        <Color
                                            setSelectedColor={setSelectedColor}
                                            onChange={handleChange}
                                            selectedLabel={selectedColor}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <div className="col-md-3">
                                        <label htmlFor="insurance">
                                            <strong>Garanti</strong>
                                        </label>
                                        <Insurance
                                            setSelectedInsurance={setSelectedInsurance}
                                            onChange={handleChange}
                                            selectedLabel={selectedInsurance}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <div className="col-md-3">
                                        <label htmlFor="license">
                                            <strong>Plaka / Uyruk</strong>
                                        </label>
                                        <LicensePlate
                                            setSelectedLicense={setSelectedLicense}
                                            onChange={handleChange}
                                            selectedLabel={selectedLicense}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3" style={{paddingBottom: "2rem"}}>
                                    <input
                                        type="text"
                                        name="from"
                                        className="form-control"
                                        id="from"
                                        placeholder="Sahibinden"
                                        value={from}
                                        // onChange={(e) => {
                                        //     setFrom(e.target.value)
                                        // {/*}}*/}
                                        disabled
                                    />
                                </div>
                                
                                { description && <Description2 existingDesc={description} setDescription={setDescription}/>}
                                <br/>

                            </div>
                            <div className="mb-3">
                                <button
                                    className="btn btn-success rounded-0"
                                    // style={{backgroundColor: "green", color: "white"}}
                                    style={!disable ? {
                                        backgroundColor: "green",
                                        color: "white"
                                    } : {backgroundColor: "lightgray", color: "white"}}
                                    disabled={disable}
                                    onClick={() => {
                                        saveEdit()
                                    }}>
                                    Değişiklikleri Kaydet
                                </button>
                            </div>


                        </div>
                    </Col>
                </Row>)
            }
            {status === 'true' &&
                (
                    <Row>
                        <div style={{marginTop: '5rem', marginBottom: '10rem'}}>
                            Degisiklikler basariyla kaydedildi.
                        </div>
                    </Row>
                )}
            {status === 'false' &&
                (
                    <Row>
                        <div style={{marginTop: '5rem', marginBottom: '10rem'}}>
                            Bir hata olustu. Lutfen tekrar deneyin.
                        </div>
                    </Row>
                )}
        </div>
    );
}

export default EditCar;
