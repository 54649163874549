import "./Raw/Register.css";
import React, {useContext, useState} from "react";
import {BaseURLContext} from "../../baseURL-context";
import useForm from "../useForm/useForm";
import {useNavigate} from "react-router";
import {defaultPostRequest} from "../../static/functions";
// import RegisterTest from "../Test/Register"
import {Spinner, Tab, Nav, Row, Col} from "react-bootstrap";
import "./Raw/Register.css";
import AccountType from "../Payment/Register/AccountType";
import Doping from "../Payment/Register/Doping";
import City from "../SearchTest/Filters/City";
import SaveMoney from '../../assets/images/saveMoney.3bd132b6.svg'
import {BiMessageDetail} from 'react-icons/bi'
import {IoIosRocket} from 'react-icons/io'
import {clearWhiteSpace} from "../../static/helpers";


const Register = () => {
    const {values, errors, handleChange} = useForm(continueRegisterUser);
    const {baseURL} = useContext(BaseURLContext);

    const [formPage, setFormPage] = useState('individual');
    const [userData, setUserData] = useState(null);
    // const [phoneNumber, setPhoneNumber] = useState(0);
    const [disable, setDisable] = useState(false);

    const [continueRegistration, setContinueRegistration] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState('first-step');
    const [selectedType, setSelectedType] = useState('');
    const [advantages, setAdvantages] = useState(0)
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedTown, setSelectedTown] = useState("");
    const [userName, setUserName] = useState('');
    const [eMail, setEMail] = useState('');
    const [errorValue, setErrorValue] = useState('');

    function handleValueChange(e, callback) {
        e.preventDefault()
        console.log('e', e.target.value)
        callback(e.target.value)
    }

    const navigate = useNavigate();

    async function continueRegisterUser() {

        const registrationData = {
            firstname: values.firstName,
            lastname: values.lastName,
            username: userName,
            password: values.password,
            email: eMail,
            phonenumber: values.phoneNumber.toString(),
            city: selectedCity,
            province: selectedTown,
            address: values.address,
            roleType: selectedType,
            companyShortlName: values.companyShortlName,
            companyOfficialName: values.companyOfficialName,
            membership: advantages,
        };

        // setContinueRegistration(true)
        setRegistrationStatus('second-step')
        setUserData(registrationData);
        window.scrollTo(0, 0)
    }

    function limit(element) {
        var max_chars = 10;

        if (element.value.length > max_chars) {
            element.value = element.value.substr(0, max_chars);
        }
    }

    async function finalizeRegistration() {
        setDisable(true)
        const response = await defaultPostRequest(`${baseURL}/authentication/RegisterUser/`, userData);
        if (response) {
            if (response.data === 'duplicate-number') {
                setRegistrationStatus('error')
                setErrorValue('Girdiğiniz telefon numarası kullanımdadır. Lütfen başka bir numara ile kayıt olmayı deneyin.')
            } else if (response.data === 'code-generation-failed') {
                setRegistrationStatus('error')
                setErrorValue('Kayıt işlemi başarıyla gerçekleştirildi ancak email adresinize doğrulama kodu gönderilemedi. Şifremi unuttum diyerek yeni bir kod alabailirsiniz. ')
            } else if (response.data == true) {
                navigate('/email-onay', {state: {email: eMail}})
            } else {
                setRegistrationStatus('error')
                setErrorValue('Kayıt işlemi sırasında bir hata oluştu. Lütfen info@hepsiotoemlak.com adresi ile iletişime geçin.')
            }

            // navigate("/email-kontrol",{
            //     username:values.username,
            //     password: values.password
            // });
        }
    }

    const activeStyle = {
        background: 'lightblue'
    }
    const passiveStyle = {
        background: 'white'
    }

    function clearAll() {

    }


    return (<>
        {registrationStatus === 'first-step' && (<div>

            <div className="container">
                <div style={{marginTop: '2rem'}} className="row">
                    <div className="col-md-3 btn rounded-0"
                         style={formPage === 'individual' ? activeStyle : passiveStyle}
                         onClick={() => {
                             setFormPage('individual')
                         }}>
                        Bireysel&nbsp; Üyelik&nbsp;
                    </div>
                    <div className="col-md-3 btn rounded-0"
                         style={formPage === 'company' ? activeStyle : passiveStyle}
                         onClick={() => {
                             setFormPage('company')
                         }}>
                        Kurumsal&nbsp; Üyelik&nbsp;
                    </div>
                    <div className="col-md-2">

                    </div>
                </div>
            </div>
            <div>

                {formPage === 'individual' && (<Row>
                    <Col md={6}>
                        <h2 style={{marginTop: "2rem"}}>
                            Bireysel bir hesap oluşturun
                        </h2>
                        <div className="ms-4 me-4">
                            <div className="form-group mt-4 mb-4">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="username"
                                        className="form-control"
                                        id="username-ind"
                                        placeholder="Kullanıcı Adı"
                                        value={userName || ''}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                            // handleValueChange(e, setUserName)
                                            clearWhiteSpace(e.target.value,setUserName)

                                        }}
                                    />
                                    <label htmlFor="username-ind">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Kullanıcı Adınız
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        id="email-ind"
                                        placeholder="Email Adresi"
                                        value={eMail || ""}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                            // handleValueChange(e, setEMail)
                                            clearWhiteSpace(e.target.value,setEMail)
                                        }}
                                        // onChange={(e) => {
                                        //     handleChange(e)
                                        //     clearWhiteSpace(e.target.value)
                                        // }}
                                    />
                                    <label htmlFor="email-ind">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Email Adresiniz
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="firstName"
                                        className="form-control"
                                        id="firstName-ind"
                                        placeholder="Adınız"
                                        value={values.firstName || ""}
                                        onChange={handleChange}
                                        // onChange={(e) => {
                                        //     handleValueChange(e,setFirstName)
                                        // }}
                                    />
                                    <label
                                        htmlFor="firstName-ind">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Adınız
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="lastName"
                                        className="form-control"
                                        id="lastName-ind"
                                        placeholder="Soyadınız"
                                        value={values.lastName || ""}
                                        onChange={handleChange}
                                    />
                                    <label
                                        htmlFor="lastName-ind">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Soyadınız
                                    </label>
                                </div>
                                <City
                                    selectedCity={selectedCity}
                                    setSelectedCity={setSelectedCity}
                                    selectedTown={selectedTown}
                                    setSelectedTown={setSelectedTown}
                                    onChange={handleChange}
                                    isRedDot={true}
                                />
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="address"
                                        className="form-control"
                                        id="address-ind"
                                        placeholder="address"
                                        value={values.address || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="address-ind">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Adres
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="number"
                                        name="phoneNumber"
                                        className="form-control"
                                        id="phoneNumber-ind"
                                        maxLength="10"
                                        placeholder="Telefon Numarasi"
                                        value={values.phoneNumber || ""}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                            handleChange(e)
                                            limit(e.target)
                                            // setInputLimit(e.target.value)
                                        }}
                                    />
                                    <label htmlFor="phoneNumber-ind">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        GSM (Telefon numaranızı başında 0 olmadan giriniz)
                                    </label>

                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        id="password-ind"
                                        placeholder="password"
                                        value={values.password || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="password-ind">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Şifre
                                    </label>
                                    <p className="errors">
                                        {errors.password ? `${errors.password}` : null}
                                    </p>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="password"
                                        name="verifyPassword"
                                        className="form-control"
                                        id="verifyPassword-ind"
                                        placeholder="verifyPassword"
                                        value={values.verifyPassword || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="verifyPassword-ind">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Şifreyi Doğrulayın
                                    </label>
                                    <p className="errors">
                                        {errors.verifyPassword ? `${errors.verifyPassword}` : null}
                                    </p>
                                </div>
                                <br/>
                            </div>
                            <button
                                disabled={errors.password ||
                                    errors.verifyPassword ||
                                    values.password == null ||
                                    values.verifyPassword == null ||
                                    userName == null ||
                                    eMail == null ||
                                    values.firstName == null ||
                                    values.lastName == null ||
                                    // selectedCity == null ||
                                    // selectedTown == null ||
                                    // selecte  09op..;p0dTown == null ||
                                    // values.address == null
                                    values.phoneNumber == null}
                                // style={{background:''}}
                                className="btn btn-primary mb-4 rounded-0"
                                onClick={() => continueRegisterUser()}
                                type="button"
                            >
                                Üye Ol
                            </button>
                        </div>
                        <span style={{fontSize: '10px'}}><span
                            style={{
                                color: 'red',
                                fontSize: '14px'
                            }}>*</span> Bütün zorunlu alanları doldurun.</span>
                    </Col>

                    <Col className="d-flex justify-content-center" style={{margin: '0 auto'}} md={4}>
                        <div style={{marginTop: "2rem"}}>
                            <div>
                                <div>
                                    <h3>Ücretsiz İlan Oluşturun</h3>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div style={{width: '55px'}}>
                                        <img src={SaveMoney} alt="save money" style={{width: '100%', height: '100%'}}/>
                                    </div>
                                    <p className="m-1">
                                        Hiç bir ücret ödemeden 2 emlak 2 vasıta ilanı oluşturun.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <div>
                                    <h3>Üyeler ile iletişime geçin</h3>
                                </div>
                                <div className="d-flex align-items-center">
                                    <BiMessageDetail size={55}/>
                                    <p className="m-1">
                                        İlgilendiğiniz ilan için ilan sahibiyle iletişime geçin.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <div>
                                    <h3>İlanlarınıza Doping Uygulayın</h3>
                                </div>
                                <div className="d-flex align-items-center">
                                    <IoIosRocket size={55}/>
                                    <p className="m-1">
                                        İlanlarınızı öne çıkarmak ve vitrinde yer almalarını sağlamak için doping
                                        uygulayın.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>)}
                {formPage === 'company' && (<Row>
                    <Col md={6}>
                        <h2 style={{marginTop: "2rem"}}>
                            Kurumsal bir hesap oluşturun
                        </h2>
                        <div className="ms-4 me-4">
                            <div className="form-group mt-4 mb-4">
                                <div className="form-floating mb-3">
                                    <h6>Hesap Tipi</h6>
                                    <AccountType
                                        setSelectedType={setSelectedType}/>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="username"
                                        className="form-control"
                                        id="username"
                                        placeholder="Kullanıcı Adı"
                                        value={userName || ''}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                            // handleValueChange(e, setUserName)
                                            clearWhiteSpace(e.target.value,setUserName)
                                        }}
                                    />
                                    <label htmlFor="username">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Kullanıcı Adınız
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Email Adresi"
                                        value={userName || ''}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                            // handleValueChange(e, setEMail)
                                            clearWhiteSpace(e.target.value,setEMail)
                                        }}
                                    />
                                    <label htmlFor="email">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Email Adresi
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="firstName"
                                        className="form-control"
                                        id="firstName"
                                        placeholder="Adınız"
                                        value={values.firstName || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="firstName">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Adınız
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="lastName"
                                        className="form-control"
                                        id="lastName"
                                        placeholder="Soyadınız"
                                        value={values.lastName || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="lastName">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Soyadınız
                                    </label>
                                </div>
                                <City
                                    selectedCity={selectedCity}
                                    setSelectedCity={setSelectedCity}
                                    selectedTown={selectedTown}
                                    setSelectedTown={setSelectedTown}
                                    onChange={handleChange}
                                    isRedDot={true}
                                />
                                {/*<div className="form-floating mb-3">*/}
                                {/*    <input*/}
                                {/*        type="text"*/}
                                {/*        name="city"*/}
                                {/*        className="form-control"*/}
                                {/*        id="city"*/}
                                {/*        placeholder="City"*/}
                                {/*        value={values.city || ""}*/}
                                {/*        onChange={handleChange}*/}
                                {/*    />*/}
                                {/*    <label htmlFor="city">İl</label>*/}
                                {/*</div>*/}
                                {/*<div className="form-floating mb-3">*/}
                                {/*    <input*/}
                                {/*        type="text"*/}
                                {/*        name="province"*/}
                                {/*        className="form-control"*/}
                                {/*        id="province"*/}
                                {/*        placeholder="province"*/}
                                {/*        value={values.province || ""}*/}
                                {/*        onChange={handleChange}*/}
                                {/*    />*/}
                                {/*    <label htmlFor="province">İlçe</label>*/}
                                {/*</div>*/}
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="address"
                                        className="form-control"
                                        id="address"
                                        placeholder="address"
                                        value={values.address || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="address">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Adresiniz
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="number"
                                        name="phoneNumber"
                                        className="form-control"
                                        id="phoneNumber-ind"
                                        maxLength="10"
                                        placeholder="Telefon Numarasi"
                                        value={values.phoneNumber || ""}
                                        onChange={(e) => {
                                            handleChange(e)
                                            limit(e.target)
                                        }}
                                    />
                                    <label htmlFor="phoneNumber-ind">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        GSM (Telefon numaranızı başında 0 olmadan giriniz)
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="password"
                                        value={values.password || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="password">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Şifreniz
                                    </label>
                                    <p className="errors">
                                        {errors.password ? `${errors.password}` : null}
                                    </p>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="password"
                                        name="verifyPassword"
                                        className="form-control"
                                        id="verifyPassword"
                                        placeholder="verifyPassword"
                                        value={values.verifyPassword || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="verifyPassword">
                                        Şifreyi Doğrulayın
                                    </label>
                                    <p className="errors">
                                        {errors.verifyPassword ? `${errors.verifyPassword}` : null}
                                    </p>
                                </div>
                                <strong>
                                    Şirket Bilgileri
                                </strong>
                                <br/>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="companyShortlName"
                                        className="form-control"
                                        id="companyShortlName"
                                        placeholder="companyShortlName"
                                        value={values.companyShortlName || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="companyShortlName">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Şirket Kısa Adı
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="companyOfficialName"
                                        className="form-control"
                                        id="companyOfficialName"
                                        placeholder="companyOfficialName"
                                        value={values.companyOfficialName || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="companyOfficialName">
                                        <span style={{color: 'red', fontSize: '14px'}}>*</span>
                                        Şirket Resmi Adı
                                    </label>
                                </div>
                            </div>
                            <button
                                disabled={errors.password ||
                                    errors.verifyPassword ||
                                    values.password == null ||
                                    values.verifyPassword == null ||
                                    userName == null ||
                                    eMail == null ||
                                    values.firstName == null ||
                                    values.lastName == null ||
                                    selectedCity == null ||
                                    selectedTown == null ||
                                    // selecte  09op..;p0dTown == null ||
                                    values.address == null ||
                                    values.companyShortlName == null ||
                                    values.companyOfficialName == null ||
                                    values.phoneNumber == null}
                                className="btn btn-primary mb-4"
                                onClick={() => continueRegisterUser()}
                            >
                                Üye Ol
                            </button>
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-center" style={{margin: '0 auto'}} md={4}>
                        <div style={{marginTop: "2rem"}}>
                            <div>
                                <div>
                                    <h3>Ücretsiz İlan Oluşturun</h3>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div style={{width: '55px'}}>
                                        <img src={SaveMoney} alt="save money" style={{width: '100%', height: '100%'}}/>
                                    </div>
                                    <p className="m-1">
                                        Hiç bir ücret ödemeden 2 emlak 2 vasıta ilanı oluşturun.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <div>
                                    <h3>Üyeler ile iletişime geçin</h3>
                                </div>
                                <div className="d-flex align-items-center">
                                    <BiMessageDetail size={55}/>
                                    <p className="m-1">
                                        İlgilendiğiniz ilan için ilan sahibiyle iletişime geçin.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <div>
                                    <h3>İlanlarınıza Doping Uygulayın</h3>
                                </div>
                                <div className="d-flex align-items-center">
                                    <IoIosRocket size={55}/>
                                    <p className="m-1">
                                        İlanlarınızı öne çıkarmak ve vitrinde yer almalarını sağlamak için doping
                                        uygulayın.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>)}
            </div>
        </div>)}
        {registrationStatus === 'second-step' && (<>
            <Doping
                userData={userData}
                advantages={advantages}
                setAdvantages={setAdvantages}
            />
            <button
                className="btn btn-info m-2"
                type="submit"
                disabled={disable}
                style={!disable ? {backgroundColor: "#2089b0", color: "white"} : {
                    backgroundColor: "lightgray",
                    color: "white"
                }}
                onClick={() => {
                    finalizeRegistration()
                }}
            >
                Üyeliği Tamamla
            </button>
        </>)}
        {registrationStatus === 'error' &&
            (<>
                <div style={{minHeight:'10rem'}} className="d-flex justify-content-center align-items-center">
                    <h6>{`${errorValue}`}</h6>
                </div>
            </>)}
    </>)
};

export default Register;
