import React, {useCallback, useContext, useEffect, useState} from "react";
import "./login.css";
import Logo from "../../img/emlak-logo2222.png";
import useForm from "../useForm/useForm";
import {Link, useNavigate, useLocation} from "react-router-dom";
import useAuth from "../../Components/useAuth/useAuth";
import {BaseURLContext} from "../../baseURL-context";
import {Button, Modal, Spinner, ToastContainer} from "react-bootstrap";
import {Toast} from "react-bootstrap";
import {loginUser, setCookie} from "../../static/functions";
import Cookies from "universal-cookie";
// import LayoutIn from "../NoAuthComponents/layoutIn";

const Login = () => {
    const {values, handleChange, handleSubmit} = useForm(login);
    const {baseURL} = useContext(BaseURLContext);
    const [checked, setChecked] = useState(false);
    //
    const [showToast, setShowToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const auth = useAuth();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function checkCache() {
        const cache = sessionStorage.getItem("token");
        if (cache) {
            auth.signin(cache, () => {
                navigate("/LayoutIn");
            });
        }
    }


    function login() {
        setIsLoading(true);
        getJwt();
        setTimeout(() => {
            setIsLoading(false);
            handleShow()
        },5000)
    }

    async function redirectToConfirm(email) {
        navigate('/email-onay', {state: {email: email}})
    }

    const getJwt = async () => {
        const credentials = {
            username: values.username,
            password: values.password,
        };
        const response = await loginUser(
            `${baseURL}/authentication/LoginSecureTest/`,
            credentials
        );

        if (response) {

            if (response.data?.result === 'confirmEmail') {
                redirectToConfirm(response.data.email)
            } else {
                const {token} = response.data;
                setCookie("hoe-jwt", token);
                if (checked) {
                    sessionStorage.setItem("hoejwt", token);

                }
                auth.signin(token, () => {
                    // console.log(token);
                    // Send them back to the page they tried to visit when they were
                    // redirected to the login page. Use { replace: true } so we don't create
                    // another entry in the history stack for the login page.  This means that
                    // when they get to the protected page and click the back button, they
                    // won't end up back on the login page, which is also really nice for the
                    // user experience.
                    // navigate(from, { replace: true });
                    navigate("/");
                });
            }
        }
        // else {
        //     handleShow()
        // }
    };

    return (
        <React.Fragment>
            <div className="account-form m-auto">
                <Link to="/">
                    <img
                        className="mt-2"
                        style={{paddingTop: "3rem"}}
                        src={Logo}
                        alt="Company Logo"
                    />
                </Link>
                <h1>Hepsi Oto Emlak</h1>
                <div className="ms-2 me-2">
                    <form onSubmit={handleSubmit}>
                        <fieldset>
                            <div className="d-grid gap-4 ms-4 me-4">
                                {/* <button className="btn btn-lg btn-primary mt-4" type="button">
                                    Google
                                </button>
                                <legend>---------- or -----------</legend> */}
                                <div className="form-group">
                                    <div className="form-group">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                name="username"
                                                className="form-control"
                                                id="floatingUsername"
                                                placeholder="Kullanıcı Adı"
                                                value={values.username || ""}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="floatingUsername">Kullanıcı Adı</label>
                                        </div>
                                        <div className="form-floating">
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                id="floatingPassword"
                                                placeholder="Şifre"
                                                value={values.password || ""}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="floatingPassword">Şifre</label>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="btn btn-lg btn-primary"
                                    type="submit"
                                    hidden={isLoading ? true : false}
                                >
                                    Login
                                </button>
                                <Spinner
                                    className="m-auto"
                                    animation="border"
                                    role="status"
                                    hidden={isLoading ? false : true}
                                >
                                    <span className="visually-hidden">Yükleniyor...</span>
                                </Spinner>
                                <div className="form-check m-auto">
                                    <input
                                        type="checkbox"
                                        name="saveLogin"
                                        className="form-check-input"
                                        id="saveLogin"
                                        onChange={() => setChecked(!checked)}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                    >
                                        Beni Hatırla
                                    </label>
                                </div>
                                <div style={{marginTop: "5rem"}}></div>
                            </div>
                        </fieldset>
                    </form>

                    <div>
                        <div>
                            <Link to="//Register">
                                <strong style={{textDecoration: 'none'}}>
                                    Üye Olun
                                </strong></Link>
                        </div>
                        <div>
                            <Link to="/sifremi-sifirla">
                                <strong style={{textDecoration: 'none'}}>
                                    Şifremi Unuttum
                                </strong>
                            </Link>
                        </div>
                    </div>
                </div>
                <Modal style={{background: 'white', minHeight: '15rem'}} show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Kullanıcı adı veya şifre yanlış.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Kapat
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default Login;
