import React from "react";
import Logo from "../../img/emlak-logo222.png"

const LogoComponent = ({initialInnerHeight , size}) => {

  return (
    <div id="headerContainer" >
      <div id="logo" className={initialInnerHeight !== size.height ? 'index-logo m-auto' : 'index-logo m-auto'}>
        <img src={Logo} alt="logo" style={{width:'100%',height:'100%'}} />
      </div>
    </div>
  );
}

export default LogoComponent;
