import React, { useState } from "react";
import Select from "react-select";

const options = [
    { value: "EstateAgency", label: "Emlak Ofisi" },
    { value: "Bank", label: "Banka" },
    { value: "AutoShowroom", label: "Galeri" },
    { value: "AutorizedDealer", label: "Yetkili Bayii" },
];

const Currency = ({ setSelectedType }) => {
    const [label, setLabel] = useState();
    return (
        <>
            <Select
                options={options}
                value={label}
                className="m-2"
                onChange={(e) => {
                    setSelectedType(e.value);
                    setLabel(e);
                }}
                placeholder="Kurumsal Uyelik Tipi"
            />
        </>
    );
};

export default Currency;
