import React, {useEffect,useContext} from "react";
import NavbarIn from "../../Layout/NavbarIn";
import {useSearchParams} from "react-router-dom";
import {protectedEnpointGetRequestWithParams, protectedEnpointPostRequest} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";
import Cookies from "universal-cookie";

const SuccessTest = () => {
    // https://localhost:3000/#/odeme-basarili&id=10000007?paymentType=Account&type=Monthly
    let [searchParams, setSearchParams] = useSearchParams();
    const {baseURL} = useContext(BaseURLContext);
    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")

    let id = searchParams.get('id')
    let paymentType = searchParams.get('paymentType')
    let adType = searchParams.get('type')
    let adId = searchParams.get('adId')

    async function setPaymentResults() {
        const model = {
            id: parseInt(id),
            type: adType,
            paymentType : paymentType,
            adId: parseInt(adId || "0")
        }
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Membership/SetPaymentResults`,
            model,
            cookie
        );
        if (response) {

        }
    }

    useEffect(() => {
        setPaymentResults()
    },[])

    return (
        <div>
            <div>
            <NavbarIn/>
            </div>
            <div style={{minHeight:'10rem'}} className="row">
                <h4 className="col-md-12" style={{textAlign:'center'}}>
                    Ödeme Başarılı
                </h4>
                <h6 className="col-md-12" style={{textAlign:'center'}}>
                    {paymentType === "Account" && "Hesabınız başarıyla yükseltildi." }
                    {paymentType === "Doping" && "Doping başarıyla uygulandı." }
                </h6>
            </div>
        </div>
    );
}

export default SuccessTest;
