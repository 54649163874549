import React, {useEffect} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import {beautifyName, setAdTitleStringLimit, useWindowSize} from "../../static/helpers";
import NoImage from "../../img/no-image.png";
import {useNavigate} from "react-router";


const CarSearchBody = ({result}) => {

    const navigate = useNavigate()
    const size = useWindowSize();

    async function navigateTo(id) {
        navigate(`/vasita/${id}`)
    }

    const rowImage = {
        // height: '',
        // width:'100%',
        maxWidth:'140px',
        // heigth:'190px',
        marginLeft: '-0.5rem',
    }

    const smallRowImage = {
        // height: '',
        width:'100%',
        heigth:'3rem',
        marginLeft: '-0.5rem',
    }

    return (
        <div style={{margin: '0 auto', width: '96%'}}>
            <div>
                <table className="table">
                    <thead>
                    <tr>
                        {/*<th scope="col">#</th>*/}
                        <th scope="col">&nbsp;&nbsp;</th>
                        <th className={size.width > 680 ? `` : `hide-column`}
                            scope="col">Tipi&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        {/*<th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>*/}
                        {/*<th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>*/}
                        <th style={{float: 'left', marginTop: '0.7rem'}} scope="col">İlan Adi</th>
                        {/*<th className={size.width > 680 ? `` : `hide-column`} scope="col">Oda Sayisi</th>*/}
                        {/*<th className={size.width > 680 ? `` : `hide-column`} scope="col">M2</th>*/}
                        <th scope="col">Konum</th>
                        <th scope="col">Fiyat</th>
                        <th scope="col">İlan Tarihi</th>
                    </tr>
                    </thead>
                    <tbody>
                    {result && result.map((item, i) => {
                            return (
                                <tr key={i}
                                    className={` ${i % 2 === 0 ? 'bg-white' : 'back-lightblue'}`}
                                    onClick={() => {navigateTo(result[i]?.id)}}
                                >
                                    <td>
                                        {/*// ${size?.width < 680 ? `w-100` : `w-100`}*/}
                                        <img
                                            className={`${size?.width < 680 ? `w-100` : `w-100`}`}
                                            style={size?.width < 680 ? smallRowImage : rowImage}
                                            src={result[i]?.smallImage ? result[i]?.smallImage : NoImage}
                                            alt="test"/>
                                    </td>
                                    <td className={` ${size.width > 680 ? `` : `hide-column`}`}>
                                        <p style={{float: 'left'}}>
                                            {result[i]?.categoryType}
                                        </p>
                                    </td>
                                    <td >
                                        <p style={{float: 'left'}}>
                                            {setAdTitleStringLimit(result[i].name)}
                                        </p>
                                    </td>
                                    {/*<td className={` ${size.width > 680 ? `` : `hide-column`}`}>*/}
                                    {/*    <p>*/}
                                    {/*        {result[i].rooms}*/}
                                    {/*    </p>*/}
                                    {/*</td>*/}
                                    {/*<td className={` ${size.width > 680 ? `` : `hide-column`}`}>*/}
                                    {/*    <p>*/}
                                    {/*        {result[i].squareMeter}*/}
                                    {/*    </p>*/}
                                    {/*</td>*/}
                                    <td className={` ${size.width > 680 ? `` : `minimize-column    `}`}>
                                        <p>
                                            {result[i].city}{" / "}
                                            {result[i].state}
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            {result[i].price} ₺
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            {moment(result[i].createdOn).format("DD/MM/YYYY")}
                                        </p>
                                    </td>
                                </tr>
                            )
                        }
                    )
                    }
                    </tbody>
                </table>

                {result && result?.length < 1 && (<p>Sonuc Yok</p>)}
                {/*{result?.length < 1*/}
                {/*    ?? }*/}
                {/*{result && result.map((item, i) => {*/}
                {/*    return (*/}
                {/*        <Link to={`/vasita/${item.id}`}>*/}
                {/*            <div key={i}*/}
                {/*                 className={`row p-1 search-row ${i % 2 === 0 ? 'bg-white' : 'back-lightblue'}`}>*/}
                {/*                <div className="col-md-2">*/}
                {/*                    <img src={item.coverImage} alt="test"/>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-1">*/}
                {/*                    <p>*/}
                {/*                        {item.from}*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-5">*/}
                {/*                    <p style={{float: 'left'}}>*/}
                {/*                        {setAdTitleStringLimit(item.name)}*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-1">*/}
                {/*                    <p>*/}
                {/*                        {item.city}{" / "}*/}
                {/*                        {item.state}*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-2">*/}
                {/*                    <p>*/}
                {/*                        {item.price}*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-1">*/}
                {/*                    <p>*/}
                {/*                        {moment(item.createdOn).format("DD/MM/YYYY")}*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </Link>*/}
                {/*    )*/}
                {/*})}*/}
            </div>
        </div>
    );
}

export default CarSearchBody;
