import React from "react";

const HouseView = ({
                       hrStyle,
                       Rooms,
                       WithFurniture,
                       Balcony,
                       Bathrooms,
                       BuildingAge,
                       Floor,
                       TotalFloor,
                       MonthlyExtra,
                       InUsage,
                       CreditStatus,
                       Ownership,
                       Heating,
                       FrontSide
                   }) => {
    return (
        <div>

            <div>


                <div
                    className="price-detail-wrap row"
                >
                    <label className="col-md-4" style={{color: "black"}}>
                        <strong>Oda Sayısı</strong>
                    </label>

                    <span className="col-md-8">{Rooms}</span>
                </div>
                <hr
                    style={hrStyle}
                />
            </div>


            <div>
                <div
                    className="price-detail-wrap row"
                >
                    <label className="col-md-4" style={{color: "black"}}>
                        <strong>Eşya Durumu</strong>
                    </label>
                    <span className="col-md-8 ad-detail">{`${WithFurniture === false ? "Evet" : "Hayir"}`}</span>
                </div>
                <hr
                    style={hrStyle}
                />
            </div>

            <div className="price-detail-wrap row">
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Balkon Sayısı</strong>
                </label>
                <span className="col-md-8 ad-detail">{Balcony}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Tuvalet Sayısı</strong>
                </label>

                <span className="col-md-8 ad-detail">{Bathrooms}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Bina Yaşı</strong>
                </label>

                <span className="col-md-8 ad-detail">{BuildingAge}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Kat</strong>
                </label>

                <span className="col-md-8 ad-detail">{Floor}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Toplam Kat</strong>
                </label>

                <span className="col-md-8 ad-detail">{TotalFloor}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Isınma</strong>
                </label>

                <span className="col-md-8 ad-detail">{Heating}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Aidat</strong>
                </label>

                <span className="col-md-8 ad-detail">{MonthlyExtra}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Kullanım Durumu</strong>
                </label>

                <span className="col-md-8 ad-detail">{InUsage}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Cephe</strong>
                </label>

                <span className="col-md-8 ad-detail">{FrontSide}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Krediye Uygun</strong>
                </label>

                <span className="col-md-8 ad-detail">{CreditStatus}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Tapu Durumu</strong>
                </label>

                <span className="col-md-8 ad-detail">{Ownership}</span>
            </div>
        </div>
    );
}

export default HouseView;
