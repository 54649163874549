import React, {useContext, useEffect, useState} from "react";
import {BaseURLContext} from "../../../baseURL-context";
import {defaultGetRequestWithParams} from "../../../static/functions";
import Select from "react-select";

const Reconstruction = ({setReconstruction,selectedLabel}) => {
    const { baseURL } = useContext(BaseURLContext);
    const [options, setOptions] = useState([])
    const [label, setLabel] = useState();

    async function getOptions() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/FieldManage/GetField`,
            {
                params : {
                    fieldType: 'Reconstruction'
                }
            }
        )
        if (response) {
            setLabel({value:0 , label:''})
            setOptions(response.data)
        }
    }
    useEffect(() => {
        if (selectedLabel != null) {
            setLabel({value:0 , label:selectedLabel})
        }  else {
            getOptions()
        }
    }, [selectedLabel])

    // useEffect(()=>{getOptions()},[])

    return (
        <div>
            {label && <Select
                onMenuOpen={() => {
                    getOptions()
                }}
                options={options}
                value={label}
                placeholder="Seçiniz"
                onChange={(e) => {
                    setReconstruction(e.label)
                    setLabel(e);
                }}
            />}
        </div>
    );
}

export default Reconstruction;
