import React, {useContext, useState} from "react";
import {useEffect} from "react";
import Select from "react-select";
import {defaultGetRequest, defaultGetRequestWithParams} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";
import useForm from "../../useForm/useForm";

// import data from "./data.json";

function CarSubCategory({
                            type,
                            make,
                            setMake,
                            fuelType,
                            setFuelType
                        }) {
    const {baseURL} = useContext(BaseURLContext);
    const [yearData, setYearData] = useState([]);
    const [makeData, setMakeData] = useState([]);
    const [fuelData, setFuelData] = useState([]);

    async function GetCarFilters() {
        console.log('type', type)
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/GetCarFilters/`,
            {
                params: {
                    type: type
                }
            }
        );
        if (response) {
            for (let i = 0; i < response.data.year.length; i++) {
                setYearData(elem => [...elem, {
                    label: response.data.year[i],
                    value: response.data.year[i]
                }])
            }
            for (let i = 0; i < response.data.fuelType.length; i++) {
                setFuelData(elem => [...elem, {
                    label: response.data.fuelType[i],
                    value: response.data.fuelType[i]
                }])
            }
            for (let i = 0; i < response.data.make.length; i++) {
                setMakeData(elem => [...elem, {
                    label: response.data.make[i],
                    value: response.data.make[i]
                }])
            }
        }
    }

    useEffect(() => {
        GetCarFilters()
    }, []);

    // const handleYearChange = (obj) => {
    //     setYear(obj);
    // };
    const handleMakeChange = (obj) => {
        setMake(obj);
    };
    const handleFuelTypeChange = (obj) => {
        console.log('obj ',obj)
        setFuelType(obj);
    };

    return (
        <div className="App">
            <div className="m-1 d-flex ">
                {/*<div style={{width:'200px'}} className="m-1 ">*/}
                {/*    <b>Yil</b>*/}
                {/*    <Select*/}
                {/*        placeholder="Seçim yapın"*/}
                {/*        value={year}*/}
                {/*        options={yearData}*/}
                {/*        onChange={handleYearChange}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<br/>*/}
                <div style={{width: '200px'}} className="m-1">
                    <b>Yakit Tipi</b>
                    <Select
                        placeholder="Seçim yapın"
                        value={fuelType}
                        options={fuelData}
                        onChange={handleFuelTypeChange}
                    />
                </div>
                <br/>
                <div style={{width: '200px'}} className="m-1">
                    <b>Marka</b>
                    <Select
                        placeholder="Seçim yapın"
                        value={make}
                        options={makeData}
                        onChange={handleMakeChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default CarSubCategory;
