import React, {useEffect, useRef, useState} from "react";
import {BiSkipNext, BiSkipPrevious} from 'react-icons/bi'
import {
    UploadFileBtn,
    FileUploadContainer,
    FormField,
    DragDropText,
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    FileMetaData,
    RemoveFileIcon,
    InputLabel
} from "../Styles/UploadStyle";
import {TiDelete} from "react-icons/ti";
import {useWindowSize} from "../../static/helpers";
import axios from "axios";


//check image names if they match
//check first image is the same
//preview the images by their order
//change cover images
const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000;

const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);


const EditImages = ({
                        existingImages,
                        files,
                        setFiles,
                        label,
                        updateFilesCb,
                        maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
                        ...otherProps
                    }) => {
    const fileInputField = useRef(null);

    const size = useWindowSize();

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const addNewFiles = (newFiles) => {
        for (let file of newFiles) {
            if (file.size <= maxFileSizeInBytes) {
                if (!otherProps.multiple) {
                    return {file};
                }
                files[file.name] = file;
            }
        }
        return {...files};
    };

    const callUpdateFilesCb = (files) => {
        const filesAsArray = convertNestedObjectToArray(files);
        updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = (e) => {
        const {files: newFiles} = e.target;
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
    };

    const removeFile = (fileName) => {
        delete files[fileName];
        setFiles({...files});
        callUpdateFilesCb({...files});
    };


    function objectToArray() {
        var array = Object.keys(files)
            .map(function(key) {
                return files[key];
            });
        return array
    }

    function moveArrayItemToNewIndex(old_index, new_index) {
        let arr = objectToArray();
        let element = arr[old_index];
        arr.splice(old_index, 1);
        arr.splice(new_index, 0, element);
        setFiles({...arr});
        callUpdateFilesCb({...arr})

        return arr;
    };




    return (
        <>
            <FileUploadContainer>
                <UploadFileBtn className="rounded-0" type="button" onClick={handleUploadBtnClick}>
                    <i className="fas fa-file-upload"/>
                    <span>Fotoğraf Yükleyin</span>
                </UploadFileBtn>
                <FormField
                    type="file"
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    {...otherProps}
                />
            </FileUploadContainer>
            {/*{Object.keys(files).length !== 0 && existingImages.length !== 0 && (*/}
                <FilePreviewContainer>
                <PreviewList>
                    {Object.keys(files).map((fileName, index) => {
                        let file = files[fileName];

                        let isImageFile = file?.type?.split("/")[0] === "image";
                        let cc = files.length;

                        let arrayLength = objectToArray().length;

                        return (
                            <PreviewContainer key={fileName}>
                                <div>
                                    {isImageFile && (
                                        <ImagePreview
                                            src={URL.createObjectURL(file)}
                                            alt={`file preview ${index}`}
                                        />
                                    )}

                                    <FileMetaData isImageFile={isImageFile}>
                                        <span>{file?.name}</span>
                                        <aside>
                                            {/*<span>{convertBytesToKB(file.size)} kb</span>*/}
                                            {index !== 0 && (<button
                                                // onClick={() => removeFile(fileName)}
                                                style={styles.delete}
                                                onClick={() => {
                                                    moveArrayItemToNewIndex(index, parseInt(index - 1))
                                                }}
                                                type="reset">
                                                <BiSkipPrevious style={{fontSize: '32px', color: 'blue'}}/>
                                            </button>)}
                                            <button
                                                onClick={() => removeFile(fileName)}
                                                style={styles.delete}
                                                type="reset">
                                                <TiDelete style={{fontSize: '32px', color: 'red'}}/>
                                            </button>
                                            {index !== arrayLength - 1 && (<button
                                                onClick={() => {
                                                    moveArrayItemToNewIndex(index, parseInt(index + 1))
                                                }}
                                                style={styles.delete}
                                                type="reset">
                                                <BiSkipNext style={{fontSize: '32px', color: 'blue'}}/>
                                            </button>)}
                                            {/*<RemoveFileIcon*/}
                                            {/*    className="fas fa-trash-alt"*/}
                                            {/*    onClick={() => removeFile(fileName)}*/}
                                            {/*/>*/}
                                        </aside>
                                    </FileMetaData>
                                </div>
                            </PreviewContainer>
                        );
                    })}
                </PreviewList>
            </FilePreviewContainer>
            {/*)}*/}
        </>
    );
};

export default EditImages;


const styles = {
    container: {
        width: '100%',
        height: '100%',
        // display:'inline-block'
    },
    preview: {
        marginTop: 50,
        // display: "flex",
        // flexDirection: "column",
        width: '130px',
        // height: '140px',
        margin: 10
    },
    image: {maxWidth: "100%", maxHeight: "100%"},
    delete: {
        cursor: "pointer",
        // padding: 10,
        // background: "red",
        // color: "white",
        border: "none",
    },
};
