import React, {useContext, useEffect, useState, Suspense} from "react";
import {ListGroup, Spinner} from "react-bootstrap";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {BaseURLContext} from "../baseURL-context";
import {defaultGetRequest, defaultGetRequestWithParams} from "../static/functions";
import Navbar from "../Components/Layout/Navbar";
import City from "../Components/Search/Filters/City";
import useForm from "../Components/useForm/useForm";
import Select from "react-select";
import '../assets/css/searchResults.css'
import CarSearchBody from "../Components/Search/CarSearchBody";
import isNumeric, {checkLevelType, useWindowSize} from "../static/helpers";
import _ from 'lodash'

const CarSearch = () => {
    const {baseURL} = useContext(BaseURLContext);
    const {values, handleChange} = useForm();

    let [searchParams, setSearchParams] = useSearchParams();

    const [result, setResult] = useState([]);
    const [type, setType] = useState('');
    const [types, setTypes] = useState([]);//null
    const [make, setMake] = useState('');
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [model, setModel] = useState('');
    const [subModels, setSubModels] = useState([]);
    const [subModel, setSubModel] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedTown, setSelectedTown] = useState("");
    const [year, setYear] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [yearData, setYearData] = useState([]);
    const [makeData, setMakeData] = useState([]);
    const [fuelData, setFuelData] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [typeLevel, setTypeLevel] = useState(null);
    let searchText = searchParams.get('text') ?? values.search_text

    const size = useWindowSize();

    let categoryParam = searchParams.get('category')
    let minPrice = searchParams.get('minPrice') !== null ? searchParams.get('minPrice') : values.minPrice ?? 0;
    let maxPrice = searchParams.get('maxPrice') !== null ? searchParams.get('maxPrice') : values.maxPrice ?? 0;
    let makeParam = searchParams.get('make')
    let fuelParam = searchParams.get('fuelType')


    let city = selectedCity ? selectedCity : searchParams.get('city');
    let state = selectedTown ? selectedTown : searchParams.get('state');

    useEffect(() => {
        // makeParam
        // fuelParam
        if(fuelParam != null || fuelParam !== 'undefined') {
            setFuelType({value:fuelParam,label:fuelParam})
        }
        if (categoryParam == null || categoryParam === 'undefined') {
            GetMainCarCategory()
        } else {
            GetCarFilters(categoryParam)
            setType(categoryParam)
            setTypes([])
            setTypes((elem) => [...elem, {categoryName:categoryParam}])
            if (makeParam != null && makeParam !== 'undefined') {
                setMake(makeParam)
                setMakes([])
                setMakes(elem => [...elem, makeParam])
                GetCarCategoryLevel2(makeParam)
            }
        }

    }, [])

    function clearFilterData() {
        setYear(null)
        setFuelType(null)
        setYearData([])
        setFuelData([])
    }

    // useEffect(() => {
    //     setTypes()
    //     // GetMainCarCategory()
    // }, [setTypes,setType])

    async function GetCarFilters(selected) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/GetCarFilters`,
            {
                params: {
                    type: selected
                }
            }
        );
        if (response) {
            for (let i = 0; i < response.data.year.length; i++) {
                setYearData(elem => [...elem, {
                    label: response.data.year[i],
                    value: response.data.year[i]
                }])
            }
            for (let i = 0; i < response.data.fuelType.length; i++) {
                setFuelData(elem => [...elem, {
                    label: response.data.fuelType[i],
                    value: response.data.fuelType[i]
                }])
            }
            // for (let i = 0; i < response.data.make.length; i++) {
            //     setMakeData(elem => [...elem, {
            //         label: response.data.make[i],
            //         value: response.data.make[i]
            //     }])
            // }
            setShowFilters(true)
        }
    }


    const handleYearChange = (obj) => {
        setYear(obj);
    };
    const handleFuelTypeChange = (obj) => {
        setFuelType(obj);
    };

    async function GetMainCarCategory() {
        const response = await defaultGetRequest(
            `${baseURL}/categories/GetMainCarCategoryTest`
        );
        if (response) {
            setTypes(response.data)
            return true;
        }
    };

    async function GetCarCategoryLevel1(selected) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetChildCarCategoryLevel1`,
            {
                params: {
                    category: selected.categoryName,
                },
            }
        );
        if (response) {
            setType(selected.categoryName)
            setMakes(response.data)
        }
    };

    async function GetCarCategoryLevel2(selected) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Categories/GetChildCarCategoryLevel2`,
            {
                params: {
                    make: selected,
                    category: categoryParam || type
                }
            }
        )
        if (response) {
            setModels(response.data)
            setMakes([])
            setMakes(elem => [...elem, selected])
            return true;
        }
    }

    async function GetCategoryLevel3(selected) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Categories/GetChildCarCategoryLevel3`,
            {
                params: {
                    make: make,
                    category: type,
                    model: selected
                }
            }
        )
        if (response) {
            setSubModels(response.data)
        }
    }

    function ClearLevel1(selected) {
        if (types.length === 1) {
            GetMainCarCategory()
            setShowFilters(false)
            setMakes([])
            setTypes([])
            ClearMake()
            return false
        } else {
            GetCarCategoryLevel1(selected)
            ClearMake()
            setType('')
            setTypes([])
            setMakes([])
            setTypes((elem) => [...(elem), selected])
            GetCarFilters(selected.categoryName)
            return true
        }
    }

    async function ClearLevel2(selected) {
        setModels([])
        setModel(null)
        setSubModels([])
        setSubModel(null)
        // setSubCat4([])
        setModels(elem => [...elem, selected])
    }

    async function ClearLevel3(selected) {
        setSubModel(selected)
        setSubModels([])
        setSubModels(elem => [...elem, selected])
    }

    async function GetCarSearch() {
        setResult([])
        let encodedText = encodeURI(searchText)
        const parameters = {
            name: encodedText,
            city: city,
            state: state,
            category: categoryParam ?? type,
            make: makeParam ?? make,
            model: model,
            submodel: subModel,
            year: year?.value,
            fuelType: fuelParam ?? fuelType?.value,
            minPrice: isNumeric(minPrice) ? minPrice : "0",
            maxPrice: isNumeric(maxPrice) ? maxPrice : "0"
        }

        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/SearchCars`,
            {
                params: parameters,
            }
        );
        if (response) {
            setResult(response.data)
        }
    };

    function ClearMake() {
        clearFilterData()
        setMake('')
        setMakes([])
        setModels([])
        setModel('')
        setSubModels([])
        setSubModel('')
    }

    function ClearModel() {
        setModels([])
        setModel('')
        setSubModels([])
        setSubModel('')
    }

    function ClearSubModel() {
        setSubModels([])
        setSubModel('')
    }

    async function getResult() {
        const array = await GetCarSearch();
        return array;
    }

    const hideFilter = {
        display: 'none',
        // maxHeight: '200px',
    }
    const breadcrumbsStyle = {
        float: 'left',
        display: 'flex',
        flexDirection: 'row'
    }

    const showF = {
        // display:'none'
    }

    const showFilter = {
        width: '165px',
        maxHeight: '200px',
        overflowY: 'scroll',
    }

    const showFilterScreen = {
        width: '90%',
        maxHeight: '200px',
        overflowY: 'scroll',
    }

    const filterStyle = {
        width: '100%',
    }

    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: 1,
        borderColor: "#000000",
    }

    const filterStyleWindowSize = size?.width > 680 ? showFilter : showFilterScreen
    const styleMake = type ? showFilter : hideFilter;
    const styleModel = make && models?.length > 0 && models[0] != null ? showFilter : hideFilter
    const submodelStyle = model && subModels.length > 0 && subModels[0] !== null ? showFilter : hideFilter




    useEffect(() => {
        getResult()
        setTypeLevel(checkLevelType(type))
    }, [])

    return (
        <div>
            <Suspense fallback={
                <Spinner
                    className="m-auto"
                    animation="border"
                    role="status"
                />
            }>
                <Navbar/>

                <div className="category-container">
                    <div className="row">

                    </div>
                    <div className="row">

                        <div className="col-md-12 m-4" style={breadcrumbsStyle}>
                            <strong>
                                Vasita&nbsp;&nbsp;/&nbsp;
                            </strong>
                            <strong>
                                &nbsp;{type ?? type}&nbsp;
                            </strong>
                            <strong style={type?.length < 1 ? hideFilter : showF}>
                                &nbsp;{" / "}&nbsp;
                            </strong>
                            <strong>
                                &nbsp;{make ?? make}&nbsp;
                            </strong>
                            <strong style={make?.length < 1 ? hideFilter : showF}>
                                &nbsp;{" / "}&nbsp;
                            </strong>
                            <strong>
                                {model ?? model}
                            </strong>
                            <strong style={models?.length > 1 ? hideFilter : showF}>
                                &nbsp;{" / "}&nbsp;
                            </strong>
                            <strong>
                                {subModel ?? subModel}
                            </strong>
                        </div>

                        <div className="col-md-2">
                            <ListGroup className="m-2"
                                       id="scroll"
                                       style={_.merge({}, showFilter, filterStyleWindowSize)}>
                                {types && types.map((item, i) => {
                                    return (
                                        <ListGroup.Item key={i} className="d-flex">
                                            <Link to="#"
                                                  style={{color: 'black'}}
                                                  onClick={() => {
                                                      // type && window.location.reload()

                                                      ClearLevel1(item)
                                                  }}
                                            >
                                                {item.categoryName}
                                            </Link>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                            <ListGroup className="m-2" id="scroll"
                                // style={type ? showFilter : hideFilter}>
                                       style={_.merge({}, styleMake, filterStyleWindowSize)}>
                                {makes && makes.map((cat, i) => {
                                    return (
                                        <ListGroup.Item className="d-flex" style={{marginLeft: '0.5rem'}} key={i}>
                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    setMake(cat)
                                                    GetCarCategoryLevel2(cat)
                                                    ClearModel()
                                                }}
                                            >
                                                {cat}
                                            </Link>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                            <div>
                                <ListGroup className="m-2" d="scroll"
                                           style={_.merge({}, styleModel, filterStyleWindowSize)}>
                                    {/*<ListGroup className="m-2" id="scroll" style={{width: '185px', maxHeight: '250px'}}>*/}
                                    {makes && models && models.map((cat, i) => {
                                        return (
                                            <ListGroup.Item className="d-flex" key={i}>
                                                <Link
                                                    to="#"
                                                    onClick={() => {
                                                        ClearLevel2(models[i])
                                                        setModel(models[i])
                                                        GetCategoryLevel3(cat)
                                                        // setTypes(types)
                                                        ClearSubModel()
                                                        // GetCarSearch()
                                                    }}
                                                >
                                                    {cat}
                                                </Link>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            </div>
                            <div>
                                {/*<ListGroup className="m-2" id="scroll" style={{width: '185px', maxHeight: '250px'}}>*/}
                                <ListGroup className="m-2" d="scroll"
                                           style={_.merge({}, submodelStyle, filterStyleWindowSize)}>
                                    {subModels && subModels.map((cat, i) => {
                                        return (
                                            <ListGroup.Item className="d-flex" key={i}>
                                                <Link
                                                    to="#"
                                                    onClick={() => {

                                                        ClearLevel3(cat)
                                                        // GetCarSearch()
                                                    }}
                                                >
                                                    {cat}
                                                </Link>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            </div>
                            <hr style={hrStyle}/>
                            <div>
                                <City
                                    setSelectedCity={setSelectedCity}
                                    setSelectedTown={setSelectedTown}
                                />
                            </div>
                            <hr style={hrStyle}/>
                            {showFilters && typeLevel === 2 && (<div style={filterStyle}>
                                <div className="m-1 ">
                                    <b>Yil</b>
                                    <Select
                                        placeholder="Seçim yapın"
                                        value={year}
                                        options={yearData}
                                        onChange={handleYearChange}
                                    />
                                </div>
                                <br/>
                                <div className="m-1">
                                    <b>Yakit Tipi</b>
                                    <Select
                                        placeholder="Seçim yapın"
                                        value={fuelType}
                                        options={fuelData}
                                        onChange={handleFuelTypeChange}
                                    />
                                </div>
                                <br/>
                            </div>)}

                            <hr style={hrStyle}/>
                            <div
                                // style={{width:'150px'}}
                                className="sidebar-input-wrapper"
                            >
                                <label className="field field_v3">
                                    <input
                                        className="field__input"
                                        type="text"
                                        name="search_text"
                                        value={values.search_text}
                                        style={{borderStyle: 'solid'}}
                                        id="search_text"
                                        // style={{width:'400px'}}
                                        placeholder="Aramak için anahtar kelime girin"
                                        onChange={
                                            handleChange
                                            // setVal(e.target.value);
                                        }
                                    />
                                    <span className="field__label-wrap">
                                  <span className="field__label">Ara</span>
                                </span>
                                </label>
                            </div>
                            <hr style={hrStyle}/>
                            {/*</div>*/}
                            {/*<div className="d-flex justify-content-center col-md-11 row">*/}
                            <div className="d-flex justify-content-center mb-3">
                                <div className="m-1 p-1 " style={{width: '70px'}}>
                                    <label className="field field_v3" htmlFor="minPrice">
                                        Min(₺)
                                    </label>
                                    <input className="rounded-1 number-input field__input"
                                           style={{
                                               borderWidth: '1px',
                                               lineHeight: '27px',
                                               borderColor: 'gray',
                                               width: '70px',
                                               textIndent: '0.5em'
                                           }}
                                           id="minPrice"
                                           type="number"
                                           name="minPrice"
                                           value={minPrice || (values.minPrice || 0)}
                                           onChange={handleChange}/>
                                </div>
                                <div className="m-1 p-1" style={{width: '70px'}}>
                                    <label className="field field_v3" htmlFor="maxPrice">
                                        Max(₺)
                                    </label>
                                    <input className="rounded-1 number-input field__input"
                                           style={{
                                               width: '70px',
                                               textIndent: '0.6em'
                                           }}
                                           id="maxPrice"
                                           name="maxPrice"
                                           type="number"
                                           value={maxPrice || (values.maxPrice || 0)}
                                           onChange={handleChange}/>
                                </div>
                            </div>
                            <hr style={hrStyle}/>


                            <div style={{marginBottom:'2rem'}}>
                                <Link
                                    onClick={() => {
                                        getResult();
                                    }}
                                    type="button"
                                    style={{width: '100%'}}
                                    className="p-2 rounded rounded-5 btn-info"
                                    // to={`/emlak-ara?city=${selectedCity}&town=${selectedTown}&minPrice=${values.minPrice}&maxPrice=${values.maxPrice}&tpye=${typeSelectOpt.value}&status=${statusSelectOpt.value}`}
                                    to="#"

                                    // state={
                                    // {
                                    //     city: selectedCity,
                                    //     town: selectedTown,
                                    //     min: values.minPrice,
                                    //     max: values.maxPrice
                                    // }}
                                >
                                    Ara
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div>
                                {result?.length < 1 ??
                                    (<p>Sonuçlar gösteriliyor</p>)}

                            </div>
                            <CarSearchBody
                                result={result}
                            />

                        </div>
                    </div>
                </div>
            </Suspense>
        </div>
    );
}

export default CarSearch;
