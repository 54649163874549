import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Components/Layout/Navbar";

const EstateCats = () => {
  return (
    <div>
      {/* {navbar === false ? <NavbarOut logoState={true} /> : <NavbarIn navLogo={navLogo} />} */}
      <Navbar />
      <div style={{ padding: 20 }}>
        <Outlet />
      </div>
      {/* <div>
        <Footer />
      </div> */}
    </div>
  );
};

export default EstateCats;
