import React, {useState, useEffect,useContext} from "react";
import Select from "react-select";
import {BaseURLContext} from "../../../baseURL-context";
import {defaultGetRequestWithParams} from "../../../static/functions";


const Heating = ({selectedHeating, setSelectedHeating, selectedLabel}) => {
    const [label, setLabel] = useState();
    const {baseURL} = useContext(BaseURLContext);
    const [options, setOptions] = useState([]);

    async function getOptions() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/FieldManage/GetField`,
            {
                params: {
                    fieldType: 'Heating'
                }
            }
        )
        if (response) {
            setLabel({value:0 , label:''})
            setOptions(response.data)
        }
    }

    useEffect(() => {
        if (selectedLabel != null) {
            setLabel({value:0 , label:selectedLabel})
        }
    }, [selectedLabel])

    const hide = {
        display:'none'
    }

    const show = {

    }

    return (
        <>
            {label && <Select
                onMenuOpen={() => {
                    getOptions()
                }}
                styles={label && options ? hide : show}
                options={options}
                value={label}
                onChange={(e) => {
                    setSelectedHeating(e.label);
                    setLabel(e);
                }}
                placeholder="Seçiniz"
            />}
        </>
    );
};

export default Heating;
