import React, { useEffect} from "react";

const Advantages = ({advantages, setAdvantages, currentType,amounts}) => {

    // eslint-disable-next-line
    useEffect(() => {
        setAdvantages(currentType)
        // eslint-disable-next-line
    }, [currentType])
    return (
        <div className="row" style={{marginBottom: '4rem',marginTop: '4rem'}}>
            <div className="col-md-12 row d-flex justify-content-center">
                <div className="card col-md-3 m-4">
                    <div className="card-body card-register">
                        <h5 className="card-title">Ücretsiz Üyelik</h5>
                        <p>2 emlak, 2 vasıta - toplam 4 ilan</p>
                        <p><strong>Ücretsiz</strong></p>

                        <div className="upgradeButtonWrapper">
                            <button
                                type="button"
                                className={` rounded-0 ${advantages === 0 ? ` customButtonUpgradeSelected ` : ` customButtonUpgradeSelect `}`}
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                onClick={() => {
                                    setAdvantages(0);
                                }}
                                defaultChecked={true}
                            >
                                Seç
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card col-md-3 m-4">
                    <div className="card-body card-register">
                        <h5 className="card-title">Aylık Üyelik</h5>
                        <p>Toplam 15 ilan</p>
                        <p style={{fontSize:'20px'}}><b>{amounts[0]?.value} ₺</b></p>
                        <div className="upgradeButtonWrapper">
                            <button
                                type="button"
                                className={` rounded-0 ${advantages === 1 ? ` customButtonUpgradeSelected ` : ` customButtonUpgradeSelect `}`}
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                onClick={() => {
                                    setAdvantages(1);
                                }}
                            >
                                Seç
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card col-md-3 m-4">
                    <div className="card-body card-register">
                        <h5 className="card-title">Yıllık Üyelik</h5>
                        <p>Toplam 30 ilan</p>
                        <p><strong>{amounts[1]?.value} ₺</strong></p>

                        <div className="upgradeButtonWrapper">
                            <button
                                type="button"
                                className={` rounded-0 ${advantages === 2 ? ` customButtonUpgradeSelected ` : ` customButtonUpgradeSelect `}`}
                                name="flexRadioDefault"
                                id="flexRadioDefault3"
                                onClick={() => {
                                    setAdvantages(2);
                                }}
                            >
                                Seç
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Advantages;
