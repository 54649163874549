import useAuth from "../useAuth/useAuth";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const Logoff = () => {
    const auth = useAuth();
    const cookies = new Cookies();
    const token = cookies.get("hoe-jwt");
    const navigate = useNavigate();

    // useEffect(() => {
    //     logout();
    // }, []);

    function logout() {
        if (token) {
            // sessionStorage.removeItem("token");
            cookies.remove("hoe-jwt");
        }
        auth.signout(() => {
            navigate("/");
        });
    }

    return null;
};

export default Logoff;
