import React, {Component} from "react";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML
} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { stateFromHTML } from 'draft-js-import-html'
import '../../../assets/css/textArea.css'


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-color-picker/index.css";

class EditorConvertToHTML extends Component {
    // draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    constructor(props) {
        super(props);

        const html = '<p id="para"></p>';
        const contentBlock = htmlToDraft(html);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );

            const editorState = EditorState.createWithContent(stateFromHTML(this.props?.existingDesc));

            this.state = {
                editorState,
                background: "",
                isOpen: false,
                emailBody: `<p id="para">test</p>`,
                fontcolor: "",
            };
        }
    }

    onEditorStateChange = editorState => {
        this.setState({
            editorState
        });
        this.props.setDescription(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        );

    };

    onEditorChange = evt => {
        this.setState({
            emailBody: evt.editor.getData()
        });
    };

    componentDidMount() {
        // document.getElementById("para").style.color = "red !important";
        // console.log('we here')
        if(this.props?.existingDesc != null) {
            htmlToDraft(this.props?.existingDesc)
            console.log('this.props?.existingDesc ',this.props?.existingDesc)
            this.state = {
                editorState: EditorState.createWithContent(stateFromHTML(this.props?.existingDesc))
            };
            // this.props.setDescription(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
        }
    }

    render() {
        const {editorState} = this.state;

        return (
            <div className="description-container">
                <div style={{border: 'solid'}}>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                    />
                </div>
            </div>
        );
    }
}

export default EditorConvertToHTML;