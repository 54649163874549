import React, {useContext, useState} from "react";
import {BaseURLContext} from "../../../../baseURL-context";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import Location from "../../../../Components2/Product/ProductDetails/Location";
import Carousel from "../../../Products/Carousel";
import Cookies from "universal-cookie";
import {protectedEnpointPostRequest} from "../../../../static/functions";
import '../../../../assets/css/adPreview.css'
import {useNavigate} from "react-router";

const CarPreview = ({setId, carData, images, formData, state, goForm}) => {
    const {baseURL} = useContext(BaseURLContext);
    const [disable, setDisable] = useState(false);
    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")
    const navigate = useNavigate();
    // const [id, setId] = useState(0)
    console.log('car data on preview ', carData)
    const {
        City,
        State,
        Brand,
        CategoryType,
        Price,
        // caseType,
        Color,
        // gear,
        Km,
        LicensePlate,
        Model,
        // traction,
        Year,
        Name,
        Description
    } = carData || []


    async function createCar() {
        setDisable(true)
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Car/AddCarProduct`,
            carData,
            cookie
        );
        if (response) {
            // console.log('car id is ', response.data.id)
            // console.log('car id is ', response.data.itemId)
            console.log('response id ', response.data.itemId)
            setId(response.data.itemId);
            let newFormData = new FormData();
            console.log('formData :', formData)
            if (formData?.length > 0) {
                for (var a = 0; a < formData?.length; a++) {
                    newFormData.append(`formFile`, formData[a]);
                }
                // newFormData.append("fileName", formData[0].name);
                newFormData.append("productId", response.data?.itemId);
                newFormData.append("type", 1);
                console.log('formFile', newFormData)
                const createImageResponse = await protectedEnpointPostRequest(
                    `${baseURL}/Estate/CreateImage`,
                    newFormData,
                    cookie
                );
                if (createImageResponse) {
                    console.log("image/images created")
                    state('success');
                    setTimeout(() => {
                        navigate("/ilanlarim")
                    }, 3000);
                }
                return response;
            } else {
                state('success')
            }
        } else {
            return false;
        }
    }

    return (
        <div className="container">
            <br/>
            <div
                className="card"
                style={{paddingTop: "4rem", backgroundColor: "white"}}
            >
                <button
                    disabled={disable}
                    className="btn btn-primary mb-4"
                    style={!disable ? {backgroundColor: "green", color: "white"} : {backgroundColor: "lightgray", color: "white"}}
                    onClick={() => {
                        createCar();
                    }}
                    type="submit"
                >
                    ILAN OLUSTUR
                </button>
                {/*<button onClick={() => {goForm() }} >geri git</button>*/}
                <div className="row">
                    <div className="col-md-5 border-right p-3">
                        <Carousel array={images} adType={2}/>
                    </div>
                    <div className="col-md-4">
                        <article className="card-body">
                            <h3 className="title mb-3" style={{color: "black"}}>
                                {Name}
                            </h3>

                            <div className="price-detail-wrap row">
                                <p className="col-md-12" style={{color: "black"}}>
                                    <strong> {Price}{" TL"} </strong>
                                </p>
                            </div>
                            <div
                                className="price-detail-wrap row"
                                // style={{ paddingBottom: "5px" }}
                            >
                                <span className="col-md-12">
                                    <p>{City}/{State}</p>

                                    {/* {city} / {province} / {state} */}
                                    {/*{address}*/}
                                </span>
                            </div>
                            <hr
                                style={{
                                    color: "#000000",
                                    backgroundColor: "#000000",
                                    height: 1,
                                    borderColor: "#000000",
                                }}
                            />
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Marka</strong>
                                </label>

                                <span className="col-md-4">{Brand}</span>

                                {/* <hr style={{ border:'none',borderBottom:'3px' }} /> */}
                            </div>
                            <hr
                                style={{
                                    color: "#000000",
                                    backgroundColor: "#000000",
                                    height: 1,
                                    borderColor: "#000000",
                                }}
                            />
                            <div
                                className="price-detail-wrap row"
                                // style={{ paddingBottom: "5px" }}
                            >
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Model</strong>
                                </label>

                                <span className="col-md-4">{Model}</span>

                                {/* <hr style={{ border:'none',borderBottom:'3px' }} /> */}
                            </div>
                            <hr
                                style={{
                                    color: "#000000",
                                    backgroundColor: "#000000",
                                    height: 1,
                                    borderColor: "#000000",
                                }}
                            />
                            <div
                                className="price-detail-wrap row"
                                // style={{ paddingBottom: "5px" }}
                            >
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Yıl</strong>
                                </label>

                                <span className="col-md-4"><p>{Year}</p></span>

                                {/* <hr style={{ border:'none',borderBottom:'3px' }} /> */}
                            </div>
                            <hr
                                style={{
                                    color: "#000000",
                                    backgroundColor: "#000000",
                                    height: 1,
                                    borderColor: "#000000",
                                }}
                            />
                            {/*<hr*/}
                            {/*    style={{*/}
                            {/*        color: "#000000",*/}
                            {/*        backgroundColor: "#000000",*/}
                            {/*        height: 1,*/}
                            {/*        borderColor: "#000000",*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <div
                                className="price-detail-wrap row"
                                // style={{ paddingBottom: "5px" }}
                            >
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Kilometre</strong>
                                </label>

                                <span className="col-md-4">{Km}</span>

                                {/* <hr style={{ border:'none',borderBottom:'3px' }} /> */}
                            </div>
                            <hr
                                style={{
                                    color: "#000000",
                                    backgroundColor: "#000000",
                                    height: 1,
                                    borderColor: "#000000",
                                }}
                            />
                            {/*<div*/}
                            {/*    className="price-detail-wrap row"*/}
                            {/*    // style={{ paddingBottom: "5px" }}*/}
                            {/*>*/}
                            {/*    <label className="col-md-4" style={{color: "black"}}>*/}
                            {/*        <strong>Kasa tipi</strong>*/}
                            {/*    </label>*/}

                            {/*    <span className="col-md-4">{caseType}</span>*/}

                            {/*    /!* <hr style={{ border:'none',borderBottom:'3px' }} /> *!/*/}
                            {/*</div>*/}
                            {/*<hr*/}
                            {/*    style={{*/}
                            {/*        color: "#000000",*/}
                            {/*        backgroundColor: "#000000",*/}
                            {/*        height: 1,*/}
                            {/*        borderColor: "#000000",*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*<div*/}
                            {/*    className="price-detail-wrap row"*/}
                            {/*    // style={{ padding: "10px" }}*/}
                            {/*>*/}
                            {/*    <label className="col-md-4" style={{color: "black"}}>*/}
                            {/*        <strong>Cekis</strong>*/}
                            {/*    </label>*/}

                            {/*    <span className="col-md-4">{traction}</span>*/}
                            {/*</div>*/}
                            <div
                                className="price-detail-wrap row"
                                // style={{ padding: "10px" }}
                            >
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Renk</strong>
                                </label>

                                <span className="col-md-4">{Color}</span>
                            </div>
                            <hr
                                style={{
                                    color: "#000000",
                                    backgroundColor: "#000000",
                                    height: 1,
                                    borderColor: "#000000",
                                }}
                            />
                            <div
                                className="price-detail-wrap row"
                                // style={{ padding: "10px" }}
                            >
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Plaka</strong>
                                </label>

                                <span className="col-md-4">{LicensePlate}</span>
                            </div>
                            <hr
                                style={{
                                    color: "#000000",
                                    backgroundColor: "#000000",
                                    height: 1,
                                    borderColor: "#000000",
                                }}
                            />
                        </article>
                    </div>
                </div>
                <div className="row">
                    <Tab.Container className="center" defaultActiveKey="first">
                        {/*<Col md={12} style={{margin: "0 auto", paddingLeft: "2rem"}}>*/}
                        {/*    <Nav variant="pills" className="flex-column">*/}
                        {/*        <Row>*/}
                        {/*            <Col>*/}
                        {/*                <Nav.Item>*/}
                        {/*                    <Nav.Link eventKey="first">Tab 1</Nav.Link>*/}
                        {/*                </Nav.Item>*/}
                        {/*            </Col>*/}
                        {/*            <Col>*/}
                        {/*                <Nav.Item>*/}
                        {/*                    <Nav.Link eventKey="second">Tab 2</Nav.Link>*/}
                        {/*                </Nav.Item>*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*    </Nav>*/}
                        {/*</Col>*/}
                        <Row style={{paddingLeft: "3.2rem", marginTop: '2rem'}}>
                            <Col md={12} className="m-auto"
                                 style={{margin: "0 auto", backgroundColor: "lightgray", minHeight: "10rem"}}>
                                <Row className="m-auto" style={{margin: "0 auto"}}>
                                    <Col md={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <Row className="m-auto">
                                                    <Col
                                                        className="d-flex center justify-content-center align-items-center"
                                                        md={12}>
                                                        <p dangerouslySetInnerHTML={{__html: `${Description}`}}>
                                                        </p>
                                                    </Col>
                                                    {/* <Col md={6}>
                            <p>
                              LoremLorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom  lreom
                            </p>
                          </Col> */}
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Row>
                                                    <Col md={12}>
                                                        <Location/>
                                                        {/* <p>
                              Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom Lorem lreom
                            </p> */}
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>
    );
}

export default CarPreview;
