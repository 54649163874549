import React, {useCallback, useContext, useEffect, useState, Suspense} from "react";
import Navbar from "../Components/Layout/Navbar";
import {Link, useNavigate} from "react-router-dom";
import {useSearchParams} from 'react-router-dom'
import {BaseURLContext} from "../baseURL-context";
import {defaultGetRequest, defaultGetRequestWithParams, isNullOrEmpty} from "../static/functions";
import {ListGroup, Spinner} from "react-bootstrap";
import EstateSearchBody from "../Components/Search/EstateSearchBody";
// import City from "../Components/Search/Filters/City";
import useForm from "../Components/useForm/useForm";
import Rooms from "../Components/Forms/CreateEstate/Rooms";
import '../assets/css/searchResults.css'
import {useWindowSize} from "../static/helpers";
import _ from 'lodash'
// import queryString from 'query-string'
import Location from "../Components/SearchTest/Filters/City";

// import '../Components/Search/Filters/data.json'
// import EstateSubCategory from "../Components/Search/Filters/EstateSubCategory";

const EstateSearch = () => {
    const {baseURL} = useContext(BaseURLContext);
    const {values, handleChange} = useForm();
    let [searchParams, setSearchParams] = useSearchParams();
    const [result, setResult] = useState([]);
    // const [refresh, setRefresh] = useState(false);

    const [searchCat, setSearchCat] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [mainCats, setMainCats] = useState([]);
    const [mainCat, setMainCat] = useState("");
    // const [mainCatVal, setMainCatVal] = useState("");
    const [subCat1, setSubCat1] = useState([]);
    const [subCatVal1, setSubCatVal1] = useState('');
    const [subCat2, setSubCat2] = useState([]);
    const [subCatVal2, setSubCatVal2] = useState('');
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedTown, setSelectedTown] = useState("");
    const [selectedNeighbour, setSelectedNeighbour] = useState("");
    const [selectedRooms, setSelectedRooms] = useState("");
    const [statusSelectOpt, setStatusSelectOpt] = useState([]);
    const [typeSelectOpt, setTypeSelectOpt] = useState([]);
    let city = selectedCity ? selectedCity : searchParams.get('city');
    let state = selectedTown ? selectedTown : searchParams.get('state');
    const rooms = selectedRooms ? selectedRooms : searchParams.get('rooms');
    let minPrice = searchParams.get('minPrice') !== null ? searchParams.get('minPrice') : values.minPrice ?? 0;
    let maxPrice = searchParams.get('maxPrice') !== null ? searchParams.get('maxPrice') : values.maxPrice ?? 0;
    let searchTextOrParameter = searchParams.get('text') ?? searchText

    const adType = searchParams.get('adType')
    const familyName = searchParams.get('familyName')
    const cityParam = searchParams.get('city')
    const stateParam = searchParams.get('state')

    const size = useWindowSize();

    async function GetEstateLevel2(selected) {
        if (mainCats.length === 1) {
            setMainCats([])
            // setSubCat1([])
        } else {
            const response = await defaultGetRequestWithParams(
                `${baseURL}/categories/GetEstateLevel2/`,
                {
                    params: {
                        famType: selected
                    }
                }
            );
            if (response) {
                setSubCat1(response.data)
            }
        }
    }

    async function GetEstateLevel3(selected) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetEstateLevel3/`,
            {
                params: {
                    famType: familyName ?? mainCat,
                    estateType: selected
                }
            }
        );
        if (response) {
            setSubCat2(response.data)
        }
        setSubCat1([])
        setSubCat1(elem => [...elem, selected])
    }

    const getCategories = async () => {
        const response = await defaultGetRequest(
            `${baseURL}/categories/GetEstateLevel1/`
        );
        if (response) {
            setMainCats(response.data);
            setMainCat('')
        }
    };

    async function getEstateSearch() {
        const encodedText =  encodeURI(searchTextOrParameter)
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/SearchEstates`,
            {
                params : {
                    name: encodedText,
                    city: city,
                    state: state,
                    neighbourhood: selectedNeighbour,
                    familyName: familyName != null ? familyName : mainCat,
                    advType: adType != null ? adType : subCatVal1,
                    estateType: subCatVal2,
                    minPrice: parseFloat(minPrice),
                    maxPrice: parseFloat(maxPrice),
                    rooms: rooms
                }
            }
        );

        if (response) {
            setResult([])
            for (const responseElement of response.data) {
                setResult(prevArray => [...prevArray, responseElement])
            }
            return response.data;
        } else {
            return [];
        }
    }
        ;

        async function getResult() {
            const array = await getEstateSearch();
            return array;
        }

        useEffect(() => {
            if(searchTextOrParameter != null || searchTextOrParameter !== 'undefined') {
                setSearchText(searchTextOrParameter)
            }
        },[searchTextOrParameter])

        useEffect(() => {

            if (familyName == null || familyName === 'undefined') {
                getCategories();
            } else {
                setMainCats((elem) => [...elem, familyName])
                setMainCat(familyName)
            }
            if (adType !== 'undefined') {
                setSubCat1((elem) => [...elem, adType])
                setSubCatVal1(adType)
                GetEstateLevel3(adType)
            } else {
                GetEstateLevel2(familyName)
            }
            if (cityParam != null) {
                setSelectedCity(cityParam)
            }
            if (stateParam != null) {
                setSelectedTown(stateParam)
            }
            window.scrollTo(0, 0)
            //eslint-disable-next-line
        }, []);

        useEffect(() => {
            getResult()
        }, [])
        // useEffect(() => {
        //
        // }, [])

        function ClearMainCat(selected) {
            setMainCats([])
            setSubCat1([])
            setSubCatVal1('')
            setSubCat2([])
            setSubCatVal2('')
            if (mainCats.length === 1) {
                getCategories()
                // GetEstateLevel2(selected)
            } else {
                // setMainCats(selected)
                setMainCats(elem => [...elem, selected])
                setMainCat(selected)
            }
        }

        function FinalizeCategorySelect(selected) {
            setSubCatVal2(selected)
            setSubCat2([])
            // setSubCat2(selected)
            setSubCat2(elem => [...elem, selected])
        }

        const hideFilter = {
            display: 'none',
            // maxHeight: '200px',
        }

        const showFilter = {
            width: '165px',
            maxHeight: '200px',
            overflowY: 'scroll',
        }

        const showFilterScreen = {
            width: '90%',
            maxHeight: '200px',
            overflowY: 'scroll',
        }

        const hrStyle = {
            color: "#000000",
            backgroundColor: "#000000",
            height: 1,
            borderColor: "#000000",
        }

        const breadcrumbsStyle = {
            float: 'left',
            display: 'flex',
            flexDirection: 'row'
        }

        const showF = {
            // display:'none'
        }

        const style1 = subCatVal1 && subCat2.length > 0 && subCat2[0] !== null ? showFilter : hideFilter
        const style2 = size?.width > 680 ? showFilter : showFilterScreen
        const style3 = mainCat ? showFilter : hideFilter

        return (
            <div className="container">
                <Suspense fallback={
                    <Spinner
                        className="m-auto"
                        animation="border"
                        role="status"
                    />
                }>
                    <Navbar/>
                    <div className="category-container">
                        <div className="row">
                            <div className="col-md-12 m-4" style={breadcrumbsStyle}>
                                <strong>
                                    Emlak&nbsp;&nbsp;/&nbsp;
                                </strong>
                                <strong>
                                    {mainCat ? ` ${mainCat} / ${"  "}` : ``}
                                </strong>
                                <strong style={mainCats?.length > 1 ? hideFilter : showF}>
                                    &nbsp;&nbsp;
                                </strong>
                                <strong>
                                    {subCatVal1 ? `${subCatVal1} / ` : ``}
                                </strong>
                                <strong style={subCat1?.length > 1 ? hideFilter : showF}>
                                    &nbsp;&nbsp;
                                </strong>
                                <strong>
                                    {subCatVal2 ? `${subCatVal2} ${"  "}` : ``}
                                </strong>

                            </div>
                            <div className="col-md-2 mt-5 p-3">
                                {/*<div className="col-md-11">*/}
                                <ListGroup className="m-2" id="scroll"
                                           style={size?.width > 680 ? showFilter : showFilterScreen}>
                                    {mainCats && mainCats.map((cat, i) => {
                                        return (
                                            <ListGroup.Item key={i} className="d-flex">
                                                <Link to="#"
                                                    // className="d-flex"
                                                      style={{color: 'black'}}
                                                      onClick={() => {
                                                          setMainCat(cat)
                                                          GetEstateLevel2(cat)
                                                          ClearMainCat(cat)
                                                      }}
                                                >
                                                    {cat}
                                                </Link>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                                {/*</div>*/}
                                {/*<div className="col-md-11">*/}
                                {/*<div>*/}


                                <ListGroup className="m-2" id="scroll" style={_.merge({}, style3, style2)}>
                                    {subCat1 && subCat1.map((cat, i) => {
                                        return (
                                            <ListGroup.Item style={{marginLeft: '0.5rem'}} key={i}>
                                                <Link
                                                    className="d-flex"
                                                    // to={`${cat.id}`}
                                                    // style={{float:'left',left:'0'}}
                                                    to="#"
                                                    // state={{cat:cat}}
                                                    onClick={() => {
                                                        setSubCatVal1(cat)
                                                        // ClearLevel1(cat);
                                                        GetEstateLevel3(cat)

                                                    }}
                                                >
                                                    {cat}
                                                </Link>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                                {/*</div>*/}
                                {/*<div className="col-md-11">*/}
                                <ListGroup className="m-2" d="scroll"
                                           style={_.merge({}, style1, style2)}>
                                    {/*style={subCatVal1 && subCat2.length > 0 && subCat2[0] !== null ? showFilter : hideFilter}>*/}
                                    {subCatVal1 && subCat2 && subCat2.map((cat, i) => {
                                        return (
                                            <ListGroup.Item style={{marginLeft: '1.2rem'}} className="d-flex" key={i}>
                                                <Link
                                                    to="#"
                                                    onClick={() => {
                                                        FinalizeCategorySelect(cat)
                                                    }}
                                                >
                                                    {cat}
                                                </Link>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                                {/*</div>*/}
                                {/*<div className="col-md-11">*/}
                                <hr style={hrStyle}/>
                                <Location
                                    selectedC={selectedCity}
                                    setSelectedCity={setSelectedCity}
                                    selectedT={selectedTown}
                                    setSelectedTown={setSelectedTown}
                                    position={false}
                                    extentedLocation={true}
                                    setSelectedNeighbour={setSelectedNeighbour}
                                    selectedN={selectedNeighbour}
                                />
                                {/*<City*/}
                                {/*    selectedCity={selectedCity}*/}
                                {/*    setSelectedCity={setSelectedCity}*/}
                                {/*    selectedTown={selectedTown}*/}
                                {/*    setSelectedTown={setSelectedTown}*/}
                                {/*/>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-11">*/}
                                <hr style={hrStyle}/>
                                {subCatVal1 === 'Konut' && (<div>
                                    <label htmlFor="rooms">
                                        <h6>Oda sayisi</h6>
                                    </label>
                                    <Rooms
                                        id="rooms"
                                        setSelectedRooms={setSelectedRooms}
                                    />
                                </div>)}
                                <div
                                    // style={{width:'150px'}}
                                    className="sidebar-input-wrapper"
                                >
                                    <label className="field field_v3">
                                        <input
                                            className="field__input"
                                            type="text"
                                            name="search_text"
                                            value={searchText}
                                            style={{borderStyle: 'solid'}}
                                            id="search_text"
                                            // style={{width:'400px'}}
                                            // placeholder="Aramak için anahtar kelime girin"
                                            onChange={(e) => {
                                                setSearchText(e.target.value)
                                            }
                                            }
                                        />
                                        <span className="field__label-wrap">
                                  <span className="field__label">Anahtar kelime ile ara</span>
                                </span>
                                    </label>
                                </div>
                                <hr style={hrStyle}/>
                                {/*</div>*/}
                                {/*<div className="d-flex justify-content-center col-md-11 row">*/}
                                <div className="d-flex justify-content-center mb-3">
                                    <div className="m-1 p-1 " style={{width: '70px'}}>
                                        <label className="field field_v4" htmlFor="minPrice">
                                            Min(₺)
                                        </label>
                                        <input className="rounded-1 number-input field__input"
                                               style={{
                                                   borderWidth: '1px',
                                                   lineHeight: '27px',
                                                   borderColor: 'gray',
                                                   width: '70px',
                                                   textIndent: '0.5em'
                                               }}
                                               id="minPrice"
                                               type="number"
                                               name="minPrice"
                                               value={values.minPrice || 0}
                                               onChange={handleChange}/>
                                    </div>
                                    <div className="m-1 p-1" style={{width: '70px'}}>
                                        <label className="field field_v3" htmlFor="maxPrice">
                                            Max(₺)
                                        </label>
                                        <input className="rounded-1 number-input field__input"
                                               style={{
                                                   // borderWidth: '1px',
                                                   // lineHeight: '17px',
                                                   // borderColor: 'gray',
                                                   width: '70px',
                                                   textIndent: '0.6em'
                                               }}
                                               id="maxPrice"
                                               name="maxPrice"
                                               type="number"
                                               value={values.maxPrice || 0}
                                               onChange={handleChange}/>
                                    </div>
                                </div>
                                <hr style={hrStyle}/>

                                <div className="mt-3">
                                    <Link
                                        onClick={() => {
                                            getResult();
                                        }}
                                        type="button"
                                        style={{width: '100%'}}
                                        className="p-2 rounded rounded-5 btn-info"
                                        // to={`/emlak-ara?city=${selectedCity}&town=${selectedTown}&minPrice=${values.minPrice}&maxPrice=${values.maxPrice}&tpye=${typeSelectOpt.value}&status=${statusSelectOpt.value}`}
                                        to="#"
                                    >
                                        Ara
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <EstateSearchBody
                                    result={result}
                                />
                            </div>
                        </div>
                    </div>
                </Suspense>
            </div>
        );
    }

    export default EstateSearch;
