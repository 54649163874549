import React, {useContext, useEffect, useState} from "react";
import {BaseURLContext} from "../../../../baseURL-context";
import useForm from "../../../useForm/useForm";
import "react-bootstrap/dist/react-bootstrap.min.js";
import {Row, Col} from "react-bootstrap";
import Cookies from "universal-cookie";
import CurrencyInput from 'react-currency-input-field';
import {
    defaultGetRequestWithParams,
    protectedEnpointGetRequest
} from "../../../../static/functions";
import City from "../../../SearchTest/Filters/City";
import Color from "../../../Forms/CreateCar/Color";
import Insurance from "../../../Forms/CreateCar/Insurance";
import LicensePlate from "../../../Forms/CreateCar/License";
import DopingOnCreateAd from "../../../Payment/CreateAd/DopingOnCreateAd";
import FuelType from "../../../Forms/CreateCar/FuelType";
import GearType from "../../../Forms/CreateCar/GearType";
import CaseType from "../../../Forms/CreateCar/CaseType";
import Description2 from "../../../Forms/Common/Description2";
import FileUpload from "../../../Common/ImageUploadNew";
import useKeypress from "../../../../static/useKeypress";
import NumberFormat from "react-number-format";

const CarForm = ({
                     goBack,
                     nextPage,
                     carCategoryId,
                     setCarData,
                     setImages,
                     setFormData,
                     doping,
                     setDoping,
                     carType,
                     carInfo
                 }) => {
    const {values, errors, handleChange, handleSubmit} = useForm([]);

    const {baseURL} = useContext(BaseURLContext);
    // const [state, setState] = useState(false);
    const [formPage, setFormPage] = useState(1);
    const [description, setDescription] = useState('');
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedTown, setSelectedTown] = useState("");
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedInsurance, setSelectedInsurance] = useState("");
    const [selectedLicense, setSelectedLicense] = useState("");
    const [selectedCaseType, setSelectedCaseType] = useState("");
    const [selectedFuelType, setSelectedFuelType] = useState("");
    const [selectedGearType, setSelectedGearType] = useState("");
    const [selectedImage, setSelectedImage] = useState([]);
    const [price, setPrice] = useState("");
    const [carModelData, setCarModelData] = useState([]);
    const [imageLimit, setImageLimit] = useState(0);

    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")

    async function GetCar() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Categories/GetCarModelById`,
            {
                params: {
                    id: carCategoryId
                }
            }
        )

        if (response) {
            console.log('carmodel data from api ', response.data)
            setCarModelData(response.data)
        }
    }


    async function GetImageLimits() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Membership/GetImageUploadLimit`,
            cookie
        )
        if (response) {
            setImageLimit(parseInt(response.data))
        }
    }

    useEffect(() => {
        GetCar(carCategoryId)
    }, [carCategoryId])

    useEffect(() => {
        GetImageLimits()
    }, [])

    function setNextPage() {
        console.log('doping', doping)
        if (doping === 2 || doping === 3) {
            nextPage('payment')
        } else {
            nextPage('no-payment')
        }
    }

    async function createCar() {
        const carData = {
            Name: values.name,
            Price: price.replace(',', '.'),
            City: selectedCity,
            State: selectedTown,
            CategoryType: carType,
            CategoryId: parseInt(carModelData.id),
            Brand: carModelData.make,
            Model: carModelData.model,
            Year: carModelData.year,
            Km: values.km,
            Gear: selectedGearType,
            Fuel: selectedFuelType,
            CaseType: selectedCaseType,
            Traction: values.traction,
            Color: selectedColor,
            LicensePlate: selectedLicense,
            MotorPower: values.motorPower,
            MotorCapacity: values.motorCapacity,
            Insurance: selectedInsurance,
            From: values.from,
            Status: values.status,
            Description: description,
        };

        setCarData(carData);

        if (selectedImage.length > 0) {
            for (let a = 0; a < selectedImage.length; a++) {
                const url = URL.createObjectURL(selectedImage[a])
                console.log('url : ', url)
                setImages((e) => [...e, url]);
                // setFormData((elem) => [...elem,selectedImage[a]])
                setFormData(selectedImage)
            }
        }

        const response = await protectedEnpointGetRequest(
            `${baseURL}/authentication/CheckUserActivity`,
            cookie
        );
        if (response) {
            if (response.data === true) {
                setNextPage()
            }
        }
    }

    const updateUploadedFiles = (files) => {
        setSelectedImage([])
        setSelectedImage(files)
    }

    const handlePrice = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            const formatted = new Intl.NumberFormat().format(parseFloat(clean));
            setPrice(formatted);
        } else {
            setPrice("");
        }
    };

    function handleLastDigit(val) {
        console.log(val)
        const key = this.event.key
        console.log('key ', key)
        if (val.length === 1) {
            console.log('wjat do we do now')
        }
    }


    const MAX_VAL = 1000000000000;
    const withValueCap = (inputObj) => {
        const {value} = inputObj;
        if (value <= MAX_VAL) return true;
        return false;
    };


    const hideFilter = {
        display: 'none',
        // maxHeight: '200px',
    }
    const breadcrumbsStyle = {
        float: 'left',
        display: 'flex',
        flexDirection: 'row'
    }
    const showF = {
        // display:'none'
    }

    const showFilter = {
        width: '165px',
        maxHeight: '200px',
        overflowY: 'scroll',
    }

    const filterStyle = {
        width: '100%',
    }

    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: 1,
        borderColor: "#000000",
    }

    // function renderUserForm() {
    return (
        <fieldset style={{maxWidth: "1180px", width: "100%", margin: "0 auto"}}>
            <div className="center m-auto" style={{width: "70%"}}>
                <Row>
                    <Col md={12} className="m-4">
                        <div style={breadcrumbsStyle}>
                            {console.log('carModelData', carModelData)}
                            <strong>
                                Vasita&nbsp;&nbsp;/&nbsp;
                            </strong>
                            <strong>
                                &nbsp;{carModelData && carModelData?.type}&nbsp;
                            </strong>
                            <strong style={carModelData?.props ? showF : hideFilter}>
                                &nbsp;{" / "}&nbsp;
                            </strong>
                            <strong>
                                &nbsp;{carModelData?.props && carModelData?.props}&nbsp;
                            </strong>
                            <strong style={carModelData?.type ? showF : hideFilter}>
                                &nbsp;{" / "}&nbsp;
                            </strong>
                            <strong>
                                &nbsp;{carModelData && carModelData?.make}&nbsp;
                            </strong>
                            <strong style={carModelData?.make ? showF : hideFilter}>
                                &nbsp;{" / "}&nbsp;
                            </strong>
                            <strong>
                                {carModelData?.model && carModelData?.model}
                            </strong>
                            <strong style={carModelData?.model ? showF : hideFilter}>
                                &nbsp;{" / "}&nbsp;
                            </strong>
                            <strong>
                                {carModelData?.subModel && carModelData?.subModel}
                            </strong>
                        </div>
                    </Col>
                    <h1 style={{marginBottom: "2rem"}}>İlan Oluştur</h1>
                    <Col md={12}>
                        <div className="ms-4 me-4">
                            <div className="form-group mt-4 mb-4">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name"
                                        placeholder="İlan Adı"
                                        value={values.name || ""}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="name">İlan Başlığı</label>
                                </div>

                                <div style={{marginTop: "3rem", marginBottom: "3rem"}}>
                                    <div className="form-floating mb-3">
                                        <div className="col-md-12 ">
                                            <label>
                                                {" "}
                                                <strong> Fotograf Ekle</strong>
                                            </label>
                                            <div className="form-floating mb-3">
                                                <FileUpload
                                                    accept=".jpg,.png,.jpeg"
                                                    label="Profile Image(s)"
                                                    // onChange={() => {setSelectedImage([])}}
                                                    updateFilesCb={updateUploadedFiles}
                                                    multiple
                                                    imageLimit={imageLimit}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <label>
                                            <strong>Fiyat</strong>
                                        </label>
                                        <NumberFormat
                                            thousandSeparator=","
                                            isAllowed={withValueCap}
                                            id="input-example"
                                            name="input-name"
                                            className="form-control"
                                            decimalSeparator={false}
                                            value={price || "0"}
                                            allowNegative={false}
                                            // allowEmptyFormatting={}
                                            form
                                            isNumericString
                                            defaultValue={"0"}
                                            // prefix="$"
                                            onValueChange={(values) => {
                                                // console.log(values)
                                                // setPrice(values.toString())
                                                setPrice(values.formattedValue.toString())
                                            }}
                                            // onValueChange={(values) => this.setState({ test: values.value })}
                                        />
                                        {/*<CurrencyInput*/}
                                        {/*    id="input-example"*/}
                                        {/*    name="input-name"*/}
                                        {/*    className="form-control col-md-2"*/}
                                        {/*    placeholder=""*/}
                                        {/*    defaultValue={null}*/}
                                        {/*    decimalsLimit={0}*/}
                                        {/*    onChange={(e) => {*/}
                                        {/*        console.log(e)*/}
                                        {/*    }}*/}
                                        {/*    onValueChange={(value) => {*/}
                                        {/*        handleLastDigit(value)*/}
                                        {/*        setPrice(value.toString())*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <label htmlFor="color">
                                            <strong>Yakıt</strong>
                                        </label>
                                        <FuelType
                                            setSelectedFuelType={setSelectedFuelType}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <label htmlFor="color">
                                            <strong>Vites</strong>
                                        </label>
                                        <GearType
                                            setSelectedGearType={setSelectedGearType}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <label htmlFor="color">
                                            <strong>Kasa Tipi</strong>
                                        </label>
                                        <CaseType
                                            setSelectedCaseType={setSelectedCaseType}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <City
                                            selectedCity={selectedCity}
                                            setSelectedCity={setSelectedCity}
                                            selectedTown={selectedTown}
                                            setSelectedTown={setSelectedTown}
                                            onChange={handleChange}
                                            selectedC={selectedCity}
                                            selectedT={selectedTown}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <div className="col-md-2">
                                        <label htmlFor="floor" className="float-start">
                                            <strong>KM</strong>
                                        </label>
                                        <input
                                            type="text"
                                            name="km"
                                            className="form-control"
                                            id="km"
                                            placeholder="KM"
                                            value={values.km || ""}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <div className="col-md-3">
                                        <label htmlFor="color">
                                            <strong>Renk</strong>
                                        </label>
                                        <Color
                                            setSelectedColor={setSelectedColor}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <div className="col-md-3">
                                        <label htmlFor="insurance">
                                            <strong>Garanti</strong>
                                        </label>
                                        <Insurance
                                            setSelectedInsurance={setSelectedInsurance}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <div className="col-md-3">
                                        <label htmlFor="license">
                                            <strong>Plaka / Uyruk</strong>
                                        </label>
                                        <LicensePlate
                                            setSelectedLicense={setSelectedLicense}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3" style={{paddingBottom: "2rem"}}>
                                    <input
                                        type="text"
                                        name="from"
                                        className="form-control"
                                        id="from"
                                        placeholder="Sahibinden"
                                        value={values.from || ""}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </div>
                                <div className="description-wrapper">
                                    <div style={{fontSize: '22px'}}>
                                        <label>
                                            <strong>Açıklama</strong>
                                        </label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <Description2 existingDesc={' '} setDescription={setDescription}/>
                                    </div>
                                </div>
                                <br/>
                            </div>

                            <DopingOnCreateAd
                                setAdvantages={setDoping}
                                advantages={doping}
                            />

                            <button
                                className="btn btn-primary mb-4"
                                style={{
                                    backgroundColor: "red",
                                    color: "black",
                                    marginRight: "2rem",
                                }}
                                onClick={() => {
                                    goBack();
                                }}
                            >
                                Geri
                            </button>
                            <button
                                // disabled={
                                //     errors.password ||
                                //     errors.verifyPassword
                                //     values.password == null ||
                                //     errors.advantages == 2 ||
                                //     errors.advantages == 3
                                // }
                                className="btn btn-primary mb-4"
                                style={{backgroundColor: "green", color: "white"}}
                                onClick={() => {
                                    createCar()
                                    // setFormPage(1);
                                    // setNextPage();
                                    // advantages === 2 && nextPage('payment');
                                    // advantages === 3 && nextPage('payment');
                                    // advantages === 1 && nextPage('no-payment');
                                }}
                                // type="submit"
                            >
                                İlan Oluşturmaya Devam Et
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </fieldset>
    );
    // }

    // return (
    //     <React.Fragment>
    //         <div>
    //             <form onSubmit={handleSubmit}>
    //                 {renderUserForm()}
    //                 {/* {goImages()} */}
    //                 {/*{formPage === 1 ? renderUserForm() : mailHaveBeenSent()}*/}
    //             </form>
    //         </div>
    //     </React.Fragment>
    // );
};

export default CarForm;


// const handleLastDigit = (e)  =>{
//     // eslint-disable-next-line react-hooks/rules-of-hooks
//     useKeypress('Delete', () => {
//         console.log('test backspace')
//     });
// }