import React, {useState} from "react";
import {Routes, Route} from "react-router-dom";
import "bootswatch/dist/morph/bootstrap.min.css";
// import { passiveSupport } from 'passive-events-support/src/utils'
import "./App.css";
import "./assets/css/layout.css";
import './assets/css/flash.css'
import {BaseURLContext} from "./baseURL-context";
import AuthProvider from "./Components/AuthProvider/AuthProvider";
import Logoff from "./Components/Logoff/Logoff";
import Footer from "./Components/NoAuthComponents/Footer";
import Index from "./Components/Layout/Index";
import RegisterPage from "./pages/Register";
import LoginPage from "./pages/Login";
import CheckMail from "./pages/CheckEmail";
import Profile from "./pages/Profile";
import CreateProduct from "./pages/CreateProduct";
import CreateEstatesView from "./pages/CreateEstateView";
import SearchNav from "./pages/SearchNav";
import Estate from "./pages/Estate";
import Estates from "./pages/Estates";
import CreateCar from "./pages/CreateCar";
import Cars from "./pages/Cars";
import Car from "./pages/Car";
import EstateCat from "./pages/EstateCat";
import EstateCats from "./pages/EstateCats";
import EstateCatList from "./pages/EstateCatList";
import CarCat from "./pages/CarCat";
import CarCatList from "./pages/CarCatList";
import CarCats from "./pages/CarCats";
import Messages from "./pages/Messages";
import StartConversation from "./pages/StartConversation";
import StartConversationLayout from "./pages/StartConversationLayout";
import AdOverview from "./pages/AdOverview";
import Favourites from "./pages/Favourites";
import Message from './Components/User/Messages'
import AdPublished from "./pages/AdPublished";
import CreateSuccess from "./pages/CreateSuccess";
import StartCreateEstateTest from "./Components/Product/Estate/Create/StartCreateEstateTest";
import EstateSearch from "./pages/EstateSearch";
import CarSearch from "./pages/CarSearch";
import TestPayment from "./Components/Payment/KuveytTurk/Test";
import SuccessTest from "./Components/Payment/KuveytTurk/SuccessTest";
import RegisterSuccess from "./pages/RegisterSuccess";
import RegisterFailed from "./pages/RegisterFailed";
import EditEstate from "./pages/EditEstate";
import UpgradeAccount from "./pages/UpgradeAccount";
import EditCar from "./pages/EditCar";
import Doping from "./pages/Doping";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import ShowcaseAds from "./pages/ShowcaseAds";
import HelmetMetaData from "./Components/Core/HelmetMetaData";
import AccountRemoved from "./pages/AccountRemoved";

function App() {
    const [baseURL, setBaseURL] = useState(process.env.REACT_APP_API + "/api");
    const [metadata, setMetadata] = useState('');
    const baseURLValue = {baseURL, setBaseURL};


    return (
        <div className="App">
            <div className="container">
                <AuthProvider>
                    <HelmetMetaData metadata={metadata}>
                    </HelmetMetaData>
                    <BaseURLContext.Provider value={baseURLValue}>
                        <div>
                            <main>
                                <Routes>
                                    <Route path="/" element={<Index/>}/>
                                    <Route path={"/giris-yap"} element={<LoginPage/>}/>
                                    <Route path={"/email-kontrol"} element={<CheckMail/>}/>
                                    <Route path={"/uye-ol"} element={<RegisterPage/>}/>
                                    <Route path={"/sifremi-sifirla"} element={<ForgetPassword/>}/>
                                    <Route path={"/sifre-degistir"} element={<ResetPassword/>}/>

                                    <Route path={"/email-onay"} element={<RegisterSuccess/>}/>
                                    <Route path={"/email-onay-basarisiz"} element={<RegisterFailed/>}/>
                                    {/*PROFILE*/}
                                    <Route path="/profil-genel" element={<Profile/>}/>
                                    <Route path="/ilanlarim" element={<AdOverview/>}/>
                                    <Route path="/favoriler" element={<Favourites/>}/>
                                    <Route path="/mesajlar" element={<Message/>}/>


                                    {/*payment routes*/}
                                    <Route path="/odeme-basarisiz" element={<TestPayment/>}/>
                                    <Route path="/odeme-basarili" element={<SuccessTest/>}/>
                                    <Route path="/hesap-yukselt" element={<UpgradeAccount/>}/>
                                    <Route path="/doping" element={<Doping/>}/>


                                    <Route path="/vitrin" element={<ShowcaseAds/>}/>

                                    {/*<Route path="/Search" element={<Search />} />*/}
                                    <Route path="/arama" element={<SearchNav/>}/>

                                    {/* CREATE ESTATE PROGRAMMATICALLY */}
                                    <Route path="" element={<Estates/>}>
                                        {/*<Route path="/ProductList" element={<EstateList />} />*/}
                                        <Route path="emlak/:id" element={<Estate setMetadata={setMetadata}/>}/>
                                    </Route>
                                    <Route path="/ilan" element={<AdPublished/>}/>
                                    <Route path="/ilan-olusturuldu" element={<CreateSuccess/>}/>


                                    {/*ESTATE EDIT*/}
                                    <Route path="/emlak-duzenle" element={<EditEstate/>}/>
                                    {/*CAR EDIT*/}
                                    <Route path="/vasita-duzenle" element={<EditCar/>}/>


                                    {/*ESTATE SEARCH*/}
                                    <Route path="/emlak-ara" element={<EstateSearch/>}/>

                                    {/*CAR SEARCH*/}
                                    <Route path="/vasita-ara" element={<CarSearch/>}/>

                                    {/* CREATE CAR PROGRAMMATICALLY */}
                                    <Route path="" element={<Cars/>}>
                                        {/*<Route path="/CarList" element={<CarList />} />*/}
                                        <Route path="vasita/:id" element={<Car setMetadata={setMetadata}/>}/>
                                    </Route>

                                    {/* CREATE ESTATE CATEGORY PROGRAMMATICALLY */}
                                    <Route path="" element={<EstateCats/>}>
                                        <Route
                                            path="/emlak-tum-kategoriler"
                                            element={<EstateCatList/>}
                                        />
                                        <Route
                                            path="emlak-kategori/:slug"
                                            element={<EstateCat/>}
                                        />
                                    </Route>

                                    {/* CREATE CAR CATEGORY PROGRAMMATICALLY */}
                                    <Route path="" element={<CarCats/>}>
                                        <Route
                                            path="/vasita-tum-kategoriler"
                                            element={<CarCatList/>}
                                        />
                                        <Route path="/vasita-kategori/:slug" element={<CarCat/>}/>
                                    </Route>

                                    <Route
                                        path={"/ilan-olustur"}
                                        element={<CreateProduct/>}
                                    />
                                    <Route
                                        path={"/emlak-goruntule"}
                                        element={<CreateEstatesView/>}
                                    />
                                    {/*<Route path="/CreateEstate" element={<CreateEstate />} />*/}
                                    <Route path={"/vasita-olustur"} element={<CreateCar/>}/>
                                    <Route path={"/emlak-olustur"} element={<StartCreateEstateTest/>}/>
                                    <Route path={"/Messages"} element={<Messages/>}/>

                                    {/*<Route path={"/test-location"} element={<Map/>} />*/}

                                    <Route path="" element={<StartConversationLayout/>}>
                                        <Route path={"/mesajGonder/:id/:type/:username"}
                                               element={<StartConversation/>}/>
                                    </Route>

                                    {/* <Route path="/UploadPhoto" element={<UploadPhoto />} /> */}
                                    <Route path="/hesap-silindi" element={<AccountRemoved/>}/>
                                    <Route path="/logoff" element={<Logoff/>}/>
                                </Routes>
                            </main>
                        </div>


                    </BaseURLContext.Provider>
                </AuthProvider>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
