import React, { Component } from 'react';
// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
    state = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '100vh', width: '100%' }}>
                {/*<GoogleMapReact*/}
                {/*    bootstrapURLKeys={{ key: "AIzaSyDajrb51TCrQZSY-kIz0-TnCix_ksN0l4Y" }}*/}
                {/*    defaultCenter={this.state.center}*/}
                {/*    defaultZoom={this.state.zoom}*/}
                {/*    center={this.state.center}*/}
                {/*    // onChange={({center,zoom}) => {this.setState(center:center, zoom:zoom)}}*/}
                {/*>*/}
                {/*    /!*<AnyReactComponent*!/*/}
                {/*    /!*    lat={39.955413}*!/*/}
                {/*    /!*    lng={59.337844}*!/*/}
                {/*    /!*    text="My Marker"*!/*/}
                    {/*/>*/}
                {/*</GoogleMapReact>*/}
            </div>
        );
    }
}

export default SimpleMap;