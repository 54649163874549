import React, {useContext, useEffect, useState} from "react";
// import {Tab, Nav, Row, Col} from "react-bootstrap";
// import AdOverview from "./AdOverview";
// import MessageFinal from "../Messages/MessageFinal";
import ProfileView from "./ProfileView";
import Favourites from "./Favourites";
import '../../assets/css/userManage.css'
import '../../assets/css/profileView.css'
import AdOverviewPublished from "./AdOverviewPublished";
import AdOverviewUnpublished from "./AdOverviewUnpublished";
import MessageFinalFinal from "../Messages/MessageFinalFinal";
import UserBoard from "./UserBoard";
import {useNavigate} from "react-router";
import {Button} from "antd";

const UserManage = ({currentUser, eventKeyProp, messageUser, productId, productType}) => {
    const [eventK, setEventK] = useState('');
    const [board, showBoard] = useState('');
    const [subManagement, showSubManagement] = useState('');
    const navigate = useNavigate();

    function navigateTo(page) {
        navigate(`/${page}`)
        window.location.reload()
        // navigate(`/${page}`, {state:{eventKey:page}})
    }

    useEffect(() => {
        if (eventKeyProp === undefined || eventKeyProp == null) {
            setEventK('board')
            showSubManagement(true)
        } else {
            if (eventK === 'messages') {
                setEventK(eventKeyProp)
                if (messageUser != null) {

                }
            } else {
                setEventK(eventKeyProp)
            }
            if (eventKeyProp === 'board') {
                showSubManagement(true)
            }
        }
    }, [eventKeyProp])

    const tabPassive = {
        background: '#30b0e3',
        fontWeight: '600'
    }
    const tabActive = {
        background: '#1d88b3',
        fontWeight: '400'
    }

    return (
        <div>
            <fieldset>
                {eventK &&
                    <div>
                        <div className="container light-style flex-grow-1 container-p-y">

                            <h4 className="font-weight-bold py-3 mb-4">
                                Hesap Ayarları
                            </h4>

                            <div className="card overflow-hidden">
                                <div className="row no-gutters row-bordered row-border-light">
                                    <div className="col-md-2 pt-0 bg-white">
                                        <div className="list-group list-group-flush account-settings-links">
                                            <Button
                                                type={eventK === 'account' ? `primary` : ``}
                                                style={eventK === 'account' ? tabActive : tabPassive}
                                                className="list-group-item list-group-item-action rounded-0"
                                                onClick={() => {
                                                    setEventK('account')
                                                }}
                                                block
                                            >
                                                Hesabım
                                            </Button>
                                            <Button
                                                type={eventK === 'account' ? `primary` : ``}
                                                style={eventK === 'messages' ? tabActive : tabPassive}
                                                className="list-group-item list-group-item-action rounded-0"
                                                onClick={() => {
                                                    setEventK('messages')
                                                    showSubManagement(false)
                                                    navigateTo('mesajlar')
                                                }}
                                                block
                                            >
                                                Mesajlar
                                            </Button>
                                            <Button
                                                style={eventK === 'board' ? tabActive : tabPassive}
                                                type={eventK === 'account' ? `primary` : ``}
                                                className="list-group-item list-group-item-action rounded-0"
                                                onClick={() => {
                                                    setEventK('board')
                                                    showSubManagement(true)
                                                    // showBoard()
                                                }}
                                            >
                                                İlan Yönetimi
                                            </Button>
                                            {subManagement === true && (<Button
                                                style={eventK === 'published-ads' ? tabActive : tabPassive}
                                                // style={{marginLeft: '2rem'}}
                                                type={eventK === 'account' ? `primary` : ``}
                                                className="list-group-item list-group-item-action rounded-0"
                                                onClick={() => {
                                                    setEventK('published-ads')
                                                }}
                                                block
                                            >
                                                Yayınlanmış İlanlar
                                            </Button>)}
                                            {subManagement === true && (<Button
                                                // style={{marginLeft: '2.5rem'}}
                                                type={eventK === 'account' ? `primary` : ``}
                                                style={eventK === 'unpublished-ads' ? tabActive : tabPassive}
                                                className="list-group-item list-group-item-action rounded-0"
                                                onClick={() => {
                                                    setEventK('unpublished-ads')
                                                }}
                                            >
                                                Yayınlanmamış İlanlar
                                            </Button>)}
                                            <Button
                                                type={eventK === 'account' ? `primary` : ``}
                                                style={eventK === 'favourites' ? tabActive : tabPassive}
                                                className="list-group-item list-group-item-action rounded-0"
                                                onClick={() => {
                                                    setEventK('favourites')
                                                    showSubManagement(false)
                                                }}
                                                block
                                            >
                                                Favoriler
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-md-10 bg-white">
                                        <div className="tab-content">
                                            <div className="tab-pane custom-tab fade active show" id="account-general">
                                                {eventK === 'account'
                                                    && (<ProfileView/>)}
                                            </div>
                                            <div className="tab-pane fade show active" id="published-unpublished-ads">
                                                {eventK === 'messages' &&
                                                    (<MessageFinalFinal currentUser={currentUser}
                                                                        messageUser={messageUser} id={productId}
                                                                        type={productType}/>)}
                                            </div>
                                            <div className="tab-pane fade show active" id="published-unpublished-ads">
                                                {eventK === 'board' &&
                                                    (<UserBoard/>)}
                                            </div>
                                            <div className="tab-pane fade show active" id="published-unpublished-ads">
                                                {eventK === 'published-ads' &&
                                                    (<AdOverviewPublished/>)}
                                            </div>
                                            <div className="tab-pane fade show active" id="account-info">
                                                {eventK === 'unpublished-ads' &&
                                                    (<AdOverviewUnpublished/>)}

                                            </div>
                                            <div className="tab-pane fade show active" id="account-info">
                                                {eventK === 'favourites' &&
                                                    (<Favourites/>)}

                                            </div>
                                        </div>
                                        {/*<div className="card-body pb-2">*/}

                                        {/*    <div className="form-group">*/}
                                        {/*        <label className="form-label">Current password</label>*/}
                                        {/*        <input type="password" className="form-control"/>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="form-group">*/}
                                        {/*        <label className="form-label">New password</label>*/}
                                        {/*        <input type="password" className="form-control"/>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="form-group">*/}
                                        {/*        <label className="form-label">Repeat new password</label>*/}
                                        {/*        <input type="password" className="form-control"/>*/}
                                        {/*    </div>*/}

                                        {/*</div>*/}
                                        <div className="tab-pane fade" id="account-notifications">
                                            <div className="card-body pb-2">

                                                <h6 className="mb-4">Activity</h6>

                                                <div className="form-group">
                                                    <label className="switcher">
                                                        <input type="checkbox" className="switcher-input" checked=""/>
                                                        <span className="switcher-indicator">
                                                    <span className="switcher-yes"></span>
                                                    <span className="switcher-no"></span>
                                                     </span>
                                                        <span className="switcher-label">Email me when someone comments on my article</span>
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <label className="switcher">
                                                        <input type="checkbox" className="switcher-input" checked=""/>
                                                        <span className="switcher-indicator">
                                                      <span className="switcher-yes">

                                                      </span>
                                                      <span className="switcher-no">

                                                      </span>
                                                    </span>
                                                        <span className="switcher-label">Email me when someone answers on my forum thread</span>
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <label className="switcher">
                                                        <input type="checkbox" className="switcher-input"/>
                                                        <span className="switcher-indicator">
                                                          <span className="switcher-yes">

                                                          </span>
                                                          <span className="switcher-no">

                                                          </span>
                                                        </span>
                                                        <span
                                                            className="switcher-label">
                                                        Email me when someone follows me
                                                    </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <hr className="border-light m-0"/>
                                            <div className="card-body pb-2">

                                                <h6 className="mb-4">Application</h6>

                                                <div className="form-group">
                                                    <label className="switcher">
                                                        <input type="checkbox" className="switcher-input" checked=""/>
                                                        <span className="switcher-indicator">
                      <span className="switcher-yes"></span>
                      <span className="switcher-no"></span>
                    </span>
                                                        <span className="switcher-label">News and announcements</span>
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <label className="switcher">
                                                        <input type="checkbox" className="switcher-input"/>
                                                        <span className="switcher-indicator">
                      <span className="switcher-yes"></span>
                      <span className="switcher-no"></span>
                    </span>
                                                        <span className="switcher-label">Weekly product updates</span>
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <label className="switcher">
                                                        <input type="checkbox" className="switcher-input" checked=""/>
                                                        <span className="switcher-indicator">
                      <span className="switcher-yes"></span>
                      <span className="switcher-no"></span>
                    </span>
                                                        <span className="switcher-label">Weekly blog digest</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="text-right mt-3">*/}
                        {/*    <button type="button" className="btn btn-primary">Save changes</button>*/}
                        {/*    &nbsp;*/}
                        {/*    <button type="button" className="btn btn-default">Cancel</button>*/}
                        {/*</div>*/}

                    </div>

                }
                {/*{eventK &&*/}
                {/*    <Tab.Container className="center" defaultActiveKey={`${eventK}`}>*/}
                {/*        <Col md={12} style={{margin: "0 auto"}}>*/}
                {/*            <Nav variant="pills" className="flex-column tabs-container" style={{minHeight: '12vh'}}>*/}
                {/*                <Row className="userManageRow  bloc-tabs">*/}
                {/*                    <Col md={3} className="tabs">*/}
                {/*                        <Nav.Item>*/}
                {/*                            <Nav.Link style={{color: 'black'}} className="btn btn-info"*/}
                {/*                                      eventKey="ad-management">İlan*/}
                {/*                                Yönetimi</Nav.Link>*/}
                {/*                        </Nav.Item>*/}
                {/*                    </Col>*/}
                {/*                    <Col md={3} className="tabs">*/}
                {/*                        <Nav.Item>*/}
                {/*                            <Nav.Link style={{color: 'black'}} className="btn btn-info"*/}
                {/*                                      eventKey="messages">Mesajlar</Nav.Link>*/}
                {/*                        </Nav.Item>*/}
                {/*                    </Col>*/}
                {/*                    <Col md={3} className="tabs">*/}
                {/*                        <Nav.Item>*/}
                {/*                            <Nav.Link style={{color: 'black'}} className="btn btn-info"*/}
                {/*                                      eventKey="account">*/}
                {/*                                Hesabım*/}
                {/*                            </Nav.Link>*/}
                {/*                        </Nav.Item>*/}
                {/*                    </Col>*/}
                {/*                    <Col md={3} className="tabs">*/}
                {/*                        <Nav.Item>*/}
                {/*                            <Nav.Link style={{color: 'black'}} className="btn btn-info"*/}
                {/*                                      eventKey="favourites">Favoriler</Nav.Link>*/}
                {/*                        </Nav.Item>*/}
                {/*                    </Col>*/}
                {/*                </Row>*/}
                {/*            </Nav>*/}
                {/*        </Col>*/}
                {/*        <Row className="m-5">*/}
                {/*            <Col md={12}>*/}
                {/*                <Row>*/}
                {/*                    <Col md={11}>*/}
                {/*                        <Tab.Content>*/}
                {/*                            <Tab.Pane eventKey="ad-management">*/}
                {/*                                <Row>*/}
                {/*                                    <Col md={12}>*/}
                {/*                                        <AdOverview/>*/}
                {/*                                    </Col>*/}
                {/*                                </Row>*/}
                {/*                            </Tab.Pane>*/}
                {/*                            <Tab.Pane eventKey="messages">*/}
                {/*                                <Row>*/}
                {/*                                    <MessageFinal currentUser={currentUser}/>*/}
                {/*                                </Row>*/}

                {/*                            </Tab.Pane>*/}
                {/*                            <Tab.Pane eventKey="account">*/}
                {/*                                <Row>*/}
                {/*                                    <Col md={12}>*/}
                {/*                                        <ProfileView/>*/}
                {/*                                    </Col>*/}
                {/*                                </Row>*/}
                {/*                            </Tab.Pane>*/}
                {/*                            <Tab.Pane eventKey="favourites">*/}
                {/*                                <Row>*/}
                {/*                                    <Col md={12}>*/}
                {/*                                        <Favourites/>*/}
                {/*                                    </Col>*/}
                {/*                                </Row>*/}
                {/*                            </Tab.Pane>*/}
                {/*                        </Tab.Content>*/}
                {/*                    </Col>*/}
                {/*                </Row>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </Tab.Container>*/}
            </fieldset>
        </div>
    );
}

export default UserManage;
