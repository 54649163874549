import React from "react";
import NavbarIn from "../Components/Layout/NavbarIn";

const App = () => {
    return (
        <div>
            <NavbarIn/>
        </div>
    );
}

export default App;
