import React, {useContext, useEffect, useState} from 'react';
import {Nav, NavItem} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';
import {protectedEnpointGetRequest} from "../../static/functions";
import {BaseURLContext} from "../../baseURL-context";
import Cookies from "universal-cookie";
import {MdPublish} from 'react-icons/md'
import {RiMapPinUserFill} from 'react-icons/ri'
import {IoStorefrontOutline} from 'react-icons/io5'
import {IoLogIn} from 'react-icons/io5'
import {BiUserPlus} from 'react-icons/bi'
import {BiLogOut} from 'react-icons/bi'
import {BiMessageDetail} from 'react-icons/bi'


const BottomNavBar = (props) => {
    // eslint-disable-next-line
    const {baseURL} = useContext(BaseURLContext);
    const [isLogged, setIsLogged] = useState();
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt")

    const [messageCount, setMessageCount] = useState([]);

    async function CheckUserActivity() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/authentication/CheckUserActivity`,
            cookie
        )
        if (response) {
            if (response.data === true) {
                setIsLogged(true)
                await getUnreadMessageCount();
            } else {
                setIsLogged(false)
            }
        } else {
            setIsLogged(false)
        }
    }
    async function getUnreadMessageCount() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Message/GetUnreadMessageCount`,
            cookie
        );
        if (response.status === 200) {
            // setFrom(response.data.users)
            // console.log(response.data)
            setMessageCount(response.data);
        } else {

        }
    }

    function checkActivity() {
        if (cookie)
            CheckUserActivity();
    }

    useEffect(() => {
        checkActivity()
        return () => {
            setIsLogged(); // This worked for me
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function logOut() {
        cookies.remove("hoe-jwt")
        window.location.reload();
    }

    return (
        <div style={{clear: 'both', zIndex: '9999', background: 'white'}}>
            <nav className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav"
                 role="navigation">
                <Nav className="w-100 bottom-nav-wrapper">
                    <div className=" d-flex flex-row justify-content-around align-items-center w-100"
                         style={{marginTop: '1rem', marginBottom: '0.5rem'}}>
                        <div className="nav-link">
                            <Link to="/vitrin" className="nav-link bottom-nav-link">
                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                    <IoStorefrontOutline size={25}/>
                                    <div className="bottom-tab-label">Vitrin</div>
                                </div>
                            </Link>
                        </div>
                        <div className="nav-link">
                            <Link to="/ilan-olustur" className="nav-link bottom-nav-link">
                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                    <MdPublish size={25}/>
                                    <div className="bottom-tab-label">İlan Yayınla</div>
                                </div>
                            </Link>
                        </div>

                        {!isLogged
                            ? (
                                <>
                                    <div className="nav-link">
                                        <Link to="/uye-ol" className="nav-link bottom-nav-link">
                                            <div
                                                className="row d-flex flex-column justify-content-center align-items-center">
                                                {/*<FontAwesomeIcon size="1x" icon={faHome}/>*/}
                                                <IoLogIn size={25}/>
                                                <div className="bottom-tab-label">Üye Ol</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="nav-link">
                                        <Link to="/giris-yap" className="nav-link bottom-nav-link">
                                            <div
                                                className="row d-flex flex-column justify-content-center align-items-center">
                                                <BiUserPlus size={25}/>
                                                <div className="bottom-tab-label">Giriş Yap</div>
                                            </div>
                                        </Link>
                                    </div>
                                </>)
                            : (
                                <>
                                    <div className="nav-link">
                                        <Link to="/ilanlarim" className="nav-link bottom-nav-link">
                                            <div
                                                className="row d-flex flex-column justify-content-center align-items-center">
                                                <RiMapPinUserFill size={25}/>
                                                <div className="bottom-tab-label">İlanlarim</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="nav-link">
                                        <Link to="/mesajlar" className="nav-link bottom-nav-link">
                                            <div
                                                className="row d-flex flex-column justify-content-center align-items-center">
                                                <BiMessageDetail size={25}/>
                                                <div className="bottom-tab-label">
                                                    Mesajlar
                                                    <strong style={messageCount > 0 ?{} : {display:"none"} }>
                                                        <p
                                                            style={{
                                                                position:'absolute',
                                                                color: "white",
                                                                right:'0',
                                                                top:'40%',
                                                                backgroundColor: "red",
                                                                fontSize: "12px",
                                                                marginRight: "-8px",
                                                                width: '14px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            {messageCount}
                                                        </p>
                                                    </strong>
                                                </div>

                                            </div>
                                        </Link>
                                    </div>
                                    <div className="nav-link">
                                        <Link to="#" onClick={logOut} className="nav-link bottom-nav-link">
                                            <div
                                                className="row d-flex flex-column justify-content-center align-items-center">
                                                <BiLogOut size={25}/>
                                                <div className="bottom-tab-label">Çıkış Yap</div>
                                            </div>
                                        </Link>
                                    </div>
                                </>)}
                    </div>
                </Nav>
            </nav>
        </div>
    )
};

export default BottomNavBar;