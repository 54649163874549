import React from "react";
import PaymentForm from "../../../Payment/PaymentForm";

const AdPayment = ({data, doping, state}) => {
    // const [advantages, setAdvantages] = useState(1)
    console.log('data ', JSON.stringify(data))
    console.log('data ', data)
    console.log('advantages state: ', doping)
    return (
        <div>
            <div>
                <PaymentForm/>
            </div>
            <div className="row">
                <div className="-col-md-4">
                </div>
                <div className="-col-md-2">
                    <button className="btn btn-danger rounded-0" onClick={state('no-payment')}>
                        Odemeyi Atla
                    </button>
                </div>
                <div className="-col-md-2">
                    <button className="btn btn-danger rounded-0" onClick={state('no-payment')}>
                        Odemeyi Atla
                    </button>
                </div>
                <div className="-col-md-4">
                </div>
            </div>
        </div>
    );
}

export default AdPayment;
