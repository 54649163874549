import React, {useState, useContext, useEffect} from "react";
import {ListGroup, Row, Col} from "react-bootstrap";
import {defaultGetRequest, defaultGetRequestWithParams} from "../../../../static/functions";
import {BaseURLContext} from "../../../../baseURL-context";

const CategorySelect = ({nextPage, setCategory, setCategoryDetails}) => {
    const [mainCategories, setMainCategories] = useState([]);
    const [mainCat, setMainCat] = useState('');
    const [subCat1, setSubCat1] = useState([]);
    const [subCatVal1, setSubCatVal1] = useState('');
    const [subCat2, setSubCat2] = useState([]);
    const [subCatVal2, setSubCatVal2] = useState('');
    const [catId, setCatId] = useState(0);
    const [showNext, setShowNext] = useState(false);
    const {baseURL} = useContext(BaseURLContext);

    const onClickNormalize1 = () => {
        setSubCat1([]);
        setSubCat2([]);
        setShowNext(false);
    };


    const selectStyle = {
        margin:'0 auto',
        marginBottom:'1rem',
        width: '90%',
        height: '240px'
    }


    const hideSelectStyle = {
        width: '90%',
        height: '240px',
        display: 'none'
    }


    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        const response = await defaultGetRequest(
            `${baseURL}/categories/GetEstateLevel1/`
        );
        if (response) {
            setMainCategories(response.data);
        }
    };

    async function getChildren(selected) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetEstateLevel2/`,
            {
                params: {
                    famType: selected
                }
            }
        );
        if (response) {
            setSubCat1(response.data);
            setSubCat2([])
        }
    }

    async function getGrandChild(selected) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetEstateLevel3/`,
            {
                params: {
                    famType: mainCat,
                    estateType: selected
                }
            }
        );
        if (response) {
            if (response.data[0] !== null && response.data.length !== 0) {
                setSubCat2(response.data)
            }
            else {
                setShowNext(true)
            }
        }
    }

    function setCat() {
        const catObject = [
            mainCat,
            subCatVal1,
            subCatVal2
        ]
        setCategoryDetails(catObject)
    }

    return (
        <div id="container">
            <h2>Kategori seçimi</h2>
            <h5>Öncelikle vermek istediğiniz ilanın kategorisini seçin</h5>

            <Row style={{marginBottom: "2rem"}}>
                <Col md={4}>
                    <ListGroup id="scroll" style={selectStyle}>
                        {mainCategories &&
                            mainCategories.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            getChildren(item);
                                            setMainCat(item);
                                            onClickNormalize1()
                                        }}
                                        action
                                        variant="dark"
                                        key={key}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>
                </Col>
                <Col md={4}>
                    <ListGroup id="scroll" style={subCat1.length > 0 ? selectStyle : hideSelectStyle}>
                        {subCat1 &&
                            subCat1.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        onClick={() => {
                                            getGrandChild(item);
                                            setSubCatVal1(item)
                                            // setSubCatVal1(item);
                                            // onClickNormalize();
                                        }}
                                        action
                                        variant="dark"
                                        key={key}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>
                </Col>
                <Col md={4}>
                    <ListGroup id="scroll" style={subCat2.length > 0 ? selectStyle : hideSelectStyle}>
                        {subCat2 &&
                            subCat2.map((item, key) => {
                                return (
                                    <ListGroup.Item
                                        action
                                        variant="dark"
                                        key={key}
                                        onClick={() => {
                                            setCatId(0);
                                            setSubCatVal2(item)
                                            setShowNext(true);
                                        }}
                                    >
                                        {item}
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>
                </Col>
            </Row>

            {showNext ? (
                <div className="m-5 p-5" style={{background: 'lightblue'}}>
                    <div>
                        <h6>Kategori secimi tamamlandi</h6>
                    </div>
                    <button
                        className="btn"
                        style={{backgroundColor: "green", color: "white"}}
                        onClick={() => {
                            nextPage(catId);
                            setCat();
                            setCategory(catId);

                        }}
                    >
                        İlan Oluşturmaya Devam Et
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default CategorySelect;

