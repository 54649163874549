import React, {useEffect, useState,useContext} from "react";
import Select from "react-select";
import {BaseURLContext} from "../../../baseURL-context";
import {defaultGetRequestWithParams} from "../../../static/functions";


const FloorPayback = ({ setFloorPayback,selectedLabel }) => {
    const { baseURL } = useContext(BaseURLContext);
    const [label, setLabel] = useState();
    const [options, setOptions] = useState([])

    async function getOptions() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/FieldManage/GetField`,
            {
                params : {
                    fieldType: 'FloorPayback'
                }
            }
        )
        if (response) {
            setLabel({value:0 , label:''})
            setOptions(response.data)
        }
    }

    useEffect(() => {
        if (selectedLabel != null) {
            setLabel({value:0 , label:selectedLabel})
        } else {
            getOptions()
        }
    }, [selectedLabel])

    // useEffect(()=>{getOptions()},[])

    return (
        <>
            {label && <Select
                onMenuOpen={() => {
                    getOptions()
                }}
                options={options}
                value={label}
                onChange={(e) => {
                    setFloorPayback(e.label);
                    setLabel(e);
                }}
                placeholder="Seçiniz"
            />}
        </>
    );
};

export default FloorPayback;
