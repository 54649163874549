import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {defaultGetRequest, defaultGetRequestWithParams} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import {setAdTitleStringLimit} from "../static/helpers";
import City from "../Components/SearchTest/Filters/City";
import useForm from "../Components/useForm/useForm";
import EstateSubCategory from "../Components/Search/Filters/EstateSubCategory";
// import {ListGroup} from "react-bootstrap";


const EstateCat = () => {
    const {baseURL} = useContext(BaseURLContext);
    const {values, errors, handleChange, handleSubmit} = useForm();
    const [estateCats, setEstateCats] = useState([]);

    const [selectedCity, setSelectedCity] = useState('');
    const [selectedTown, setSelectedTown] = useState('');
    const [statusSelectOpt, setStatusSelectOpt] = useState([]);
    const [typeSelectOpt, setTypeSelectOpt] = useState([]);
    const minPrice = parseInt(values.minPrice) ? parseInt(values.minPrice) : 0;
    const maxPrice = parseInt(values.maxPrice) ? parseInt(values.maxPrice) : 0;
    // const statusOpt = sele

    const {slug} = useParams();

    async function getCategoryItems() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/categories/GetEstateCategoryShowcaseItems`,
            {
                params: {
                    famType: slug,
                }
            }
        );
        if (response) {
            setEstateCats(response.data);
            return null;
        }
    }


    var query = `/emlak-ara?city=${selectedCity}&state=${selectedTown}&minPrice=${minPrice}&maxPrice=${maxPrice}&familyName=${typeSelectOpt.label}&adType=${statusSelectOpt.label}`

    function updateQuery() {
        var queryNew = query;
        return queryNew;
    }

    useEffect(() => {
        getCategoryItems();
        window.scrollTo(0, 0)
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        setTypeSelectOpt((elem) => [...elem, {label: slug, value: slug}])
        updateQuery(query)
        //eslint-disable-next-line
    }, [slug]);

    return (
        <div className="category-container">
            <div className="row">

                <div className="col-md-11 p-1">
                    <div className="row">
                        <div className="filter-container">
                            <form onSubmit={handleSubmit} className="form-floating">
                                <div className="row">
                                    <div className="col-md-6">
                                        <City
                                            selectedCity={selectedCity}
                                            setSelectedCity={setSelectedCity}
                                            selectedTown={selectedTown}
                                            setSelectedTown={setSelectedTown}
                                            onChange={handleChange}
                                            className="col-md-12"
                                            position={true}
                                        />

                                    </div>
                                    <div className="col-md-5 row">
                                        <div className="m-1 p-1 col-md-2 ">
                                            <label className="field field_v3" htmlFor="minPrice">
                                                Min(₺)
                                            </label>
                                            <input className="rounded-1 number-input field__input"
                                                   style={{
                                                       borderWidth: '1px',
                                                       lineHeight: '27px',
                                                       borderColor: 'gray',
                                                       width: '70px',
                                                       textIndent: '0.5em'
                                                   }}
                                                   id="minPrice"
                                                   type="number"
                                                   name="minPrice"
                                                   value={values.minPrice || 0}
                                                   onChange={handleChange}
                                            />
                                        </div>
                                        <div className="m-1 p-1 col-md-2">
                                            <label className="field field_v3" htmlFor="maxPrice">
                                                Max(₺)
                                            </label>
                                            <input className="rounded-1 number-input field__input"
                                                   style={{
                                                       width: '70px',
                                                       textIndent: '0.6em'
                                                   }}
                                                   id="maxPrice"
                                                   name="maxPrice"
                                                   type="number"
                                                   value={values.maxPrice || 0}
                                                   onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <EstateSubCategory
                                            statusSelectOpt={statusSelectOpt}
                                            setStatusSelectOpt={setStatusSelectOpt}
                                            typeSelectOpt={typeSelectOpt}
                                            setTypeSelectOpt={setTypeSelectOpt}

                                        />
                                    </div>
                                    <div className="col-md-2"
                                         style={{marginLeft: '0', float: 'left', marginTop: '1.7rem'}}>
                                        {typeSelectOpt && (<Link
                                            type="button"
                                            style={{width: '80px'}}
                                            // onClick={() => {
                                            // setTypeSelectOpt((elem) => [...elem, {label: slug, value: slug}])
                                            // }}
                                            className="p-2 rounded rounded-5 btn-info"
                                            to={`/emlak-ara?city=${selectedCity}&state=${selectedTown}&minPrice=${minPrice}&maxPrice=${maxPrice}&familyName=${typeSelectOpt.label || slug}&adType=${statusSelectOpt.label}`}
                                            // to={query}
                                            // to={`/emlak-ara?city=${selectedCity}&state=${selectedTown}&minPrice=${minPrice}&maxPrice=${maxPrice}&familyName=${typeSelectOpt.label}&adType=${statusSelectOpt.label}`}
                                        >
                                            Ara
                                        </Link>)}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div style={{marginTop: '5rem'}} className="row image-row">
                        <h5>Vitrin</h5>
                        {estateCats.map((item, i) => {
                            return (
                                <div key={item.id} className="image-column">
                                    <Link to={`/emlak/${item.id}`}>
                                        <div className="image-content">
                                            {item?.coverImage !== null
                                                ? (<img style={{width: '100%'}}
                                                        src={`${item.coverImage}`}
                                                        alt="test"/>)
                                                : <div>test</div>}
                                            <p>{setAdTitleStringLimit(item.name)}</p>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EstateCat;
