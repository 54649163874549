import React, {useContext, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Card} from "antd";
import {protectedEnpointGetRequest, protectedEnpointPostRequest} from "../../static/functions";
import Cookies from "universal-cookie";
import {BaseURLContext} from "../../baseURL-context";
import {Link} from "react-router-dom";
import moment from "moment";
import EstateSearchBody from "../Search/EstateSearchBody";
import CarSearchBody from "../Search/CarSearchBody";

const Favourites = () => {
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt");
    const {baseURL} = useContext(BaseURLContext);
    const [cars, setCars] = useState()
    const [estates, setEstates] = useState()

    useEffect(() => {
        GetFavs()
    }, [])

    async function GetFavs() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Membership/GetFavouritesList`,
            cookie
        )
        if (response) {
            setCars(response.data.cars)
            setEstates(response.data.estates)
            return response.data
        }
    }

    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: 1,
        borderColor: "#000000",
    }

    return (
        <div className="d-flex justify-content-center ">
            <div className="row">
                {/*<Col md={12}>*/}
                <h3>Favori İlanlar</h3>
                <span style={{height:'2rem'}}></span>
                {/*<hr style={hrStyle}/>*/}
                <h4>Emlak</h4>
                <hr style={hrStyle}/>
                {estates?.length  > 0
                    ? (<EstateSearchBody
                    result={estates}
                    />)
                    : (<p>Favori emlak ilani yok</p>)
                }
                <span style={{height:'2rem'}}></span>
                <h4>Vasıta</h4>
                <hr style={hrStyle}/>
                {cars?.length > 0
                    ? (<CarSearchBody
                    result={cars}
                    />)
                    : (<p>Favori vasita ilani yok</p>)
                }
                {/*<div className="row">*/}
                {/*    <div className="col-md-3">*/}
                {/*        &nbsp;&nbsp;&nbsp;&nbsp;*/}
                {/*    </div>*/}
                {/*    <div className="col-md-3">*/}
                {/*        <h6 style={{float: 'left'}}>&nbsp;&nbsp;&nbsp;&nbsp;Ilan Adi</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-1">*/}
                {/*        <h6>Fiyat</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-1">*/}
                {/*        <h6>Konum</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-2">*/}
                {/*        <h6>Fiyat</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-1">*/}
                {/*        <h6>Ilan Tarihi</h6>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*{favs && favs..map((item,i) => {*/}
                {/*    return (*/}
                {/*        <div key={i}>*/}
                {/*            {console.log(item)}*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*})}*/}
                {/*<table className="table table-image d-flex justify-content-center">*/}
                {/*    <tbody>*/}
                {/*    {cars && cars.map((item, i) => {*/}
                {/*        return (*/}
                {/*            <tr key={i} className="d-flex m-1 row">*/}
                {/*                <td className="w-25 col-md-4">*/}
                {/*                    <Link to={`/vasita/${item.id}`}>*/}
                {/*                        <img*/}
                {/*                            src={`${item.coverImage}`}*/}
                {/*                            alt=""*/}
                {/*                            className="img-fluid img-thumbnail"/>*/}
                {/*                    </Link>*/}
                {/*                </td>*/}
                {/*                <td className="col-md-5">*/}
                {/*                    <Link to={`/vasita/${item.id}`}>*/}
                {/*                        {item.name}*/}
                {/*                    </Link>*/}
                {/*                </td>*/}
                {/*                <td className="col-md-2">{item.price}</td>*/}
                {/*                <td className="col-md-2">*/}
                {/*                    {moment(item.createdOn).format("DD/MM/YYYY")}*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*    {estates && estates.map((item, i) => {*/}
                {/*        return (*/}
                {/*            <tr key={i} className="d-flex m-1 row">*/}
                {/*                <td className="w-25 col-md-4">*/}
                {/*                    <Link to={`/emlak/${item.id}`}>*/}
                {/*                        <img*/}
                {/*                            src={`${item.coverImage}`}*/}
                {/*                            alt=""*/}
                {/*                            className="img-fluid img-thumbnail"/>*/}
                {/*                    </Link>*/}
                {/*                </td>*/}
                {/*                <td className="col-md-5">*/}
                {/*                    <Link to={`/emlak/${item.id}`} style={{float: 'left'}}>*/}
                {/*                        {item.name}*/}
                {/*                    </Link>*/}
                {/*                </td>*/}
                {/*                <td className="col-md-2">{item.price}</td>*/}
                {/*                <td className="col-md-2">*/}
                {/*                    {moment(item.createdOn).format("DD/MM/YYYY")}*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*    </tbody>*/}
                {/*</table>*/}
                {/*</Col>*/}
                {/*<Col md={12}>*/}
                {/*    <div>*/}
                {/*        <ul>*/}
                {/*            <li className="d-flex m-1">*/}
                {/*                <Card className="w-25 m-2"  style={{backgroundColor:'lightgray'}}>*/}
                {/*                    test 1*/}
                {/*                </Card>*/}
                {/*                <Card className="w-25 m-2 " style={{backgroundColor:'lightgray'}}>*/}
                {/*                    test 1*/}
                {/*                </Card>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</Col>*/}
                {/*</Row>*/}
            </div>
        </div>
    )
}

export default Favourites