import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar from "../Components/Layout/NavbarIn";

const CreateEstateAd = () => {
  return (
    <div>
      <Navbar className="navbar" />
      <div style={{ height: '12rem' }}>

      </div>
      <div className="row" style={{ height: '30rem' }} >
        <div className="col-sm-3" >
        </div>
        <div className="col-sm-3" >
          <div className="card" >
            <div className="card-body" style={{border:'1px solid black'}}>
              <h5 className="card-title" style={{marginBottom:'2rem'}}>Emlak</h5>
              {/* <p className="card-text">Ekstra Açıklama</p> */}
              <Link to="/emlak-olustur">
                <Button style={{ backgroundColor: 'yellow', color: 'black' }} variant="success" >İlan Oluştur</Button>
              </Link>
              {/* <a href="#" className="btn btn-warn"></a> */}
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card">
            <div className="card-body" style={{border:'1px solid black'}}>
              <h5 style={{marginBottom:'2rem'}} className="card-title">Vasıta</h5>
              {/* <p className="card-text">Ekstra Açıklama</p> */}
              <Link to="/vasita-olustur">
                <Button style={{ backgroundColor: 'green', color: 'white' }} variant="warning" >İlan Oluştur</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-3" >
        </div>
      </div>
      {/* <StartCreateEstate /> */}
    </div>
  );
}

export default CreateEstateAd;
