import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import EstateCatList from "../../pages/EstateCatList";
import CarCatList from "../../pages/CarCatList";
import {BaseURLContext} from "../../baseURL-context";
import {defaultGetRequest} from "../../static/functions";
import {setAdTitleStringLimit, useWindowSize} from "../../static/helpers";
import {FaCar, FaHouseUser} from 'react-icons/all'
import NoImage from '../../img/no-image.png'

const MainBody = () => {
    const {baseURL} = useContext(BaseURLContext);
    const [carAds, setCarAds] = useState([]);
    const [estateAds, setEstateAds] = useState([]);
    const [showcaseAds, setShowcaseAds] = useState([]);


    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: 1,
        borderColor: "#000000",
    }

    async function getMainAds() {
        const response = await defaultGetRequest(
            `${baseURL}/Categories/GetLatestAds`
        );
        if (response) {
            setCarAds(response.data.cars);
            setEstateAds(response.data.estates);
            return;
        }
    }

    async function getShowcaseAds() {
        const response = await defaultGetRequest(
            `${baseURL}/Showcase/GetShowcaseAds`
        );
        if (response) {
            setShowcaseAds(response.data);
            return null;
        }
    }


    useEffect(() => {
        getMainAds();
        getShowcaseAds()
    }, []);


    const adBox = {
        flexDirection: 'column',
        justifyContent: 'center'
    }

    const size = useWindowSize();

    return (
        <div>
            <section id="main">
                <div className="container">
                    <div className="row m-auto" style={{width: '100%'}}>
                        <div className="col-md-3 d-block mb-5"
                             style={adBox}
                        >
                            <div className="m-auto mb-5">
                                <h3 className="w-100" style={{minWidth:'150px'}}>
                                    <span className="estateCatHeader">
                                        <FaHouseUser className="mb-1"/>
                                    </span>
                                    &nbsp;&nbsp;
                                    <strong style={{textAlign: 'center'}}>Emlak</strong>
                                </h3>
                                <EstateCatList/>
                            </div>
                            <div className="m-auto">
                                <h3>
                                    {/*&nbsp;&nbsp;*/}
                                    <span className="carCatHeader">
                                        <FaCar className="mb-1"/>
                                    </span>
                                    &nbsp;&nbsp;
                                    <strong style={{textAlign: 'center'}}>
                                        Vasıta
                                    </strong>
                                </h3>
                                <CarCatList/>
                            </div>
                        </div>
                        <div className={`imp-medium m-auto ${size.width > 680 ? ` col-6 col-6-medium ` : ` col-12-medium `}`}>
                            <article className="box post" style={{paddingBottom: "4rem"}}>
                                <h3>
                                    Emlak
                                </h3>
                                <hr style={hrStyle}/>
                                {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                <Carousel
                                    swipeable={false}
                                    draggable={false}
                                    showDots={true}
                                    responsive={responsive}
                                    infinite={true}
                                    autoPlay={false}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    containerClass="carousel-container"
                                    itemClass="carousel-item-padding-40-px p-1"
                                >
                                    {estateAds && estateAds.map((estate, i) => {
                                        return (
                                            <div key={i}>
                                                <Link to={`/emlak/${estate.id}`}>
                                                    <figure>
                                                        {estate?.smallImage !== null ? (<img style={{width: '100%'}}
                                                                                             src={estate?.smallImage}
                                                                                             alt="test"/>) :
                                                            <div><img src={NoImage}/></div>}
                                                        <figcaption>{setAdTitleStringLimit(estate.name)}</figcaption>
                                                    </figure>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </article>
                            <article className="box post" style={{paddingBottom: "4rem"}}>
                                <h3>
                                    Vasıta
                                </h3>
                                <hr style={hrStyle}/>
                                {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                <Carousel
                                    swipeable={false}
                                    draggable={false}
                                    showDots={true}
                                    responsive={responsive}
                                    infinite={true}
                                    autoPlay={false}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    containerClass="carousel-container"
                                    itemClass="carousel-item-padding-40-px p-1"
                                >
                                    {carAds && carAds.map((car, i) => {
                                        return (
                                            <div key={i}>
                                                <Link to={`/vasita/${car.id}`}>
                                                    <figure>
                                                        {car?.smallImage !== null
                                                            ? (<img
                                                                style={{width: '100%'}}
                                                                src={car?.smallImage}
                                                                alt="test"/>)
                                                            : (<div>
                                                                <img src={NoImage} alt={car.name}/>
                                                            </div>)}
                                                        <figcaption>{setAdTitleStringLimit(car.name)}</figcaption>
                                                    </figure>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </article>
                            <article className="box post" style={{paddingBottom: "4rem"}}>
                                <h3>
                                    <Link to="/vitrin">
                                        Vitrin
                                    </Link>
                                </h3>
                                <hr style={hrStyle}/>
                                {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                <Carousel
                                    swipeable={false}
                                    draggable={false}
                                    showDots={true}
                                    responsive={responsive}
                                    infinite={true}
                                    autoPlay={false}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    containerClass="carousel-container"
                                    itemClass="carousel-item-padding-40-px p-1">
                                    {showcaseAds && showcaseAds.map((ad, i) => {
                                        return (
                                            <div key={i}>
                                                <Link to={ad.type === 0 ? `/emlak/${ad.id}` : `/vasita/${ad.id}`}>
                                                    <figure>
                                                        {ad?.smallImage !== null ? (<img style={{width: '100%'}}
                                                                                         src={ad?.smallImage}
                                                                                         alt="test"/>) :
                                                            <div><img alt="test" src={NoImage}/></div>}
                                                        <figcaption>{setAdTitleStringLimit(ad.name)}</figcaption>
                                                    </figure>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MainBody;
