import React, {useContext, useEffect, useState} from "react";
import {useSearchParams, useLocation} from "react-router-dom";
import { defaultPostRequest} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import NavbarOut from "../Components/Layout/NavbarOut";

const ResetPassword = () => {
    const {baseURL} = useContext(BaseURLContext);
    let [searchParams, setSearchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [status, setStatus] = useState(null)
    useEffect(() => {
        let tokenParam = searchParams.get('token')
        setToken(tokenParam)
        let emailParam = searchParams.get('email')
        setEmail(emailParam)
        //eslint-disable-next-line
    }, [])

    function handlePasswordChange(e) {
        setPassword(e.target.value)
    }

    function handlePasswordRepeatChange(e) {
        setPasswordRepeat(e.target.value)
    }

    async function confirmPassword() {
        const model = {
            Token: token,
            Email: email,
            Password: password,
            ConfirmPassword: passwordRepeat
        }

        const response = await defaultPostRequest(
            `${baseURL}/Authentication/ConfirmPassword`,
            model
        )
        if (response) {
            if (response.data === true) {
                setStatus('success')
            } else {
                setStatus('fail')
            }
        }
    }

    const validStyle = {}

    const invalidStyle = {
        border: '1px solid black'
    }

    return (
        <div>
            <NavbarOut/>
            <div className="account-form m-auto">
                <div className="ms-2 me-2 m-5">
                    <div className="d-grid gap-4 ms-4 me-4">
                        {/*{status == null &&*/}
                        {/*    (*/}

                        {status == null && (
                            <div className="form-group">
                                <div>
                                    <h6>
                                        Şifrenizi değistirin
                                    </h6>
                                </div>
                                <div className="form-floating">

                                    <div>
                                        <label htmlFor="password">
                                            Yeni Şifre
                                        </label>
                                        <input
                                            type="string"
                                            name="password"
                                            className={`form-control ${password == passwordRepeat ? validStyle : invalidStyle}`}
                                            id="password"
                                            placeholder="Yeni Şifre"
                                            value={password || ""}
                                            onChange={(e) => {
                                                handlePasswordChange(e)
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="passwordRepeat">
                                            Şifre Tekrari
                                        </label>
                                        <input
                                            type="string"
                                            name="passwordRepeat"
                                            className={`form-control ${password == passwordRepeat ? validStyle : invalidStyle}`}
                                            id="passwordRepeat"
                                            placeholder="Şifre Tekrar"
                                            value={passwordRepeat || ""}
                                            onChange={(e) => {
                                                handlePasswordRepeatChange(e)
                                            }}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-primary m-2 rounded-0"
                                        style={{background: 'lightblue', color: 'black'}}
                                        onClick={() => {
                                            confirmPassword()
                                        }}>
                                        Dogrula
                                    </button>
                                </div>
                            </div>)}

                        {status === 'success' && (<div className="m-auto" style={{minHeight:'15rem'}}>
                            <h6>Şifre başarıyla değiştirildi.</h6>
                        </div>)}
                        {status === 'fail' && (<div className="m-auto" style={{minHeight:'15rem'}}>
                            <h6>Şifre değiştirilirken bir problem oluştu. Lütfen tekrar deneyin.</h6>
                        </div>)}
                    </div>
                </div>
            </div>

        </div>
    )
        ;
}

export default ResetPassword;
