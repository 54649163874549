import React, {useState, useEffect, useContext} from "react";
import Select, {components} from "react-select";
import {defaultGetRequestWithParams} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";


const Ownership = ({setSelectedOwnership, selectedLabel, selectedOwnership}) => {
    const {baseURL} = useContext(BaseURLContext);
    const [label, setLabel] = useState();
    const [options, setOptions] = useState([]);
    const [count, setCount] = useState(0);
    const [tempArray, setTempArray] = useState([]);

    async function getOptions() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/FieldManage/GetField`,
            {
                params: {
                    fieldType: 'Ownership'
                }
            }
        )
        if (response) {
            setCount(1)
            setLabel({value: 0, label: ''})
            setOptions(response.data)
        }
    }

    function convertToLegitArray(array) {
        setSelectedOwnership('')
        let arr = [];
        for (let i = 0; i < array.length; i++) {
            arr.push(array[i].label)
        }
        let filteredArray = arr.filter(it => it !== 'Seçiniz').toString();

        filteredArray = filteredArray[0] === ',' ? filteredArray.substring(1) : filteredArray;
        setSelectedOwnership(filteredArray);
    }

    useEffect(() => {

        if (selectedLabel != null) {
            setLabel({value: 0, label: selectedLabel})
        }
    }, [])
    // useEffect(()=>{getOptions()},[])

    const customStyles = {multiValueRemove: (base) => ({...base, display: 'none'})}

    return (
        <>
            <span
                className="multi-option-header"
                data-toggle="popover"
                data-trigger="focus"
                data-content="Seçiniz"
            >
            {label && <Select
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                onMenuOpen={() => {
                    if (count === 0) {
                        getOptions()
                    }
                }}
                isClearable={false}
                styles={customStyles}
                options={options}
                components={{
                    Option
                }}
                value={label}
                onChange={(e) => {
                    convertToLegitArray(e)
                    setLabel(e);
                }}
                placeholder="Seçiniz"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
            />}
            </span>
        </>
    );
};

export default Ownership;


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    style={{float: 'left'}}
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label style={{fontSize: '1em'}} className="multi-option-label">{props.label}</label>
            </components.Option>
        </div>
    );
};