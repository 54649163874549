import React, {useContext, useEffect, useState} from "react";
import {protectedEnpointPostRequest} from "../../static/functions";
import Cookies from "universal-cookie";
import {BaseURLContext} from "../../baseURL-context";
import {TiDelete} from "react-icons/ti";
import {ImCross} from "react-icons/ti";
import {useNavigate} from "react-router";
import ReactLoading from 'react-loading';
import {useWindowSize} from "../../static/helpers";

const styles = {
    container: {
        width: '100%',
        height: '100%',
        // display:'inline-block'
    },
    preview: {
        marginTop: 50,
        // display: "flex",
        // flexDirection: "column",
        width: '130px',
        // height: '140px',
        margin: 10
    },
    image: {maxWidth: "100%", maxHeight: "100%"},
    delete: {
        cursor: "pointer",
        // padding: 10,
        // background: "red",
        // color: "white",
        border: "none",
    },
};


const UploadProfilePicture = () => {
    const {baseURL} = useContext(BaseURLContext);
    const [count, setCount] = useState(0);
    const [disable, setDisable] = React.useState(false);
    const [limit, setLimit] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [images, setImages] = useState(null);
    // const [formData, setFormData] = useState([]);
    const [isLoading, setIsLoading] = useState('start');
    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")
    const navigate = useNavigate();
    const size = useWindowSize();

    async function uploadProfileImage() {
        let newFormData = new FormData();
        newFormData.append(`formFile`, selectedImage)
        newFormData.append("name", 'teste');

        const createImageResponse = await protectedEnpointPostRequest(
            `${baseURL}/UserManage/UploadProfilePicture`,
            newFormData,
            cookie
        );
        if (createImageResponse) {
            console.log('profile picture upload successful')
            setIsLoading('success')
            setTimeout(() => {
                navigate('/profil-genel');
                window.location.reload();
                // this.setState({ position: 1 });
            }, 2000);
        }
    }

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            // console.log('upload profule pic ', e.target.files[0])
            setSelectedImage(e.target.files[0])
            setDisable(true)
            // for (let i = 0; i < e.target.files.length; i++) {
            //     setSelectedImage((elem) => [...elem, e.target.files[i]]);
            // }
        }
    };

    // function RaiseCount() {
    //     const temp = parseInt(count) + 1;
    //     setCount(temp)
    //     console.log('count', count)
    // }

    const removeSelectedImage = (i) => {
        // setSelectedImage(selectedImage.filter(item => item.name !== i.name))
        setSelectedImage(null)
        setDisable(false)
    };

    useEffect(() => {
        if (isLoading === 'success') {
            setTimeout(() => {

            }, 1000)
        }

    }, [])

    return (
        <div className="row">
            {isLoading === 'start'
                && (<div className="media-body ml-4">
                    {/*className={size.width > 680 ? "media-body ml-4" : "d-flex ml-12"}*/}
                    <div>
                        <div>
                            <label className="btn btn-default md-btn-flat rounded-3">
                                <input accept="image/*"
                                       type="file"
                                    // className="d-flex justify-content-center"
                                       style={{display: 'none'}}
                                       className="hidden"
                                       title=" "
                                       id="files"
                                       placeholder="Dosya Seçiniz"
                                       onChange={(e) => {
                                           imageChange(e)
                                           // RaiseCount()
                                       }}
                                       disabled={disable}
                                />
                                {/*<input type="file" id="files" className="hidden"/>*/}
                                <label htmlFor="files">Dosya Seçiniz</label>
                            </label>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-default md-btn-flat"
                                onClick={() => {
                                    setIsLoading('loading')
                                    uploadProfileImage()
                                }}
                                disabled={!disable}
                            >
                                Yükle
                            </button>
                        </div>
                    </div>
                    <div>

                    </div>
                    &nbsp;
                    {/*<div>*/}
                    {/*    <button disabled={!disable} type="button" onClick={() => {*/}
                    {/*        setSelectedImage(null);*/}
                    {/*        setDisable(false)*/}
                    {/*    }} className="btn btn-default md-btn-flat">*/}
                    {/*        Sıfırla*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    {/*&nbsp;*/}

                    <div className="m-auto d-flex justify-content-center">
                        {selectedImage &&
                            (
                                <div style={styles.preview}>
                                    <img
                                        src={URL.createObjectURL(selectedImage)}
                                        style={styles.image}
                                        alt="Thumb"
                                    />
                                    <button onClick={() => {
                                        removeSelectedImage(selectedImage?.name);
                                    }} style={styles.delete} type="reset">
                                        <TiDelete style={{fontSize: '34px', color: 'red'}}/>
                                    </button>
                                </div>
                            )

                        }
                    </div>
                </div>)}
            {isLoading === 'loading'
                && (<div className="m-auto" style={{width: '10%'}}>
                    <ReactLoading type='spokes' color='#000000' height={'100%'} width={'100%'}/>
                </div>)}
            {isLoading === 'success'
                && (<div><h6>Profil fotoğrafınız başarıyla güncellendi.</h6></div>)}
        </div>
    );
}

export default UploadProfilePicture;