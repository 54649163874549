import React, {useContext, useEffect, useState} from "react";
import {defaultGetRequestWithParams} from "../../../../../static/functions";
import {BaseURLContext} from "../../../../../baseURL-context";

const TechnicalDetails = ({categoryId}) => {
    const {baseURL} = useContext(BaseURLContext);
    const [catData, setCatData] = useState([]);

    async function getTechicalDetails() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Categories/GetCarCategoryById`,
            {
                params: {
                    id: parseInt(categoryId)
                }
            }
        )
        if(response) {
            console.log('setCatData',response.data)
            setCatData(response.data)
        }
    }

    const {
        detailFinal,
        fuelType,
        gear,
        hpCcFinal,
        id,
        make,
        model,
        motorType,
        nameFinal,
        props,
        subModel,
        type,
        year,
        yearFinal,
    } = catData || [];

    useEffect(() => {
        getTechicalDetails()
    },[])

    const rowWrapper = {
        float:'left',
        margin:'1rem'
    }

    const detailStyle = {
        marginLeft:'1rem'
    }
    const hrStyle = {
        // color: "#000000",
        backgroundColor: "#000000",
        height: '1px',
        // borderColor: "#000000",
    }
    const hrHidden = {
        display:'none'
    }
    return (
        <div className="container">
            {/*<div className="row">*/}
            {/*    <h1 className="text-center">Bootstrap Table Demo</h1>*/}
            {/*</div>*/}


            <div className="row mt-3">
                {fuelType && (<div className="col-md-12" style={rowWrapper}>
                    <div className="col-md-3">
                        <div className="price-detail-wrap row">
                            <label className="col-md-12" style={{color: "black", float: 'left'}}>
                                <strong>Yakit Tipi</strong>
                                <span style={detailStyle} className="col-md-12 ad-detail">{fuelType}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-8">

                    </div>
                </div>)}
                <hr style={fuelType ? hrStyle : hrHidden}/>

                {year && (<div className="col-md-12" style={rowWrapper}>
                    <div className="col-md-3">
                        <div className="price-detail-wrap row">
                            <label className="col-md-12" style={{color: "black", float: 'left'}}>
                                <strong>Uretim Yili</strong>
                                <span style={detailStyle} className="col-md-12 ad-detail">{year}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-8">

                    </div>
                </div>)}
                <hr style={year ? hrStyle : hrHidden}/>

                {make && (<div className="col-md-12" style={rowWrapper}>
                    <div className="col-md-3">
                        <div className="price-detail-wrap row">
                            <label className="col-md-12" style={{color: "black", float: 'left'}}>
                                <strong>Plaka</strong>
                                <span style={detailStyle} className="col-md-12 ad-detail">{nameFinal}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-8">

                    </div>
                </div>)}
                <hr style={make ? hrStyle : hrHidden}/>

                {model && (<div className="col-md-12" style={rowWrapper}>
                    <div className="col-md-3">
                        <div className="price-detail-wrap row">
                            <label className="col-md-12" style={{color: "black", float: 'left'}}>
                                <strong>Plaka</strong>
                                <span style={detailStyle} className="col-md-12 ad-detail">{nameFinal}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-8">

                    </div>
                </div>)}
                <hr style={hrStyle}/>
                <div className="col-md-12"  style={rowWrapper}>
                    <div className="col-md-3">
                        <div className="price-detail-wrap row">
                            <label className="col-md-12" style={{color: "black",float:'left'}}>
                                <strong>Plaka</strong>
                                <span style={detailStyle} className="col-md-12 ad-detail">{nameFinal}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-8">

                    </div>
                </div>
                <hr style={hrStyle}/>
                <div className="col-md-12"  style={rowWrapper}>
                    <div className="col-md-3">
                        <div className="price-detail-wrap row">
                            <label className="col-md-12" style={{color: "black",float:'left'}}>
                                <strong>Plaka</strong>
                                <span style={detailStyle} className="col-md-12 ad-detail">{nameFinal}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-8">

                    </div>
                </div>
                {/*<div className="price-detail-wrap row">*/}
                {/*    <label className="col-md-4" style={{color: "black"}}>*/}
                {/*        <strong>Plaka</strong>*/}
                {/*    </label>*/}

                {/*    <span className="col-md-4 ad-detail">{licensePlate}</span>*/}
                {/*</div>*/}
            </div>
            {/*<div className="row">*/}
            {/*    <div className="col-sd-8 col-md-offset-2">*/}
            {/*        <table className="table table-hover table-striped table-condensed ">*/}
            {/*            <tbody>*/}
            {/*            <tr>*/}
            {/*                <td><strong>{nameFinal}</strong></td>*/}
            {/*                <td>{nameFinal}</td>*/}
            {/*            </tr>*/}
            {/*            <tr>*/}
            {/*                <td><strong>Model</strong></td>*/}
            {/*                <td>{model}</td>*/}
            {/*            </tr>*/}
            {/*            <tr>*/}
            {/*                <td><strong>Mickey</strong></td>*/}
            {/*                <td>Mouse</td>*/}
            {/*            </tr>*/}
            {/*            </tbody>*/}
            {/*        </table>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default TechnicalDetails;
