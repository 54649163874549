import React from "react";

const OfficeView = ({
                        hrStyle,
                        BuildingAge,
                        TotalFloor,
                        MonthlyExtra,
                        InUsage,
                        Heating,
                        CreditStatus
                    }) => {
    return (
        <div>
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Bina Yaşı</strong>
                </label>

                <span className="col-md-8 ad-detail">{BuildingAge}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Toplam Kat</strong>
                </label>

                <span className="col-md-8 ad-detail">{TotalFloor}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Isınma</strong>
                </label>

                <span className="col-md-8 ad-detail">{Heating}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Aidat</strong>
                </label>

                <span className="col-md-8 ad-detail">{MonthlyExtra}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Kullanım Durumu</strong>
                </label>

                <span className="col-md-8 ad-detail">{InUsage}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Krediye Uygun</strong>
                </label>

                <span className="col-md-8 ad-detail">{CreditStatus}</span>
            </div>
        </div>
    );
}

export default OfficeView;
