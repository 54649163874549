import React, {useContext, useEffect, useState} from "react";
import {BaseURLContext} from "../../../../baseURL-context";
import useForm from "../../../useForm/useForm";
import "react-bootstrap/dist/react-bootstrap.min.js";
import {Row, Col} from "react-bootstrap";
import { protectedEnpointGetRequest } from "../../../../static/functions";
import City from "../../../SearchTest/Filters/City";
import Cookies from "universal-cookie";
import DopingOnCreateAd from "../../../Payment/CreateAd/DopingOnCreateAd";
import Land from "../../../Forms/CreateEstate/Type/Land"
import Hotel from "../../../Forms/CreateEstate/Type/Hotel";
import House from "../../../Forms/CreateEstate/Type/House";
import Description2 from "../../../Forms/Common/Description2";
import Building from "../../../Forms/CreateEstate/Type/Building";
import Office from "../../../Forms/CreateEstate/Type/Office";
import GeoLocationNewNew from "../../../Map/GeoLocationNewNew";
import FileUpload from "../../../Common/ImageUploadNew";
import NumberFormat from "react-number-format";

const EstateForm = ({
                        goBack,
                        nextPage,
                        categoryId,
                        setEstateData,
                        setImages,
                        setFormData,
                        doping,
                        setDoping,
                        categoryDetails
                    }) => {
    const {values, errors, handleChange, handleSubmit} = useForm([]);

    const {baseURL} = useContext(BaseURLContext);
    const [formPage, setFormPage] = useState(1);
    const [estateName, setEstateName] = useState('');
    const [selectedImage, setSelectedImage] = useState([]);
    const [imageLimit, setImageLimit] = useState(0);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedTown, setSelectedTown] = useState("");
    const [selectedNeighbour, setSelectedNeighbour] = useState("");
    const [userRole, setUserRole] = useState("");
    const [breadcrumbs, setBreadcrumbs] = useState([])

    //map
    const [latitude, setLatitude] = useState(39.92744408640298);
    const [longitude, setLongitude] = useState(32.863969802856445);
    const [initialLocation, setInitialLocation] = useState({id: 0, pos: {lat: latitude, lng: longitude}})
    const [center, setCenter] = useState({lat: 39.92744408640298, lng: 32.863969802856445});

    //house
    const [selectedRooms, setSelectedRooms] = useState("");
    const [selectedHeating, setSelectedHeating] = useState("");
    const [selectedUsage, setSelectedUsage] = useState("");
    const [selectedOwnership, setSelectedOwnership] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState('');
    const [squaremeter, setSquaremeter] = useState(0);
    const [pricePerM2, setPricePerM2] = useState(0);
    const [buildingAge, setBuildingAge] = useState(0);
    const [floor, setFloor] = useState(0);
    const [totalfloor, setTotalfloor] = useState(0);
    const [balcony, setBalcony] = useState(0);
    const [bathrooms, setBathrooms] = useState(0);
    const [monthlyextra, setMonthlyextra] = useState(0);
    const [withFurniture, setWithFurniture] = useState('');
    const [availableForCredit, setAvailableForCredit] = useState('');
    const [frontSide, setFrontSide] = useState('');

    // area group setReconstruction,setAda,setParcel,setPafta,setKaks,setGabari,setFloorPayback,setSwappable
    const [reconstruction, setReconstruction] = useState("");
    const [ada, setAda] = useState("");
    const [parcel, setParcel] = useState("");
    const [pafta, setPafta] = useState("");
    const [kaks, setKaks] = useState("");
    const [gabari, setGabari] = useState("");
    const [floorPayback, setFloorPayback] = useState("");
    const [swappable, setSwappable] = useState("");

    // area group Hotel
    const [starCount, setStarCount] = useState();
    const [roomCount, setRoomCount] = useState();
    const [bedCount, setBedCount] = useState();
    const [floorCount, setFloorCount] = useState();
    const [closedArea, setClosedAreaM2] = useState()
    const [groundSurvey, setGroundSurvey] = useState();

    //area group Building
    const [flatCountPerFloor, setFlatCountPerFloor] = useState(0);

    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")


    function setNextPage() {
        console.log('doping', doping)
        if (doping === 2 || doping === 3) {
            nextPage('payment')
        } else {
            nextPage('no-payment')
        }
    }


    async function GetImageLimits() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Membership/GetImageUploadLimit`,
            cookie
        )
        if (response) {
            setImageLimit(parseInt(response.data))
        }
    }

    const handlePrice = (e) => {
        const value = e.target.value;
        // if(value.includes())
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            const formatted = new Intl.NumberFormat().format(parseFloat(clean));
            formatted.replace('.', '')
            setPrice(formatted);
        } else {
            setPrice("");
        }
    };

    async function calculatePricePerM2(sqm2, prc) {
        if (categoryDetails[0] === 'Arsa') {
            if (sqm2 !== 0 && prc !== 0) {
                var rawRes = prc * sqm2;
                var rawResult2 = rawRes.toString()
                const clean = rawResult2.replace(/,/g, "");
                const regex = /^[0-9]*\.?[0-9]*$/;

                if (rawResult2 && clean.match(regex)) {
                    const formatted = new Intl.NumberFormat().format(parseFloat(clean));
                    formatted.replace('.', '')
                    setPrice(formatted);
                } else {
                    setPrice("");
                }
            }
        }
    }

    useEffect(() => {
        GetImageLimits()
    }, [])

    async function createEstateTest() {

        const estateData = {
            Name: estateName,
            AdType: categoryDetails[1],
            FamilyType: categoryDetails[0],
            EstateType: categoryDetails[2] ?? categoryDetails[0],
            Description: description,
            Price: price.replace(',','.'),
            City: selectedCity,
            State: selectedTown,
            Neighbourhood: selectedNeighbour,
            SquareMeter: parseInt(squaremeter),
            Rooms: selectedRooms,
            BuildingAge: parseInt(buildingAge),
            Floor: parseInt(floor),
            TotalFloor: parseInt(totalfloor),
            Heating: selectedHeating,
            Balcony: parseInt(balcony),
            Bathrooms: parseInt(bathrooms),
            WithFurniture: withFurniture === 'Evet',
            InUsage: selectedUsage,
            MonthlyExtra: parseInt(monthlyextra),
            CreditStatus: availableForCredit === 'Evet',
            Ownership: selectedOwnership,
            From: userRole,
            CategoryId: parseInt(categoryId),
            Reconstruction: reconstruction,
            Ada: ada,
            Parcel: parcel,
            Pafta: pafta,
            Kaks: kaks,
            Gabari: gabari,
            FloorPayback: floorPayback,
            Swappable: swappable,
            BedCount: parseInt(bedCount),
            ClosedAreaM2: parseInt(closedArea),
            FloorCount: parseInt(floorCount),
            StarCount: parseInt(starCount),
            FlatCountPerFloor: parseInt(flatCountPerFloor),
            GroundSurvey: groundSurvey,
            RoomCount: parseInt(roomCount),
            FrontSide: frontSide,
            Latitude: initialLocation?.pos?.lat.toString(),
            Longitude: initialLocation?.pos?.lng.toString()
            // {id: "place1", pos: {lat: latitude, lng: longitude}}
        };

        setEstateData(estateData);

        if (selectedImage.length > 0) {
            for (let a = 0; a < selectedImage.length; a++) {
                const url = URL.createObjectURL(selectedImage[a])
                console.log('url : ', url)
                setImages(e => [...e, url]);
                setFormData(selectedImage)
            }
        }


        const response = await protectedEnpointGetRequest(
            `${baseURL}/authentication/CheckUserActivity`,
            cookie
        );
        if (response) {
            if (response.data === true) {
                console.log("repsonse data ", response.data)
                setNextPage()
            }
        }
    }
    useEffect(() => {
        console.log(selectedOwnership)
    },[selectedOwnership])

    async function getCurrentLocation() {
        navigator.geolocation.getCurrentPosition(function (position) {
            setInitialLocation({
                id: 0,
                pos: {
                    lat: position?.coords?.latitude,
                    lng: position?.coords?.longitude
                }
            })
            setLatitude(position?.coords?.latitude)
            setLongitude(position?.coords?.longitude)
            setCenter({lat: position?.coords?.latitude, lng: position?.coords?.longitude})
        });
    }

    const updateUploadedFiles = (files) => {
        setSelectedImage({})
        setSelectedImage(files)
    }

    const MAX_VAL = 1000000000000;
    const withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value <= MAX_VAL) return true;
        return false;
    };

    return (
        <fieldset style={{maxWidth: "1180px", width: "100%", margin: "0 auto"}}>
            <div className="center m-auto" style={{width: "70%"}}>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {categoryDetails && categoryDetails.map((item, i) => {
                            return (
                                <li key={i} className="breadcrumb-item active" aria-current="page">{item}</li>
                            )
                        })}
                    </ol>
                </nav>

                <Row>
                    <h1 style={{marginBottom: "2rem"}}>İlan Oluştur</h1>
                    <Col md={12}>
                        <div className="ms-4 me-4">
                            <div className="form-group mt-4 mb-4">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name"
                                        placeholder="İlan Adı"
                                        value={estateName}
                                        onChange={(e) => {
                                            setEstateName(e.target.value)
                                        }}
                                    />
                                    <label htmlFor="name">İlan Başlığı</label>
                                </div>
                                {/*photo*/}
                                <div className="form-group mt-4 mb-4">
                                    <label style={{marginBottom: "1rem"}}>
                                        <strong>Fotograf Ekle</strong>
                                    </label>
                                    <div className="form-floating mb-3">
                                        <FileUpload
                                            accept=".jpg,.png,.jpeg"
                                            label="Profile Image(s)"
                                            // onChange={() => {setSelectedImage([])}}
                                            updateFilesCb={updateUploadedFiles}
                                            multiple
                                            imageLimit={imageLimit}
                                        />

                                    </div>
                                </div>

                                {/*<div className="form-group mt-4 mb-4">*/}
                                {/*    <label style={{marginBottom: "1rem"}}>*/}
                                {/*        <strong> konum</strong>*/}
                                {/*    </label>*/}
                                {/*    <div className="form-floating mb-3">*/}
                                {/*        /!*<MapWrapper/>*!/*/}

                                {/*    </div>*/}
                                {/*</div>*/}

                                {categoryDetails[0] === 'Arsa' &&
                                    (<div className="form-floating mb-3 row">
                                        <div className="col-md-4">
                                            <label htmlFor="pricePerM2" className="float-start">
                                                <strong>Meterekare Birim Fiyati(₺)</strong>
                                            </label>

                                            <input
                                                type="number"
                                                name="pricePerM2"
                                                min="0"
                                                className="form-control"
                                                id="pricePerM2"
                                                placeholder="M²"
                                                value={pricePerM2}
                                                onChange={(e) => {
                                                    setPricePerM2(e.target.value);
                                                    calculatePricePerM2(e.target.value, squaremeter)
                                                }}
                                            />
                                        </div>
                                    </div>)}

                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <label htmlFor="buildingage" className="float-start">
                                            <strong>Meterekare</strong>
                                        </label>
                                        <input
                                            type="number"
                                            name="squaremeter"
                                            min="0"
                                            className="form-control"
                                            id="squaremeter"
                                            placeholder="M²"
                                            value={squaremeter}
                                            onChange={(e) => {
                                                setSquaremeter(e.target.value)
                                                calculatePricePerM2(pricePerM2, e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        {/*<Row>*/}
                                        {/*<Col>*/}
                                        <label className="float-start">
                                            <strong>Fiyat(₺)</strong>
                                        </label>
                                        {categoryDetails[0] === 'Arsa' ?
                                            (<input
                                                type="text"
                                                className="form-control"
                                                id="price"
                                                placeholder={0}
                                                value={price}
                                                onChange={(e) => {
                                                    handlePrice(e);
                                                    // console.log(e.target.value)
                                                }}
                                                disabled
                                            />) :
                                            (
                                                <NumberFormat
                                                    thousandSeparator=","
                                                    id="input-example"
                                                    name="input-name"
                                                    className="form-control"
                                                    decimalSeparator={false}
                                                    value={price}
                                                    allowNegative={false}
                                                    // allowEmptyFormatting={}
                                                    // form
                                                    isNumericString
                                                    defaultValue={"0"}
                                                    // prefix="$"
                                                    isAllowed={withValueCap}
                                                    onValueChange={(values) => {
                                                        // console.log(values)
                                                        // setPrice(values.toString())
                                                        setPrice(values.formattedValue.toString())
                                                    }}
                                                    // onValueChange={(values) => this.setState({ test: values.value })}
                                                    // onChange={(e) => setPrice(e.target.value.toString())}
                                                    // onBlur={(e) => console.log('onBlur',e.target.value)}
                                                />
                                            )}
                                                {/*<CurrencyInput*/}
                                                {/*    id="input-example"*/}
                                                {/*    name="input-name"*/}
                                                {/*    className="form-control"*/}
                                                {/*    placeholder=""*/}
                                                {/*    defaultValue={0}*/}
                                                {/*    decimalsLimit={0}*/}
                                                {/*    onValueChange={(value) => {*/}
                                                {/*        setPrice(value.toString())*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                    </div>
                                </div>


                                {userRole && (<div className="col-md-3" style={{paddingBottom: "2rem"}}>
                                    <label htmlFor="from" className="float-start">
                                        <strong>Kimden</strong>
                                    </label>
                                    <input
                                        type="text"
                                        name="from"
                                        className="form-control"
                                        id="from"
                                        placeholder={userRole}
                                        value={values.from || ""}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </div>)}

                                <div className="form-floating mb-3 row">
                                    <div className="col-md-4">
                                        <City
                                            // selectedCity={selectedCity}
                                            setSelectedCity={setSelectedCity}
                                            // selectedTown={selectedTown}
                                            setSelectedTown={setSelectedTown}
                                            onChange={handleChange}
                                            extentedLocation={true}
                                            setSelectedNeighbour={setSelectedNeighbour}
                                            selectedNeighbour={selectedNeighbour}
                                            position={false}
                                        />
                                    </div>
                                </div>
                                {categoryDetails[0] === 'Konut'
                                    &&
                                    <House
                                        // setSquaremeter={setSquaremeter}
                                        setBuildingage={setBuildingAge}
                                        setFloor={setFloor}
                                        setTotalfloor={setTotalfloor}
                                        setBalcony={setBalcony}
                                        setBathrooms={setBathrooms}
                                        setMonthlyextra={setMonthlyextra}
                                        setSelectedRooms={setSelectedRooms}
                                        setSelectedHeating={setSelectedHeating}
                                        setSelectedUsage={setSelectedUsage}
                                        setSelectedOwnership={setSelectedOwnership}
                                        selectedRooms={selectedRooms}
                                        selectedHeating={selectedHeating}
                                        selectedUsage={selectedUsage}
                                        selectedOwnership={selectedOwnership}
                                        availableForCredit={availableForCredit}
                                        setAvailableForCredit={setAvailableForCredit}
                                        withFurniture={withFurniture}
                                        setWithFurniture={setWithFurniture}
                                        setFrontSide={setFrontSide}
                                        categoryType={categoryDetails[1]}
                                        handleChange={handleChange}
                                    />
                                }
                                <br/>
                            </div>

                            {/*if land has choosen*/}
                            {categoryDetails[0] === 'Bina' &&
                                <Building
                                    // setRoomCount={setRoomCount}
                                    setBuildingAge={setBuildingAge}
                                    setFloorCount={setFloorCount}
                                    setFlatCountPerFloor={setFlatCountPerFloor}
                                />}

                            {categoryDetails[0] === 'Arsa' &&
                                <Land
                                    handleChange={handleChange}
                                    values={values}
                                    setReconstruction={setReconstruction}
                                    setAda={setAda}
                                    setParcel={setParcel}
                                    setPafta={setPafta}
                                    setKaks={setKaks}
                                    setGabari={setGabari}
                                    setFloorPayback={setFloorPayback}
                                    setSwappable={setSwappable}
                                    setSelectedOwnership={setSelectedOwnership}
                                />
                            }
                            {/*if hotel has choosen*/}

                            {categoryDetails[0] === 'Turistik Tesis' &&
                                <Hotel
                                    // setOpenAreaM2={setOpenAreaM2}
                                    setStarCount={setStarCount}
                                    setBedCount={setBedCount}
                                    setClosedAreaM2={setClosedAreaM2}
                                    setFloorCount={setFloorCount}
                                    setGroundSurvey={setGroundSurvey}
                                    setRoomCount={setRoomCount}
                                />
                            }
                            {categoryDetails[0] === 'İş Yeri' &&
                                <Office
                                    setBuildingage={setBuildingAge}
                                    setTotalfloor={setTotalfloor}
                                    setMonthlyextra={setMonthlyextra}
                                    setSelectedHeating={setSelectedHeating}
                                    setSelectedUsage={setSelectedUsage}
                                    selectedHeating={selectedHeating}
                                    selectedUsage={selectedUsage}
                                />}

                            <div>
                                <div style={{fontSize: '22px'}}>

                                    <button type="button" style={{cursor: 'pointer', background: 'lightblue'}} onClick={() => {
                                        getCurrentLocation()
                                    }}>
                                        <strong>
                                            Konumumu göster
                                        </strong>
                                    </button>
                                </div>

                                <label>
                                </label>


                                <div className="form-floating mb-3">

                                    <GeoLocationNewNew
                                        latitude={latitude}
                                        setLatitude={setLatitude}
                                        longitude={longitude}
                                        setLongitude={setLongitude}
                                        initialLocation={initialLocation}
                                        setInitialLocation={setInitialLocation}
                                        center={center}
                                        setCenter={setCenter}
                                        clickEnabled={true}
                                    />
                                </div>
                            </div>

                            <div className="description-wrapper">
                                <div style={{fontSize: '22px'}}>
                                    <label>
                                        <strong>Açıklama</strong>
                                    </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <Description2 existingDesc={' '} setDescription={setDescription}/>
                                </div>
                            </div>

                            <DopingOnCreateAd
                                setAdvantages={setDoping}
                                advantages={doping}
                            />
                            <button
                                className="btn btn-primary mb-4"
                                style={{
                                    backgroundColor: "lightcoral",
                                    color: "black",
                                    marginRight: "2rem",
                                }}
                                onClick={() => {
                                    goBack();
                                }}
                            >
                                Geri
                            </button>
                            <button
                                // disabled={
                                // estateName == null ||
                                // price === "" ||
                                // description === "" ||
                                // description === " " ||
                                // description == null ||
                                // doping == null
                                // }
                                className="btn btn-primary mb-4"
                                style={{backgroundColor: "green", color: "white"}}
                                onClick={() => {
                                    createEstateTest()
                                }}
                                // type="submit"
                            >
                                İlan Oluşturmaya Devam Et
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </fieldset>
    );
    // }

    // return (
    //     <React.Fragment>
    //         <div>
    //             <form onSubmit={handleSubmit}>
    //                 {renderUserForm()}
    //             </form>
    //         </div>
    //     </React.Fragment>
    // );
};

export default EstateForm;
