import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Tab, Nav, Row, Col} from "react-bootstrap";
import {
    defaultGetRequestWithParams,
    protectedEnpointGetRequest,
    protectedEnpointPostRequest
} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import Carousel from "../Components/Products/Carousel";
import Location from "../Components2/Product/ProductDetails/Location";
// import {Link} from "react-router-dom";
import Cookies from "universal-cookie";
// import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import HouseView from "../Components/Product/Estate/View/Types/HouseView";
import LandView from "../Components/Product/Estate/View/Types/LandView";
import HotelView from "../Components/Product/Estate/View/Types/HotelView";
import OfficeView from "../Components/Product/Estate/View/Types/OfficeView";
import BuildingView from "../Components/Product/Estate/View/Types/BuildingView";
import '../assets/css/adPreview.css'
import MessageBox from "../Components/Common/MessageBox";
import GeoLocation from "../Components/Forms/CreateEstate/GeoLocation";
import GeoLocationNewNew from "../Components/Map/GeoLocationNewNew";
import GeoLocationView from "../Components/Map/GeoLocationView";
import {
    FacebookIcon,
    FacebookShareButton, TelegramIcon, TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {generateUrl} from "../static/helpers";


const Estate = ({setMetadata}) => {
    const {baseURL} = useContext(BaseURLContext);
    const baseApp = process.env.REACT_APP_MAIN
    const {id} = useParams();
    const type = "estate";
    const [product, setProduct] = useState()
    const [user, setUser] = useState();
    const [isLogged, setIsLogged] = useState();
    const [currentUser, setCurrentUser] = useState([]);
    const [viewCount, setViewCount] = useState(0);
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt")
    const [favIconStatus, setFavIconStatus] = useState(false);
    const [tabState, setTabState] = useState('details');

    //map
    const [latitudeExisting, setLatitudeExisting] = useState(null);
    const [longitudeExisting, setLongitudeExisting] = useState(null);
    const [initialLocationExisting, setInitialLocationExisting] = useState(null)
    const [center, setCenter] = useState(null);


    async function CheckUserActivity() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/authentication/CheckUserActivity`,
            cookie
        )
        if (response) {
            if (response.data === true) {
                setIsLogged(true)
            } else {
                setIsLogged(false)
            }
        } else {
            setIsLogged(false)
        }
    }

    async function getCurrentUser() {
        if(cookie) {
            const getUser = await protectedEnpointGetRequest(
                `${baseURL}/UserManage/GetUser`,
                cookie
            )
            if (getUser) {
                setCurrentUser(getUser.data)
            }
        }
    }

    async function GetEstateById() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/GetEstateById`,
            {
                params: {
                    id: id,
                },
            }
        );
        if (response) {
            setProduct(response.data.estate);
            setUser(response.data.owner)
            setMetadata(
                {
                    image: response.data.estate.coverImage,
                    title: response.data.estate.name,
                }
            )
            return null;
        } else {
            return null;
        }
    }

    async function increaseViewCount() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/IncreaseViewCount`,
            {
                params: {
                    id: id,
                    type: 0,
                },
            }
        );
        if (response) {
            setViewCount(response.data)
        }
    }

    useEffect(() => {
        CheckUserActivity()
        GetEstateById();
        CheckFavourite();
        getCurrentUser();
        increaseViewCount();
        //eslint-disable-next-line
    }, []);

    const activeStyle = {
        background: 'lightblue'
    }

    const passiveStyle = {
        background: 'lightgray'
    }

    async function CheckFavourite() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/authentication/CheckUserActivity`,
            cookie
        );
        if (response) {
            if (response.data === true) {
                const requestBody = {
                    Id: parseInt(id),
                    Type: 0
                }
                const fav = await protectedEnpointPostRequest(
                    `${baseURL}/Membership/GetFavourite`,
                    requestBody,
                    cookie
                );

                if (fav) {
                    setFavIconStatus(fav.data.result)
                }
            }
        }
    }

    async function SetFavourite() {
        const requestBody = {
            Id: parseInt(id),
            Type: 0
        }
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Membership/SetFavourite`,
            requestBody,
            cookie
        );
        if (response) {
            window.location.reload()
        }
    }

    const post = product;

    const {
        adType,
        ada,
        balcony,
        bathrooms,
        bedCount,
        buildingAge,
        categoryId,
        categoryType,
        city,
        neighbourhood,
        closedAreaM2,
        coverImage,
        createdOn,
        creditStatus,
        description,
        editedOn,
        estateType,
        familyType,
        flatCountPerFloor,
        floor,
        floorCount,
        floorPayback,
        from,
        gabari,
        groundSurvey,
        heating,
        inUsage,
        isActive,
        isDopingEnabled,
        kaks,
        monthlyExtra,
        name,
        ownership,
        pafta,
        parcel,
        price,
        province,
        reconstruction,
        roomCount,
        rooms,
        rootCatId,
        squareMeter,
        starCount,
        state,
        swappable,
        totalFloor,
        userId,
        withFurniture,
        latitude,
        longitude
    } = post || [];

    useEffect(() => {
        setInitialLocationExisting({
            id: parseInt(id),
            pos: {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude)
            }
        })
        setCenter({lat: parseFloat(latitude), lng: parseFloat(longitude)})
    }, [longitude, latitude])


    const owner = user;

    const {
        ownerName,
        phone,
        username
    } = owner || [];

    const CreditStatus = creditStatus === true ? 'Evet' : 'Hayır'
    const WithFurniture = withFurniture === true ? 'Evet' : 'Hayır'

    const border = {
        color: "black",
        borderBottom: '1px solid black',
        width: '100%',
        lineHeight: '2em'
    }

    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: 1,
        borderColor: "#000000",
    }

    return (
        <div className="container-ad">
           <br/>
           {product && (
            <div
                className="card"
                style={{backgroundColor: "white"}}
            >
                <div className="row">
                    <h3 className="title col-md-12 d-flex m-auto mb-4"
                        style={border}>
                        {name}
                    </h3>
                </div>
                <div className="row">
                    <div className="col-md-5 mt-5 border-right p-3">
                        <Carousel id={id} adType={0}/>
                    </div>
                    <div className="col-md-4">
                        <article className="card-body">
                            <div className="price-detail-wrap row">
                                <p className="col-md-12 price-tag" style={{color: "black"}}>
                                    <strong> {price} </strong>
                                </p>
                            </div>
                            <div className="price-detail-wrap row">
                                <div className="col-md-12 city-state">

                                    {city} / {state} / {neighbourhood}

                                </div>
                            </div>
                            <hr style={hrStyle}/>
                            <div className="price-detail-wrap row">
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Emlak Tipi</strong>
                                </label>

                                <span className="col-md-8 ad-detail">
                                            {familyType} / {adType} {estateType !== familyType
                                    ? (<>/{estateType}</>)
                                    : (<></>)}
                                        </span>
                            </div>
                            <hr
                                style={hrStyle}
                            />
                            <div>
                                <div
                                    className="price-detail-wrap row"
                                >
                                    <label className="col-md-4" style={{color: "black"}}>
                                        <strong>Metrekare</strong>
                                    </label>

                                    <span className="col-md-8 ad-detail">{squareMeter} M²</span>
                                </div>
                                <hr
                                    style={hrStyle}
                                />
                            </div>

                            {familyType === 'Konut' &&
                                (<HouseView
                                    hrStyle={hrStyle}
                                    Rooms={rooms}
                                    WithFurniture={WithFurniture}
                                    Balcony={balcony}
                                    Bathrooms={bathrooms}
                                    BuildingAge={buildingAge}
                                    Floor={floor}
                                    TotalFloor={totalFloor}
                                    MonthlyExtra={monthlyExtra}
                                    InUsage={inUsage}
                                    CreditStatus={CreditStatus}
                                    Ownership={ownership}
                                    Heating={heating}
                                />)}


                            {familyType === 'Arsa' &&
                                <LandView
                                    hrStyle={hrStyle}
                                    Reconstruction={reconstruction}
                                    Ada={ada}
                                    Parcel={parcel}
                                    Pafta={pafta}
                                    Kaks={kaks}
                                    Gabari={gabari}
                                    FloorPayback={floorPayback}
                                    Swappable={swappable}
                                    Ownership={ownership}
                                />
                            }

                            {familyType === 'Turistik Tesis' &&
                                <HotelView
                                    StarCount={starCount}
                                    RoomCount={roomCount}
                                    BedCount={bedCount}
                                    FloorCount={floorCount}
                                    ClosedAreaM2={closedAreaM2}
                                    GroundSurvey={groundSurvey}
                                />
                            }

                            {familyType === 'İş Yeri' &&
                                <OfficeView
                                    hrStyle={hrStyle}
                                    BuildingAge={buildingAge}
                                    TotalFloor={totalFloor}
                                    MonthlyExtra={monthlyExtra}
                                    InUsage={inUsage}
                                    Heating={heating}
                                    CreditStatus={CreditStatus}
                                />
                            }

                            {familyType === 'Bina' &&
                                <BuildingView
                                    hrStyle={hrStyle}
                                    FlatCountPerFloor={flatCountPerFloor}
                                    FloorCount={floorCount}
                                    BuildingAge={buildingAge}
                                />
                            }
                            <hr
                                style={hrStyle}
                            />
                        </article>
                    </div>
                    <aside className="col-md-3 p-3 favourites-wrapper">
                        {currentUser?.username !== username
                            &&
                            (
                                <div>
                                    <MessageBox
                                        type={type}
                                        phone={phone}
                                        ownerName={ownerName}
                                        id={id}
                                        username={username}
                                        isLogged={isLogged}
                                    />
                                    <div className="title m-3 favourites-button-wrapper"
                                         style={{color: "black", cursor: 'pointer'}}
                                         onClick={() => {
                                             SetFavourite();
                                         }}>
                                        {favIconStatus === true
                                            ?
                                            (
                                                <h6 className="favourites-text">Favorilerden Cikar</h6>
                                            )
                                            :
                                            (
                                                <h6 className="favourites-text">Favorilere Ekle</h6>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div>
                            <div><h6>Paylaş</h6></div>
                            <div>
                                <FacebookShareButton
                                    url={generateUrl(baseApp)}
                                    quote={name}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={32} round/>
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={generateUrl(baseApp)}
                                    title={name}
                                    className="Demo__some-network__share-button"
                                >
                                    <TwitterIcon size={32} round/>
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    url={generateUrl(baseApp)}
                                    title={name}
                                    separator=":: "
                                    className="Demo__some-network__share-button"
                                >
                                    <WhatsappIcon size={32} round/>
                                </WhatsappShareButton>
                                <TelegramShareButton
                                    url={generateUrl(baseApp)}
                                    title={name}
                                    className="Demo__some-network__share-button"
                                >
                                    <TelegramIcon size={32} round/>
                                </TelegramShareButton>
                            </div>
                        </div>
                    </aside>
                </div>

                <div className="row col-md-12">
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-2 btn rounded-0 m-2"
                         onClick={() => {
                             setTabState('details')
                         }}
                         style={tabState === 'details' ? activeStyle : passiveStyle}
                    >
                        İlan Detayları
                    </div>
                    <div className="col-md-2 btn rounded-0 m-2"
                         onClick={() => {
                             setTabState('location')
                         }}
                         style={tabState === 'location' ? activeStyle : passiveStyle}
                    >
                        İlan Konumu
                    </div>
                    <div className="col-md-4">

                    </div>
                </div>

                <div>


                    {tabState === 'details' &&
                        (
                            <div className="row"
                                 style={{margin: "0 auto", backgroundColor: "lightgray", minHeight: "10rem"}}>
                                <div className="d-flex center justify-content-center align-items-center col-md-12">
                                    <div dangerouslySetInnerHTML={{__html: `${description}`}}>
                                    </div>
                                </div>
                            </div>
                        )}
                    {tabState === 'location' && latitude && longitude &&
                        (
                            <div className="row">
                                <GeoLocationView
                                    latitudeExisting={latitudeExisting}
                                    setLatitudeExisting={setLatitudeExisting}
                                    longitudeExisting={longitudeExisting}
                                    setLongitudeExisting={setLongitudeExisting}
                                    initialLocationExisting={initialLocationExisting}
                                    center={center}
                                    setCenter={setCenter}
                                />
                            </div>
                        )}
                </div>

            </div>)
}
        </div>
        
    );
};
export default Estate;
