import React, {useState} from "react";
// import MessageBox2 from "../Components/Messages/Message";
// import MessageBox from "../Components/Messages/Chat2";
import NavbarIn from "../Components/Layout/NavbarIn"
import "../assets/css/message.css";
import MessageFinal from "../Components/Messages/MessageFinal";

const App = () => {
    const [currentUser, setCurrentUser] = useState('');
    return (
        <div>
            <div>
                <NavbarIn setCurrentUser={setCurrentUser}/>
            </div>
            <div>
                { currentUser && (<MessageFinal currentUser={currentUser}/>)}
            </div>
        </div>
    );
};


export default App;
