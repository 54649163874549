// import React from "react";

import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";



export function beautifyName(text) {
  let resized = text.toLowerCase();
  let result = resized
    .replace("ü", "u")
    .replace("ğ", "g")
    .replace("ı", "i")
    .replace("ş", "s")
    .replace("ç", "c")
    .replace("ö", "o")
    .replace(",", "")
    .replace(" ", "-")
    .replace(".", "");
  return result;
}

export function setAdTitleStringLimit(text) {
  if(text !== null) {
    var count = text.length;

    if(count > 25){
      return text.substring(0, 25) + "...";
    } else {
      return text;
    }
  } else {
    return null
  }

}
export default function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}



// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}


// export function getMarkers() {
//   const cnt = 10;
//   const markers = [];
//   for (let i = 0; i < cnt; i++) {
//     markers.push({
//       id: i,
//       title: "marker: " + i,
//       lat: base.lat + 0.04 * i,
//       lng: base.lng + 0.04 * i
//     });
//   }
//   return markers;
// }

export function checkLevelType(mainCategory) {
  if (mainCategory === 'Engelli Araci' ||
      mainCategory === 'ATV & UTV' ||
      mainCategory === 'Traktör' ||
      mainCategory === 'Tarım & İş Makineleri' ||
      mainCategory === 'Motosiklet'
  ) {
    return 0
  } else if (mainCategory === 'Ticari Araç' ||
      mainCategory === 'Klasik Araçlar' ||
      mainCategory === 'Elektrikli Araçlar' ||
      mainCategory === 'Karavan' ||
      mainCategory === 'Modifiyeli Arabalar' ||
      mainCategory === 'Hasarli Araçlar'
  ) {
    return 1
  } else {
    return 2
  }
}


export function generateUrl(baseUrl) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let location = useLocation();
  return baseUrl + '/#' + location.pathname
}

export function clearWhiteSpace(text,callback) {
  let newText = text.replace(/\s/g, "");
  console.log(newText)
  callback(newText)
  // return
}

export function capitalizeFirstLetter(text) {
  console.log(text)
  const result = text.toString().charAt(0).toUpperCase() + text.toString().toLowerCase().slice(1);
  console.log('result',result)
  return result;

  // return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
}



