import React, {useEffect, useState} from "react";
import NavbarIn from "../Layout/NavbarIn";
import UserManage from "./UserManage";
import {useLocation} from "react-router-dom";

const Message = () => {
    const [user, setUser] = useState([]);
    const [messageUser, setMessageUser] = useState([]);
    const [productId, setProductId] = useState(0);
    const [productType, setProductType] = useState(0);
    const location = useLocation();

    useEffect(() => {
        console.log('ad and user info on messages',location?.state?.user,location?.state?.id,location?.state?.type,location?.state?.eventKey)
        setMessageUser(location?.state?.user)
        setProductId(parseInt(location?.state?.id))
        setProductType(location?.state?.type)
        console.log('location?.eventKey',location?.state?.eventKey)

        // console.log(location?.state?.user)
        // console.log(parseInt(location?.state?.id))
        // console.log(location?.state?.type)
    },[])

    return (
        <div>
            <NavbarIn setCurrentUser={setUser}/>
            <UserManage currentUser={user}
                        eventKeyProp={'messages'}
                        messageUser={messageUser}
                        productId={productId}
                        productType={productType}
            />
        </div>
    );
}

export default Message;
