import React, {useContext, useState} from "react";
import {useLocation} from "react-router-dom";
import {defaultGetRequestWithParams} from "../static/functions";
import {BaseURLContext} from "../baseURL-context";
import Navbar from "../Components/Layout/Navbar";
import {useNavigate} from "react-router";

const RegisterSuccess = () => {
    const {baseURL} = useContext(BaseURLContext);
    const navigate = useNavigate()
    const location = useLocation();
    const [code, setCode] = useState(0);
    const [status, setStatus] = useState(null)
    const email = location.state.email

    function handleChange(e) {
        setCode(e.target.value)
    }

    async function confirmAccount() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Email/ConfirmEmail`,
            {
                params: {
                    token: code,
                    email: email
                }
            }
        )
        if (response) {
            if(response.data === true){
                setStatus('success')
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            } else {
                setStatus('fail')
            }
        }
    }

    async function resendOtp(selectedMail) {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Email/ResendConfirmation`,
            {
                params: {
                    email: selectedMail
                }
            }
        )
        if (response) {
            if(response.data === true) {
                setStatus('resent')
            } else {
                setStatus('fail')
            }
        } else {
            setStatus('fail')
        }
    }

    return (
        <div>
            <Navbar/>
            <div className="account-form m-auto">
                <div className="ms-2 me-2 m-5">
                    <div className="d-grid gap-4 ms-4 me-4">
                        {status == null &&
                            (<div className="form-group">
                                <div className="form-group">
                                    <div >
                                        <h6>
                                            Mailinize gelen dogrulama kodunu girin
                                        </h6>
                                    </div>
                                    <div className="form-floating">

                                        <input
                                            type="number"
                                            name="username"
                                            style={{background:'lightgray'}}
                                            className="form-control"
                                            id="code"
                                            // placeholder=""
                                            value={code || ""}
                                            onChange={handleChange}
                                        />
                                        <button
                                            className="btn btn-primary m-2"
                                            onClick={() => {
                                                confirmAccount()
                                            }}
                                            type="button"
                                        >
                                            Dogrula
                                        </button>
                                        <button
                                            className="btn  btn-primary m-2"
                                            onClick={() => {
                                                resendOtp(email)
                                            }}
                                            type="button"
                                        >
                                            Tekrar Gonder
                                        </button>
                                    </div>
                                </div>
                            </div>)
                        }
                        {status === 'success' &&
                            (<div className="form-group">
                                <div className="form-group">
                                    <div >
                                        <h6>
                                            Hesabiniz basari ile aktiflestirildi. Anasayfaya yönlendiriliyorsunuz.
                                        </h6>
                                    </div>
                                </div>
                            </div>)
                        }
                        {status === 'fail' &&
                            (<div className="form-group">
                                <div className="form-group">
                                    <div >
                                        <h6>
                                            Bir sorun olustu. Lutfen info@hepsiotoemlak.com ile iletisime gecin.
                                        </h6>
                                    </div>
                                </div>
                            </div>)
                        }
                        {status === 'resent' &&
                            (<div className="form-group">
                                    <div className="form-group">
                                        <div >
                                            <h6>
                                                Kod tekrar basariyla gonderildi. Mailinize gelen dogrulama kodunu girin.
                                            </h6>
                                        </div>
                                        <div className="form-floating">

                                            <input
                                                type="number"
                                                name="username"
                                                style={{background:'lightgray'}}
                                                className="form-control"
                                                id="code"
                                                // placeholder=""
                                                value={code || ""}
                                                onChange={handleChange}
                                            />
                                            <button
                                                className="btn btn-primary m-2"
                                                onClick={() => {
                                                    confirmAccount(email)
                                                }}>
                                                Dogrula
                                            </button>
                                        </div>
                                    </div>
                                </div>)
                        }
                    </div>
                </div>
            </div>

        </div>
    )
        ;
}

export default RegisterSuccess;
