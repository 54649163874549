import React from "react";
import Logo from '../../img/emlak-logo2222.png'

const NavLogo = () => {
    const navlogo = {
        width: '200px',
        height: '40px'
    }
    const navlogoWrapper = {
        paddingTop: "0rem"
    }
    return (
        <div style={navlogoWrapper}>
            <div style={navlogo}>
                <img src={Logo} alt="logo" style={{width: '100%', height: '100%'}}/>
            </div>
        </div>
    );
}

export default NavLogo;
