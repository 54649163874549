import React from "react";
import Aydinlatma from '../../assets/documents/Aydınlatma Metni.docx'
import Kullanim from '../../assets/documents/Kullanım Koşulları.docx'
import Uyelik from '../../assets/documents/Üyelik Sözleşmesi.docx'

function Footer() {

    const footerListElement = {marginTop: '1rem', fontSize: '0.9em'}
    const footerListTopElement = {marginTop: '1rem', marginBottom: '1rem', fontSize: '0.9em'}

    return (
        <div style={{backgroundColor: "rgb(217, 213, 9)", color: "black"}}>
            <footer
                className="text-center text-lg-start bg-light text-muted"
                style={{backgroundColor: "rgb(217, 213, 9)", color: "black"}}
            >
                {/* <section
          className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
          style={{ backgroundColor: "rgb(217, 213, 9)", color: "black" }}
        >
          <div
            style={{ paddingTop: "5rem", backgroundColor: "rgb(235, 232, 52)" }}
          ></div>
        </section> */}

                <section style={{paddingTop: "1rem", backgroundColor: "rgb(217, 213, 9)", color: 'black'}}>
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            {/*<div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">*/}
                            {/*    <h6 className="text-uppercase fw-bold mb-4">*/}
                            {/*        <i className="fas fa-gem me-3"></i>HEPSİ OTO EMLAK*/}
                            {/*    </h6>*/}
                            {/*    <p>*/}
                            {/*        Hepsi oto emlak*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Kurumsal</h6>
                                <div style={footerListTopElement}>
                                    <a href={Aydinlatma} target='_blank' rel='noopener noreferrer'>
                                        Aydinlatma Metni
                                    </a>
                                </div>
                                <div style={footerListElement}>
                                    <a href={Kullanim} className="text-reset" target='_blank' rel='noopener noreferrer'>
                                        Kullanim Kosullari
                                    </a>
                                </div>
                                <div style={footerListElement}>
                                    <a href={Uyelik} className="text-reset" target='_blank' rel='noopener noreferrer'>
                                        Uyelik Sozlesmesi
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Site Haritasi</h6>
                                <div style={footerListTopElement}>
                                    <a href="#!" className="text-reset" target="_blank" rel="noreferrer">
                                        Vasita Ilanlari
                                    </a>
                                </div>
                                <div style={footerListElement}>
                                    <a href="#!" className="text-reset" target="_blank" rel="noreferrer">
                                        Emlak Ilanlari
                                    </a>
                                </div>
                                <div style={footerListElement}>
                                    <a href="#!" className="text-reset" target="_blank" rel="noreferrer">
                                        Vitrin
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">İletişim</h6>
                                <div style={footerListTopElement}>
                                    <a href="mailto:info@hepsiotoemlak.com" target="_blank" rel="noreferrer">
                                        <i className="fas fa-envelope me-3"></i>
                                        info@hepsiotoemlak.com
                                    </a>
                                </div>
                                {/*<div style={footerListElement}>*/}
                                {/*    <a href="https://wa.me/905555555555" target="_blank" rel="noreferrer">*/}
                                {/*        <i className="fas fa-print me-3"></i> + 90 555 555 55 55*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </section>
                <div
                    className="text-center p-4"
                    style={{backgroundColor: "rgb(217, 213, 9)", color: 'black'}}>
                    <p>
                        © 2022 Copyright&nbsp;&nbsp;
                    </p>

                    <a className="text-reset fw-bold" href="https://hepsiotoemlak.com/" target="_blank"
                       rel="noreferrer">
                        hepsiotoemlak.com
                    </a>
                </div>

            </footer>
        </div>
    );
}

export default Footer;
