import React from "react";
import { Outlet } from "react-router-dom";

const StartConversationLayout = () => {
    return (
        <div>

            <div style={{ padding: 20 }}>
                <Outlet />
            </div>
        </div>
    );
};

export default StartConversationLayout;
