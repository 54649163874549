import React, {useEffect, useState} from "react";
import Select from "react-select";

const options = [
    { value: "Elektrik", label: "Elektrik" },
    { value: "LPG", label: "LPG" },
    { value: "Benzin", label: "Benzin" },
    { value: "LPG & Benzin", label: "LPG & Benzin" },
    { value: "Dizel", label: "Dizel" },
    { value: "Elektrik", label: "Elektrik" },
    { value: "Hibrit", label: "Hibrit" },
]

const FuelType = ({ selectedFuelType,setSelectedFuelType,selectedLabel }) => {
    const [label, setLabel] = useState();
    useEffect(() => {

        if(selectedLabel != null) {
            for (let i = 0; i < options.length ; i++) {
                if(options[i].label === selectedLabel) {
                    setLabel(options[i])

                    // setExistedLabel
                }
            }
        }
    }, [selectedLabel])

    return (
        <>
            <Select
                options={options}
                value={label}
                onChange={(e) => {
                    setSelectedFuelType(e.label);
                    setLabel(e)
                }
                }
                placeholder="Yakit Tipi"
            />
        </>
    );
};

export default FuelType;
