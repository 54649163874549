import React, {useContext, useState} from "react";
import {useEffect} from "react";
import Select from "react-select";
import {defaultGetRequest, defaultGetRequestWithParams} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";

function EstateSubCategory({statusSelectOpt, setStatusSelectOpt, typeSelectOpt, setTypeSelectOpt}) {
    const {baseURL} = useContext(BaseURLContext);
    const [statusSelect, setStatusSelect] = useState([]);

    const [typeSelect, setTypeSelect] = useState([]);

    async function GetFilters() {
        const adTypesResponse = await defaultGetRequest(
            `${baseURL}/categories/GetEstateLevel2/`
        );
        if (adTypesResponse) {
            for (let i = 0; i < adTypesResponse.data.length; i++) {
                setStatusSelect((elem) => [...elem, {label: adTypesResponse.data[i], value: adTypesResponse.data[i]}])
            }
        } else {
            console.log("no response");
        }
        const famTypesResponse = await defaultGetRequest(
            `${baseURL}/categories/GetEstateLevel1/`
        );
        if (famTypesResponse) {
            for (let i = 0; i < famTypesResponse.data.length; i++) {
                setTypeSelect((elem) => [...elem, {label: famTypesResponse.data[i], value: famTypesResponse.data[i]}])
            }
        } else {
            console.log("no response");
        }
    }

    useEffect(() => {
        GetFilters()
    }, [])

    const handleStatusSelectChange = (obj) => {
        setStatusSelectOpt(obj);
    };

    const handleTypeSelectChange = (obj) => {
        setTypeSelectOpt(obj);
    };

    return (
        <div className="App">
            <div className="m-1 d-flex ">
                {typeSelect && (<div style={{width: '200px'}} className="m-1">
                    <b>Tipi</b>
                    <Select
                        placeholder="Seçim yapın"
                        value={typeSelectOpt}
                        options={typeSelect || ''}
                        onChange={handleTypeSelectChange}
                    />
                </div>)}
                <br/>
                {statusSelect && (<div style={{width: '200px'}} className="m-1 ">
                    <b>Durumu</b>
                    <Select
                        placeholder="Seçim yapın"
                        value={statusSelectOpt}
                        options={statusSelect || ''}
                        onChange={handleStatusSelectChange}
                    />
                </div>)
                }


            </div>
        </div>
    );
}

export default EstateSubCategory;
