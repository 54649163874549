import React, {useState, Fragment, useEffect} from "react";
import MarkerIcon from '../../img/Map-Pin.svg'

// We will use these things from the lib
// https://react-google-maps-api-docs.netlify.com/
import {
    useLoadScript,
    GoogleMap,
    Marker
} from "@react-google-maps/api";
import Navbar from "../Layout/Navbar";

// import {getLocation} from "../../static/functions";


function GeoLocationView({
                             latitudeExisting,
                             setLatitudeExisting,
                             longitudeExisting,
                             setLongitudeExisting,
                             initialLocationExisting,
                             center,
                             setCenter
                         }) {

    // The things we need to track in state
    const [mapRef, setMapRef] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [markerMap, setMarkerMap] = useState({});
    // const [center, setCenter] = useState({lat: 44.076613, lng: -98.362239833});
    const [zoom, setZoom] = useState(5);
    const [clickedLatLng, setClickedLatLng] = useState(null);
    const [infoOpen, setInfoOpen] = useState(false);

    const {isLoaded} = useLoadScript({
        // Enter your own Google Maps API key
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });

    // The places I want to create markers for.
    // This could be a data-driven prop.
    const myPlaces = [
        {id: 0, pos: {lat: latitudeExisting, lng: longitudeExisting}}
    ];

    function handleCenterChanged() {
        if (!mapRef?.current) return;
        const newPos = mapRef?.current?.getCenter().toJSON();
        setCenter(newPos);
    }

    // Iterate myPlaces to size, center, and zoom map to contain all markers
    const fitBounds = map => {
        map.setZoom(15);
        const bounds = new window.google.maps.LatLngBounds();
        myPlaces.map(place => {
            bounds.extend(place.pos);
            return place.id;
        });
        // map.fitBounds(bounds);
        // map.setCenter()
    };

    const loadHandler = map => {
        // Store a reference to the google map instance in state
        setMapRef(map);
        // Fit map bounds to contain all markers
        fitBounds(map);
    };

    // We have to create a mapping of our places to actual Marker objects
    const markerLoadHandler = (marker, place) => {
        return setMarkerMap(prevState => {
            // console.log('prevState', marker, place)
            return {...prevState, [place.id]: marker};
        });
    };

    const markerClickHandler = (event, place) => {
        setSelectedPlace(place);


        // Required so clicking a 2nd marker works as expected
        if (infoOpen) {
            setInfoOpen(false);
        }

        // if you want to center the selected Marker
        setCenter(place.pos)
    };

    // function setLocationParameters(id, lat, lng) {
    //     setLatitudeExisting(lat);
    //     setLongitude(lng);
    //     setInitialLocation({id: id, pos: {lat: lat, lng: lng}})
    //     // setInitialExistingLocation({id: id, pos: {lat: lat, lng: lng}})
    //     setCenter({lat: lat, lng: lng})
    // }

    // useEffect(() => {
    //     console.log('clickedLatLng', clickedLatLng)
    //     // console.log('initialExistingLocation   ', initialExistingLocation)
    //     var count = parseInt((parseInt(initialLocation?.pos?.id) || 0) + 1);
    //     var count = parseInt((parseInt(initialLocation?.pos?.id) || 0) + 1);
    //     setInitialLocation({id: count, pos: {lat: latitude, lng: longitude}})
    //     setCenter({lat: latitude, lng: longitude})
    //     setZoom(14);
    //
    // }, [])

    useEffect(() => {
        // console.log(' we shoulde be here only once')
        setZoom(5)
        if (initialLocationExisting != null) {
            setLatitudeExisting(initialLocationExisting?.pos?.lat)
            // setLatitudeExisting(initialExistingLocation?.pos?.lat)
            setLongitudeExisting(initialLocationExisting?.pos?.lng)
            // setLocationParameters(0, initialLocation?.pos?.lat, initialLocation?.pos?.lng)
            // setLongitudeExisting(initialExistingLocation?.pos?.lng)
            // setInitialExistingLocation({id: 0, pos: {lat: initialExistingLocation?.pos?.lat, lng: initialExistingLocation?.pos?.lng}})
            setCenter({lat: initialLocationExisting?.pos?.lat, lng: initialLocationExisting?.pos?.lng})
        }
    }, [])


    const renderMap = () => {
        return (
            <Fragment>
                {center &&
                    (<GoogleMap
                        // Do stuff on map initial laod
                        onLoad={loadHandler}
                        // Save the current center position in state
                        // onCenterChanged={() => setCenter(mapRef?.getCenter().toJSON())}
                        // Save the user's map click position
                        onCenterChanged={() => {
                            handleCenterChanged()
                        }}
                        onClick={e => setClickedLatLng(e.latLng.toJSON())}
                        center={center}
                        zoom={zoom}
                        mapContainerStyle={{
                            height: "70vh",
                            width: "100%"
                        }}
                        on
                    >
                        {initialLocationExisting &&
                            (
                                <Marker
                                    key={initialLocationExisting.id}
                                    // clusterer={clusterer}
                                    position={initialLocationExisting.pos}
                                    onLoad={marker => markerLoadHandler(marker, initialLocationExisting)}
                                    onClick={event => markerClickHandler(event, initialLocationExisting)}
                                    // Not required, but if you want a custom icon:
                                    // icon={{
                                    //     path:
                                    //         'https://hepsiotoemlak.com/static/media/Map-Pin.svg',
                                    //     fillColor: "#000000",
                                    //     fillOpacity: 0.5,
                                    //     strokeWeight: 0,
                                    //     scale: 1.25
                                    // }}
                                />
                            )
                        }
                    </GoogleMap>)
                }
                {/*/!* Our center position always in state *!/*/}
                {/*<h3>*/}
                {/*    Center {center.lat}, {center.lng}*/}
                {/*</h3>*/}

                {/*/!* Position of the user's map click *!/*/}
                {/*{clickedLatLng && (*/}
                {/*    <h3>*/}
                {/*        You clicked: {clickedLatLng.lat}, {clickedLatLng.lng}*/}
                {/*    </h3>*/}
                {/*)}*/}

                {/*/!* Position of the user's map click *!/*/}
                {/*{selectedPlace && <h3>Selected Marker: {selectedPlace.id}</h3>}*/}
            </Fragment>
        );
    };

    return isLoaded ? renderMap() : null;
}

export default GeoLocationView