import React from "react";

const BuildingView = ({
                          hrStyle,
                          FlatCountPerFloor,
                          FloorCount,
                          BuildingAge
                      }) => {
    return (
        <div>
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Bir Kattaki Daire Sayısı</strong>
                </label>

                <span className="col-md-8 ad-detail">{FlatCountPerFloor}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Kat Sayısı</strong>
                </label>
                <span className="col-md-8  ad-detail">{FloorCount}</span>
            </div>
            <hr
                style={hrStyle}
            />
            <div
                className="price-detail-wrap row"
            >
                <label className="col-md-4" style={{color: "black"}}>
                    <strong>Bina Yaşı</strong>
                </label>

                <span className="col-md-8  ad-detail">{BuildingAge}</span>
            </div>
        </div>
    );
}

export default BuildingView;
