import React, {useContext, useEffect, useState} from "react";
import {Tab, Nav, Row, Col, Modal} from "react-bootstrap";
import {Card} from "antd";
import {protectedEnpointGetRequest, protectedEnpointGetRequestWithParams} from "../../static/functions";
import {SetPassiveAd, SetActiveAd} from "../../static/adStateFunctions";
import {BaseURLContext} from "../../baseURL-context";
import Cookies from "universal-cookie";
import {Link, useNavigate} from "react-router-dom";
import '../../assets/css/table.css'
import NoImage from '../../img/no-image.png'
import {setAdTitleStringLimit, useWindowSize} from "../../static/helpers";

const AdOverviewUnpublished = () => {
    // setActiveEstates, setPassiveEstates, setActiveCars, setPassiveCars
    const {baseURL} = useContext(BaseURLContext);
    const [show, setShow] = useState('published');
    // const [count, setCount] = useState(0);
    const [accountType, setAccountType] = useState();
    const [activeEstates, setActiveEstates] = useState([]);
    const [passiveEstates, setPassiveEstates] = useState([]);
    const [activeCars, setActiveCars] = useState([]);
    const [passiveCars, setPassiveCars] = useState([]);
    const [itemToDelete, setItemToDelete] = useState({id:'',type:''});
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt")
    const navigate = useNavigate();
    const size = useWindowSize()

    //modal
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    useEffect(() => {
        GetAllMembership()
        setShow("unpublished")
    }, [])

    // useEffect(() => {
    // }, [GetUserActivity])


    async function editAd(id, type) {
        if (type === 0) {
            navigate('/emlak-duzenle', {
                state: {
                    id: id
                }
            })
        } else if (type === 1) {
            navigate('/vasita-duzenle', {
                state: {
                    id: id
                }
            })
        }
    }


    async function SetActive(id, type) {
        console.log('id : ', id, 'type : ', type)
        const response = await SetActiveAd(id, type);
        if (response) {
            navigate('/ilanlarim');
            window.location.reload();
            // await GetAllMembership()
            // if(activity) {
            //     // console.log('activity ',activity)
            //     // setCount(activity.countLeft)
            //     // window.location.reload();
            // }
            // setCount(response.data.countLeft);
            // setAccountType(response.data.type);
        }
    }

    async function removeEstate(id) {

        const response = await protectedEnpointGetRequestWithParams(
            `${baseURL}/Estate/RemoveEstate`,
            {
                id: parseInt(id)
            },
            cookie
        );
        if (response) {
            window.location.reload()
        }
    }

    async function removeCar(id) {

        const response = await protectedEnpointGetRequestWithParams(
            `${baseURL}/Car/RemoveCar`,
            {
                id: parseInt(id)
            },
            cookie
        );
        if (response) {
            window.location.reload()
        }
    }

    async function SetPassive(id, type) {

        const response = await SetPassiveAd(id, type);
        if (response) {
            // await GetAllMembership();
            navigate('/ilanlarim');
            window.location.reload();
            // const activity = await GetUserActivity(id,type)
            // if(activity) {
            //     console.log('activity ',activity)
            //     setCount(activity.countLeft)
            // }
            // console.log('setaccount response', JSON.stringify(response.data))
            // setCount(response.data);
        }
    }


    async function GetAllMembership() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Membership/GetAllMembership`,
            cookie
        );
        if (response) {
            // console.log("response adoveview : ", response.data.all.activeEstates)
            // setCount(response.data.availableAdPublish)
            setAccountType(response.data.accountType)
            setActiveEstates(response.data.all.activeEstates)
            setActiveCars(response.data.all.activeCars)
            setPassiveEstates(response.data.all.passiveEstates)
            setPassiveCars(response.data.all.passiveCars)
        }
    }

    const customButton = {
        // width:'150px'
    }

    function handleRemove() {
        if (itemToDelete.type === '0')
            removeEstate(itemToDelete.id)
        else if (itemToDelete.type === '1')
            removeCar(itemToDelete.id)
    }

    return (
        <div>
            <div style={{background: 'black', width: '600px', height: '1px', margin: '0 auto'}}>

            </div>
            <Row style={{marginTop: '2rem'}}>
                {show === 'unpublished' && (<Row>
                    <Col md={6}>
                        <h3>Emlak İlanları</h3>
                        <table className="table table-image">
                            <tbody>
                            {passiveEstates && passiveEstates.map((item, i) => {
                                return (
                                    <tr key={i} className="d-flex  m-1 row h-100">
                                        {/*<td className="col-md-1">{i}</td>*/}
                                        <td className={`col-md-2 ${size?.width < 680 ? `w-100` : `w-25`}`}>
                                            <Link to={`/emlak/${item.id}`}>
                                                {item?.smallImage !== null ? (<img style={{width: '63%'}}
                                                                                   src={item?.smallImage}
                                                                                   alt="test"/>) :
                                                    <div>
                                                        <img src={NoImage} alt="test" style={{width: '63%'}}/>
                                                    </div>}
                                            </Link>
                                        </td>
                                        <td className="col-md-3">
                                            <Link to={`/emlak/${item.id}`} style={{float: 'left'}}>
                                                {setAdTitleStringLimit(item.name)}
                                            </Link>
                                        </td>
                                        {/*<td className="col-md-2">{item.price}</td>*/}
                                        <td className={`col-md-2`}>
                                            <button
                                                // style={customButton}
                                                className="rounded-0 customButtonActive"
                                                onClick={async () => {
                                                    await SetActive(item.id, 0);
                                                }}
                                                type="submit"
                                            >
                                                Yayınla
                                            </button>
                                        </td>
                                        <td className="col-md-2">
                                            <button
                                                // style={customButton}
                                                className="rounded-0 customButtonEdit"
                                                onClick={async () => {
                                                    await editAd(item.id, 0);
                                                }}
                                                type="submit"
                                            >
                                                Duzenle
                                            </button>
                                        </td>
                                        <td className="col-md-2">
                                            <button
                                                // style={customButton}
                                                className="rounded-0 customButtonDelete"
                                                onClick={() => {
                                                    setItemToDelete({id:item.id.toString(),type:'0'})
                                                    handleShow()
                                                    // removeEstate();
                                                }}
                                                type="submit"
                                            >
                                                Sil
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </Col>
                    <Col md={6}>
                        <h3>Vasıta İlanları</h3>
                        <table className="table table-image">
                            <tbody>
                            {passiveCars && passiveCars.map((item, i) => {
                                return (

                                    <tr key={i} className="d-flex m-1 row h-100">
                                        {/*<td className="col-md-1">{i}</td>*/}
                                        <td className={`col-md-2 ${size?.width < 680 ? `w-100` : `w-25`}`}>
                                            <Link to={`/vasita/${item.id}`}>
                                                {item?.smallImage !== null
                                                    ? (<img style={{width: '63%'}}
                                                            src={item?.smallImage}
                                                            alt="test"/>) :
                                                    <div>
                                                        <img src={NoImage} alt="test" style={{width: '63%'}}/>
                                                    </div>}
                                            </Link>
                                        </td>
                                        <td className="col-md-3">
                                            <Link to={`/vasita/${item.id}`}>
                                                {setAdTitleStringLimit(item.name)}
                                            </Link>
                                        </td>
                                        <td className="col-md-2 ">
                                            <button
                                                className="rounded-0 customButtonActive"
                                                onClick={async () => {
                                                    await SetActive(item.id, 1);
                                                }}
                                                type="submit"
                                            >
                                                Yayınla
                                            </button>
                                        </td>
                                        <td className="col-md-2 ">
                                            <button
                                                // style={customButton}
                                                className="rounded-0 customButtonEdit"
                                                onClick={async () => {
                                                    await editAd(item.id, 1);
                                                }}
                                                type="submit"
                                            >
                                                Duzenle
                                            </button>
                                        </td>
                                        <td className="col-md-2 ">
                                            <button
                                                // style={customButton}
                                                className="rounded-0 customButtonDelete"
                                                onClick={() => {
                                                    setItemToDelete({id:item.id.toString(),type:'0'})
                                                    // await removeCar(item.id);
                                                    handleShow()
                                                }}
                                                type="submit"
                                            >
                                                Sil
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </Col>
                </Row>)}
            </Row>
            <Modal style={{background: 'white', minHeight: '15rem'}} show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Silme Islemini Dogrulayim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*{resp != null && (*/}
                    {/*    <div style={{width: '90vw', height: '10vh', display: ''}}*/}
                    {/*         dangerouslySetInnerHTML={{__html: `${resp}`}}>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <div className="d-flex justify-content-center">
                        <h6>Silmek istediğinize emin misiniz?</h6>
                        {/*<div style={{width: '90vw', height: '10vh'}}*/}
                        {/*     dangerouslySetInnerHTML={{__html: `${resp}`}}>*/}
                        {/*</div>*/}
                    </div>
                    <div className="m-1">
                        <div
                            className="btn btn-danger rounded-0"
                            style={{backgroundColor: "darkred", color: "white"}}
                            onClick={() => {
                                handleRemove()
                            }}>
                            Sil
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
        ;
}

export default AdOverviewUnpublished;
