import React, {useContext, useEffect, useState} from "react";
import {protectedEnpointPostRequest} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";
import Cookies from "universal-cookie";

const TestPayment = () => {
    const [resp, setResp] = useState(null)
    const {baseURL} = useContext(BaseURLContext);
    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")

    // let myRef = React.useRef();
    // const [body, setBody] = React.useState("");
    //
    // const click = async () => {
    //     const response = await SendPayment()
    //     if(response) {
    //     setBody(myRef.current.innerHTML);
    //
    //     }
    // };

    // function OpenNewWindow() {
    //     const win = window.open("", "Test Payment", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=" + (window.screen.height - 400) + ",left=" + (window.screen.width - 840));
    //     win.document.body.innerHTML = resp;
    // }

    const testObject = {
        test: ''
    }

    async function SendPayment() {
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Payment/SendPayment/`,
            testObject,
            cookie
        )

        if (response) {
            console.log("kuveyt turk api response: ", response.data)
            setResp(response.data.replace('noscript', 'div'))
            // OpenNewWindow()
        }
    }

    // useEffect(() => {
    //     SendPayment()
    // }, [])
    return (
        <div>
            {resp && (
                <div style={{width: '90vw', height: '90vh', display: ''}} dangerouslySetInnerHTML={{__html: `${resp}`}}>

                </div>)}
            <div onClick={() => {
                SendPayment()
            }}>TEST
            </div>

            {/*<h1 ref={myRef} contentEditable={true} suppressContentEditableWarning={true}> rewrite me!</h1>*/}

            {/*<button onClick={click}> CLICK!</button>*/}
            {/*<h1>{body}</h1>*/}
        </div>
    );
}

export default TestPayment;
