import React, {useEffect, useContext, useState} from "react";
import useForm from "../useForm/useForm";
// import useAuth from "../../Components/useAuth/useAuth";
import {BaseURLContext} from "../../baseURL-context";
import {
    protectedEnpointPostRequest,
    protectedEnpointGetRequest, protectedEnpointGetRequestWithParams,
} from "../../static/functions";
import Cookies from "universal-cookie";
import UploadProfilePhoto from "./UploadProfilePhoto";
import {Button, Modal} from "react-bootstrap";
import {useNavigate} from "react-router";
// import City from "../Search/Filters/City";

// function ProfileBody(userDetail) {
const ProfileView = () => {
    const {values, errors, handleChange, handleSubmit} = useForm(null);
    const {baseURL} = useContext(BaseURLContext);
    const navigate = useNavigate()
    const [user, setUser] = useState([]);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [username, setUsername] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [address, setAddress] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedTown, setSelectedTown] = useState("");
    const [companyShortName, setCompanyShortName] = useState("");
    const [companyOfficialName, setCompanyOfficialName] = useState("");
    const [profilePicture, setProfilePicture] = useState("");
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt");

    const [show, setShow] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseRemove = () => setShowRemove(false);
    const handleShow = () => setShow(true);
    const handleShowRemove = () => setShowRemove(true);



    const userData = {
        firstname: firstname,
        lastname: lastname,
        username: username,
        phonenumber: phonenumber,
        city: city,
        province: province,
        address: address,
        companyShortName: companyShortName,
        oldPassword: values.oldPassword,
        password: values.password,
        verifyPassword : values.verifyPassword
    };


    useEffect(() => {
        getUserDetails();
    }, []);

    async function getUserDetails() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/UserManage/GetUserDetails`,
            cookie
        );

        if (response) {
            setUser(response.data);
            setAddress(response.data.address)
            setCity(response.data.city)
            setCompanyOfficialName(response.data.companyOfficalName)
            setCompanyShortName(response.data.companyShortName)
            setFirstname(response.data.firstName)
            setLastname(response.data.lastName)
            setPhonenumber(response.data.phoneNumber)
            setProvince(response.data.province)
            setProfilePicture(response.data?.profilePicture || "https://www.pedigreecatworld.co.uk/wp-content/uploads/2019/05/customers-icon-3.png");
        }
    }

    async function updateUser() {
        const response = await protectedEnpointPostRequest(
            `${baseURL}/UserManage/UpdateUser/`,
            userData,
            cookie
        );
        if (response) {
            if(response.data === "success") {
                handleShow()
            }
        }
        return !!response;
    }
    async function removeUser() {

        const response = await protectedEnpointGetRequestWithParams(
            `${baseURL}/UserManage/RemoveUser/`,
            {
                email: Email
            },
            cookie
        );
        if (response) {

            navigate("/hesap-silindi")
        }
        return !!response;
    }

    const FirstName = user.firstName;
    const LastName = user.lastName;
    const UserName = user.username;
    const Email = user.email;
    const PhoneNumber = user.phoneNumber;
    const City = user.city;
    const Province = user.province;
    const Address = user.address;
    const CompanyShortName = user?.companyShortName;
    const CompanyOfficialName = user?.companyOfficialName;


    return (
        <div>
            <div className=" bg-white">
                <div className="card-body media align-items-center row">

                    {profilePicture &&
                        (
                            <div className="col-md-4">
                                <img
                                     src={profilePicture} alt={username}
                                     style={{maxWidth: '100px'}}
                                     className="d-block ui-w-100"
                                />
                            </div>
                        )}

                    <div className="col-md-4">
                        <UploadProfilePhoto/>
                    </div>

                </div>
                <hr className="border-light m-0"/>

                <div className="card-body ">
                    <div className="form-group">
                        <label className="form-label">Kullanıcı Adınız</label>
                        <input
                            type="text"
                            className="form-control mb-1"
                            placeholder="Kullanıcı Adınız"
                            value={username || UserName}
                            onChange={(e) => setUsername(e.target.value)}
                            disabled/>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Adınız</label>
                        <input
                            type="text"
                            className="form-control mb-1"
                            id="firstname"
                            name="firstname"
                            placeholder="Adınız"
                            value={firstname || FirstName}
                            onChange={(e) => setFirstname(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Soyadınız</label>
                        <input
                            type="text"
                            className="form-control mb-1"
                            id="firstname"
                            name="firstname"
                            placeholder="Adınız"
                            value={lastname || LastName}
                            onChange={(e) => setLastname(e.target.value)}
                        />
                    </div>
                    {/*<div className="form-group">*/}
                    {/*    <label className="form-label">E-mail</label>*/}
                    {/*    <input type="text" className="form-control mb-1"*/}
                    {/*           value="nmaxwell@mail.com"/>*/}
                    {/*    <div className="alert alert-warning mt-3">*/}
                    {/*        Your email is not confirmed. Please check your inbox.<br/>*/}
                    {/*        <button>Resend confirmation</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="form-group">
                        <label className="form-label">Telefon Numaranız</label>
                        <input
                            type="text"
                            className="form-control mb-1"
                            placeholder="Telefon Numaranız"
                            value={phonenumber || PhoneNumber}
                            onChange={(e) => setPhonenumber(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        {/*{selectedCity && selectedTown && (<City*/}
                        {/*    selectedCity={selectedCity}*/}
                        {/*    setSelectedCity={setSelectedCity}*/}
                        {/*    selectedTown={selectedTown}*/}
                        {/*    setSelectedTown={setSelectedTown}*/}
                        {/*/>)}*/}
                        <label className="form-label">İl</label>
                        <input
                            type="text"
                            className="form-control mb-1"
                            placeholder="İl"
                            value={city || City}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label">İlçe</label>
                        <input
                            type="text"
                            className="form-control mb-1"
                            placeholder="İlçe"
                            value={province || Province}
                            onChange={(e) => setProvince(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Adres Bilgileri</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Adres Bilgileri"
                            value={address || Address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                </div>

                <div className="card-body">
                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            name="oldPassword"
                            className="form-control"
                            id="password-old"
                            placeholder="oldPassword"
                            value={values.oldPassword || ""}
                            onChange={handleChange}
                        />
                        <label htmlFor="password-ind">
                            Eski Şifre
                        </label>
                        {/*<p className="errors">*/}
                        {/*    {errors.password ? `${errors.password}` : null}*/}
                        {/*</p>*/}
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="password-ind"
                            placeholder="password"
                            value={values.password || ""}
                            onChange={handleChange}
                        />
                        <label htmlFor="password-ind">
                            Şifre
                        </label>
                        <p className="errors">
                            {errors.password ? `${errors.password}` : null}
                        </p>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            name="verifyPassword"
                            className="form-control"
                            id="verifyPassword-ind"
                            placeholder="verifyPassword"
                            value={values.verifyPassword || ""}
                            onChange={handleChange}
                        />
                        <label htmlFor="verifyPassword-ind">
                            Şifreyi Doğrulayın
                        </label>
                        <p className="errors">
                            {errors.verifyPassword ? `${errors.verifyPassword}` : null}
                        </p>
                    </div>
                </div>
                <div className="mt-5 text-center">
                    <button
                        className="mb-4 btn btn-primary profile-button"
                        onClick={() => updateUser()}
                        type="button"
                    >
                        Kaydet
                    </button>
                </div>
                <div className="mt-5 text-center">
                    <button
                        style={{
                            background:'red'
                        }}
                        className="mb-4 btn btn-danger profile-remove-button"
                        onClick={() => handleShowRemove()}
                        type="button"
                    >
                        Hesabımı Sil
                    </button>
                </div>

            </div>
            <Modal style={{background: 'white', minHeight: '15rem'}} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Profiliniz Kaydedildi.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*{resp != null && (*/}
                    {/*    <div style={{width: '90vw', height: '10vh', display: ''}}*/}
                    {/*         dangerouslySetInnerHTML={{__html: `${resp}`}}>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <div className="d-flex justify-content-center">
                        <h6>Değişiklikler başarıyla kaydedildi.</h6>
                        <div style={{width: '90vw', height: '10vh'}}>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal style={{background: 'white', minHeight: '15rem'}} show={showRemove} onHide={handleCloseRemove}>
                <Modal.Header closeButton>
                    <Modal.Title>Kullanıcınızı silmek istediğinize emin misiniz:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="red" className="bg-red" variant="danger" onClick={removeUser}>
                        Sil
                    </Button>
                    <Button variant="secondary" onClick={handleCloseRemove}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
        ;
};

export default ProfileView;
