import React, {useEffect, useState} from "react";
import Rooms from "../Rooms";
import Heating from "../Heating";
import InUsage from "../InUsage";
import Ownership from "../Ownership";
import AvailableForCredit from "../AvailableForCredit";
import WithFurniture from "../WithFurniture";
import Front from "../Front";

const House = ({
                   setBuildingage,
                   setFloor,
                   setTotalfloor,
                   setBalcony,
                   setBathrooms,
                   setMonthlyextra,
                   setSelectedRooms,
                   setSelectedHeating,
                   setSelectedUsage,
                   selectedRooms,
                   selectedHeating,
                   selectedUsage,
                   withFurniture,
                   setWithFurniture,
                   setAvailableForCredit,
                   availableForCredit,
                   selectedOwnership,
                   setSelectedOwnership,
                   setFrontSide,
                   categoryType,
                   handleChange,
                   buildingage,
                   floor,
                   totalfloor,
                   balcony,
                   bathrooms,
                   monthlyextra,
                   estateObject

               }) => {


    return (
        <div>
            <div className="form-floating mb-3 row">
                <div className="col-md-2">
                    <label htmlFor="rooms">
                        <strong> Oda Sayısı</strong>
                    </label>
                    <Rooms
                        setSelectedRooms={setSelectedRooms}
                        selectedRooms={selectedRooms}
                        selectedLabel={estateObject?.rooms || "Seçiniz"}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-2">
                    <label htmlFor="buildingage" className="float-start">
                        <strong>Bina Yaşı</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        //   max="100"
                        value={buildingage}
                        name="buildingage"
                        className="form-control"
                        id="buildingage"
                        placeholder="Bina Yaşı"
                        onChange={(e) => {
                            setBuildingage(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-2">
                    <label htmlFor="floor" className="float-start">
                        <strong>Kat</strong>
                    </label>
                    <input
                        type="number"
                        min="-10"
                        name="floor"
                        value={floor}
                        className="form-control"
                        id="floor"
                        placeholder="Kat"
                        onChange={(e) => {
                            setFloor(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3 ">
                <div className="col-md-3">
                    <label htmlFor="totalfloor" className="float-start">
                        <strong>Kat Sayısı</strong>
                    </label>
                    <input
                        type="number"
                        name="totalfloor"
                        min="0"
                        value={totalfloor}
                        className="form-control"
                        id="totalfloor"
                        placeholder="Kat Sayısı"
                        onChange={(e) => {
                            setTotalfloor(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="heating">
                        <strong>Isınma</strong>{" "}
                    </label>
                    <Heating
                        setSelectedHeating={setSelectedHeating}
                        selectedHeating={selectedHeating}
                        selectedLabel={estateObject?.heating || "Seçiniz"}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="balcony" className="float-start">
                        <strong>Balkon Sayısı</strong>{" "}
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="balcony"
                        className="form-control"
                        id="balcony"
                        value={balcony}
                        placeholder="Balkon Sayısı"
                        onChange={(e) => {
                            setBalcony(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="balcony" className="float-start">
                        <strong>Banyo Sayısı</strong>{" "}
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="bathrooms"
                        value={bathrooms}
                        className="form-control"
                        id="bathrooms"
                        placeholder="Banyo Sayısı"
                        onChange={(e) => {
                            setBathrooms(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="checkbox mb-3">
                <div className="col-md-3">
                    <label htmlFor="ownership">
                        {" "}
                        <strong>Eşyalı</strong>
                    </label>
                    <WithFurniture
                        withFurniture={withFurniture}
                        setWithFurniture={setWithFurniture}
                        selectedLabel={estateObject?.withFurniture === true ? 'Evet' : 'Hayır'}
                    />
                </div>
            </div>
            <div className="checkbox mb-3">
                <div className="col-md-4">
                    <label htmlFor="frontside" className="d-flex" style={{flexDirection:'column'}}>
                        {" "}
                        <strong>Cephe</strong>
                        <Front
                            setFrontSide={setFrontSide}
                            selectedLabel={estateObject?.frontSide || "Seçiniz"}
                        />
                    </label>
                </div>
            </div>

            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="monthlyextra" className="float-start">
                        <strong> Aidat</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="monthlyextra"
                        value={monthlyextra}
                        className="form-control"
                        id="monthlyextra"
                        placeholder="Aidat"
                        onChange={(e) => {
                            setMonthlyextra(e.target.value)
                        }}
                    />
                </div>
            </div>
            {categoryType !== 'Kiralık' && <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="ownership">
                        {" "}
                        <strong>Krediye Uygun</strong>
                    </label>
                    <AvailableForCredit
                        availableForCredit={availableForCredit}
                        setAvailableForCredit={setAvailableForCredit}
                        selectedLabel={estateObject?.creditStatus === true ? 'Evet' : 'Hayır'}
                    />
                </div>
            </div>}
            <div className="checkbox mb-3">
                <div className="col-md-4">
                    <label htmlFor="ownership" className="d-flex" style={{flexDirection:'column'}}>
                        {" "}
                        <strong>Tapu Durumu</strong>
                        <Ownership
                            setSelectedOwnership={setSelectedOwnership}
                            selectedLabel={estateObject?.ownership || "Seçiniz"}
                        />
                    </label>
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="inusage">
                        <strong> Kullanım Durumu</strong>
                    </label>
                    <InUsage
                        setSelectedUsage={setSelectedUsage}
                        selectedUsage={selectedUsage}
                        onChange={handleChange}
                        selectedLabel={estateObject?.inUsage || "Seçiniz"}
                    />
                </div>
            </div>


        </div>
    );
}

export default House;
