import React from "react";
import Register from "../Components/Register/Register";
import NavbarOut from "../Components/Layout/NavbarOut";

function App() {
  return (
    <div>
      <div>
        <NavbarOut/>
      </div>
      <div>
        <Register />
      </div>
    </div>
  );
}

export default App;
