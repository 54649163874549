import React, {useState} from "react";
import Navbar from '../../../Layout/NavbarIn'
import CreateCarForm from "./CreateCarForm";
import Success from "../../Success";
import AdPayment from "./Payment";
import CarPreview from "../View/CarPreview";
import SelectCarNew from "./SelectCarNew";

const StartCreateCar = () => {
    const [state, setState] = useState("start");
    const [carData, setCarData] = useState();
    const [carInfo, setCarInfo] = useState([])
    const [images, setImages] = useState([])
    const [doping, setDoping] = useState(1);
    const [formData, setFormData] = useState();
    const [carType, setCarType] = useState(0);
    const [carModel, setCarModel] = useState(0);
    const [id, setId] = useState(0);
    const [carCategoryId, setCarCategoryId] = useState(0);
    const [carObject, setCarObject] = useState([]);

    //payment
    const [amount, setAmount] = useState("")
    const [resp, setResp] = useState(null)

    const goStart = () => {
        setState("start");
    };
    const goForm = (e) => {
        setState("form");
    };
    const changeState = (e) => {
        setState(e);
    };
    const type = 1;
    // function setBreadcrumb(array) {
    //   for (var i = 0; i < array.length; i++) {
    //     breadcrumbs.push(array[i]);
    //   }
    // }

    // function setLastBreadcrumb(lastCrumb) {
    //   if (breadcrumbs.length < 3) {
    //     setBreadcrumbs((breadcrumbs) => [...breadcrumbs, lastCrumb]);
    //   }
    //   //     setBreadcrumbs((breadcrumbs) => [...breadcrumbs, lastCrumb]);
    // }

    return (
        <div>
            <div>
                <Navbar logoStatus={true}/>
            </div>
            <div style={{minHeight: "70vh", marginTop: "5rem"}}>
                {/* <CreateCarForm /> */}
                {state === "start" && (
                    <SelectCarNew
                        state={state}
                        nextPage={goForm}
                        setCarModel={setCarModel}
                        setCarType={setCarType}
                        setCarCategoryId={setCarCategoryId}
                        setCarInfo={setCarInfo}
                        // setBreadcrumbs={setBreadcrumbs}
                    />
                )}
                {state === "form" && (
                    <div>
                        <CreateCarForm
                            goBack={goStart}
                            nextPage={changeState}
                            carCategoryId={carCategoryId}
                            setCarData={setCarData}
                            setImages={setImages}
                            setFormData={setFormData}
                            doping={doping}
                            setDoping={setDoping}
                            carType={carType}
                            // carInfo={carInfo}
                            // advantages={advantages}
                            // setProductId={setProductId}
                            // breadcrumbs={breadcrumbs}
                        />
                        {console.log('carType ',carType)}
                    </div>
                )}
                {state === 'payment' && <AdPayment data={carData} doping={doping} state={changeState}/>}
                {state === 'no-payment' &&
                    <CarPreview setId={setId} carData={carData} images={images} formData={formData} state={changeState}
                                goForm={goForm}/>}
                {/*{state === 'payment-done' &&*/}
                {/*    <CarPreview setId={setId} carData={carData} images={images} formData={formData} state={changeState}/>}*/}
                {state === 'success' && <Success id={id} type={type} doping={doping} amount={amount} setAmount={setAmount}
                                                 resp={resp} setResp={setResp} />}
                {/* {productId !== undefined ? setState("images" ) : setState("form")} */}

                {/* {productId !== undefined &&
          <ImageUpload
            // goNext={goStart}
            goBack={goForm}
            productId={productId}
             />} */}
                {/* {state === "info" && (
          <CreateForm
            goBack={goStart}
            goImages={goImages}
            state={state}
            category={category}
            //     setCategory={setCategory}
          />
        )}
        {state === "images" &&
        <AddImages 
        goNext={goInfo}
        // goBack={goStart}
        />} */}
                {/* <p>this is from child {category}</p> */}
            </div>
            {/* <Footer /> */}
        </div>
    );
};

export default StartCreateCar;
