import {protectedEnpointPostRequest} from "./functions";
import Cookies from "universal-cookie";
// import {BaseURLContext} from "../baseURL-context";


const cookies = new Cookies()
const cookie = cookies.get("hoe-jwt")
const baseURL = process.env.REACT_APP_API + "/api";
// const baseURL = "https://hepsiotoemlak.com:44373/api";
// const App = () => {
//     return (
//         <div>
//
//         </div>
//     );
// }
//
// export default App;

export async function GetUserActivity(id, type) {
    const requestBody = {
        Id: parseInt(id),
        Type: parseInt(type)
    }

    const response = await protectedEnpointPostRequest(
        `${baseURL}/Membership/GetUserActivity/`,
        requestBody,
        cookie
    );
    if (response) {
        console.log(response.data)
        return response.data;
    } else {
        return null;
    }
}

export async function SetActiveAd(id, type) {
    const requestBody = {
        Id: parseInt(id),
        Type: parseInt(type)
    }
    const response = await protectedEnpointPostRequest(
        `${baseURL}/Membership/SetActiveAd/`,
        requestBody,
        cookie
    );
    return !!response
}

export async function SetPassiveAd(id, type) {
    const requestBody = {
        Id: parseInt(id),
        Type: parseInt(type)
    }
    const response = await protectedEnpointPostRequest(
        `${baseURL}/Membership/SetPassiveAd/`,
        requestBody,
        cookie
    );
    return !!response;
}