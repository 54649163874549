import React from "react";
import {useLocation} from "react-router-dom";
import NavbarIn from "../Components/Layout/NavbarIn";

const AdPublished = () => {
    const location = useLocation();
    const {value} = location.state;
    return (
        <div>
            <NavbarIn/>
            <div style={{height: '200px'}}>

            </div>
            <h2>{value}</h2>
            <div style={{height: '200px'}}>

            </div>
        </div>
    );
}

export default AdPublished;
