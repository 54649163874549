import React from "react";
import Navbar from "../Components/Layout/Navbar";
import SearchSmallBody from "../Components/Search/SearchSmallBody";

function SearchNav() {
    return (
        <div>
            <Navbar/>
            <div>
                <SearchSmallBody/>
            </div>
        </div>
    );
}

export default SearchNav;