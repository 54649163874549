import React, {useEffect, useState} from "react";
import {Form, Row, Col, InputGroup, DropdownButton, Dropdown} from "react-bootstrap";
import {BsSearch, BsFillMicFill} from "react-icons/bs";
import '../../assets/css/searchBar.css'
import {useSpeechRecognition} from "react-speech-kit";
import {Link} from "react-router-dom";
import {useWindowSize} from "../../static/helpers";
import {useNavigate} from "react-router";

const SearchNew = ({search, setSearch, setVal, val, setSearchTerm, setSearchType}) => {
    const navigate = useNavigate()
    const [optionText, setOptionText] = useState('Emlak')
    const [optionQuery, setOptionQuery] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [options, showOptions] = useState(false)
    const [options2, showOptions2] = useState(false)
    const size = useWindowSize();

    function fadeOut() {
        showOptions(!options)
        setTimeout(() => {
            showOptions2(!options2)
        }, 300)
    }

    useEffect(() => {
        setSearchType(optionText)
        if (optionText === 'Emlak') {
            setOptionQuery('emlak-ara')
        } else if (optionText === 'Vasıta') {
            setOptionQuery('vasita-ara')
        }
        setSearchQuery(`/${optionQuery}?text=${val}`)
    }, [optionText, setSearchType, val, optionQuery, searchQuery])

    function addArray(str) {
        setVal([...val,str])
    }

    const {listen, listening, stop} = useSpeechRecognition({
        onResult: (result) => {
            // addArray(result);
            setVal(result)
        },
    });

    useEffect(() => {
        if(listening == true)
            setVal("")
    },[listening])

    const handleChange = (e) => {
        setVal((val) => [...val, e]);
    };

    const handleWrite = () => {
        setSearchTerm();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setSearch(val);
            navigate(searchQuery)
        }
    }

    return (<div className="center">
        <div className="center" style={{margin: "0 auto"}}>
            <Row>
                <Col md={9} className="m-3 m-auto" style={{maxWidth: '700px'}}>
                    <InputGroup>
                        <Form.Control
                            onSubmit={() => {
                                handleWrite();
                            }}
                            onKeyDown={(e) => {
                                handleKeyPress(e)
                            }}
                            style={{
                                fontSize: '1em',
                                marginInlineStart: '1em'
                            }}
                            value={val}
                            placeholder="Aramak için anahtar kelime girin"
                            onChange={(e) => {
                                handleChange();
                                setVal(e.target.value);
                                setSearch(e.target.value);
                            }}
                            aria-label="Text input with dropdown button"/>
                        <div className="m-auto"
                             id="mic-button"
                             // onMouseDown={listen}
                             onMouseDown={(e)=>{
                                 setVal("")
                                 listen(e)
                             }}
                             onMouseUp={stop}>
                            <BsFillMicFill size={26}/>
                        </div>
                        <span className="m-2"></span>
                        <div className="m-auto">
                            <Link to={searchQuery}>
                                <BsSearch size={26}/>
                            </Link>
                        </div>
                        <span className="m-1"></span>

                        <DropdownButton
                            variant="outline-secondary"
                            title={optionText}
                            id="input-group-dropdown-2"
                            align="end"
                        >
                            <Dropdown.Item
                                href="#"
                                onClick={() => {
                                    setOptionText('Emlak')
                                }}>
                                Emlak
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item
                                href="#"
                                onClick={() => {
                                    setOptionText('Vasıta')
                                }}>
                                Vasıta
                            </Dropdown.Item>
                        </DropdownButton>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    </div>);
};
export default SearchNew;
