import React, { createContext } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const cookie = cookies.get("hoe-jwt");

export const AuthContext = createContext({
  jwt: cookie,
  signin: () => {},
  signout: () => {},
});
