import React, {useContext,useEffect, useState} from "react";
import {BaseURLContext} from "../../../../baseURL-context";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import Location from "../../../../Components2/Product/ProductDetails/Location";
import Carousel from "../../../Products/Carousel";
import Cookies from "universal-cookie";
import {protectedEnpointPostRequest} from "../../../../static/functions";
import HouseView from "./Types/HouseView";
import LandView from "./Types/LandView";
import HotelView from "./Types/HotelView";
import OfficeView from "./Types/OfficeView";
import BuildingView from "./Types/BuildingView";
import GeoLocationView from "../../../Map/GeoLocationView";
import {useNavigate} from "react-router";

const EstatePreview = ({setId, estateData, images, formData, state, goBack}) => {
    const {baseURL} = useContext(BaseURLContext);
    const [disable, setDisable] = useState(false);
    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")
    const [tabState, setTabState] = useState('details');

    const {
        AdType,
        FamilyType,
        EstateType,
        Name,
        Description,
        Price,
        City,
        State,
        SquareMeter,
        Rooms,
        WithFurniture,
        Balcony,
        Bathrooms,
        BuildingAge,
        Floor,
        TotalFloor,
        Heating,
        MonthlyExtra,
        InUsage,
        creditStatus,
        From,
        Ownership,
        Reconstruction,
        Ada,
        Parcel,
        Pafta,
        Kaks,
        Gabari,
        FloorPayback,
        Swappable,
        BedCount,
        ClosedAreaM2,
        FloorCount,
        StarCount,
        RoomCount,
        GroundSurvey,
        FlatCountPerFloor,
        FrontSide,
        Latitude,
        Longitude
    } = estateData || [];

    console.log(estateData)

    const [latitudeExisting, setLatitudeExisting] = useState(Latitude);
    const [longitudeExisting, setLongitudeExisting] = useState(Longitude);
    const [initialLocationExisting, setInitialLocationExisting] = useState(null)
    const [center, setCenter] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setInitialLocationExisting({
            id: parseInt("1"),
            pos: {
                lat: parseFloat(Latitude),
                lng: parseFloat(Longitude)
            }
        })
        setCenter({lat: parseFloat(Latitude), lng: parseFloat(Longitude)})
    }, [Longitude,Latitude])

    const CreditStatus = creditStatus === true ? 'Evet' : 'Hayır'

    const hrStyle = {
        color: "#000000",
        backgroundColor: "#000000",
        height: 1,
        borderColor: "#000000",
    }

    async function createEstate() {
        setDisable(true)
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Estate/AddEstateProduct`,
            estateData,
            cookie
        );
        if (response) {
            setId(response.data.itemId)
            let newFormData = new FormData();
            console.log('formData :', formData)
            if (formData?.length > 0) {
                for (var a = 0; a < formData.length; a++) {
                    console.log( 'formData[a] ',formData[a])
                    newFormData.append(`formFile`, formData[a], formData[a].name);
                }
                // newFormData.append("fileName", formData[0].name);
                newFormData.append("productId", response.data.itemId);
                newFormData.append("type", 0);
                console.log('formFile', newFormData)
                const createImageResponse = await protectedEnpointPostRequest(
                    `${baseURL}/Estate/CreateImage`,
                    newFormData,
                    cookie
                );
                if (createImageResponse) {
                    console.log("image/images created")
                    state('success')
                    setTimeout(() => {
                        navigate("/ilanlarim")
                    }, 3000);
                }

                return response;
            } else {
                state('success')
            }
        } else {
            return false;
        }
    }

    const activeStyle = {
        background: 'lightblue'
    }

    const passiveStyle = {
        background: 'lightgray'
    }

    return (
        <div className="container">

            <br/>
            <div
                className="card"
                style={{paddingTop: "4rem", backgroundColor: "white"}}
            >
                <button
                    className="btn btn-primary mb-4"
                    style={!disable ? {backgroundColor: "green", color: "white"} : {backgroundColor: "lightgray", color: "white"}}
                    disabled={disable}
                    onClick={() => {
                        createEstate();

                    }}
                    type="submit"
                >
                    {!disable ? "İLAN OLUŞTUR" : "ILAN OLUŞTURULUYOR"}
                </button>
                {/*<button onClick={goBack}>Ilan olusturmaya geri git</button>*/}
                <div className="row">
                    <div className="col-md-5 border-right p-3">
                        <Carousel array={images} adType={2}/>
                    </div>
                    <div className="col-md-4">
                        <article className="card-body">
                            <h3 className="title mb-3" style={{color: "black"}}>
                                {Name}
                            </h3>

                            <div className="price-detail-wrap row">
                                <p className="col-md-12" style={{color: "black"}}>
                                    <strong> {Price} </strong>
                                </p>
                            </div>
                            <div
                                className="price-detail-wrap row"
                            >
                <span className="col-md-12">
                  {City} / {State}
                </span>
                            </div>
                            <hr
                                style={hrStyle}
                            />
                            {FamilyType && AdType && (
                                <div>
                                    <div className="price-detail-wrap row">
                                        <label className="col-md-4" style={{color: "black"}}>
                                            <strong>Emlak Tipi</strong>
                                        </label>

                                        <span className="col-md-8">
                                            {FamilyType} / {AdType} {EstateType !== FamilyType
                                            ? (<>/{EstateType}</>)
                                            : (<></>)}
                                        </span>

                                    </div>
                                    <hr
                                        style={hrStyle}
                                    />
                                </div>
                            )}

                            <div>
                                <div
                                    className="price-detail-wrap row"
                                >
                                    <label className="col-md-4" style={{color: "black"}}>
                                        <strong>Metrekare</strong>
                                    </label>

                                    <span className="col-md-8  ad-detail">{SquareMeter} M²</span>
                                </div>
                                <hr
                                    style={hrStyle}
                                />
                            </div>

                            {FamilyType === 'Konut' &&
                                (<HouseView
                                    hrStyle={hrStyle}
                                    Rooms={Rooms}
                                    WithFurniture={WithFurniture}
                                    Balcony={Balcony}
                                    Bathrooms={Bathrooms}
                                    BuildingAge={BuildingAge}
                                    Floor={Floor}
                                    TotalFloor={TotalFloor}
                                    MonthlyExtra={MonthlyExtra}
                                    InUsage={InUsage}
                                    CreditStatus={CreditStatus}
                                    Ownership={Ownership}
                                    Heating={Heating}
                                    FrontSide={FrontSide}
                                />)}


                            {FamilyType === 'Arsa' &&
                                <LandView
                                    hrStyle={hrStyle}
                                    Reconstruction={Reconstruction}
                                    Ada={Ada}
                                    Parcel={Parcel}
                                    Pafta={Pafta}
                                    Kaks={Kaks}
                                    Gabari={Gabari}
                                    FloorPayback={FloorPayback}
                                    Swappable={Swappable}
                                    Ownership={Ownership}
                                />
                            }

                            {FamilyType === 'Turistik Tesis' &&
                                <HotelView
                                    StarCount={StarCount}
                                    RoomCount={RoomCount}
                                    BedCount={BedCount}
                                    FloorCount={FloorCount}
                                    ClosedAreaM2={ClosedAreaM2}
                                    GroundSurvey={GroundSurvey}
                                />
                            }

                            {FamilyType === 'İş Yeri' &&
                                <OfficeView
                                    hrStyle={hrStyle}
                                    BuildingAge={BuildingAge}
                                    TotalFloor={TotalFloor}
                                    MonthlyExtra={MonthlyExtra}
                                    InUsage={InUsage}
                                    Heating={Heating}
                                    CreditStatus={CreditStatus}
                                />
                            }

                            {FamilyType === 'Bina' &&
                                <BuildingView
                                    hrStyle={hrStyle}
                                    FlatCountPerFloor={FlatCountPerFloor}
                                    FloorCount={FloorCount}
                                    BuildingAge={BuildingAge}
                                />
                            }
                            <hr
                                style={hrStyle}
                            />

                            <div
                                className="price-detail-wrap row"
                            >
                                <label className="col-md-4" style={{color: "black"}}>
                                    <strong>Kimden</strong>
                                </label>
                                <span className="col-md-8  ad-detail">{From}</span>
                            </div>
                            <hr
                                style={hrStyle}
                            />

                        </article>
                    </div>
                </div>
                {/*<div className="row">*/}
                {/*    {Description &&*/}
                {/*        (*/}
                {/*            <div className="row"*/}
                {/*                 style={{margin: "0 auto", backgroundColor: "lightgray", minHeight: "10rem"}}>*/}
                {/*                <div className="d-flex center justify-content-center align-items-center col-md-12">*/}
                {/*                    <div dangerouslySetInnerHTML={{__html: `${Description}`}}>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*    {Latitude && Longitude &&*/}
                {/*        (*/}
                {/*            <div className="row">*/}
                {/*                <Location/>*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*</div>*/}

                <div className="row">
                        <div className="row col-md-12">
                            <div className="col-md-4">

                            </div>
                            <div className="col-md-2 btn rounded-0 m-2"
                                 onClick={() => {
                                     setTabState('details')
                                 }}
                                 style={tabState === 'details' ? activeStyle : passiveStyle}
                            >
                                İlan Detayları
                            </div>
                            <div className="col-md-2 btn rounded-0 m-2"
                                 onClick={() => {
                                     setTabState('location')
                                 }}
                                 style={tabState  === 'location' ? activeStyle : passiveStyle}
                            >
                                İlan Konumu
                            </div>
                            <div className="col-md-4">

                            </div>
                        </div>


                        <div style={{marginBottom:'2rem'}}>


                            {tabState === 'details' &&
                                (
                                    <div className="row"
                                         style={{margin: "0 auto", backgroundColor: "lightgray", minHeight: "10rem"}}>
                                        <div className="d-flex center justify-content-center align-items-center col-md-12">
                                            <div dangerouslySetInnerHTML={{__html: `${Description}`}}>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            {tabState === 'location' && Latitude && Longitude &&
                                (
                                    <div className="row">
                                        <GeoLocationView
                                            latitudeExisting={latitudeExisting}
                                            setLatitudeExisting={setLatitudeExisting}
                                            longitudeExisting={longitudeExisting}
                                            setLongitudeExisting={setLongitudeExisting}
                                            initialLocationExisting={initialLocationExisting}
                                            center={center}
                                            setCenter={setCenter}
                                        />
                                    </div>
                                )}
                        </div>


                </div>
            </div>
        </div>
    );
}

export default EstatePreview;
