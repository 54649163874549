import React, {useEffect, useState} from "react";
import Select from "react-select";

const options = [
    { value: "Cabrio", label: "Cabrio" },
    { value: "Sedan", label: "Sedan" },
    { value: "Coupe", label: "Coupe" },
    { value: "Hatchback", label: "Hatchback" },
    { value: "Roadster", label: "Roadster" },
    { value: "Jip", label: "Jip" },
    { value: "Pick-up", label: "Pick-up" },
    { value: "Station wagon", label: "Station wagon" },
    { value: "SUV", label: "SUV" },
];

const CaseType = ({ selectedCaseType,setSelectedCaseType,selectedLabel }) => {
    const [label, setLabel] = useState();
    useEffect(() => {

        if(selectedLabel != null) {
            for (let i = 0; i < options.length ; i++) {
                if(options[i].label === selectedLabel) {
                    setLabel(options[i])

                    // setExistedLabel
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLabel])
    return (
        <>
            <Select
                options={options}
                value={label}
                onChange={(e) => {
                    setSelectedCaseType(e.label);
                    setLabel(e)
                }
                }
                placeholder="Kasa Tipi"
            />
        </>
    );
};

export default CaseType;
