import React from "react";

const Hotel = ({
                   setStarCount,
                   setRoomCount,
                   setBedCount,
                   setFloorCount,
                   setClosedAreaM2,
                   setGroundSurvey,
                   starCount,
                   bedCount,
                   closedArea,
                   floorCount,
                   groundSurvey,
                   roomCount
               }) => {

    return (
        <div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="closedarea" className="float-start">
                        <strong>Kapalı Alan (m2) </strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="closedarea"
                        className="form-control"
                        id="closedarea"
                        value={closedArea}
                        placeholder="Kapalı Alan (m2)"
                        // value={values. || ""}
                        onChange={(e) => {
                            setClosedAreaM2(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="starcount" className="float-start">
                        <strong>Yıldız Sayısı</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="starcount"
                        className="form-control"
                        id="starcount"
                        max="7"
                        value={starCount}
                        placeholder="Yıldız Sayısı"
                        // value={values. || ""}
                        onChange={(e) => {
                            setStarCount(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="roomcount" className="float-start">
                        <strong>Oda Sayısı</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="roomcount"
                        className="form-control"
                        id="roomcount"
                        value={roomCount}
                        placeholder="Oda Sayısı"
                        // value={values. || ""}
                        onChange={(e) => {
                            setRoomCount(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="bedcount" className="float-start">
                        <strong>Yatak Sayısı</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="bedcount"
                        className="form-control"
                        id="bedcount"
                        value={bedCount}
                        placeholder="Yatak Sayısı"
                        // value={values. || ""}
                        onChange={(e) => {
                            setBedCount(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="floorcount" className="float-start">
                        <strong>Kat Sayısı</strong>
                    </label>
                    <input
                        type="number"
                        min="0"
                        name="floorcount"
                        className="form-control"
                        id="floorcount"
                        value={floorCount}
                        placeholder="Kat Sayısı"
                        onChange={(e) => {
                            setFloorCount(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form-floating mb-3">
                <div className="col-md-3">
                    <label htmlFor="groundsurvey" className="float-start">
                        <strong>Zemin Etüdü</strong>
                    </label>
                    <input
                        type="text"
                        min="0"
                        name="groundsurvey"
                        className="form-control"
                        id="groundsurvey"
                        value={groundSurvey}
                        placeholder="Zemin Etüdü"
                        onChange={(e) => {
                            setGroundSurvey(e.target.value)
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Hotel;
