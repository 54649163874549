import React from "react";
import moment from "moment";
import NoImage from '../../img/no-image.png'
import {Link} from "react-router-dom";
import {setAdTitleStringLimit, useWindowSize} from "../../static/helpers";
import {useNavigate} from "react-router";

const EstateSearchBody = ({result}) => {
    const size = useWindowSize()
    const navigate = useNavigate()

    async function navigateTo(id) {
        navigate(`/emlak/${id}`)
    }

    const rowImage = {
        // height: '',
        // width:'100%',
        maxWidth:'140px',
        // heigth:'190px',
        marginLeft: '-0.5rem',
    }

    // console.log('resylt', result)

    const smallRowImage = {
        // height: '',
        width:'100%',
        heigth:'3rem',
        marginLeft: '-0.5rem',
    }
    return (
        <div style={{margin:'0 auto',width:'96%'}}>
            <div>
                <table className="table">
                    <thead>
                    <tr>
                        {/*<th scope="col">#</th>*/}
                        <th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th className={size.width > 680 ? `` : `hide-column`} scope="col">Tipi&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th style={{float:'left',marginTop:'0.7rem'}} scope="col">İlan Adı</th>
                        <th className={size.width > 680 ? `` : `hide-column`} scope="col">Oda Sayısı</th>
                        <th className={size.width > 680 ? `` : `hide-column`} scope="col">M2</th>
                        <th scope="col">Konum</th>
                        <th scope="col">Fiyat</th>
                        <th scope="col">İlan Tarihi</th>

                    </tr>
                    </thead>
                    <tbody>
                    {result && result.map((item, i) => {
                            return (
                                <tr key={i}
                                    className={` ${i % 2 === 0 ? 'bg-white' : 'back-lightblue'}`}
                                    onClick={() => {navigateTo(result[i]?.id)}}
                                >
                                    <td>
                                        {/*// ${size?.width < 680 ? `w-100` : `w-100`}*/}
                                        <img
                                            className={`${size?.width < 680 ? `w-100` : `w-100`}`}
                                            style={size?.width < 680 ? smallRowImage : rowImage}
                                            src={result[i]?.smallImage ? result[i]?.smallImage : NoImage}
                                            alt="test"/>
                                    </td>
                                    <td className={` ${size.width > 680 ? `` : `hide-column`}`}>
                                        <p style={{float: 'left'}}>
                                            {result[i].familyType}
                                        </p>
                                    </td>
                                    <td >
                                        <p style={{float: 'left'}}>
                                            {setAdTitleStringLimit(result[i].name)}
                                        </p>
                                    </td>
                                    <td className={` ${size.width > 680 ? `` : `hide-column`}`}>
                                        <p>
                                            {result[i].rooms}
                                        </p>
                                    </td>
                                    <td className={` ${size.width > 680 ? `` : `hide-column`}`}>
                                        <p>
                                            {result[i].squareMeter}
                                        </p>
                                    </td>
                                    <td className={` ${size.width > 680 ? `` : `minimize-column    `}`}>
                                        <p>
                                            {result[i].city}{" / "}
                                            {result[i].state}
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            {result[i].price} ₺
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            {moment(result[i].createdOn).format("DD/MM/YYYY")}
                                        </p>
                                    </td>
                                </tr>
                            )
                        }
                    )}
                    </tbody>

                </table>

                {result && result?.length < 1 && (<p>Sonuc Yok</p>)}
            </div>
        </div>
    );
}

export default EstateSearchBody;
