import React, {useState, Fragment, useEffect} from "react";
import MarkerIcon from '../../img/Map-Pin.svg'

// We will use these things from the lib
// https://react-google-maps-api-docs.netlify.com/
import {
    useLoadScript,
    GoogleMap,
    Marker
} from "@react-google-maps/api";
import Navbar from "../Layout/Navbar";

// import {getLocation} from "../../static/functions";


function GeoLocationNewNew({
                               latitude,
                               setLatitude,
                               longitude,
                               setLongitude,
                               initialLocation,
                               setInitialLocation,
                               center,
                               setCenter
                           }) {

    // The things we need to track in state
    const [mapRef, setMapRef] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [markerMap, setMarkerMap] = useState({});
    // const [center, setCenter] = useState({lat: 44.076613, lng: -98.362239833});
    const [zoom, setZoom] = useState(15);
    const [clickedLatLng, setClickedLatLng] = useState(null);
    const [infoOpen, setInfoOpen] = useState(false);
    // const [latitude, setLatitude] = useState(0);
    // const [longitude, setLongitude] = useState(0);
    // const [initialLocation, setInitialLocation] = useState({id: 0, pos: {lat: latitude, lng: longitude}})


    // const initialLocation = { id: "place1", pos: { lat: latitude , lng: longitude } }
    // Load the Google maps scripts
    const {isLoaded} = useLoadScript({
        // Enter your own Google Maps API key
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });

    // The places I want to create markers for.
    // This could be a data-driven prop.
    const myPlaces = [
        {id: 0, pos: {lat: latitude, lng: longitude}}
    ];

    function handleCenterChanged() {
        if (!mapRef?.current) return;
        const newPos = mapRef?.current?.getCenter().toJSON();
        setCenter(newPos);
    }

    // Iterate myPlaces to size, center, and zoom map to contain all markers
    const fitBounds = map => {
        map.setZoom(15);
        const bounds = new window.google.maps.LatLngBounds();
        myPlaces.map(place => {
            bounds.extend(place.pos);
            return place.id;
        });
        map.fitBounds(bounds);
    };

    const loadHandler = map => {
        // Store a reference to the google map instance in state
        setMapRef(map);
        // Fit map bounds to contain all markers
        fitBounds(map);
    };

    // We have to create a mapping of our places to actual Marker objects
    const markerLoadHandler = (marker, place) => {
        return setMarkerMap(prevState => {
            // console.log('prevState', marker, place)
            return {...prevState, [place.id]: marker};
        });
    };

    const markerClickHandler = (event, place) => {
        setSelectedPlace(place);


        // Required so clicking a 2nd marker works as expected
        if (infoOpen) {
            setInfoOpen(false);
        }

        // if you want to center the selected Marker
        setCenter(place.pos)
    };


    useEffect(() => {
        // console.log('clickedLatLng', clickedLatLng)
        // console.log('initialExistingLocation   ', initialExistingLocation)
        var count = parseInt((parseInt(initialLocation?.pos?.id) || 0) + 1);
        if (clickedLatLng == null) {
            if (initialLocation == null) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                });

                setInitialLocation({id: count, pos: {lat: latitude, lng: longitude}})
                setCenter({lat: latitude, lng: longitude})
                setZoom(14);
            } else {
                var count = parseInt((parseInt(initialLocation?.pos?.id) || 0) + 1);
                setInitialLocation({id: count, pos: {lat: latitude, lng: longitude}})
                setCenter({lat: latitude, lng: longitude})
                setZoom(14);
            }
        } else {
            var count = parseInt(parseInt(initialLocation.id) + 1);
            setInitialLocation({id: count, pos: {lat: clickedLatLng.lat, lng: clickedLatLng.lng}})
            setCenter({lat: clickedLatLng.lat, lng: clickedLatLng.lng})
        }

    }, [clickedLatLng])

    useEffect(() => {
        if (initialLocation != null) {
            setLatitude(initialLocation?.pos?.lat)
            setLongitude(initialLocation?.pos?.lng)
            setCenter({lat: initialLocation?.pos?.lat, lng: initialLocation?.pos?.lng})
        }
    }, [])


    const renderMap = () => {
        return (
            <Fragment>
                {center &&
                    (<GoogleMap
                        // Do stuff on map initial laod
                        onLoad={loadHandler}
                        // Save the current center position in state
                        // onCenterChanged={() => setCenter(mapRef?.getCenter().toJSON())}
                        // Save the user's map click position
                        onCenterChanged={() => {
                            handleCenterChanged()
                        }}
                        onClick={e => setClickedLatLng(e.latLng.toJSON())}
                        center={center}
                        zoom={zoom}
                        mapContainerStyle={{
                            height: "70vh",
                            width: "100%"
                        }}
                        on
                    >
                        {initialLocation && center &&
                            (
                                <Marker
                                    key={initialLocation.id}
                                    // clusterer={clusterer}
                                    position={initialLocation.pos}
                                    onLoad={marker => markerLoadHandler(marker, initialLocation)}
                                    onClick={event => markerClickHandler(event, initialLocation)}
                                    // Not required, but if you want a custom icon:
                                    // icon={{
                                    //     path:
                                    //         'https://hepsiotoemlak.com/static/media/Map-Pin.svg',
                                    //     fillColor: "#000000",
                                    //     fillOpacity: 0.5,
                                    //     strokeWeight: 0,
                                    //     scale: 1.25
                                    // }}
                                />
                            )
                        }
                    </GoogleMap>)
                }
                {/*/!* Our center position always in state *!/*/}
                {/*<h3>*/}
                {/*    Center {center.lat}, {center.lng}*/}
                {/*</h3>*/}

                {/*/!* Position of the user's map click *!/*/}
                {/*{clickedLatLng && (*/}
                {/*    <h3>*/}
                {/*        You clicked: {clickedLatLng.lat}, {clickedLatLng.lng}*/}
                {/*    </h3>*/}
                {/*)}*/}

                {/*/!* Position of the user's map click *!/*/}
                {/*{selectedPlace && <h3>Selected Marker: {selectedPlace.id}</h3>}*/}
            </Fragment>
        );
    };

    return isLoaded ? renderMap() : null;
}

export default GeoLocationNewNew