import React, {useContext, useEffect, useState} from "react";
import {useWindowSize} from "../../../static/helpers";
import {defaultGetRequestWithParams} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";

const DopingOnCreateAd = ({advantages,setAdvantages}) => {
    // const {baseURL} = useContext(BaseURLContext);
    // const [amount, setAmount] = useState([])
    const getSize = useWindowSize();

    // async function getDopingPrice() {
    //     const response = await defaultGetRequestWithParams(
    //         `${baseURL}/FieldManage/GetField`,
    //         {
    //             params: {
    //                 fieldType: 'MembershipDetail'
    //             },
    //         }
    //     );
    //     if (response) {
    //         console.log('response.data',response.data)
    //         for (let i = 0; i < response.data.length; i++) {
    //             if (response.data[i].label === 'Doping')
    //                 setAmount(response.data[i].value)
    //         }
    //     }
    // }
    // useEffect(() => {
    //     getDopingPrice()
    // },[])
    // // const [label, setLabel] = useState(0)
    return (
        <div className="row" style={{marginBottom:'4rem'}}>
            <div className={`col-md-10 m-auto ${getSize?.width > 680  ? `d-flex justify-content-center align-items-center` : ``}`}>
                <div className="card col-md-4"  style={{margin:'1rem'}}>
                    <div className="card-body">
                        <h5 className="card-title">Doping Yok</h5>
                        <p className="card-text">
                            İlanınız vitrinde yer almayacak ama aramalarda görünecek.
                        </p>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            // defaultChecked
                            onChange={() => {
                                setAdvantages(1)
                            }}
                            checked={advantages === 1}
                            onClick={() => {
                                setAdvantages(1)
                            }}
                        />
                    </div>
                </div>
                <div className="card col-md-4"  style={{margin:'1rem'}}>
                    <div className="card-body">
                        <h5 className="card-title">1 Haftalık Doping</h5>
                        <p className="card-text">
                            İlaniniz 1 hafta boyunca vitrinde görüntülenecek. Kart bilgileri ilani olusturduktan sonra alinacak.
                        </p>
                        <input
                            className="form-check-input"
                            type="radio"
                            // onClick={setLabel}
                            onChange={() => {
                                setAdvantages(2)
                            }}
                            onClick={() => {
                                setAdvantages(2)
                                // console.log(label)
                            }}
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            // checked={advantages === 2}
                        />
                    </div>
                </div>
                <div className="card col-md-4"  style={{margin:'1rem'}}>
                    <div className="card-body">
                        <h5 className="card-title">1 Aylık</h5>
                        <p className="card-text">
                            İlaniniz 1 ay boyunca vitrinde görüntülenecek. Kart bilgileri ilani olusturduktan sonra alinacak.
                        </p>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                            // checked={advantages === 3}
                            onChange={() => {
                                setAdvantages(3)
                            }}
                            onClick={() => {
                                setAdvantages(3);
                                // console.log(label)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DopingOnCreateAd;
