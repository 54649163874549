import React, {useContext, useEffect, useState} from "react";
import {Tab, Nav, Row, Col} from "react-bootstrap";
import {Card} from "antd";
import {
    protectedEnpointGetRequest,
    protectedEnpointGetRequestWithParams,
    protectedEnpointPostRequest
} from "../../static/functions";
import {SetPassiveAd, SetActiveAd} from "../../static/adStateFunctions";
import {BaseURLContext} from "../../baseURL-context";
import Cookies from "universal-cookie";
import {Link, useLocation, useNavigate} from "react-router-dom";
import '../../assets/css/table.css'
import NoImage from '../../img/no-image.png'
import {setAdTitleStringLimit, useWindowSize} from "../../static/helpers";

const AdOverviewPublished = () => {
    // setActiveEstates, setPassiveEstates, setActiveCars, setPassiveCars
    const {baseURL} = useContext(BaseURLContext);
    const [show, setShow] = useState('published');
    // const [count, setCount] = useState(0);
    const [accountType, setAccountType] = useState();
    const [activeEstates, setActiveEstates] = useState([]);
    const [passiveEstates, setPassiveEstates] = useState([]);
    const [activeCars, setActiveCars] = useState([]);
    const [passiveCars, setPassiveCars] = useState([]);
    const cookies = new Cookies();
    const cookie = cookies.get("hoe-jwt")
    const navigate = useNavigate();

    const size = useWindowSize();


    useEffect(() => {
        GetAllMembership()
    }, [])

    // useEffect(() => {
    // }, [GetUserActivity])


    // async function SetActive(id, type) {
    //     console.log('id : ', id, 'type : ', type)
    //     const response = await SetActiveAd(id, type);
    //     if (response) {
    //         navigate('/ilanlarim');
    //         window.location.reload();
    //     }
    // }

    async function applyDoping(id,type) {
        navigate("/doping",{state:{id:id,type:type}})
    }

    async function EditAd(id, type) {
        if(type === 0) {
            navigate('/emlak-duzenle', {
                state: {
                    id: id,
                    type: type
                }
            })
        } else {
            navigate('/vasita-duzenle', {
                state: {
                    id: id,
                    type: type
                }
            })
        }
    }

    async function SetPassive(id, type) {

        const response = await SetPassiveAd(id, type);
        if (response) {
            navigate('/ilanlarim');
            window.location.reload();
        }
    }


    async function GetAllMembership() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Membership/GetAllMembership`,
            cookie
        );
        if (response) {
            // console.log("response adoveview : ", response.data.all.activeEstates)
            // setCount(response.data.availableAdPublish)
            setAccountType(response.data.accountType)
            setActiveEstates(response.data.all.activeEstates)
            setActiveCars(response.data.all.activeCars)
            setPassiveEstates(response.data.all.passiveEstates)
            setPassiveCars(response.data.all.passiveCars)
        }
    }

    const customButton = {
        // width:'150px'
    }

    return (
        <div>
            <div style={{background: 'black', width: '100%', height: '1px', margin: '0 auto'}}>
            </div>
            {/*<Row>*/}
            {/*    <Col md={12}>*/}

            {/*        <h4>Üyelik Tipi: {accountType === 0*/}
            {/*            ? <span>Ücretsiz Uyelik</span>*/}
            {/*            : accountType === 1*/}
            {/*                ? <span>1 Aylık Uyelik</span>*/}
            {/*                : accountType === 2 && <>1 Yıllık Uyelik</>}*/}
            {/*            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
            {/*            Kalan İlan Yayınlama Hakkınız : {count}*/}
            {/*        </h4>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<div style={{background: 'black', width: '600px', height: '1px', margin: '0 auto'}}>*/}

            {/*</div>*/}
            <Row style={{marginTop: '2rem'}}>
                {show === 'published' && (<Row>
                    <Col md={6}>
                        <h3>Emlak İlanları</h3>
                        <table className="table table-image">
                            <tbody>
                            {activeEstates && activeEstates.map((item, i) => {
                                return (
                                    <tr key={i} className="d-flex m-1 row">
                                        <td className={`col-md-2 ${size?.width < 680 ? `w-100` : `w-25`}`}>
                                            <Link to={`/emlak/${item.id}`}>
                                                {item?.smallImage !== null ? (<img style={{width: '63%'}}
                                                                                   src={item?.smallImage}
                                                                                   alt="test"/>) :
                                                    <div>
                                                        <img alt="no-img" style={{width:'63%'}} src={NoImage}/>
                                                    </div>}
                                            </Link>
                                        </td>
                                        <td className="col-md-3">
                                            <Link to={`/emlak/${item.id}`}>
                                                {setAdTitleStringLimit(item.name)}
                                            </Link>
                                        </td>
                                        <td className="col-md-2">
                                            <button
                                                style={customButton}
                                                className="rounded-0 customButtonActive"
                                                onClick={() => {
                                                    applyDoping(item.id, 0);
                                                }}
                                                type="submit"
                                            >
                                                Doping Uygula
                                            </button>
                                        </td>
                                        <td className="col-md-2">
                                            <button
                                                style={customButton}
                                                className="rounded-0 customButtonEdit"
                                                onClick={async () => {
                                                    await EditAd(item.id, 0);
                                                }}
                                                type="submit"
                                            >
                                                Duzenle
                                            </button>
                                        </td>
                                        <td className="col-md-2">
                                            <button
                                                // style={customButton}
                                                className="rounded-0 customButtonPassive"
                                                onClick={async () => {
                                                    await SetPassive(item.id, 0)
                                                }}
                                                type="submit"
                                            >
                                                Yayından Kaldır
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </Col>
                    <Col md={6}>
                        <h3>Vasıta İlanları</h3>
                        <table className="table table-image">
                            <tbody>
                            {activeCars && activeCars.map((item, i) => {
                                return (
                                    <tr key={i} className="d-flex p-1 row">
                                        {/*<td className="col-md-1">{i}</td>*/}
                                        <td className={`col-md-2 ${size?.width < 680 ? `w-100` : `w-25`}`}>
                                            <Link to={`/vasita/${item.id}`}>
                                                {item?.smallImage !== null ? (<img style={{width: '63%'}}
                                                                                   src={item?.smallImage}
                                                                                   alt="test"/>) :
                                                    <div>
                                                        <img style={{width:'63%'}} alt="no-img" src={NoImage}/>
                                                    </div>}
                                            </Link>
                                        </td>
                                        <td className="col-md-3"
                                            style={{float: 'left'}}>
                                            <Link to={`/vasita/${item.id}`}>
                                                {setAdTitleStringLimit(item.name)}
                                            </Link>
                                        </td>
                                        <td className="col-md-2">
                                            <button
                                                style={customButton}
                                                className="rounded-0 customButtonActive"
                                                onClick={() => {
                                                    applyDoping(item.id, 1);
                                                }}
                                                type="submit"
                                            >
                                                Doping Uygula
                                            </button>
                                        </td>
                                        <td className="col-md-2">
                                            <button
                                                style={customButton}
                                                className="rounded-0 customButtonEdit"
                                                onClick={async () => {
                                                    await EditAd(item.id, 1);
                                                }}
                                                type="submit"
                                            >
                                                Duzenle
                                            </button>
                                        </td>
                                        <td className="col-md-2">
                                            <button
                                                style={customButton}
                                                className="rounded-0 customButtonPassive"
                                                onClick={async () => {
                                                    await SetPassive(item.id, 1);
                                                }}
                                                type="submit"
                                            >
                                                Yayından Kaldır
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </Col>
                </Row>)}
            </Row>
        </div>
    )
        ;
}

export default AdOverviewPublished;