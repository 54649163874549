import React, {useContext, useEffect, useState} from "react";
import PaymentForm from "./RegisterPayment";
import {defaultPostRequest} from "../../../static/functions";
import {BaseURLContext} from "../../../baseURL-context";
import {useNavigate} from "react-router";

const Doping = ({userData, advantages, setAdvantages}) => {
    // const [advantages, setAdvantages] = useState(1)
    const {baseURL} = useContext(BaseURLContext);
    const navigate = useNavigate();
    async function finalizeRegistration() {
        const response = await defaultPostRequest(
            `${baseURL}/authentication/RegisterUser/`,
            userData
        );
        if (response) {
            navigate("/email-kontrol");
        }
    }

    return (
        <div>
            <form onSubmit={() => {
                finalizeRegistration();
            }}>
            <PaymentForm
                advantages={advantages}
                setAdvantages={setAdvantages}
            />
                <button
                type="submit">

                </button>
            </form>

        </div>
    );
}

export default Doping;
