import React, {useEffect, useState} from "react";
import Login from "../Components/Login/Login";
// import Navbar from "../Components/Layout/Navbar";
import NavbarOut from "../Components/Layout/NavbarOut";
import Footer from "../Components/NoAuthComponents/Footer";
import Cookies from "universal-cookie";

function App() {
    const cookies = new Cookies()
    const [navLogo, setNavLogo] = useState(true)

    useEffect(() => {
        cookies.remove("hoe-jwt")
        setNavLogo(true)
    },[])

  return (
    <div>
      <div>
        <NavbarOut navLogo={navLogo} />
      </div>
      <div style={{marginBottom:'4rem'}}>
        <Login />
      </div>
    </div>
  );
}

export default App;
