import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {BaseURLContext} from "../../baseURL-context";
import {
    defaultGetRequest, defaultGetRequestWithParams,
} from "../../static/functions";
import {ListGroup, Row, Col, Nav} from "react-bootstrap";
// import SearchBar from "./SearchBar";
import City from "./Filters/City";
// import axios from "axios";
// import SmallSearch from "./Filters/SmallSearch";

const SearchSmallBody = () => {

    const {baseURL} = useContext(BaseURLContext);
    const [result, setResult] = useState([]);
    const [mainCategories, setMainCategories] = useState([]);
    const [subCat1, setSubCat1] = useState([]);
    const [subCat2, setSubCat2] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const location = useLocation();
    const {search, type} = location.state;


    function setLastBreadcrumb(lastCrumb) {
        if (breadcrumbs.length < 3) {
            setBreadcrumbs((breadcrumbs) => [...breadcrumbs, lastCrumb]);
        }
        //     setBreadcrumbs((breadcrumbs) => [...breadcrumbs, lastCrumb]);
    }

    useEffect(() => {
        // getAllProducts();
        getCategories();
        setBreadcrumbs([]);
        // if(search) {
        setResult([])
        getResult()
        // }
    }, [search,type]);


    async function getResult() {
        const array = await getEstateSearch();
        return array;
    }

    async function getEstateSearch() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/Search/SearchEstates`,
            {
                params: {
                    name: search
                },
            }
        );

        if (response) {
            console.log('getEstateSearch', response.data)
            for (const responseElement of response.data) {
                console.log('getEstateSearch2', responseElement)
                setResult(prevArray => [...prevArray, responseElement])
            }
            return response.data;
        } else {
            console.log("Couldn't find object with id: ");
            return [];
        }
    };


    const getCategories = async () => {
        const response = await defaultGetRequest(
            `${baseURL}/Categories/GetCategoryTree`
        );
        if (response) {
            setMainCategories(response.data["mainCats"]);
        } else {
            console.log("false");
        }
    };

    function getChildren(source, name) {
        for (var i = 0; i < source.length; i++) {
            if (source[i].name === name) {
                setSubCat1(source[i]["children"]);
                setMainCategories([]);
                if (breadcrumbs.length < 3) {
                    setBreadcrumbs((breadcrumbs) => [
                        ...breadcrumbs,
                        source[i].name + " / ",
                    ]);
                }

                return null;
            }
        }
        console.log("Couldn't find object with id: " + name);
    }

    function getGrandChild(source, name) {
        for (var i = 0; i < source.length; i++) {
            if (source[i].name === name) {
                setSubCat2(source[i].grandChildren);
                setSubCat1([]);
                // setBreadcrumbs((breadcrumbs) => [...breadcrumbs, source[i].grandChildren]);
                //   console.log("gran test " + source[i].grandChildren);
                // for (var j = 0; j < source[i].grandChildren.length; j++) {
                // }
                return null;
            }
        }
        console.log("Couldn't find object with id: " + name);
    }


    return (
        <div className="container" style={{width: '100%', height: '100%'}}>
            <Nav style={{width: '100%', height: '100%', marginBottom: '2rem'}}>
                <Row>
                    <Col sm={12}>
                        <Row>
                            <Col sm={3} style={{marginTop: "3rem"}}>
                                {/*<ul className="breadcrumb">*/}
                                {/*    /!*{breadcrumbs ??*!/*/}
                                {/*    /!*    breadcrumbs.map((e, i) => {*!/*/}
                                {/*    /!*        return (*!/*/}
                                {/*    /!*            <li key={i}>*!/*/}
                                {/*    /!*                <p>{e}</p>*!/*/}
                                {/*    /!*            </li>*!/*/}
                                {/*    /!*        )*!/*/}
                                {/*    /!*    })}*!/*/}
                                {/*</ul>*/}
                                <section className="box">
                                    <ListGroup
                                        className="list-1"
                                        style={{marginBottom: "4rem"}}
                                    >
                                        {mainCategories &&
                                            mainCategories.map((e, key) => {
                                                return (
                                                    <ListGroup.Item
                                                        onClick={() => {
                                                            getChildren(mainCategories, e.name);
                                                        }}
                                                        action
                                                        variant="dark"
                                                        key={key}
                                                    >
                                                        {e["name"]}
                                                    </ListGroup.Item>
                                                );
                                            })}
                                        {/*{subCat1 &&*/}
                                        {/*    subCat1.map((e, key) => {*/}
                                        {/*        return (*/}
                                        {/*            <ListGroup.Item*/}
                                        {/*                onClick={() => {*/}
                                        {/*                    getGrandChild(subCat1, e.name);*/}
                                        {/*                    setBreadcrumbs((breadcrumbs) => [*/}
                                        {/*                        ...breadcrumbs,*/}
                                        {/*                        e.name + " / ",*/}
                                        {/*                    ]);*/}
                                        {/*                    // onClickNormalize();*/}
                                        {/*                }}*/}
                                        {/*                action*/}
                                        {/*                variant="dark"*/}
                                        {/*                key={key}*/}
                                        {/*            >*/}
                                        {/*                {e.name}*/}
                                        {/*            </ListGroup.Item>*/}
                                        {/*        );*/}
                                        {/*    })}*/}
                                        {/*{subCat2 &&*/}
                                        {/*    subCat2.map((e, key) => {*/}
                                        {/*        return (*/}
                                        {/*            <ListGroup.Item*/}
                                        {/*                action*/}
                                        {/*                variant="dark"*/}
                                        {/*                key={key}*/}
                                        {/*                onClick={() => {*/}
                                        {/*                    setLastBreadcrumb(e.name);*/}

                                        {/*                    // onClickNormalize();*/}
                                        {/*                }}*/}
                                        {/*                // onClick={() => {*/}
                                        {/*                //   setCatId(e["id"]);*/}
                                        {/*                //   onClickNext();*/}
                                        {/*                //   setCategory(catId);*/}
                                        {/*                // }}*/}
                                        {/*            >*/}
                                        {/*                {e["name"]}*/}
                                        {/*            </ListGroup.Item>*/}
                                        {/*        );*/}
                                        {/*    })}*/}
                                    </ListGroup>
                                </section>
                                <section className="box" style={{marginBottom: "12rem"}}>
                                    <City/>
                                </section>
                            </Col>
                            {/*<Col sm={1}></Col>*/}
                            <Col sm={9}>
                                <input
                                    value={search || ""}
                                    className="h5 mb-4 text-center"
                                    style={{display: "none"}}
                                />
                                <div className="table-wrap">
                                    <table className="table">
                                        <thead className="thead-primary">
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>İlan Başlığı</th>
                                            <th>Fiyatı</th>
                                            <th>İlan Tarihi</th>
                                            <th>İl / İlçe</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {search && result.map((item, i) => {
                                            return (
                                                <tr key={i} className="alert" role="alert">
                                                    <td>
                                                        <Link to={`/emlak/${item.id}`}>
                                                            <img
                                                                alt="test"
                                                                className="img"
                                                                src="https://picsum.photos/600/450"
                                                                width={120}
                                                                height={120}
                                                            />
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={`/emlak/${i}`}
                                                            style={{textDecoration: "none"}}
                                                        >
                                                            {item?.name}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={`/emlak/${i}`}
                                                            style={{textDecoration: "none"}}
                                                        >
                                                            {item?.price}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={`/emlak/${i}`}
                                                            style={{textDecoration: "none"}}
                                                        >
                                                            12.12.2021
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={`/emlak/${i}`}
                                                            style={{textDecoration: "none"}}
                                                        >
                                                            {item?.city + " / " + item?.province}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Nav>
        </div>
    );
};

export default SearchSmallBody;
