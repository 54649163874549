import React, {useEffect, useState} from "react";
import Select from "react-select";

const options = [
  { value: 1, label: "TR Plaka" },
  { value: 2, label: "Yabancı Plaka" },
  { value: 3, label: "Mavi Plaka" },
];

const License = ({  setSelectedLicense,selectedLabel }) => {
    const [label, setLabel] = useState();
    useEffect(() => {
        console.log('selectedLabel',selectedLabel)
        if(selectedLabel != null) {
            for (let i = 0; i < options.length ; i++) {
                if(options[i].label === selectedLabel) {
                    setLabel(options[i])
                    console.log('arrayKey',options[i])
                    // setExistedLabel
                }
            }
        }
    }, [selectedLabel])
  return (
    <>
      <Select
        options={options}
        value={label}
        onChange={(e) => {
            setSelectedLicense(e.label);
            setLabel(e);
        }}
        placeholder="Plaka / Uyruk"
      />
    </>
  );
};

export default License;
