import React, {useEffect, useState, useContext} from "react";
import Select from "react-select";
import {BaseURLContext} from "../../../baseURL-context";
import {defaultGetRequestWithParams} from "../../../static/functions";


const WithFurniture = ({withFurniture, setWithFurniture, selectedLabel}) => {
    const [label, setLabel] = useState();
    const {baseURL} = useContext(BaseURLContext);
    const [options, setOptions] = useState([]);

    async function getOptions() {
        const response = await defaultGetRequestWithParams(
            `${baseURL}/FieldManage/GetField`,
            {
                params: {
                    fieldType: 'WithFurniture'
                }
            }
        )
        if (response) {
            setLabel({value: 0, label: ''})
            setOptions(response.data)
        }
    }

    useEffect(() => {
        if (selectedLabel != null) {
            setLabel({value: 0, label: selectedLabel})
        }
    }, [selectedLabel])

    return (
        <>
            {label && <Select
                onMenuOpen={() => {
                    getOptions()
                }}
                options={options}
                value={label}
                onChange={(e) => {
                    setWithFurniture(e.label);
                    setLabel(e);
                }}
                placeholder="Seçiniz"
            />}
        </>
    );
};

export default WithFurniture;
