import React, {useContext, useEffect, useState} from "react";
import {BiUserCircle} from 'react-icons/bi'
import {Link} from "react-router-dom";
import '../../assets/css/messageBox.css'
import {BsWhatsapp} from 'react-icons/bs'
import {MdPhoneInTalk} from 'react-icons/md'
import {useNavigate} from "react-router";
import {useWindowSize} from "../../static/helpers";
// import {FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton} from "react-share";
// import HelmetMetaData from "../Core/HelmetMetaData";
// import {BaseURLContext} from "../../baseURL-context";
// import {protectedEnpointGetRequest} from "../../static/functions";
// import Cookies from "universal-cookie";

const MessageBox = ({ownerName, phone, id, type, username, isLogged}) => {
    // const [accountType, setAccountType] = useState('')
    // const {baseURL} = useContext(BaseURLContext);
    // const cookies = new Cookies();
    // const cookie = cookies.get("hoe-jwt")
    const navigate = useNavigate();
    const size = useWindowSize();

    function goMessages() {
        if (isLogged === true) {
            navigate("/mesajlar", {state: {user: username, id: id, type: type}})
        } else {
            navigate("/giris-yap")
        }

    }

    return (
        <div>

            <div className="message-box-wrapper rounded">
                <div className="message-header">
                    <h6>
                        Yeni Uye
                    </h6>
                </div>
                <div className="">
                    <p id="owner" style={{marginTop: '5px'}}
                       className="d-flex justify-content-center phone-number-wrapper">{ownerName}</p>
                </div>
                {/*<div className="row">*/}
                {/*    <h3 className="title col-md-12 d-flex m-auto mb-1"*/}
                {/*        style={border}>*/}
                {/*    </h3>*/}
                {/*</div>*/}
                <div className="d-flex justify-content-center phone-number-wrapper">
                    <label style={{marginTop: '3px'}} htmlFor="phoneNumber">
                        GSM:&nbsp;&nbsp;&nbsp;
                    </label>
                    <p id="phoneNumber" className="phone-number"><strong>{phone}</strong></p>
                    <span>
                        &nbsp;&nbsp;&nbsp;
                        <a href={`https://api.whatsapp.com/send?phone=90${phone}&text=`} target="_blank" rel="noreferrer">
                            <BsWhatsapp style={{color: 'green', marginBottom: '5px'}} size={14}/>
                        </a>
                    </span>

                    {isLogged && <span>
                        &nbsp;&nbsp;&nbsp;
                        <a href={`tel:90${phone}`} target="_blank" rel="noreferrer">
                            <MdPhoneInTalk style={{color: 'green', marginBottom: '5px'}} size={14}/>
                        </a>
                    </span>}
                </div>
                {/*<div className="row">*/}
                {/*    <h3 className="title col-md-12 d-flex m-auto m-3 mb-3"*/}
                {/*        style={border}>*/}
                {/*    </h3>*/}
                {/*</div>*/}
                <div
                    className="btn p-2 rounded-1 message-box"
                    onClick={() => {
                        goMessages()
                    }}
                >
                    Mesaj Gonder
                </div>
            </div>

        </div>
    );
}

export default MessageBox;
