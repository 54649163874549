import React, {useEffect, useState} from "react";
import Select from "react-select";

const options = [
  { value: 1, label: "Mavi" },
  { value: 2, label: "Sarı" },
  { value: 3, label: "Kırmızı" },
  { value: 4, label: "Yeşil" },
  { value: 5, label: "Mor" },
  { value: 6, label: "Pembe" },
  { value: 7, label: "Beyaz" },
  { value: 8, label: "Siyah" },
  { value: 9, label: "Gri" },
];

const Color = ({ selectedColor, setSelectedColor,selectedLabel }) => {
  const [label, setLabel] = useState();
    useEffect(() => {

        if(selectedLabel != null) {
            for (let i = 0; i < options.length ; i++) {
                if(options[i].label === selectedLabel) {
                    setLabel(options[i])
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLabel])
  return (
    <>
      <Select
        options={options}
        value={label}
        onChange={(e) => {
          setSelectedColor(e.label);
          setLabel(e)
        }
        }
        placeholder="Renk"
      />
    </>
  );
};

export default Color;
