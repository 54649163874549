import React, {useEffect} from "react";
import {useNavigate} from "react-router";
import useAuth from "../Components/useAuth/useAuth";
import Cookies from "universal-cookie";

const AccountRemoved = () => {

    const auth = useAuth();
    const cookies = new Cookies();
    const token = cookies.get("hoe-jwt");

    function logout() {
        if (token) {
            cookies.remove("hoe-jwt");
        }
        auth.signout(() => {
            navigate("/");
        });
    }
    const navigate = useNavigate()



    useEffect(() => {
        setTimeout(() => {
            logout()
        }, 5000)
    })

    return (
        <div className="m-5 m-auto"  style={{margin:'10rem !important'}}>
            <div className="m-auto" style={{margin:'10rem'}}>
                <h5>Hesabınız başarıyla iptal edildi. Anasayfaya yönlendiriliyorsunuz.</h5>
            </div>
        </div>
    );
}

export default AccountRemoved;