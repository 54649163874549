import React, {useState} from "react";
import NavbarIn from "../Components/Layout/NavbarIn";
import UserManage from "../Components/User/UserManage";

const Profile = () => {
    const [user, setUser] = useState([]);

    return (
        <div>
            <NavbarIn setCurrentUser={setUser}/>
            <UserManage currentUser={user} eventKeyProp={'account'}/>
        </div>
    );
};

export default Profile;
