import React, {useState, useEffect, useRef, useContext} from 'react';
import {HubConnectionBuilder} from '@microsoft/signalr';
import {
    protectedEnpointGetRequest,
    protectedEnpointGetRequestWithParams,
    protectedEnpointPostRequest
} from "../../static/functions";
import Cookies from "universal-cookie";
import * as signalR from "@microsoft/signalr";
import {BaseURLContext} from "../../baseURL-context";
import moment from "moment";
import 'moment/locale/tr';
import {Link} from "react-router-dom";
import NoImage from "../../img/no-image.png";
import isNumeric, {useWindowSize} from "../../static/helpers";
import useKeypress from "../../static/useKeypress";

const MessageFinalFinal = ({currentUser, messageUser, id, type}) => {
    const {baseURL} = useContext(BaseURLContext);
    // const [chat, setChat] = useState([]);


    const cookies = new Cookies()
    const cookie = cookies.get("hoe-jwt")
    const [connection, setConnection] = useState(null);
    const [message, setMessage] = useState('');
    const [to, setTo] = useState('');
    const [productId, setProductId] = useState(0);
    const [productType, setProductType] = useState('');
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [from, setFrom] = useState([])
    const [messages, setMessages] = useState([]);
    const [sender, setSender] = useState([])
    const [inputStatus, setInputStatus] = useState([])
    const [isAdmin, setIsAdmin] = useState(false)
    const size = useWindowSize();
    // const latestChat = useRef([React.createRef(), React.createRef()]);
    const latestChat = useRef(null)
    latestChat.current = messages;

    // const [timeLeft, setTimeLeft] = useState(null);

    // useEffect(() => {
    //     if(timeLeft===0){
    //         console.log("TIME LEFT IS 0");
    //         setTimeLeft(null)
    //     }
    //
    //     // exit early when we reach 0
    //     console.log("TIME LEFT IS ",timeLeft);
    //     if (!timeLeft) return;
    //
    //     // save intervalId to clear the interval when the
    //     // component re-renders
    //     const intervalId = setInterval(() => {
    //         console.log("TIME LEFT IS ",timeLeft);
    //         setTimeLeft(timeLeft - 1);
    //     }, 1000);
    //
    //     // clear interval on re-render to avoid memory leaks
    //     return () => clearInterval(intervalId);
    //     // add timeLeft as a dependency to re-rerun the effect
    //     // when we update it
    // }, [timeLeft]);


    useEffect(() => {
        if (messageUser != null) {
            if (type === 'estate') {
                setProductType(0)
            } else if (type === 'car') {
                setProductType(1)
            }
            if (id != null) {
                setProductId(id)
            }
            setSender(messageUser)
            handleUserChange(messageUser)
            readMessages(messageUser)
        }
        // console.log('messageUser',messageUser)
    }, [])

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        })
        // scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [messages]);
    // useEffect(() => {
    //     scrollToBottom()
    // }, [messages]);

    // let messageRef = useRef(messages.map(() => createRef()));
    //
    // useEffect(() => {
    //     messageRef.current[0].current.focus()
    // },[])

    // const executeScroll = async (index) => messageRef.current[index].scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'center',
    //     inline: 'center',
    // })

    const handleScroll = () => {
        // id={`${mess.from}-${mess.to}-${i}`}
        const element = document.getElementById(`${to}-${messages.length}`)
        // console.log('element', element)
        // // id={`${mess.productId}-${i}`}
        // console.log('current element id', currentUser)
        // console.log('from element id', from)
        // console.log('to element id', to)
        setTimeout(() => {
            window.scrollTo({
                behavior: element ? "smooth" : "auto",
                top: element ? element.offsetParent : 0
            });
        }, 100);
        // window.scrollTo({
        //     top: ref.offsetBottom,
        //     // bottom: 0,
        //     behavior: "smooth",
        // });
    };

    const onButtonClick = () => {
        // `current` points to the mounted text input element
        latestChat.current.focus();
    };

    const handleMessageChange = e => {
        setMessage(e.target.value);

    };

    const handleSend = () => {


        setMessages((elem) => [...elem, {
            from: currentUser,
            messageText: message,
            to: to,
            sentOn: moment(Date.now()).locale('tr').format("dddd, MMMM Do YYYY, h:mm:ss a"),
            productId: productId,
            productType: productType
        }])
    }

    const handleUserChange = (user, prodId, prodType) => {
        if (prodId != null) {
            setProductId(prodId)
        }
        if (prodType != null) {
            setProductType(prodType)
        }
        setTo(user);
        const ifExists = checkUserExist(user, users)
        if (!ifExists) {
            getFirstAdDetail()
        }
    };

    const onSubmit = () => {
        if (message == null) {
            console.log('pressing unnecessary')
        } else {
            handleSend()
            sendMessage();
            setMessage('')
        }
        // e.preventDefault();

        // setTimeLeft(3)

        // handleScroll()
        // if(scrollFinish){
        //     console.log('ref should be scrolled')
        // }
    }

    function isUserAdmin(username) {
        for (let i = 0; i < users?.length ; i++) {
            if(users[i].user === username){
                if(roles[i] === "Admin") {
                    setIsAdmin(true)
                }
            }
        }
    }


    useEffect(() => {
        const options = {
            accessTokenFactory: () => cookie,
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": process.env.REACT_APP_URL,
            // "Access-Control-Allow-Origin": "https://hepsiotoemlak.com:4242",
            "Access-Control-Allow-Credentials": true,
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,

        };
        const connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API + '/hubs/chat',
                // .withUrl('https://hepsiotoemlak.com:4242/hubs/chat',
                options
            )
            .withAutomaticReconnect()
            .build();
        setConnection(connection)
        connection.start()
            .then(result => {
                console.log('Connected!');
                connection.on('ReceiveMessage'
                    , message => {
                        const updatedChat = [...latestChat.current];
                        if (message?.messageText !== messages[messages?.length - 1].messageText) {
                            setMessages(elem => [...elem, message])
                        }

                        // console.log('current : ',latestChat.current)
                        // console.log('updatedChat : ',updatedChat)
                        // updatedChat.push(message);

                        // setMessages(updatedChat);
                        // console.log('updatedChat ',updatedChat)
                        // onButtonClick()
                    });
            })
            .catch(e => console.log('Connection failed: ', e));
        getMessages();
    }, []);

    const sendMessage = async () => {
        const chatMessage = {
            from: currentUser,
            messageText: message,
            to: to,
            productId: productId,
            productType: productType
            // productId: isNumeric(parseInt(prodId)) ? parseInt(prodId)  : parseInt(prodId),
            // productType: isNumeric(parseInt(prodType)) ? parseInt(prodType) : parseInt(prodType)
        };

        if (connection) {
            try {
                await connection.send('SendChatMessage', chatMessage);
                // setTimeLeft(3)


            } catch (e) {
                console.log(e);
            }
        } else {
            alert('No connection to server yet.');
        }
    }

    async function getMessages() {
        const response = await protectedEnpointGetRequest(
            `${baseURL}/Message/GetContactUsers`,
            cookie
        );
        if (response.status === 200 || response.status === 201) {
            // console.log(id, type, messageUser)
            // console.log('we here')
            if (messageUser != null && id != null && type != null) {
                checkUserExist(messageUser, response.data.lastMessage)
                setMessages(response.data.messages);
                setSender(messageUser)
                handleUserChange(messageUser)
                readMessages(messageUser)
            } else {
                setFrom(response.data.users)
                setMessages(response.data.messages);
                setUsers(response.data.lastMessage)
                setRoles(response.data.roles)

            }

            // console.log('response.data.lastMessage', response.data.lastMessage)
        } else {
            console.log("error on getContacts")
        }
    }

    function checkUserExist(selected, array) {
        // console.log('selectedLabel', selectedLabel)
        for (let i = 0; i < array.length; i++) {
            if (array[i].user === selected) {
                setUsers([])
                setUsers(elem => [...elem, array[i]])
                return true
                // setExistedLabel
            }
        }
        return false
    }

    async function getFirstAdDetail() {
        const response = await protectedEnpointGetRequestWithParams(
            `${baseURL}/Message/GetFirstTimeAd`,
            {
                id: id,
                type: type,
                owner: messageUser
            },
            cookie
        )

        if (response) {
            setUsers([])
            setUsers(elem => [...elem, response.data])
        }
    }

    async function readMessages(selected) {
        const model = {
            to: selected
        }
        const response = await protectedEnpointPostRequest(
            `${baseURL}/Message/ReadMessages`,
            model,
            cookie
        );
        if (response.status === 200 || response.status === 201) {
            console.log('successfully read');
            // executeScroll()
        } else {
            console.log("error on getContacts")
        }
    }

    // useEffect(() => {
    //     const statusInp = isUserAdmin()
    //     console.log('statusInp',statusInp)
    //     setIsAdmin(true)
    // },[])

    useKeypress('Enter', () => {
        onSubmit()
    });

    const rowStyle = {
        // maxHeight: '120px'
    }

    const columnStyle = {
        // maxHeight: '100px',
        // marginBottom: '1.5rem',
        float: 'left',
    }

    const disableInput = {
        pointerEvents: 'none',
        backgroundColor: 'lightgray'
    }

    const enableInput = {
        textIndent: '1rem'
    }


    return (
        <div>
            <div>
                <h3 className=" text-center">Mesajlar</h3>
                <div className="row">
                    <table className="table table-image">
                        <tbody>
                        {users && users.map((item, i) => {
                            return (
                                <tr style={rowStyle} key={i} className="d-flex m-3 row">
                                    <td style={columnStyle}
                                        className={`col-md-1 m-3  ${size?.width < 680 ? `` : ``}`}>
                                        <Link to={`/${item.adType === 0 ? `emlak` : `vasita`}/${item.productId}`}>
                                            {item?.productCover !== null ? (<img style={columnStyle}
                                                                                 src={item?.productCover}

                                                                                 alt="test"/>) :
                                                <div><img alt={`${i}`} src={NoImage}/></div>}
                                        </Link>
                                    </td>
                                    <td className={`col-md-8 row`}
                                        onClick={() => {
                                            setSender(item.user)
                                            handleUserChange(item.user, item.productId, item.adType)
                                            readMessages(item.user)
                                            isUserAdmin(item.user)
                                        }}>
                                        <div style={{display: 'flex'}} className={`col-md-12`}>
                                            <h5>{item.productName}</h5>
                                        </div>
                                        <div className={`col-md-12 row d-flex`}>
                                            <div className="col-md-3">
                                                <h6 style={{float: 'left'}}>{item.user}</h6>
                                            </div>
                                            <div className="col-md-7">
                                                <h6 style={{float: 'left'}}>
                                                    {moment(item.sentOn).locale('tr').format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                                </h6>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{item?.hasBeenRead}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`col-md-4`}>

                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                {users?.length === 1 &&
                    (<div className="container-message">
                        <div className="messaging">
                            <div className="inbox_msg">
                                <div className="inbox_people">
                                </div>
                                <div className="mesgs">
                                    <div
                                        // onSubmit={onSubmit}
                                        style={{width: '100%'}}>

                                        <div className="msg_history" style={{width: '100%'}}>
                                            <div className="incoming_msg" style={{width: '100%'}}>

                                                {messages && messages.map((mess, i) => {
                                                    return (
                                                        <div key={i}
                                                            // ref={messageRef.current[i]}
                                                             className={mess.from}>
                                                            {mess.from === sender && mess.to === currentUser
                                                                ? (<div className="received_msg">
                                                                    <div className="received_withd_msg">
                                                                        <p>{mess.messageText}</p>
                                                                        <span className="time_date">
                                                                    {moment(mess.createdOn).locale('tr').format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                                                </span>
                                                                    </div>
                                                                </div>)
                                                                :
                                                                mess.from === currentUser && mess.to === sender ? (
                                                                    <div className="outgoing_msg">
                                                                        <div className="sent_msg">
                                                                            <p
                                                                                id={`${to}-${messages.length}`}
                                                                            >{mess.messageText}</p>
                                                                            <span
                                                                                className="time_date">
                                                                        {moment(mess.createdOn).locale('tr').format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                                                    </span>{" "}
                                                                        </div>
                                                                    </div>) : (<span></span>)}
                                                            <div ref={messagesEndRef}/>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="type_msg">
                                            <div className="input_msg_write">
                                                <input
                                                    type="text"
                                                    // style={{textIndent: '1rem'}}
                                                    style={isAdmin ? disableInput : enableInput}
                                                    id="message"
                                                    name="message"
                                                    value={message}
                                                    onChange={handleMessageChange}

                                                />
                                                <div
                                                    onClick={() => {
                                                        onSubmit()
                                                    }}
                                                    className="msg_send_btn" style={{marginRight: '5px'}}>
                                                    <i className="fa fa-paper-plane-o" aria-hidden="true"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
            </div>
        </div>
    );
}

export default MessageFinalFinal;
