import axios from "axios";


export async function defaultGetRequestXXX(endpoint, token) {
    const headers = {
        tokenEE: token
    }
    const result = await axios
        .post(endpoint, headers
            // , config
        )
        .then((response) => {
            return response;
        })
    return result;
}

axios.defaults.headers.get['Access-Control-Allow-Origin'] = process.env.REACT_APP_API;
axios.defaults.headers.get['Access-Control-Allow-Credentials'] = true;

export async function defaultGetRequest(endpoint) {
    const result = await axios
        .get(endpoint
        )
        .then((response) => {
            return response;
        })

    return result;
}

export async function defaultGetRequestWithParams(endpoint, params) {
    const result = await axios
        .get(endpoint, params
        )
        .then((response) => {
            return response;
        })
    return result;
}

export function defaultGetRequestWithParamsSync(endpoint, params) {
    const result = axios
        .get(endpoint, params
        )
        .then((response) => {
            return response;
        })
    return result;
}

export async function loginUser(endpoint, credentials) {
    const result = await axios
        .post(endpoint, credentials)
        .then((response) => {
            return response;
        })
    return result;
}

export async function protectedEnpointGetRequest(endpoint, token) {
    const result = await axios
        .get(endpoint, {headers: {Authorization: `Bearer ${token}`}})
        .then((response) => {
            return response;
        })
    return result;
}

export async function protectedEnpointGetRequestWithParams(endpoint, params, token) {
    const result = await axios
        .get(endpoint, {params: params, headers: {Authorization: `Bearer ${token}`}})
        .then((response) => {
            return response;
        })
    return result;
}

export async function protectedEnpointPostRequest(endpoint, body, token) {
    const result = await axios
        .post(endpoint, body, {headers: {Authorization: `Bearer ${token}`}})
        .then((response) => {
            // console.log("repsonse" + response)
            return response;
        })
    return result;
}

export async function protectedEnpointDeleteRequest(endpoint, token) {
    const result = await axios
        .delete(endpoint, {headers: {Authorization: `Bearer ${token}`}})
        .then((response) => {
            return response;
        })
    return result;
}

export async function protectedEnpointPutRequest(endpoint, body, token) {
    const result = await axios
        .put(endpoint, body, {headers: {Authorization: `Bearer ${token}`}})
        .then((response) => {
            return response;
        })
         
    return result;
}

export function defaultGetRequestSync(endpoint) {
    const result = axios
        .get(endpoint)
        .then((response) => {
            return response;
        })
    return result;
}

export async function defaultPostRequest(endpoint, body) {
    const result = await axios
        .post(endpoint, body)
        .then((response) => {
            return response;
        })
    return result;
}

export async function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
    return null;
}

export async function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export async function ping(baseURL) {
    const result = await axios
        .get(`${baseURL}/ping`)
        .then((response) => {
            // console.log("auth is ok")
            return response
        })

    return result;
}


export async function defaultEndpointPostPhoto(endpoint, formData, token) {
    const result = await axios
        .post(endpoint, formData, {headers: {Authorization: `Bearer ${token}`}})
        .then((response) => {
            // console.log("uploadImage response " + response);
            return response;
        })
    return result;
}


export function isNullOrEmpty(value) {
    return !value;
}


export function getLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
        let latitude = position?.coords?.latitude;
        let longitude = position?.coords?.longitude;
        return {
            lat: latitude,
            lng: longitude
        };
    });
}


// const getBlob = async (fileUrl) => {
export async function getBlob(fileUrl,selectedId,fileName) {
    const arr = []
    // return await
    var x =  axios({
        method: 'get',
        url: fileUrl,
        responseType: 'blob'
    })
        .then(function (response) {
            var reader = new FileReader();
            reader.readAsDataURL(response.data);
            // reader.onloadend = callbackFunction(reader.result)
            reader.onloadend =  async () => {
                // base64data = reader.result;
                // setBase64string(base64data)
                // console.log(base64data)
                // return reader.result
                await fetch(reader.result)
                    .then(res => res.blob())
                    .then(blob => {
                        // console.log('test', test)
                        // let newFormData = new FormData();
                        // newFormData.append("productId", selectedId);
                        // newFormData.append("type", 0);
                        const file = new File([blob], `${fileName}`);

                        console.log('blob  ',blob)
                        console.log('file ',file)
                        // newFormData.append('image', file)
                        // file.append('image', file)
                        // file.append("type", 0);
                        // setExistingImages(elem => [...elem, {newFormData}])
                        // arr.push(newFormData)

                        arr.push(file)
                    })
            }
            // return reader.result
        })
    if (x) {
        return arr;
    }

}


export function convertToFormData(test,selectedId,fileName) {
    const arr = []
    fetch(test)
        .then(res => res.blob())
        .then(blob => {
            // console.log('test', test)
            let newFormData = new FormData();
            newFormData.append("productId", selectedId);
            newFormData.append("type", 0);
            const file = new File([blob], `${fileName}`);

            console.log('blob  ',blob)
            console.log('file ',file)
            newFormData.append('image', file)
            // file.append('image', file)
            // file.append("type", 0);
            // setExistingImages(elem => [...elem, {newFormData}])
            // arr.push(newFormData)
            console.log('teetete ',newFormData)
            arr.push(file)
        })

    console.log('arr    ',arr)
    return arr;
}
